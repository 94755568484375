import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import type {Item} from './types'
import {ItemType, Model} from '@equistamp/types'

type ConnectionItemProps = {
  item: Item
  itemType: ItemType
  priceHint?: string
  alignPrice?: 'left' | 'center' | 'right'
}
export const ConnectionItem = ({
  item,
  itemType,
  priceHint,
  alignPrice = 'left',
}: ConnectionItemProps) => (
  <Grid container>
    <Grid item xs={9}>
      <Typography align="left" sx={{fontWeight: 600}}>
        {item.name}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Typography align={alignPrice} sx={{fontWeight: 600}}>
        ${item.price?.toFixed(2)}
      </Typography>
    </Grid>

    <Grid item xs={9}>
      <Typography component="p" align="left" variant="caption" sx={{color: 'secondary.subText'}}>
        {itemType === 'model' ? (item as Model).publisher : ''}
      </Typography>
    </Grid>
    <Grid item xs={3}>
      {priceHint && (
        <Typography
          align={alignPrice}
          component="p"
          variant="caption"
          sx={{color: 'secondary.subText'}}
        >
          {priceHint}
        </Typography>
      )}
    </Grid>
  </Grid>
)
