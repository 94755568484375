import {useMemo} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import CssBaseline from '@mui/material/CssBaseline'
import '@mui/material/styles'
import {PageViews} from '@piwikpro/react-piwik-pro'
import Routes from './routes'
import {CachedObjectsProvider} from './hooks/useCachedObjects'
import {UserProvider} from './hooks/useUser'
import {themeColours} from './palette'

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const mode = prefersDarkMode ? 'dark' : 'light'
  PageViews.trackPageView('Page views')

  const theme = useMemo(() => {
    return createTheme({
      typography: {
        fontFamily: 'Montserrat, Arial',
      },
      palette: {mode, ...themeColours[mode]},
    })
  }, [mode])

  return (
    <CachedObjectsProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Routes />
          </LocalizationProvider>
        </ThemeProvider>
      </UserProvider>
    </CachedObjectsProvider>
  )
}

export default App
