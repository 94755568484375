import React, {ReactNode} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from '../components/Page'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {Action, Hero} from 'components/Landing'
import ContactForm from 'components/forms/Contact'
import Score from 'components/Score'

const CenteredText = ({text, sx, variant}: {text: string; variant?: string; sx?: any}) => (
  <Box display="flex" justifyContent="center" alignItems="center" sx={sx}>
    <Typography variant={variant as any} sx={{textAlign: 'center'}}>
      {text}
    </Typography>
  </Box>
)

type PersonProps = {
  name: string
  pic: string
  role?: string
}
const Person = ({name, pic, role}: PersonProps) => {
  return (
    <Stack direction="column" spacing={0.5} justifyContent="center" alignItems="center">
      <Box>
        <Avatar alt={name} src={pic} sx={{height: 100, width: 100, mb: 2}} />
      </Box>
      <CenteredText text={name} variant="h5" sx={{width: 210}} />
      {role && <CenteredText text={role} />}
    </Stack>
  )
}

const About: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  type TextedIconProps = {
    title: string
    img?: string
    children: ReactNode
  }
  const TextedIcon = ({title, img, children}: TextedIconProps) => (
    <Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      sx={{pt: 2, maxWidth: isMobile ? 250 : 560}}
    >
      <Action
        title={title}
        img={img}
        imgWidth={160}
        titleFont={{fontWeight: 700, fontSize: 25, color: 'primary.main'}}
      />
      <Box>{children}</Box>
    </Stack>
  )

  return (
    <Page title="About EquiStamp" fullWidth>
      <Hero
        text="A Credit Bureau for AI Models"
        textSize={45}
        img={
          <Score
            score={Math.random() * 100}
            maxScore={100}
            size={400}
            sx={{
              fontWeight: 700,
              backgroundColor: 'secondary.main',
              color: 'primary.main',
            }}
          />
        }
      />

      <Stack
        direction="column"
        spacing={12}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{mt: 8}}
      >
        <TextedIcon title="Our Mission" img="/imgs/world.png">
          <Typography align="center">
            EquiStamp's mission is to provide a platform that allows users to evaluate the
            capabilities of their AI models. We aim to provide a stamp of approval that lets
            companies and individuals know how much they can trust AI models.
          </Typography>
        </TextedIcon>

        <TextedIcon title="Our Methodology" img="/imgs/clipboard.png">
          <Typography align="center">
            We use various human benchmarks to test AI models. These benchmarks are designed to
            evaluate the intelligence and morality of the models. Our evaluation process is
            transparent and rigorous, ensuring that the results are reliable and meaningful.
          </Typography>
        </TextedIcon>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{pt: 3, pb: 1}}
        >
          <Typography variant="h5" sx={{fontWeight: 700, fontSize: 25, color: 'primary.main'}}>
            Team
          </Typography>

          <Typography gutterBottom sx={{maxWidth: isMobile ? 250 : 600, textAlign: 'center'}}>
            EquiStamp is made up of a team of dedicated and experienced professionals in the field
            of AI. We are committed to advancing the field of AI and helping users understand the
            capabilities of their AI models.
          </Typography>

          <Stack
            direction={{sm: 'column', md: 'row'}}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{p: 10}}
          >
            <Person name="Chris Canal" pic="/imgs/chris.png" role="CEO" />
            <Person name="Daniel O'Connell" pic="/imgs/daniel.png" role="CTO" />
            <Person name="Rob Miles" pic="/imgs/rob.png" role="AI Advisor" />
            <Person name="Dozens of contributors" pic="/imgs/group.png" />
          </Stack>

          <TextedIcon title="We're here to help!">
            <ContactForm />
          </TextedIcon>
        </Stack>
      </Stack>
    </Page>
  )
}

export default About
