import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useUser from '../../hooks/useUser'
import Page from '../../components/Page'
import Path from '../../routeLinks'

const LoginChecker: React.FC = () => {
  const [state, setState] = useState('initial')
  const [error, setError] = useState<string>()
  const {getExternalUser} = useUser()

  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const checkUser = async () => {
      setState('loading')
      const res = await getExternalUser({
        external_auth: 'github',
        code: searchParams.get('code') || '',
      })
      if (res) {
        setState('error')
        setError(res)
      } else {
        window.location.href = searchParams.get('redirect') || Path.home()
      }
    }
    if (state === 'initial') {
      checkUser()
    }
  }, [state, getExternalUser, location])

  return (
    <Page title="Checking credentials..." showTitle>
      <Stack justifyContent="center" alignItems="center" spacing={1}>
        <Typography sx={{pt: 3}}>
          {error || 'You will be redirected as soon as you are authenticated'}
        </Typography>
      </Stack>
    </Page>
  )
}

export default LoginChecker
