import {SVGProps} from 'react'
const SvgFilterAlt2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#788492">
      <path d="M12 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11A.5.5 0 0 1 12 2ZM8 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11A.5.5 0 0 1 8 2ZM4.5 2.5a.5.5 0 0 0-1 0v11a.5.5 0 0 0 1 0v-11Z" />
    </g>
    <path
      d="M4 10.5A1.25 1.25 0 1 0 4 8a1.25 1.25 0 0 0 0 2.5ZM12 12a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM8 6.5A1.25 1.25 0 1 0 8 4a1.25 1.25 0 0 0 0 2.5Z"
      fill="#fff"
      stroke="#788492"
    />
  </svg>
)
export default SvgFilterAlt2
