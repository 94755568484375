import {useState} from 'react'
import Container from '@mui/material/Container'
import Modal from '@mui/material/Modal'
import {StepProps, OldWizard as Wizard} from 'components/forms/Wizard'
import {
  BasicSettings,
  EndpointBody,
  EndpointHeaders,
  EndpointResponse,
  EndpointTest,
  EndpointUrl,
  Limitations,
} from 'components/models/configure'
import {Model as ModelType} from '@equistamp/types'
import {parseEndpointCode} from 'components/dsl'

type ModelWizardProps = {
  initial?: ModelType
  onSave: (e: ModelType) => Promise<string | null>
  basic?: boolean
  limitations?: boolean
  endpointConfig?: boolean
}
const ModelWizard = ({initial, onSave, basic, limitations, endpointConfig}: ModelWizardProps) => {
  const [model, setModel] = useState(initial || ({modalities: []} as unknown as ModelType))
  const [endpoint, setEndpoint] = useState({
    response_code: initial?.response_code,
    ...parseEndpointCode(initial?.request_code),
  })

  const onChange = (field: string, val: any) => setModel((e) => ({...e, [field]: val}))
  const onEndpointUpdate = (field: string, val: any) => setEndpoint((e) => ({...e, [field]: val}))

  const onSubmit = async () => onSave(model)

  const props = {model, endpoint, onChange, onEndpointUpdate}
  const steps = [
    basic && {
      label: 'Basic settings',
      Step: BasicSettings,
      extraProps: props,
    },
    limitations && {
      label: 'Model Limitaions',
      Step: Limitations,
      extraProps: props,
    },
    endpointConfig && {
      label: 'Endpoint URL',
      Step: EndpointUrl,
      extraProps: props,
    },
    endpointConfig && {
      label: 'Endpoint Headers',
      Step: EndpointHeaders,
      extraProps: props,
    },
    endpointConfig && {
      label: 'Endpoint Body',
      Step: EndpointBody,
      extraProps: props,
    },
    endpointConfig && {
      label: 'Endpoint Test',
      Step: EndpointTest,
      extraProps: props,
    },
    endpointConfig && {
      label: 'Endpoint Response',
      Step: EndpointResponse,
      extraProps: props,
    },
  ].filter(Boolean) as StepProps<any>[]
  if (steps.length) {
    return <Wizard title="Create Model" sx={{mt: 4}} onFinish={onSubmit} steps={steps} />
  }
  return null
}

const modalStyle = {
  mt: 10,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: 'calc(100% - 80px)', // Subtract some pixels for margin
}

type ModelWizardModalProps = {
  show?: boolean
  onCancel: () => void
} & ModelWizardProps
export const ModelWizardModal = (props: ModelWizardModalProps) => {
  return (
    <Modal open={props.show || false} onClose={props.onCancel}>
      <Container sx={{flex: 1, ...modalStyle}}>
        <ModelWizard {...props} />
      </Container>
    </Modal>
  )
}

export default ModelWizard
