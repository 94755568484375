import {ReactElement, useState, useCallback} from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import StepperStep from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stack from '@mui/material/Stack'
import {RoundedButton} from '../../components/forms/inputs'

type NextHandler = () => boolean | Promise<boolean>
export type StepRendererProps = {
  enableNext: (enabled: boolean) => void
  setNextHandler: (handler: NextHandler) => void
  index: number
}

type Step<T> = (props: StepRendererProps & T) => ReactElement

export type StepProps<U> = {
  label: string
  nextLabel?: string
  Step: Step<U>
  extraProps?: U
}

type OldWizardProps = {
  title: string
  steps: StepProps<any>[]
  sx?: {[k: string]: any}
  onCancel?: () => void
  onFinish: () => Promise<string | null>
}
export const OldWizard = ({steps, sx, onCancel, onFinish}: OldWizardProps) => {
  const [index, setIndex] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [activeStep, setActiveStepIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [nextEnabled, setNextEnabled] = useState(true)
  const [nextHandler, setNextHandler] = useState<NextHandler>()

  const totalSteps = () => steps.length
  const isLastStep = () => activeStep === totalSteps() - 1
  const setActiveStep = (step: number) => {
    setIndex((k) => k + 1)
    setActiveStepIndex(step)
    setNextHandler(undefined)
    setNextEnabled(true)
    setLoading(false)
    setError(null)
  }

  const checkCanContinue = useCallback(async () => {
    setLoading(true)
    try {
      return await Promise.resolve(!nextHandler || nextHandler())
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
    return false
  }, [nextHandler])

  const handleNext = async () => {
    if (loading || !(await checkCanContinue())) {
      // can't continue, so do nothing
    } else if (!isLastStep()) {
      setActiveStep(activeStep + 1)
    } else if (onFinish) {
      setLoading(true)
      setError(await onFinish())
      setLoading(false)
    }
  }
  const updateHandler = useCallback(
    (handler: NextHandler) => setNextHandler(() => handler),
    [setNextHandler]
  )

  const handleBack = () => setActiveStep(activeStep - 1)
  const handleStep = (step: number) => () => setActiveStep(step)

  const {Step, extraProps} = steps[activeStep]

  return (
    <Stack sx={sx}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map(({label}, index) => (
          <StepperStep key={label} completed={false}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </StepperStep>
        ))}
      </Stepper>

      <Box sx={{pt: 5, pr: 10, pl: 10}}>
        <Step
          index={index}
          enableNext={setNextEnabled}
          setNextHandler={updateHandler}
          {...extraProps}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </Box>

      <Stack direction="row" sx={{pt: 5}}>
        <RoundedButton disabled={activeStep === 0} onClick={handleBack} sx={{mr: 1}} label="Back" />
        <Box sx={{flex: '1 1 auto'}} />
        {onCancel && <RoundedButton label="Close" onClick={onCancel} sx={{mr: 1}} />}
        <RoundedButton
          label={isLastStep() ? 'Save' : 'Next'}
          onClick={handleNext}
          sx={{mr: 1}}
          loading={loading}
          disabled={!nextEnabled}
        />
      </Stack>
    </Stack>
  )
}

export default OldWizard
