import {useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useUser from 'hooks/useUser'
import {CheckboxGroup, HelpTooltip} from 'components/filters/widgets'
import {ColouredTypography} from 'components/text'
import {formatNum, smartRound} from 'components/formatters'
import {ConfirmationDialog} from 'components/dialogs'
import {Eval} from '@equistamp/types'
import {Permission, PRIVATE_MODEL} from 'permissions'
import Path from 'routeLinks'

export const EvalStats = ({evaluation, hasLink}: {evaluation?: Eval; hasLink?: boolean}) => {
  if (!evaluation) return <Typography component="span">-</Typography>
  const {evaluation_name, datetime_completed, datetime_started, evaluation_id, score} = evaluation

  const date = datetime_completed || datetime_started
  return (
    <Stack spacing={2} component="span">
      <Typography component="span">
        {evaluation_id && hasLink ? (
          <NavLink to={Path.evaluations.show(evaluation_id)}>{evaluation_name}</NavLink>
        ) : (
          evaluation_name
        )}
        {' - ' + score.toFixed(2)}
      </Typography>
      {date && <Typography component="span">{'(' + date.slice(0, 10) + ')'}</Typography>}
    </Stack>
  )
}

type NumberSelectorProps = {
  title: string
  value: number | undefined
  help?: string
  min?: number
  max?: number
  onChange: any
  direction?: 'row' | 'column' | {[k: string]: number}
  sx?: {[k: string]: any}
}
export const NumberSelector = ({
  title,
  value,
  onChange,
  help,
  min,
  max,
  direction,
  sx,
}: NumberSelectorProps) => {
  const [val, setVal] = useState(value?.toString())

  const scaler = (val: number) => Math.pow(10, val)
  const formatter = (val: number) => formatNum(val)
  const handleChange = (val: string) => {
    setVal(val)
    const parsed = parseFloat(val)
    if (!Number.isNaN(parsed)) onChange(parsed)
  }
  const textValue = (val: number | string | undefined) => {
    if (val === undefined) return ''
    if (typeof val !== 'string') return smartRound(val).toString()

    const parsedVal = parseFloat(val)
    if (parsedVal === 0) return val
    return smartRound(parsedVal).toString()
  }

  return (
    <Stack direction="column" sx={{width: 250, ...sx}}>
      <ColouredTypography align="center" variant="overline">
        {title} {help && <HelpTooltip help={help} />}
      </ColouredTypography>

      <Stack direction={(direction as any) || 'column'} spacing={2}>
        <Slider
          getAriaLabel={() => title}
          step={0.01}
          min={min ?? 0}
          max={max ?? 15}
          scale={scaler}
          value={val ? Math.log10(parseFloat(val)) : 0}
          onChange={(_, val: number | number[]) => handleChange(scaler(val as number).toString())}
          valueLabelDisplay="auto"
          valueLabelFormat={formatter}
          getAriaValueText={formatter}
        />
        <TextField
          defaultValue={textValue(val)}
          type="number"
          onChange={(e) => handleChange(e.target.value)}
          inputProps={{
            min: Math.pow(10, min || 0),
            max: Math.pow(10, max || 15),
            step: (min ? Math.pow(10, min) : 1) / 1000,
          }}
        />
      </Stack>
    </Stack>
  )
}

export const PUBLIC_VISIBLE = 'publicVisible'
export const PUBLIC_USAGE = 'publicUsage'

const getVisibilityOptions = (hasPermission: (p: Permission) => boolean) => {
  const options: {[k: string]: string} = {}
  if (hasPermission(PRIVATE_MODEL)) {
    options[PUBLIC_VISIBLE] = 'Visible to everyone'
  }
  options[PUBLIC_USAGE] = 'Usable by anyone'
  return options
}

export const VisibilityOptions = ({
  initial,
  onChange,
}: {
  initial: string[]
  onChange: (v: any) => void
}) => {
  const [selected, setSelected] = useState(initial)
  const [confirmedPublic, setConfirmedPublic] = useState<boolean | undefined>(
    initial.includes(PUBLIC_USAGE) || undefined
  )
  const {hasPermission} = useUser()

  useEffect(() => {
    if (!selected.includes(PUBLIC_USAGE)) {
      setConfirmedPublic(undefined)
    } else if (confirmedPublic === undefined) {
      setConfirmedPublic(false)
    }
  }, [selected, confirmedPublic])

  const change = (vals: string[]) => {
    setSelected(vals)
    onChange(vals)
  }

  return (
    <>
      <ConfirmationDialog
        label="Are you sure you want to make this model publicly useable?"
        onSelect={(isSelected: boolean) => {
          if (isSelected) {
            setConfirmedPublic(true)
          } else {
            change(selected.filter((v) => v !== PUBLIC_USAGE))
          }
        }}
        open={confirmedPublic === false}
      >
        Making your model publicaly available means that anyone who can create an evaluation can
        connect your model to their evaluation. If their evaluation is run often and/or contains a
        lot of tasks, this can quickly result in a lot of requests to your endpoint, which in turn
        can result in large costs for you. Are you sure you want to make this publicly available?
      </ConfirmationDialog>
      <CheckboxGroup
        label="Visibility"
        allOptions={getVisibilityOptions(hasPermission)}
        info={{
          [PUBLIC_VISIBLE]:
            'When set, this model will appear for everyone on the lists of models. This setting' +
            " doesn't change how people can interact with your model, only whether it can be" +
            ' viewed by them.',
          [PUBLIC_USAGE]:
            'When set, anyone can connect evaluations to your model. If you set this, make ' +
            ' sure your infrastructure can handle potentially large numbers of requests. This' +
            ' can also result in large costs for you, so be sure you really want this.',
        }}
        selected={selected}
        onChange={change}
        sx={{m: 0}}
      />
    </>
  )
}
