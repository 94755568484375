import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {makeApi, ServerError} from '@equistamp/api'
import useUser from 'hooks/useUser'
import type {Alert, Alert as AlertType} from '@equistamp/types'
import Path from 'routeLinks'
import AlertWizard from 'components/alerts/AlertWizard'
import {EMAIL} from '@equistamp/constants'
import useAlerts from 'hooks/useAlerts'
import {InfoDialog} from 'components/dialogs'
import {RoundedButton} from 'components/forms/inputs'

const CreateAlert: React.FC = () => {
  const {user} = useUser()
  const [showInfo, setShowInfo] = useState(false)
  const {refresh} = useAlerts()
  const navigate = useNavigate()

  const onClose = () => navigate(Path.alerts.all())

  const saveAlert = async (a: AlertType) => {
    try {
      await makeApi().alerts.create({...a, owner: user || undefined})
      refresh({}, true)
      setShowInfo(true)
    } catch (e) {
      const error = e instanceof ServerError ? e.error.toString() : `${e}`
      return 'Error while creating alert: ' + error
    }
    return null
  }

  return !user ? null : (
    <>
      <AlertWizard
        title="Create an alert"
        onCancel={() => navigate(Path.alerts.all())}
        onSave={saveAlert}
        initial={
          {
            subscriptions: [{method: EMAIL, destination: user?.email_address}],
            trigger_cooldown: 'always',
          } as Alert
        }
        basic
        triggers
        notifications
      />
      <InfoDialog label="Your alert has been created" open={showInfo} onClose={onClose}>
        <RoundedButton label="Ok" onClick={onClose} />
      </InfoDialog>
    </>
  )
}

export default CreateAlert
