import React from 'react'
import {useRouteError} from 'react-router-dom'
import Page from '../components/Page'

const ERROR_MSGS = {
  404: 'This page cannot be found',
}

const ErrorPage: React.FC = () => {
  const error = useRouteError() as any
  const errorMsg = error.statusText || error.data
  const errorType = ERROR_MSGS[error.status as keyof typeof ERROR_MSGS]

  console.log(error)

  return (
    <Page title={errorMsg} showTitle>
      <div>
        <h1>{errorType}</h1>
      </div>
    </Page>
  )
}

export default ErrorPage
