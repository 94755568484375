import React, {useState} from 'react'
import {Snackbar, Alert} from '@mui/material'

export const ErrorAlert = ({error}: {error?: string}) => {
  const [open, setOpen] = useState(true)

  const handleClose = (event: any) => {
    if (event === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
        A network error occurred. Please try again later.
      </Alert>
    </Snackbar>
  )
}

export default ErrorAlert
