import {useState, useEffect} from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {taskTypes} from 'components/filters/constants'
import {MInput, ButtonGroup} from 'components/forms/inputs'
import {HelpTooltip} from 'components/filters/widgets'
import {MULTIPLE_CHOICE_QUESTION} from '@equistamp/constants'
import type {EvaluationCheck, Evaluation as EvaluationType} from '@equistamp/types'
import type {EvaluationEditProps, EvaluationStageProps} from './types'
import CSVFetcher from './CSVFetcher'
import ConfigureColumns from './ColumnsMapper'
import CheckEvaluation from './CheckEvaluation'
import References from './References'

const SelectTaskType = ({evaluation, onChange}: EvaluationEditProps) => {
  const current = evaluation?.default_task_type || 'mixed'
  const onSelect = (taskType?: string | string[]) =>
    onChange(
      'default_task_type',
      taskTypes[taskType as keyof typeof taskTypes] ? taskType : undefined
    )
  const items = Object.entries(taskTypes)
    .map(([taskType, label]) => ({label, id: taskType}))
    .sort((a, b) => (a.id === MULTIPLE_CHOICE_QUESTION ? -1 : 1))
  return (
    <Stack spacing={1}>
      <Typography>Choose a format for your test questions</Typography>
      <ButtonGroup
        items={[...items, {label: 'Mixed', id: 'mixed'}]}
        selected={current}
        onSelect={onSelect}
      />
    </Stack>
  )
}

const numberOfQuestionsHelp = `
Here you can specify how many tasks should be evaluated for each evaluation run. If you select
"Automatic", we will use the number of uploaded tasks to estimate the minimum number of tasks
needed to be evaluated in order to be able to be statistically confident in the resulting score.
`
const NumberOfQuestions = ({evaluation, onChange}: EvaluationEditProps) => {
  const [selected, setSelected] = useState<string | undefined>('auto')
  const chooseType = (t?: string | string[]) => {
    setSelected(t as string)
    if (t === 'auto') {
      onChange('min_questions_to_complete', undefined)
    }
  }
  const setNum = (v: string) => {
    const val = parseInt(v, 10)
    onChange('min_questions_to_complete', isNaN(val) ? undefined : val)
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2}>
        <Typography>Number of questions</Typography>
        <HelpTooltip help={numberOfQuestionsHelp} />
        <Chip label="Advanced" />
      </Stack>
      <ButtonGroup
        perRow={2}
        selected={selected}
        onSelect={chooseType}
        items={[
          {
            id: 'auto',
            label: 'Automatic',
            subText:
              "We'll calculate how many questions are needed to achieve a 95% confidence level",
          },
          {
            id: 'manual',
            label: 'Choose',
            subText: 'Specify exactly how many tasks should be run in each evaluation',
          },
        ]}
      />
      {selected === 'manual' && (
        <MInput
          label="How many questions should be run?"
          value={evaluation?.min_questions_to_complete?.toString() || ''}
          setter={setNum}
        />
      )}
    </Stack>
  )
}

type TaskUploaderProps = EvaluationStageProps & {
  onSave: (e: EvaluationType) => Promise<string | null>
}
const TasksUploader = ({
  evaluation,
  onChange,
  onSave,
  enableNext,
  setNextHandler,
}: TaskUploaderProps) => {
  const [checkData, setCheckData] = useState(false)
  const [invalidReferences, setInvalidReferences] = useState({} as {[k: string]: string[]})
  useEffect(() => {
    setNextHandler(async () => {
      setCheckData(true)
      return false
    })
  }, [setNextHandler])
  useEffect(
    () => enableNext(!!evaluation?.columns_mapping),
    [evaluation?.columns_mapping, enableNext]
  )

  const onCheck = (e: EvaluationCheck) => {
    onChange('references', {
      ...Object.keys(e.invalid_references || {}).reduce((a, k) => ({...a, [k]: {}}), {}),
      ...(evaluation.references || {}),
    })
    setInvalidReferences(e.invalid_references)
  }

  return (
    <Stack spacing={4}>
      <SelectTaskType evaluation={evaluation} onChange={onChange} />
      <CSVFetcher evaluation={evaluation} onChange={onChange} />
      {evaluation?.columns_mapping && (
        <ConfigureColumns evaluation={evaluation} onChange={onChange} />
      )}
      {evaluation?.references && (
        <References
          evaluation={evaluation}
          onChange={onChange}
          invalidReferences={invalidReferences}
        />
      )}
      {evaluation?.columns_mapping && (
        <NumberOfQuestions evaluation={evaluation} onChange={onChange} />
      )}
      {checkData && (
        <CheckEvaluation
          evaluation={evaluation}
          onChange={onChange}
          onCheck={onCheck}
          onSave={onSave}
          onClose={() => setCheckData(false)}
        />
      )}
    </Stack>
  )
}

export default TasksUploader
