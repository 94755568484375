import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import {styled} from '@mui/material/styles'
import {titleStyle} from '../components/text'

export const HeaderCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    ...titleStyle,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.dark,
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))

export const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.body}`]: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))
