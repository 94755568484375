import React, {useEffect} from 'react'
import useModels from 'hooks/useModels'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import type {Evaluation as EvaluationType} from '@equistamp/types'
import {ModelFilters} from 'components/filters'
import RunEvalsWizard from 'components/connections/EvalRunWizard'

const RenderEvaluationConnections = ({
  item: evaluation,
  setItem,
}: RendererProps<EvaluationType>) => {
  const {loading, refresh, getItems} = useModels()
  useEffect(
    () =>
      refresh({
        filters: {
          public_usable: true,
          evaluation: evaluation.id,
          fields: [
            'name',
            'description',
            'publisher',
            'next_run',
            'score',
            'architecture',
            'modalities',
            'num_parameters',
            'price',
            'statistics',
          ],
        },
      }),
    [evaluation.id, refresh]
  )
  return (
    <RunEvalsWizard
      title={`Run ${evaluation.name}`}
      itemType="evaluation"
      item={evaluation}
      itemsGetter={getItems}
      loading={loading}
      searchOptions={{
        searchLabel: 'filter models',
        sortLabel: 'Sort by:',
        sortOptions: {
          scoreDesc: 'Top average score',
          scoreAsc: 'Bottom average score',
          priceDesc: 'Most expensive',
          priceAsc: 'Least expensive',
          nameAsc: 'Model name (asc)',
          nameDesc: 'Model name (desc)',
          publisherAsc: 'Publisher (asc)',
          publisherDesc: 'Publisher (desc)',
        },
        filtersElem: ModelFilters,
      }}
    />
  )
}

const EvaluationConnections: React.FC = () => (
  <ItemLoader itemName="evaluation" renderer={RenderEvaluationConnections} />
)
export default EvaluationConnections
