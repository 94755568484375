import {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid'
import {Input, SelectElem} from 'components/forms/inputs'
import {architectures, modalities as allModalities} from 'components/filters/constants'
import {CheckboxGroup} from 'components/filters/widgets'
import {Model as ModelType} from '@equistamp/types'
import {NumberSelector, VisibilityOptions, PUBLIC_VISIBLE, PUBLIC_USAGE} from '../widgets'
import type {ModelStageProps} from './types'

const BasicSettings = ({model, onChange, setNextHandler, index}: ModelStageProps) => {
  const [validate, setValidate] = useState(false)
  const [name, setName] = useState(model.name || '')
  const [desc, setDesc] = useState(model.description || '')
  const [publisher, setPublisher] = useState(model.publisher || '')
  const [architecture, setArchitecture] = useState(model.architecture || '')
  const [modalities, setModalities] = useState(model.modalities || [])
  const [visibility, setVisibilities] = useState(
    [model.public_usable ? PUBLIC_USAGE : '', model.public ? PUBLIC_VISIBLE : ''].filter(Boolean)
  )
  const [numParameters, setNumParameters] = useState(model.num_parameters)

  useEffect(() => {
    const checkCorrect = () => {
      const valid = !!(model.name && model.description)
      setValidate(!valid)
      return valid
    }

    setNextHandler(checkCorrect)
  }, [index, model, setNextHandler])

  const setVal = (field: keyof ModelType, updater: (v: any) => void) => (val: any) => {
    updater(val)
    onChange(field, val)
  }

  const setVisibility = (visible: string[]) => {
    setVisibilities(visible)
    onChange('public', visible.includes(PUBLIC_VISIBLE))
    onChange('public_usable', visible.includes(PUBLIC_USAGE))
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{mb: 2}}>
        <Input
          label="Name"
          value={name}
          setter={setVal('name', setName)}
          validate={validate}
          validator={(v) => !v && 'Name is required'}
          sx={{width: '100%'}}
        />
      </Grid>
      <Grid item xs={12} sx={{mb: 2}}>
        <Input
          label="Description"
          value={desc}
          validate={validate}
          setter={setVal('description', setDesc)}
          validator={(v) => !v && 'Please provide a description'}
          multiline
          sx={{width: '100%'}}
        />
      </Grid>
      <Grid item xs={12} sx={{mb: 3}}>
        <Input
          label="Publisher"
          value={publisher}
          validate={validate}
          setter={setVal('publisher', setPublisher)}
          sx={{width: '100%'}}
        />
      </Grid>

      <Grid container direction="column">
        <Grid item sm={3}>
          <SelectElem
            label="Architecture"
            values={architectures}
            value={architecture}
            onChange={setVal('architecture', setArchitecture)}
            validate={validate}
            sx={{width: '100%'}}
          />
        </Grid>
        <Grid item sm={8}>
          <NumberSelector
            min={0}
            title="Number of parameters"
            value={numParameters}
            onChange={setVal('num_parameters', setNumParameters)}
            direction="column"
            sx={{width: '100%'}}
          />
        </Grid>

        <Grid item sm={12}></Grid>

        <Grid item sm={12}>
          <CheckboxGroup
            label="Modalities"
            allOptions={allModalities}
            selected={modalities}
            onChange={setVal('modalities', setModalities)}
            sx={{m: 0}}
          />
        </Grid>
        <Grid item sm={12}>
          <VisibilityOptions initial={visibility} onChange={setVisibility} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BasicSettings
