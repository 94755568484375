import {ButtonGroup} from 'components/forms/inputs'
import {runCadences} from 'components/filters/constants'
import {capitalize} from 'components/formatters'
import type {RunEvalsStageProps} from './types'

const ChooseCadence = ({runConfig, updateRunConfig}: RunEvalsStageProps) => {
  return (
    <ButtonGroup
      perRow={3}
      selected={runConfig.cadence}
      onSelect={(selected?: string | string[]) => updateRunConfig('cadence', selected)}
      items={runCadences.map((c) => ({id: c, label: capitalize(c)}))}
    />
  )
}

export default ChooseCadence
