import Stack from '@mui/material/Stack'
import {Input} from 'components/forms/inputs'
import type {DSLCode} from '@equistamp/types'

const Editor = ({code, onChange}: {code: DSLCode; onChange: (val: string) => void}) => {
  const handleInput = (val: string) => {
    onChange(val)
  }

  return (
    <Stack>
      <Input multiline value={code} rows="20" sx={{width: '600px'}} setter={handleInput} />
    </Stack>
  )
}
export default Editor
