import Stack from '@mui/material/Stack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {IconButton} from 'components/forms/inputs'
import {SvgIconComponent} from '@mui/icons-material'
import {Column} from './columns'

const ColumnSort = ({onSort, sortDir}: Column) => {
  const UpDown = (props: any) => (
    <Stack justifyContent="center" sx={{height: 20}}>
      <KeyboardArrowUpIcon
        sx={{
          position: 'relative',
          top: 8,
          color: sortDir === 'Asc' ? 'primary.main' : 'secondary.subText',
        }}
      />
      <KeyboardArrowDownIcon
        sx={{
          position: 'relative',
          bottom: 8,
          color: sortDir === 'Desc' ? 'primary.main' : 'secondary.subText',
        }}
      />
    </Stack>
  )

  if (!onSort) return null
  return (
    <IconButton
      selected={!!sortDir}
      Icon={UpDown as SvgIconComponent}
      aria-label="sort"
      onClick={onSort}
    />
  )
}

export default ColumnSort
