import {useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import {Input} from 'components/forms/inputs'
import {parseJSON} from 'components/formatters'
import type {Reference as ReferenceType} from '@equistamp/types'
import type {EvaluationEditProps} from './types'

type ReferenceProps = {
  name: string
  reference: ReferenceType
  errors?: string[]
  onChange: (ref: ReferenceType) => void
}
const Reference = ({name, reference, onChange, errors}: ReferenceProps) => {
  const [displayName, setDisplayName] = useState(reference.name)
  const [description, setDescription] = useState(reference.description)
  const [schema, setSchema] = useState(reference.schema ? JSON.stringify(reference.schema) : '{}')
  const valid = !errors || errors.length === 0

  const changeVal = (key: string, setter: (val: string) => void) => (val: string) => {
    setter(val)
    const value = key === 'schema' ? parseJSON(val) : val
    onChange({name: displayName, description, schema: parseJSON(schema), [key]: value})
  }
  const commonProps = {sx: {mt: 2, width: '100%'}}
  return (
    <Accordion defaultExpanded={!valid}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {valid ? <DoneIcon color="success" /> : <CloseIcon color="error" />} {displayName || name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Input
          label="Title"
          value={displayName}
          setter={changeVal('name', setDisplayName)}
          {...commonProps}
        />
        <Input
          label="Description"
          value={description}
          setter={changeVal('description', setDescription)}
          {...commonProps}
        />
        <Input
          label="Schema"
          value={schema}
          setter={changeVal('schema', setSchema)}
          {...commonProps}
        />
        {errors?.map((error) => (
          <Alert icon={<CloseIcon />} severity="error">
            {error}
          </Alert>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

type ReferencesProps = EvaluationEditProps & {
  invalidReferences: {[k: string]: string[]}
}
const References = ({evaluation, onChange, invalidReferences}: ReferencesProps) => {
  const updateRefs = (name: string) => (ref: ReferenceType) => {
    onChange('references', {...evaluation.references, [name]: ref})
  }
  return (
    <Stack>
      <Typography variant="h3">References</Typography>
      {evaluation.references &&
        Object.entries(evaluation.references).map(([name, reference]) => (
          <Reference
            key={name}
            name={name}
            reference={reference}
            onChange={updateRefs(name)}
            errors={invalidReferences && invalidReferences[name]}
          />
        ))}
    </Stack>
  )
}

export default References
