import {useState, useEffect} from 'react'
import {parseEDNString, toEDNStringFromSimpleObject} from 'edn-data'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Input} from '../../../components/forms/inputs'
import {isInvalidJSON} from '../../../components/forms/validators'
import type {ModelStageProps} from './types'
import {CodeBlock} from '../../text'
import {parseJSON} from '../../formatters'

const parserPath = (code?: string) => {
  if (!code) return null
  const edn = parseEDNString(code, {mapAs: 'object', keywordAs: 'string'}) as any
  if (Array.isArray(edn?.list)) {
    return edn.list[2]
  }
  return null
}

const EndpointResponse = ({
  endpoint,
  onChange,
  onEndpointUpdate,
  setNextHandler,
  index,
}: ModelStageProps) => {
  const [validate, setValidate] = useState(false)
  const [response, setResponse] = useState()
  const [path, setPath] = useState([] as string[])
  const [inputVal, setInputVal] = useState(() => {
    const path = parserPath(endpoint?.parser)
    return path ? JSON.stringify(path) : '[]'
  })

  useEffect(() => {
    const checkCorrect = () => {
      const valid = !isInvalidJSON(inputVal)
      setValidate(!valid)
      return valid
    }
    setNextHandler(checkCorrect)
  }, [index, inputVal, setNextHandler])

  const handleChange = (val: string) => {
    setInputVal(val)
    const path = parseJSON(val)
    if (path) {
      // Change the first item to a keyword, as that is what gets returns
      const ednPath = toEDNStringFromSimpleObject(path).replace(
        /^\["(status|headers|json|text)"/,
        '[:$1'
      )
      const parser = `(get-in response ${ednPath})`
      onEndpointUpdate('parser', parser)
      onChange('response_code', parser)
    }
  }

  useEffect(() => {
    if (endpoint?.response) {
      setResponse(parseEDNString(endpoint?.response, {mapAs: 'object', keywordAs: 'string'}) as any)
    }
  }, [endpoint?.response, setResponse])

  useEffect(() => {
    setPath(parserPath(endpoint?.parser))
  }, [endpoint?.parser, setPath])

  const current = !path
    ? response
    : path.reduce((cur, item) => {
        try {
          return cur[item as keyof typeof cur] as any
        } catch (e) {
          return undefined
        }
      }, response || {})

  return (
    <Box>
      <Typography component="div">
        Here you can specify how the response should be parsed. It should be provided as a JSON list
        specifing which field to extract
      </Typography>
      <Input
        label="Response parser"
        value={inputVal}
        setter={handleChange}
        validate={validate}
        validator={isInvalidJSON}
        sx={{mt: 5, mb: 5, width: '100%'}}
      />
      <Typography component="div">This is what would be used with the current selector:</Typography>
      <CodeBlock>{current && JSON.stringify(current, null, 2)}</CodeBlock>
    </Box>
  )
}

export default EndpointResponse
