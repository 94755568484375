import {useState} from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Score from 'components/Score'
import {RoundedButton} from 'components/forms/inputs'
import {InfoItem} from 'components/text'
import CallToAction from 'components/CallToAction'
import {ModelWizardModal} from './ModelWizard'
import {Model as ModelType, Eval} from '@equistamp/types'
import Path from 'routeLinks'

const EvalInfo = ({title, score, evaluation_name, evaluation_id}: Eval & {title: string}) => (
  <Stack direction="column" spacing={4}>
    <Score score={score} maxScore={100} size={180} />
    <InfoItem
      align="center"
      title={title}
      value={evaluation_name}
      to={Path.evaluations.show(evaluation_id)}
    />
  </Stack>
)

type EditableSummaryProps = {
  model: ModelType
  updateModel?: (model: ModelType) => void
}
export const ModelSummary = ({model, updateModel}: EditableSummaryProps) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [editEndpoint, setEditEndpoint] = useState(false)
  const saveModel = async (m: ModelType) => {
    if (updateModel) {
      await updateModel(m)
    }
    setShowEditModal(false)
    setEditEndpoint(false)
    return null
  }

  return (
    <Container sx={{mt: 3}}>
      <Grid container spacing={2} sx={{mb: 3}}>
        <Grid item xs={12} md={6}>
          <InfoItem underline markdown title="Description:" value={model.description} />
          <Stack direction="row" spacing={3} sx={{mt: 4}}>
            <CallToAction
              title="Find out if this model is right for you"
              actionLabel="Run evaluation"
              action={Path.models.connections(model.id)}
            />
          </Stack>
        </Grid>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={4}>
          <Stack direction="column" spacing={10} sx={{height: '100%'}}>
            <Stack direction="row" spacing={2}>
              {updateModel && (
                <RoundedButton label="Edit Model" onClick={() => setShowEditModal(true)} />
              )}
              {updateModel && (
                <RoundedButton label="Edit Endpoint" onClick={() => setEditEndpoint(true)} />
              )}
            </Stack>
            <Stack direction="row" spacing={8}>
              {model.best_evaluation_session && (
                <EvalInfo title="Best Evaluation" {...model.best_evaluation_session} />
              )}
              {model.worst_evaluation_session && (
                <EvalInfo title="Worst Evaluation" {...model.worst_evaluation_session} />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <ModelWizardModal
        basic
        limitations
        show={showEditModal}
        onSave={saveModel}
        onCancel={() => setShowEditModal(false)}
        initial={model}
      />
      <ModelWizardModal
        endpointConfig
        show={editEndpoint}
        onSave={saveModel}
        onCancel={() => setEditEndpoint(false)}
        initial={model}
      />
    </Container>
  )
}

export default ModelSummary
