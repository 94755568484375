import React, {useState} from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import type {User} from '@equistamp/types'
import {Input} from './inputs'
import {isInvalidPassword} from './validators'
import {Form} from 'components/forms'
import {RoundedButton} from 'components/forms/inputs'

interface ChangePasswordProps {
  action?: string
  onSubmit: (password: string) => Promise<string | null>
}

const ChangePassword: React.FC<ChangePasswordProps> = ({action, onSubmit}) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalid, setInvalid] = useState(false)

  const isInvalid = () =>
    !!(isInvalidPassword(password, confirmPassword) || isInvalidPassword(confirmPassword, password))
  const submitter = async () => onSubmit(password)

  return (
    <Form
      actionLabel="Change password"
      isInvalid={isInvalid}
      setInvalid={setInvalid}
      submitter={submitter}
      action={action}
      rounded
    >
      <Input
        label="Password:"
        value={password}
        setter={setPassword}
        validate={invalid}
        validator={(p: string | undefined) => isInvalidPassword(p, confirmPassword)}
        type="password"
      />
      <Input
        label="Confirm password:"
        value={confirmPassword}
        setter={setConfirmPassword}
        validate={invalid}
        validator={(p: string | undefined) => isInvalidPassword(p, password)}
        type="password"
      />
    </Form>
  )
}

type ChangePasswordState = 'hidden' | 'form' | 'changing' | 'changed'
export const ChangePasswordWidget = ({user, sx}: {user: User; sx: {[k: string]: any}}) => {
  const [state, setState] = useState<ChangePasswordState>('hidden')

  const changePassword = async (password: string) => {
    setState('changing')
    const res = await makeApi().users.update({id: user.id, password} as unknown as User)
    if (res !== 'User updated') {
      setState('form')
      return 'could not change password: ' + res
    }
    setState('changed')
    return 'changed'
  }

  const Confirmed = () => (
    <Stack alignItems="center" spacing={3}>
      <Typography align="center">Password changed</Typography>
      <RoundedButton
        label="close"
        onClick={() => {
          setState('hidden')
        }}
      />
    </Stack>
  )

  return (
    <>
      <Dialog
        onClose={() => setState('hidden')}
        open={state !== 'hidden'}
        maxWidth="md"
        fullWidth={state === 'form'}
        sx={{'& .MuiPaper-root': {borderRadius: 5}}}
      >
        <DialogTitle>Change your password</DialogTitle>
        <DialogContent>
          {state === 'form' && <ChangePassword onSubmit={changePassword} />}
          {state === 'changing' && (
            <Box sx={{display: 'flex'}}>
              <CircularProgress />
            </Box>
          )}
          {state === 'changed' && <Confirmed />}
        </DialogContent>
      </Dialog>
      <RoundedButton label="Change password" sx={sx} onClick={() => setState('form')} />
    </>
  )
}

export default ChangePassword
