import React, {useEffect} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi, ServerError} from '@equistamp/api'
import useEvaluationSessions from 'hooks/useEvaluationSessions'
import useUser from 'hooks/useUser'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import ManageUsers from 'components/ManageUsers'
import Page from 'components/Page'
import EvalRunsWidget from 'components/EvalRunsWidget'
import LatestRuns from 'components/evaluationSessions/EvalRuns'
import ModelSummary from 'components/models/Summary'
import ModelMetaData from 'components/models/MetaData'
import CallToAction from 'components/CallToAction'
import {EvaluationFilters} from 'components/filters'
import type {Model as ModelType, User} from '@equistamp/types'
import Path from 'routeLinks'

const ShowModel = ({item: model, setItem: setModel}: RendererProps<ModelType>) => {
  const {canEdit} = useUser()
  const {items: evalSessions, refresh} = useEvaluationSessions()

  useEffect(() => {
    if (model) {
      refresh({
        filters: {
          models: [model],
          latest: true,
          fields: [
            'price',
            'evaluation_id',
            'evaluation_name',
            'datetime_completed',
            'datetime_started',
            'score',
          ],
        },
      })
    }
  }, [model, refresh])

  const saveModel = async (m: ModelType) => {
    if ((await makeApi().models.update(m)) === 'Model updated') {
      setModel({...model, ...m})
    }
  }

  const addUsers = async (users: string[]) => {
    try {
      await makeApi().models.update({id: model.id, name: model.name, add_admins: users})
    } catch (e) {
      return e instanceof ServerError ? e.error.toString() : `${e}`
    }
  }
  const removeUser = async (user: User) => {
    if (!user.email_address) return
    try {
      await makeApi().models.update({
        id: model.id,
        name: model.name,
        delete_admins: [user.email_address],
      })
    } catch (e) {
      return e instanceof ServerError ? e.error.toString() : `${e}`
    }
  }

  return (
    <Page
      title={`${model.publisher}${model.publisher ? ' | ' : ''}${model.name}`}
      showTitle
      breadcrumbs={[{title: 'models', link: Path.models.all()}, {title: model.name}]}
    >
      <Stack spacing={5} sx={{mt: 10, mb: 5, pr: 4}}>
        <ModelSummary model={model} updateModel={canEdit(model) ? saveModel : undefined} />
        <ModelMetaData model={model} />

        <Typography variant="h3" sx={{color: 'primary.main'}}>
          Latest Runs
        </Typography>
        <LatestRuns
          evals={evalSessions}
          columns={['evaluation', 'date_completed', 'score', 'price']}
        />

        <CallToAction
          title="Compare This Model’s Performance"
          actionLabel="Connect Now"
          action={Path.models.create()}
        >
          <Typography align="center" sx={{color: 'white', fontWeight: 700}}>
            Connect your own model to see how it stacks up against
            {model.publisher ? ` ${model.publisher}'s ` : ' '}
            {model.name}
          </Typography>
        </CallToAction>

        <EvalRunsWidget
          models={[model]}
          searchOptions={{
            filtersElem: EvaluationFilters,
            searchLabel: 'filter evaluations',
            sortLabel: 'Sort by:',
          }}
        />

        {model.admin_users && (
          <ManageUsers users={model.admin_users} addUsers={addUsers} removeUser={removeUser} />
        )}
      </Stack>
    </Page>
  )
}

const ModelView: React.FC = () => <ItemLoader itemName="model" renderer={ShowModel} />
export default ModelView
