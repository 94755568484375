import {ReactNode, useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export type DocItem = {
  title: string
  contents: string | ReactNode
}

type DocumentationSectionProps = {
  title: string
  children: ReactNode | string
  expanded?: boolean
  defaultExpanded?: boolean
  onSelect: () => void
}
export const DocsSection = ({
  title,
  children,
  defaultExpanded,
  expanded,
  onSelect,
}: DocumentationSectionProps) => (
  <Stack spacing={2}>
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      elevation={0}
      onChange={onSelect}
      sx={{
        color: 'secondary.contrastText',
        backgroundColor: 'inherit',
        '.MuiAccordionDetails-root, .MuiAccordionSummary-root': {
          p: 0,
        },
      }}
    >
      <AccordionSummary>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{color: 'secondary.subText'}}>
        {typeof children === 'string' ? <Typography>{children}</Typography> : children}
      </AccordionDetails>
    </Accordion>
    <Divider sx={{m: '0 16px', borderColor: 'secondary.divider'}} />
  </Stack>
)

const DocContents = ({contents}: {contents: string | string[] | ReactNode}) => {
  if (!Array.isArray(contents)) return <>{contents}</>
  return (
    <Stack spacing={2}>
      {contents.map((text, i) => (
        <Typography key={i}>{text}</Typography>
      ))}
    </Stack>
  )
}

export const DocsPanel = ({items}: {items: DocItem[]}) => {
  const [selected, setSelected] = useState<number>()

  return (
    <Stack spacing={2}>
      {items.map(({title, contents}, i) => (
        <DocsSection
          key={title}
          title={title}
          expanded={i === selected}
          onSelect={() => setSelected((current) => (current === i ? undefined : i))}
        >
          <DocContents contents={contents} />
        </DocsSection>
      ))}
    </Stack>
  )
}
