import React from 'react'
import Page from 'components/Page'
import Compare from 'components/Compare'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {InfoBox} from 'components/text'
import useEvaluations from 'hooks/useEvaluations'
import useModels from 'hooks/useModels'

const CompareLLMs: React.FC = () => {
  const {allModels} = useModels()
  const {evaluations} = useEvaluations()

  return (
    <Page title="EquiStamp" fullWidth>
      <Stack sx={{p: 15, pb: 1}}>
        <Stack
          direction={{sm: 'column', md: 'row'}}
          justifyContent="space-between"
          alignItems="topr"
        >
          <Typography variant="h2" sx={{pt: 3, fontWeight: 600}}>
            LLM Compare
          </Typography>
          <InfoBox title="Third-party, trustworthy data" sx={{maxWidth: 400}}>
            Our mission is to provide the most accurate LLM evaluations possible. We achieve this
            through independent testing and a steadfast commitment to objectivity, free from any
            commercial bias.
          </InfoBox>
        </Stack>
      </Stack>
      <Compare models={allModels} evaluations={evaluations} />
    </Page>
  )
}

export default CompareLLMs
