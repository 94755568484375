import {SVGProps} from 'react'
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M.594 7.708a.5.5 0 0 0 0 .584 11.763 11.763 0 0 0 1.83 1.983C4.036 11.657 5.907 12.5 8 12.5c2.093 0 3.964-.842 5.576-2.226a11.76 11.76 0 0 0 1.83-1.982.5.5 0 0 0 0-.584 11.758 11.758 0 0 0-1.83-1.982C11.964 4.342 10.093 3.5 8 3.5c-2.093 0-3.964.842-5.576 2.226a11.761 11.761 0 0 0-1.83 1.982Z"
      stroke="#788492"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={8} cy={8} r={2.5} stroke="#788492" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-1 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="#788492"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.188 7.416a1 1 0 0 0 0 1.168 12.26 12.26 0 0 0 1.91 2.07C3.78 12.098 5.763 13 8 13s4.22-.902 5.902-2.346a12.263 12.263 0 0 0 1.91-2.07 1 1 0 0 0 0-1.168 12.262 12.262 0 0 0-1.91-2.07C12.22 3.902 10.237 3 8 3s-4.22.902-5.902 2.346a12.26 12.26 0 0 0-1.91 2.07Zm14.504.988c.116-.144.219-.28.308-.404a11.26 11.26 0 0 0-1.75-1.895C11.71 4.781 9.95 4 8 4c-1.95 0-3.709.781-5.25 2.105A11.26 11.26 0 0 0 1 8a11.26 11.26 0 0 0 1.75 1.895C4.29 11.219 6.05 12 8 12c1.95 0 3.709-.781 5.25-2.105a11.26 11.26 0 0 0 1.442-1.49Z"
      fill="#788492"
    />
  </svg>
)
export default SvgEye
