import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import {styled} from '@mui/material/styles'

const CircleChip = styled(Chip)({
  borderRadius: '50%',
  width: 70,
  height: 70,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.3rem',
})

const CircleContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 10,
})

const mapValueToColour = (value: number) => {
  // Clamp value between 0 and 100
  const clampedValue = Math.max(0, Math.min(value, 100))

  // Convert the clampedValue to a percentage of the red to green range
  const hue = clampedValue.toString(10)

  // Return a CSS HSL color string. Saturation is set to 100%, Lightness is 50%.
  return `hsl(${hue}, 100%, 50%)`
}

type ScoreProps = {
  score?: number
  maxScore: number
  size?: number
  sx?: {[k: string]: any}
}
function Score({score, maxScore, size, sx}: ScoreProps) {
  const normalizedScore = score ? (score / maxScore) * 100 : 0
  const circleSize = size || 90

  return (
    <CircleContainer sx={{height: circleSize, width: circleSize}}>
      <CircularProgress
        variant="determinate"
        value={normalizedScore}
        thickness={3}
        size={circleSize}
        style={{
          position: 'absolute',
          zIndex: 0,
          transform: 'rotate(90deg)',
        }}
        sx={{
          '& .MuiCircularProgress-circle': {
            stroke: mapValueToColour(normalizedScore),
            strokeLinecap: 'round',
          },
        }}
      />
      <CircleChip
        label={score === undefined ? 'NA' : normalizedScore.toFixed(1)}
        color="primary"
        sx={{
          width: circleSize * 0.75,
          height: circleSize * 0.75,
          fontSize: circleSize / 4.9,
          fontWeight: 700,
          ...sx,
        }}
      />
    </CircleContainer>
  )
}

export default Score
