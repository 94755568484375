import {formatNum, formatPrice, formatDuration} from 'components/formatters'
import {FormatterType, ChangeVal} from './types'
import {Direction, FilterConfig, FilterKey, FiltersTypes} from '@equistamp/types'

const objectsFormatter = (label: string): FormatterType => ({
  label,
  formatter: (o: any) => o?.name,
  comparer: (a: any, b: any) => a.id === b.id,
  isArray: true,
})

const dateFormatter = (label: string): FormatterType => ({
  label,
  formatter: (v: string) => v.slice(0, 10),
})

const formatters = {
  // Evaluations
  maxNumParameters: {
    label: 'max parameters',
  },
  minNumTasks: {
    label: 'min number of tasks',
  },
  maxNumTasks: {
    label: 'max number of tasks',
  },
  startDate: dateFormatter('run after'),
  endDate: dateFormatter('run before'),

  // Alerts
  evaluations: objectsFormatter('evaluations'),
  models: objectsFormatter('models'),
  alerts: objectsFormatter('alerts'),
  startCreationDate: dateFormatter('created after'),
  endCreationDate: dateFormatter('created before'),
  startPredictedTriggerDate: dateFormatter('next after'),
  endPredictedTriggerDate: dateFormatter('next before'),
  triggerCadence: {
    label: 'cadence',
  },
  minThreshold: {
    label: 'min threshold',
  },
  maxThreshold: {
    label: 'max threshold',
  },

  // Models
  minScore: {
    label: 'min score',
  },
  maxScore: {
    label: 'max score',
  },
  minNumParameters: {
    label: 'min parameters',
  },

  maxLatency: {
    label: 'max latency',
    formatter: (v: number) => formatDuration(v / 1000),
  },
  minLatency: {
    label: 'min latency',
    formatter: (v: number) => formatDuration(v / 1000),
  },
  minInputTokensPrice: {
    label: 'min input tokens price',
    formatter: formatPrice,
  },
  maxInputTokensPrice: {
    label: 'max input tokens price',
    formatter: formatPrice,
  },
  minOutputTokensPrice: {
    label: 'min output tokens price',
    formatter: formatPrice,
  },
  maxOutputTokensPrice: {
    label: 'max output tokens price',
    formatter: formatPrice,
  },
  minInstanceHourPrice: {
    label: 'min instance hour price',
    formatter: formatPrice,
  },
  maxInstanceHourPrice: {
    label: 'max instance hour price',
    formatter: formatPrice,
  },
} as {
  [key: string]: FormatterType
}

export const formatFilter = (field: string, val: any) => {
  const {label, formatter} = formatters[field as FilterKey] || {}
  return [label || field, formatter ? formatter(val) : formatNum(val)]
}

export const removeFilter = (filters: FiltersTypes | undefined, field: FilterKey, val: any) => {
  const current = filters && filters[field]
  const comparer = formatters[field]?.comparer || ((a: any, b: any) => a === b)

  if (Array.isArray(current) || formatters[field]?.isArray) {
    return {
      ...filters,
      [field]: (current as any[])?.filter((i) => !comparer(i, val)),
    }
  } else if (filters !== undefined) {
    delete filters[field]
    return filters
  }
  return filters
}

export const addFilter = (filters: FiltersTypes | undefined, field: FilterKey, val: any) => {
  const current = filters && filters[field]
  if (!(Array.isArray(current) || formatters[field]?.isArray)) {
    return {...filters, [field]: val}
  } else if (!current || !(current as any[]).includes(val)) {
    return {...filters, [field]: [...((current as any[]) || []), val]}
  }
  return filters
}

export const handleRangeChange =
  (
    currentFilters: FiltersTypes | undefined,
    field: string,
    updater: (field: FilterKey, val: any) => void
  ) =>
  (vals: number[]) => {
    const filters = currentFilters || {}
    const [min, max] = vals
    const minField = ('min' + field) as FilterKey
    const maxField = ('max' + field) as FilterKey

    if (filters[minField] !== min) {
      updater(minField, min)
    } else if (filters[maxField] !== max) {
      updater(maxField, max)
    }
  }

type FiltersProps = {
  config: FilterConfig
  onChange: ChangeVal
}
export const filterChangeHandlers = ({config, onChange}: FiltersProps) => {
  const handleChange = (field: FilterKey) => (val: any) =>
    onChange('filters', {...config.filters, [field]: val})

  const updateField = (field: FilterKey, val: any) =>
    onChange('filters', addFilter(config?.filters, field, val))

  return {handleChange, updateField}
}

export const makeSorter = (key: string, direction: Direction) => (items: any[]) => {
  const bigger = direction === 'Asc' ? 1 : -1
  return items.sort((a, b) => {
    const [aVal, bVal] = [a[key], b[key]]
    if ([undefined, null, ''].includes(aVal)) return 1
    if ([undefined, null, ''].includes(bVal)) return -1
    if (typeof aVal === 'string') {
      return aVal.toLowerCase() > bVal.toLowerCase() ? bigger : -bigger
    }
    return aVal > bVal ? bigger : -bigger
  })
}

export const sortItems = (items: any[], {sort}: FilterConfig): any[] => {
  if (sort?.sorter) return sort.sorter(items)
  if (sort?.key) {
    return makeSorter(sort.key, sort?.direction)(items)
  }
  return items
}
