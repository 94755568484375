import {useState, useEffect} from 'react'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {makeApi, ServerError} from '@equistamp/api'
import {HeaderCell} from 'components/tables'
import {taskTypes} from 'components/filters/constants'
import type {
  Evaluation as EvaluationType,
  EvalCheckErrorItem,
  TaskErrors,
  EvaluationCheck,
} from '@equistamp/types'
import type {EvaluationEditProps} from './types'
import {modalStyle} from 'components/dialogs'

const ListErrors = ({errors}: {errors: TaskErrors[]}) => {
  const errorType = (errors: undefined | EvalCheckErrorItem[], errorLevel: string) =>
    errors
      ?.filter(({level}) => level === errorLevel)
      .map(({message, key}) => (key ? `${message}: ${key}` : message))
      .join(', ')

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Tasks with issues">
        <TableHead>
          <TableRow>
            <HeaderCell sx={{width: 20}}>Row</HeaderCell>
            <HeaderCell align="center" sx={{pl: 0}}>
              Task type
            </HeaderCell>
            <HeaderCell align="center" sx={{pl: 0}}>
              Errors
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {errors.map(({task_num, task_type, errors}, i) => (
            <TableRow key={i} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th">{task_num + 1}</TableCell>
              <TableCell align="left">{taskTypes[task_type as keyof typeof taskTypes]}</TableCell>
              <TableCell align="left">{errorType(errors, 'error')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type CheckState = 'checking' | 'ok' | 'error'
type CheckProps = {
  label: string
  state: CheckState
  errors?: TaskErrors[]
}
const Check = ({label, state, errors}: CheckProps) => {
  if (state === 'checking') {
    return <Typography sx={{color: 'grey'}}>{label}...</Typography>
  } else if (state === 'ok') {
    return (
      <ListItem disablePadding>
        <ListItemIcon>
          <CheckCircleIcon color="success" />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    )
  } else {
    return (
      <>
        <ListItem disablePadding>
          <ListItemIcon>
            <CancelIcon color="error" />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
        {errors && <ListErrors errors={errors} />}
      </>
    )
  }
}

const replaceLast = (
  items: CheckProps[],
  label: string,
  state?: CheckState,
  errors?: TaskErrors[]
) => {
  const rest = items.slice(0, items.length - 1)
  const last = items[items.length - 1]
  return [...rest, {...last, label, state: state || last.state, errors}]
}

type CheckEvaluationProps = EvaluationEditProps & {
  onClose: () => void
  onSave: (e: EvaluationType) => Promise<string | null>
  onCheck: (e: EvaluationCheck) => void
}
const CheckEvaluation = ({evaluation, onClose, onCheck, onSave}: CheckEvaluationProps) => {
  const [checks, setChecks] = useState([] as CheckProps[])

  useEffect(() => {
    var log = [] as CheckProps[]
    const update = (vals: CheckProps[]) => {
      log = vals
      setChecks(vals)
      return vals
    }
    const addItem = (label: string) => update([...log, {label, state: 'checking'}])
    const setOk = (label: string) => update(replaceLast(log, label, 'ok'))
    const setError = (error: string, errors?: TaskErrors[]) =>
      update(replaceLast(log, error, 'error', errors))

    const doStuff = async () => {
      addItem('Validating data')

      if (!evaluation.csv_url) {
        setError('No CSV URL found')
        return
      }
      setOk('Validated data')
      addItem('Sending CSV file...')

      try {
        const checkRes = await makeApi().evaluations.checkEvaluationUrl(evaluation)
        setOk('Sent CSV file')
        addItem('Validating CSV file...')

        onCheck(checkRes)

        if (checkRes.errors.length > 0) {
          setError('Errors found in CSV file', checkRes.errors)
          return
        }
        setOk('Validated CSV file')
        addItem('Saving evaluation...')
        const res = await onSave(evaluation)

        if (res) {
          setError(res)
        } else {
          setOk('Saved')
        }
      } catch (e) {
        const error = e instanceof ServerError ? e.error.toString() : `${e}`
        setError(`Could not validate CSV file: ${error}`)
        return
      }
    }
    if (checks.length === 0) {
      doStuff()
    }
  }, [checks, onCheck, evaluation, onSave])

  return (
    <Modal open={true} onClose={onClose} sx={{...modalStyle, backgroundColor: 'initial'}}>
      <Card sx={{m: '10%', p: 6}}>
        <Stack spacing={4}>
          {checks.length && checks[checks.length - 1].state === 'checking' && (
            <CircularProgress size={60} thickness={4.5} />
          )}
          <Typography variant="h4">Checking your evaluation</Typography>
          <List>
            {checks.map((c) => (
              <Check key={c.label} {...c} />
            ))}
          </List>
        </Stack>
      </Card>
    </Modal>
  )
}

export default CheckEvaluation
