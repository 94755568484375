import {ReactNode, useEffect} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Container from '@mui/material/Container'

type Breadcrumb = {
  title: string
  link?: string
}
type PageProps = {
  children: ReactNode
  title?: string
  showTitle?: boolean
  fullWidth?: boolean
  breadcrumbs?: Breadcrumb[]
  sx?: {[k: string]: any}
}

const Page = ({title, children, showTitle, fullWidth, sx, breadcrumbs}: PageProps) => {
  const location = useLocation()

  useEffect(() => {
    // Allow links to scroll to a specific element
    if (location.hash) {
      const id = location.hash.replace('#', '')
      const element = document.getElementById(id) || document.getElementsByName(id)[0]

      // Scroll to the element if it exists
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [location])

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Header title={title} />
      {showTitle && (
        <AppBar color="primary" enableColorOnDark position="relative" elevation={0}>
          <Toolbar>
            <Container sx={{p: 2.5}}>
              <Typography variant="h4" sx={{fontWeight: 700}}>
                {title}
              </Typography>
              {breadcrumbs && (
                <Breadcrumbs>
                  {breadcrumbs.map(({title, link}) =>
                    link ? (
                      <Link
                        key={title}
                        component={NavLink}
                        underline="hover"
                        color="inherit"
                        to={link}
                      >
                        {title}
                      </Link>
                    ) : (
                      <Typography key={title} color="text.primary">
                        {title}
                      </Typography>
                    )
                  )}
                </Breadcrumbs>
              )}
            </Container>
          </Toolbar>
        </AppBar>
      )}
      {fullWidth ? (
        <Box component="main" sx={{flex: 1}}>
          {children}
        </Box>
      ) : (
        <Container component="main" sx={{flex: 1}}>
          {children}
        </Container>
      )}
      <Footer />
    </Box>
  )
}

export default Page
