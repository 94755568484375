import {useEffect, useState} from 'react'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useRuns from 'hooks/useRuns'
import {formatPercentage, formatDatetime} from 'components/formatters'
import {ExternalA} from 'components/text'
import Path from 'routeLinks'
import type {Eval, Evaluation, Model, Score} from '@equistamp/types'
import {NavButton} from 'components/forms/inputs'
import Chart, {Dataset} from 'components/Chart'
import {filterModels} from 'components/filters/Serializer'

type PastRunsProps = {
  evaluation: Evaluation
  model: Model & {
    scores: {[k: string]: Score}
  }
}

const RunHistory = ({evaluation, model}: PastRunsProps) => {
  const {getRuns} = useRuns()
  const [runs, setRuns] = useState<Eval[] | undefined>()

  useEffect(() => {
    const fetch = async () => {
      setRuns(await getRuns(evaluation, model))
    }
    fetch()
  }, [setRuns, evaluation, model, getRuns])

  if (!runs)
    return (
      <Stack direction="row" justifyContent="space-around">
        <CircularProgress />
      </Stack>
    )

  const dataset = {
    key: 'Score',
    data: runs
      .filter((v) => v.datetime_completed && v.score !== undefined)
      .reduce(
        (acc, v) => ({...acc, [v.datetime_completed?.slice(0, 10) || '']: v.score?.toFixed(2)}),
        {}
      ),
  } as Dataset
  return (
    <Stack spacing={1}>
      <Chart datasets={[dataset]} />
      {runs?.map((r) => (
        <Stack id={r.id} direction="row" justifyContent="space-between">
          <Typography>{formatDatetime(r.datetime_completed)}</Typography>
          <Typography>{formatPercentage(r.score)}</Typography>
        </Stack>
      ))}
    </Stack>
  )
}

const PastRuns = ({evaluation, model}: PastRunsProps) => {
  const runsUrl = Path.evaluations.runs(evaluation.id) + filterModels([model])
  const evaluation_session_id = model.scores[evaluation.id]?.evaluation_session_id
  return (
    <Card
      sx={{
        position: 'absolute',
        width: '256px',
        overflow: 'visible',
        zIndex: 200,
        height: 'fit-content',
        p: 2,
        top: 0,
        left: -245,
      }}
    >
      <Stack spacing={2}>
        <RunHistory evaluation={evaluation} model={model} />
        <Typography>
          View the most recent report to see what questions were incorrect, or check the full list
          of <ExternalA to={runsUrl} text="previous runs." />
        </Typography>
        {evaluation_session_id && (
          <NavButton
            label="View full report"
            to={Path.evaluationSessions.show(evaluation_session_id)}
            sx={{mb: 0}}
          />
        )}
      </Stack>
    </Card>
  )
}

export default PastRuns
