import {MInput} from 'components/forms/inputs'

type BodyInputProps = {
  body?: string
  onChange: (h: string) => void
}
const BodyInput = ({body, onChange}: BodyInputProps) => {
  return <MInput label="Request body" value={body?.toString() || ''} setter={onChange} />
}

export default BodyInput
