import {useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CheckCircle from '@mui/icons-material/CheckCircle'
import {makeApi} from '@equistamp/api'
import useUser from 'hooks/useUser'
import {ActionButton, NavButton, RoundedButton} from 'components/forms/inputs'
import {ConfirmationDialog, InfoDialog} from 'components/dialogs'
import Path from 'routeLinks'
import type {RoundedButtonProps} from 'components/filters/types'
import type {Alert as AlertType} from '@equistamp/types'

type SubscriptionButtonProps = RoundedButtonProps
const SubscribeButton = ({onClick, ...props}: SubscriptionButtonProps) => (
  <ActionButton label="Subscribe" action={() => onClick && onClick()} {...props} />
)

const UnSubscribeButton = ({sx, onClick, ...props}: SubscriptionButtonProps) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleConfirm = async (confirmed?: boolean) => {
    if (confirmed && onClick) {
      await onClick()
    }
    setShowDialog(false)
  }

  return (
    <>
      <ConfirmationDialog label="Unsubscribe" open={showDialog} onSelect={handleConfirm}>
        Are you sure you want to unsubscribe?
      </ConfirmationDialog>
      <RoundedButton
        tooltip="Unsubscribe"
        label={
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
            sx={{opacity: props.disabled ? 0.5 : 1}}
          >
            <CheckCircle color="primary" sx={{width: 12}} />
            <Typography color="primary.main">Subscribed</Typography>
          </Stack>
        }
        variant="outlined"
        onClick={() => setShowDialog(true)}
        sx={{color: 'primary.main', ...sx}}
        {...props}
      />
    </>
  )
}

const Subscribe = ({alert, sx}: {alert: AlertType; sx?: {[k: string]: any}}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [subscribed, setSubscribed] = useState<boolean>(alert?.subscribed || false)
  const {user} = useUser()

  const toggleSubscription = async () => {
    const res = await makeApi().alerts.setSubscription(alert.id, !subscribed)
    if (res === 'Alert updated') {
      setSubscribed(!subscribed)
      setShowDialog(true)
    }
  }

  return (
    <>
      <InfoDialog
        open={showDialog}
        label={`You ${subscribed ? 'are subscribed to' : 'have unsubscribed from'} alerts for ${
          alert.name
        }`}
        onClose={() => setShowDialog(false)}
      >
        {subscribed
          ? 'You will receive alerts to your registered email'
          : `You will no longer receive alerts for ${alert.name}`}
      </InfoDialog>
      {user && subscribed && (
        <UnSubscribeButton
          onClick={toggleSubscription}
          sx={sx}
          disabled={false && user?.id === alert.owner?.id}
        />
      )}
      {user && !subscribed && <SubscribeButton sx={sx} onClick={toggleSubscription} />}
      {!user && (
        <NavButton label="Subscribe" sx={sx} to={Path.user.login(window.location.pathname)} />
      )}
    </>
  )
}

export default Subscribe
