import {useState} from 'react'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {MInput, IconButton, RoundedButton} from 'components/forms/inputs'
import {modalStyle} from 'components/dialogs'
import {ColumnType, minMaxTypes, type Column} from './columns'
import {rounded} from 'components/formatters'

type FilterProps = Column & {
  onClose: () => void
}

const startAdornment = (label: string) => ({
  startAdornment: <InputAdornment position="start">{label}</InputAdornment>,
})
const endAdornment = (label: string) => ({
  endAdornment: <InputAdornment position="end">{label}</InputAdornment>,
})
const makeInputProps = (filterType?: ColumnType) => {
  switch (filterType) {
    case 'latency':
      return endAdornment('s')
    case 'percentage':
      return endAdornment('%')
    case 'price':
      return startAdornment('$')
    default:
      return {}
  }
}

const MinMaxFilter = ({
  name,
  formatter,
  minValue,
  maxValue,
  step,
  filterType,
  filters,
  onFilter,
}: FilterProps) => {
  const [min, setMin] = useState<string | undefined>(filters?.min?.toString())
  const [max, setMax] = useState<string | undefined>(filters?.max?.toString())
  const update = (min?: string, max?: string) => {
    setMin(min)
    setMax(max)
    onFilter && onFilter(parseFloat(min || '') || undefined, parseFloat(max || '') || undefined)
  }

  const inputProps = makeInputProps(filterType)
  return (
    <Stack sx={{p: 3, pt: 5}} spacing={3}>
      <Slider
        getAriaLabel={() => `Filter ${name}`}
        value={[filters?.min || minValue || 0, filters?.max || maxValue || 100]}
        onChange={(_, val) => {
          const [min, max] = val as number[]
          update(min.toString(), max.toString())
        }}
        min={minValue || 0}
        max={maxValue || 100}
        step={step || 1}
        valueLabelDisplay="auto"
        getAriaValueText={(val: number) => `${(formatter ? formatter(val) : rounded(val)) || 0}`}
        valueLabelFormat={(val: number) => `${(formatter ? formatter(val) : rounded(val)) || 0}`}
      />
      <MInput
        label="Minumum"
        value={min?.toString() || ''}
        setter={(v: string) => update(v, filters?.max?.toString())}
        InputProps={inputProps}
        sx={{m: 0}}
      />
      <MInput
        label="Maximum"
        value={max?.toString() || ''}
        setter={(v: string) => update(filters?.min?.toString(), v)}
        InputProps={inputProps}
        sx={{m: 0}}
      />
      <RoundedButton label="Reset" onClick={() => update(undefined, undefined)} />
    </Stack>
  )
}

const Filter = (props: FilterProps) => {
  if (minMaxTypes.includes(props.filterType || '')) return <MinMaxFilter {...props} />
  return null
}

const ColumnFilter = (col: Column) => {
  const [showFilter, setShowFilter] = useState(false)
  const close = () => setShowFilter(false)

  if (!col.onFilter) return null
  return (
    <>
      <Modal open={showFilter} onClose={close}>
        <Box
          sx={{...modalStyle, m: '80px auto', p: 0, border: 'none', borderRadius: 2, width: 256}}
        >
          <Filter onClose={close} {...col} />
        </Box>
      </Modal>
      <IconButton
        selected={!!col.filters}
        aria-label="filter"
        Icon={FilterAltIcon}
        onClick={() => setShowFilter(true)}
      />
    </>
  )
}

export default ColumnFilter
