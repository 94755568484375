import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from '../components/Page'
import {ExternalA} from 'components/text'

const Privacy: React.FC = () => {
  return (
    <Page title="Equistamp's privacy policy" showTitle>
      <Stack
        direction="column"
        spacing={3}
        justifyContent="space-evenly"
        sx={{mt: 8, maxWidth: 600}}
      >
        <Typography>
          We use <b>Piwik PRO Analytics Suite</b> as our analytics software and consent management
          tool. We collect data about website visitors based on{' '}
          <ExternalA
            to="https://help.piwik.pro/support/getting-started/cookies-created-by-piwik-pro/"
            text="cookies."
          />
          The collected information may include a visitor’s IP address, operating system, browser
          ID, browsing activity and other information. See the{' '}
          <ExternalA
            to="https://help.piwik.pro/support/getting-started/what-data-does-piwik-pro-collect/"
            text="scope of data collected by Piwik PRO."
          />
        </Typography>

        <Typography>
          We calculate metrics like bounce rate, page views, sessions and the like to understand how
          our website/app is used. We may also create visitors’ profiles based on browsing history
          to analyze visitor behavior, show personalized content and run online campaigns.
        </Typography>

        <Typography>
          We host our solution on Microsoft Azure in Germany, Netherlands, United States and Hong
          Kong, Orange in France and ElastX in Sweden, and the data is stored for 14/25 months.
        </Typography>

        <Typography>
          The purpose of data processing: analytics and conversion tracking based on consent. Legal
          basis: Art. 6 (1)(a) GDPR.
        </Typography>

        <Typography>
          Piwik PRO does not send the data about you to any other sub-processors or third parties
          and does not use it for its own purposes. For more, read{' '}
          <ExternalA
            to="https://piwik.pro/privacy-policy/#product"
            text="Piwik PRO’s privacy policy."
          />
        </Typography>
      </Stack>
    </Page>
  )
}

export default Privacy
