import {useEffect, useState} from 'react'
import {useModels as useCachedModels} from './useCachedObjects'
import type {Model} from '@equistamp/types'

const useModels = () => {
  const [models, setModels] = useState([] as Model[])
  const {loading, items, getItems, refresh} = useCachedModels()

  useEffect(() => {
    getItems({}).then(({items}) => setModels(items as Model[]))
  }, [setModels, getItems])

  return {loading, getItems, refresh, models, allModels: items}
}

export default useModels
