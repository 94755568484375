import {useState, ChangeEvent, ReactNode} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import {formatNum} from 'components/formatters'
import {FilterKey} from '@equistamp/types'

type OptionsGroupProps = {
  label?: string | ReactNode
  sx?: {[key: string]: any}
  children: ReactNode
}
export const OptionsGroup = ({label, sx, children}: OptionsGroupProps) => (
  <FormControl sx={{m: 3, ...sx}} component="fieldset" variant="standard">
    <FormLabel component="legend">{label}</FormLabel>
    <FormGroup sx={{mt: 1}}>{children}</FormGroup>
  </FormControl>
)

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary?.main,
    color: theme.palette.secondary.contrastText,
    boxShadow: theme.shadows[1],
    fontSize: 12,
    padding: 8,
  },
}))

export const DarkTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{popper: className}}
    slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -8]}}]}}}
  />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.mode === 'dark' ? 'black' : 'white',
    backgroundColor: theme.palette.secondary.contrastText,
  },
}))

export const HelpTooltip = ({help, sx}: {help: string | ReactNode; sx?: {[k: string]: any}}) => (
  <LightTooltip title={help}>
    <HelpOutlineIcon fontSize="inherit" sx={sx} />
  </LightTooltip>
)

type CheckboxGroupType = {
  label: string
  allOptions: {[key: string]: string}
  selected?: string[]
  info?: {[key: string]: string}
  onChange: (val: any) => void
  sx?: {[key: string]: any}
}
export const CheckboxGroup = ({
  label,
  allOptions,
  info,
  selected,
  onChange,
  sx,
}: CheckboxGroupType) => {
  const handleChange = (val: string) => (e: ChangeEvent) => {
    const items = selected || []
    if ((e as any).target.checked) {
      onChange([...items, val])
    } else {
      onChange(items.filter((i) => i !== val))
    }
  }

  return (
    <OptionsGroup label={label} sx={sx}>
      {Object.entries(allOptions).map(([option, label]) => (
        <Box key={option}>
          <FormControlLabel
            sx={{mr: 1}}
            control={
              <Checkbox
                checked={selected?.includes(option) || false}
                onChange={handleChange(option)}
              />
            }
            label={
              <Typography>
                {label}
                {info && info[option] && (
                  <Box sx={{position: 'absolute', display: 'inline'}}>
                    <HelpTooltip help={info[option]} />
                  </Box>
                )}
              </Typography>
            }
          />
        </Box>
      ))}
    </OptionsGroup>
  )
}

type DatesPickerProps = {
  label: string
  field?: string
  start?: string
  end?: string
  onChange: (field: FilterKey, val: string) => void
}
export const DatesPicker = ({label, start, field, end, onChange}: DatesPickerProps) => {
  const onDateChange = (field: FilterKey) => (e: any) => {
    const val = e['$d'].toISOString().slice(0, 10)
    onChange(field, val)
  }

  return (
    <OptionsGroup label={label} sx={{width: 300}}>
      <Stack direction="column" spacing={3}>
        <DatePicker
          label="from"
          defaultValue={start && dayjs(start)}
          sx={{width: 180}}
          onChange={onDateChange((field ? 'start' + field : 'startDate') as FilterKey)}
        />
        <DatePicker
          label="to"
          defaultValue={end && dayjs(end)}
          sx={{width: 180}}
          onChange={onDateChange((field ? 'end' + field : 'endDate') as FilterKey)}
        />
      </Stack>
    </OptionsGroup>
  )
}

type RangeSliderProps = {
  label: string
  start?: number
  end?: number
  min?: number
  max?: number
  scale?: 'log' | 'linear'
  onChange: (val: number[]) => void
}
export const RangeSlider = ({label, min, max, scale, start, end, onChange}: RangeSliderProps) => {
  const scalers = {
    log: (val: number) => Math.pow(10, val),
    linear: (v: number) => v,
  }
  const descalers = {
    log: (v: number) => (v === 0 ? 0.0001 : Math.log10(v)),
    linear: (v: number) => v,
  }
  const scaler = scalers[scale || 'linear']
  const descaler = descalers[scale || 'linear']

  const handleChange = (e: Event, vals: number | number[]) =>
    onChange((vals as number[]).map(scaler))

  return (
    <OptionsGroup label={label} sx={{width: 300}}>
      <Stack direction="row" spacing={3}>
        <Typography sx={{minWidth: 30}}>{formatNum(min ?? 0)}</Typography>
        <Slider
          getAriaLabel={() => label}
          min={descaler(min ?? 0)}
          max={descaler(max ?? 100)}
          step={0.01}
          scale={scaler}
          value={[descaler(start ?? min ?? 0), descaler(end ?? max ?? 100)]}
          onChange={handleChange}
          valueLabelDisplay="auto"
          valueLabelFormat={(val: number) => formatNum(val)}
          getAriaValueText={(val: number) => formatNum(val)}
        />
        <Typography sx={{minWidth: 30}}>{formatNum(max ?? 100)}</Typography>
      </Stack>
    </OptionsGroup>
  )
}

type SingleRangeSliderProps = {
  label: string
  min: number
  max: number
  val: number
  scale?: (val: number) => number
  onChange: (val: number) => void
}
export const SingleRangeSlider = ({
  label,
  max,
  min,
  scale,
  val,
  onChange,
}: SingleRangeSliderProps) => {
  const scaler = scale || ((v: number) => v)
  const handleChange = (e: Event, val: number | number[]) => onChange(scaler(val as number))

  return (
    <OptionsGroup label={label} sx={{width: 300}}>
      <Stack direction="row" spacing={3}>
        <Typography sx={{minWidth: 30}}>{formatNum(scaler(val))}</Typography>
        <Slider
          getAriaLabel={() => label}
          min={min}
          max={max}
          value={val}
          onChange={handleChange}
          valueLabelDisplay="auto"
          getAriaValueText={(val: number) => formatNum(scaler(val))}
        />
      </Stack>
    </OptionsGroup>
  )
}

type FilterAutocompleteProps = {
  options: any[]
  label: string
  onSelect: (v: any) => void
  groupBy?: (a: any) => string
  labelFunc?: (a: any) => string
}
export const FilterAutocomplete = ({
  options,
  label,
  onSelect,
  groupBy,
  labelFunc,
}: FilterAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      disableCloseOnSelect
      disableClearable
      onChange={(event, val) => {
        onSelect && onSelect(val)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (event?.type === 'change') {
          setInputValue(newInputValue)
        }
      }}
      options={options}
      groupBy={groupBy}
      getOptionLabel={labelFunc || ((option) => option.name || '')}
      sx={{width: 300}}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}

export const FiltersContainer = ({children}: {children: ReactNode}) => (
  <Paper>
    <Stack direction={{sm: 'column', md: 'row'}} justifyContent="center">
      {children}
    </Stack>
  </Paper>
)
