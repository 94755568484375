import {useState, ReactNode} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material/styles'
import {styled} from '@mui/material/styles'
import {Chart as ChartJS, ArcElement} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {Input, RoundedButton} from 'components/forms/inputs'
import RenderMarkdown from 'components/RenderMarkdown'
import {ColouredTypography, InfoItem} from 'components/text'
import {formatDuration} from 'components/formatters'
import {
  BOOLEAN_QUESTION,
  FREE_RESPONSE_QUESTION,
  JSON_QUESTION,
  MULTIPLE_CHOICE_QUESTION,
} from '@equistamp/constants'
import type {Evaluation as EvaluationType, Task as TaskType} from '@equistamp/types'

ChartJS.register(ArcElement)

export type TaskStats = {
  total: number
  completed: number
  correct: number
  secondsSpent: number
  questionStartedAt: number
}

const ProgressBar = styled(LinearProgress)(({theme}) => ({
  height: 30,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

const TitledProgressBar = ({value}: {value: any}) => {
  return (
    <Box sx={{position: 'relative', display: 'grid', alignItems: 'center'}}>
      <ProgressBar variant="determinate" value={value} />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          {`Progress ${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

type CurrentResultsProps = {
  title: string
  total: number
  correct: number
  children: ReactNode
}
const CurrentResults = ({title, total, correct, children}: CurrentResultsProps) => {
  const theme = useTheme()
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      sx={{width: 220}}
    >
      <ColouredTypography variant="overline">{title}</ColouredTypography>
      <Box sx={{width: 160}}>
        <Pie
          options={{plugins: {legend: {display: false}} as any}}
          data={{
            labels: ['Incorrect', 'Correct'],
            datasets: [
              {
                data: [total - correct, correct],
                backgroundColor: ['rgba(208, 0, 17)', theme.palette.primary.main],
                borderWidth: 0,
              },
            ],
          }}
        />
      </Box>
      {children}
    </Stack>
  )
}

const TextQuestion = ({title, task, onSubmit}: QuestionHandler) => {
  const [loading, setLoading] = useState(false)
  const [answer, setAnswer] = useState<string>('')

  const handleClick = async () => {
    setLoading(true)
    await onSubmit({text: answer})
    setAnswer('')
    setLoading(false)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ColouredTypography variant="h4">{title}</ColouredTypography>
      </Grid>
      <Grid item xs={12} sx={{minHeight: 100}}>
        <Typography>Question:</Typography>
        <RenderMarkdown content={task.question} />
      </Grid>
      <Grid item sm={12} md={9}>
        <Input
          label="Your answer"
          value={answer}
          setter={setAnswer}
          multiline
          sx={{width: '100%', maxWidth: 500}}
        />
      </Grid>
      <Grid item sm={12} md={3}>
        <RoundedButton disabled={!answer} label="Submit" onClick={handleClick} loading={loading} />
      </Grid>
    </Grid>
  )
}

const FRQQuestion = TextQuestion
const JsonQuestion = TextQuestion

type TextSelectQuestionProps = {
  title: string
  question: string
  answers: {[k: string]: string}
  onSubmit: (answer: string) => void
}
const TextSelectQuestion = ({title, question, answers, onSubmit}: TextSelectQuestionProps) => {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<string>('')

  const handleClick = async () => {
    setLoading(true)
    await onSubmit(selected)
    setSelected('')
    setLoading(false)
  }

  const detectEnter = (event: any) => {
    if (event.key === 'Enter' && selected) {
      handleClick()
    }
  }

  return (
    <RadioGroup
      name="answers-group"
      value={selected}
      onKeyDown={detectEnter}
      onChange={(_, val: string) => setSelected(val)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ColouredTypography variant="h4">{title}</ColouredTypography>
        </Grid>
        <Grid item xs={12} sx={{minHeight: 100}}>
          <RenderMarkdown content={question} />
        </Grid>
        {Object.entries(answers).map(([id, text]) => (
          <Grid item xs={12} sm={6} key={id}>
            <FormControlLabel
              value={id}
              control={<Radio />}
              label={<RenderMarkdown content={text} />}
            />
          </Grid>
        ))}
        {Object.keys(answers).length % 2 !== 0 && <Grid item xs={12} sm={6}></Grid>}
        <Grid item xs={12} sm={3}>
          <RoundedButton
            disabled={!selected}
            label="Submit"
            onClick={handleClick}
            loading={loading}
          />
        </Grid>
      </Grid>
    </RadioGroup>
  )
}

type QuestionHandler = {
  title: string
  task: TaskType
  onSubmit: (answer: any) => void
}
const MultiChoiceQuestion = ({title, task, onSubmit}: QuestionHandler) => (
  <TextSelectQuestion
    title={title}
    question={task.question || ''}
    answers={task.answers?.reduce((acc, {id, text}) => ({...acc, [id]: text}), {}) || {}}
    onSubmit={(selected: string) => onSubmit(task.answers?.filter(({id}) => id === selected)[0])}
  />
)

const BooleanQuestion = ({title, task, onSubmit}: QuestionHandler) => (
  <TextSelectQuestion
    title={title}
    question={task.question || ''}
    answers={{true: 'True', false: 'False'}}
    onSubmit={(answer: string) => onSubmit({text: answer})}
  />
)

const questionTypes = {
  [FREE_RESPONSE_QUESTION]: FRQQuestion,
  [MULTIPLE_CHOICE_QUESTION]: MultiChoiceQuestion,
  [BOOLEAN_QUESTION]: BooleanQuestion,
  [JSON_QUESTION]: JsonQuestion,
} as {[k: string]: any}

type TestCardWrapperProps = {
  children: ReactNode
  stats: TaskStats
}
export const TestCardWrapper = ({children, stats}: TestCardWrapperProps) => {
  return (
    <Card sx={{mt: 3, p: 5, borderRadius: 5}}>
      <CardContent>
        <Stack direction="column" justifyContent="space-between" spacing={15}>
          {children}
          <TitledProgressBar value={stats.total && (100 * stats.completed) / stats.total} />
        </Stack>
      </CardContent>
    </Card>
  )
}

type TestCardProps = {
  title: string
  task: TaskType
  stats: TaskStats
  onSubmit: (answer: any) => void
  onRestart: () => void
}
export const TestCard = ({title, task, stats, onSubmit, onRestart}: TestCardProps) => {
  const renderer = questionTypes[task.type]
  return (
    <TestCardWrapper stats={stats}>
      <Stack direction={{sm: 'column', md: 'row'}} spacing={10}>
        {renderer && renderer({task, onSubmit, title})}
        <Box></Box>
        {stats.completed > 0 && (
          <CurrentResults
            total={stats.completed}
            correct={stats.correct}
            title={'Current score: ' + ((100 * stats.correct) / stats.completed).toFixed(1)}
          >
            {onRestart && stats.completed > 0 && (
              <RoundedButton label="Start over" onClick={onRestart} />
            )}
          </CurrentResults>
        )}
        <Box></Box>
      </Stack>
    </TestCardWrapper>
  )
}

export const TasksSummary = ({
  evaluation,
  stats,
}: {
  evaluation: EvaluationType
  stats: TaskStats
}) => {
  const evalStats = evaluation.stats || {}

  type ResultsProps = {
    title: string
    percent: number
    perQuestionSeconds?: number
  }
  const Results = ({title, percent, perQuestionSeconds}: ResultsProps) => (
    <CurrentResults title={title + ': ' + percent.toFixed(1) + '%'} total={100} correct={percent}>
      {perQuestionSeconds && (
        <InfoItem
          title="Average time per question"
          align="center"
          variant="inherit"
          value={formatDuration(perQuestionSeconds)}
        />
      )}
    </CurrentResults>
  )

  return (
    <TestCardWrapper stats={stats}>
      <ColouredTypography variant="h4">Results</ColouredTypography>
      <Stack direction={{sm: 'row', xs: 'column'}} justifyContent="space-around" spacing={4}>
        <Results
          title="Your score"
          percent={(100 * stats.correct) / stats.total}
          perQuestionSeconds={stats.secondsSpent / stats.total}
        />

        {evalStats.median_human_accuracy && (
          <Results
            title="Median human score"
            percent={evalStats.median_human_accuracy}
            perQuestionSeconds={evalStats.median_human_test_seconds}
          />
        )}

        {evalStats.max_human_accuracy && (
          <Results
            title="Top human score"
            percent={evalStats.max_human_accuracy}
            perQuestionSeconds={evalStats.min_human_test_seconds}
          />
        )}

        {evalStats.median_model_accuracy && (
          <>
            <Divider orientation="vertical" flexItem />
            <Results
              title="Median AI score"
              percent={evalStats.median_model_accuracy}
              perQuestionSeconds={evalStats.median_model_test_seconds}
            />
          </>
        )}
      </Stack>
    </TestCardWrapper>
  )
}
