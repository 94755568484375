import {ReactNode, useState} from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import {ServerError} from '@equistamp/api'
import {ActionButton} from './forms/inputs'

export const modalStyle = {
  mt: 10,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: 'calc(100% - 80px)', // Subtract some pixels for margin
}

type ConfirmationDialogProps = {
  label: string
  open?: boolean
  onSelect: (v: boolean) => void | Promise<void>
  children: ReactNode
}
export const ConfirmationDialog = ({label, children, open, onSelect}: ConfirmationDialogProps) => {
  const [error, setError] = useState('')
  const handleConfirm = async () => {
    try {
      await onSelect(true)
    } catch (e) {
      setError(e instanceof ServerError ? e.error.toString() : `${e}`)
    }
  }
  return (
    <Dialog open={open || false} onClose={() => onSelect(false)} aria-labelledby={label}>
      <DialogTitle id="alert-dialog-title">{label}</DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        ) : (
          children
        )}
        {error && (
          <Alert sx={{mt: 2}} severity="error">
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <ActionButton label="Yes" action={handleConfirm} />
        <Button onClick={() => onSelect(false)} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type InfoDialogProps = {
  label: string
  open?: boolean
  onClose: () => void
  children: ReactNode
}
export const InfoDialog = ({label, children, open, onClose}: InfoDialogProps) => {
  return (
    <Dialog open={open || false} onClose={() => onClose()} aria-labelledby={label}>
      <DialogTitle id="alert-dialog-title">
        <Typography sx={{mr: 4}}>{label}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? (
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
    </Dialog>
  )
}
