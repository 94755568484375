import {useEffect, useState} from 'react'
import {useAlerts as useCachedAlerts} from './useCachedObjects'
import type {Alert} from '@equistamp/types'

const useAlerts = () => {
  const [alerts, setAlerts] = useState([] as Alert[])
  const {loading, getItems, refresh, removeItem} = useCachedAlerts()

  useEffect(() => {
    getItems({}).then(({items}) => setAlerts(items as Alert[]))
  }, [setAlerts, getItems])

  return {loading, getItems, refresh, alerts, removeItem}
}

export default useAlerts
