import {useEffect, useState} from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import {InfoItem} from 'components/text'
import {modalStyle} from 'components/dialogs'
import {CodeConfig, avaliableCodeConfigs} from './defaults'
import CodeSettings from './Settings'
import TestCode from './Tester'
import {CodeStage, DSLCode, QuestionType} from '@equistamp/types'
import Editor from './Editor'
import {RoundedButton} from 'components/forms/inputs'

type CodeChooserItemProps = CodeConfig & {onSelect: () => void}
const CodeChooserItem = ({name, description, onSelect}: CodeChooserItemProps) => (
  <Grid item xs={12} md={6}>
    <Card sx={{minWidth: 275, m: 2}}>
      <CardActionArea onClick={onSelect}>
        <CardContent>
          <InfoItem title="Name" value={name} />
          <InfoItem title="Description" value={description} />
        </CardContent>
      </CardActionArea>
    </Card>
  </Grid>
)

type CodeGridProps = {
  available: CodeConfig[]
  onSelected: (config: CodeConfig) => void
}
const CodeGrid = ({available, onSelected}: CodeGridProps) => {
  useEffect(() => {
    if (available.length === 1) {
      onSelected(available[0])
    }
  }, [available, onSelected])
  return (
    <Grid container>
      {available.map((config) => (
        <CodeChooserItem key={config.id} onSelect={() => onSelected(config)} {...config} />
      ))}
    </Grid>
  )
}

export type ChosenDSLCode = {
  code: DSLCode
  variables?: {[k: string]: any}
  config?: CodeConfig
}

const applyLet = (config?: CodeConfig, variables?: {[k: string]: any}) => {
  const formattedVars =
    variables &&
    Object.entries(variables)
      .map(([varName, val]) => `${varName} ${val}`)
      .join('  \n')
  const code = formattedVars ? `(let [${formattedVars}]\n  ${config?.code})` : config?.code || ''
  return {code, config, variables}
}

type ConfigureChosenProps = ChosenDSLCode & {
  onChange: (chosen: ChosenDSLCode) => void
}
const ConfigureChosen = ({code, variables, config, onChange}: ConfigureChosenProps) => {
  const editCode = (code: string) => {
    onChange({code, variables, config})
  }

  const updateVars = (name: string, val: any) => {
    onChange(applyLet(config, {...(variables || {}), [name]: val}))
  }

  return (
    <>
      {config?.editable && <Editor code={code} onChange={editCode} />}
      {!config?.editable && (
        <CodeSettings vars={variables || {}} config={config} onChange={updateVars} />
      )}
    </>
  )
}

type CodeChooserProps = {
  open: boolean
  stage: CodeStage
  current?: ChosenDSLCode
  taskType: QuestionType
  onChoose: (chosen: ChosenDSLCode) => void
  onUpdate: (chosen: ChosenDSLCode) => void
  onClose: () => void
}
const CodeChooser = ({
  open,
  stage,
  taskType,
  current,
  onChoose,
  onUpdate,
  onClose,
}: CodeChooserProps) => {
  const [testPassed, setTestPassed] = useState(false)

  const updateCurrent = (current: ChosenDSLCode) => {
    onUpdate(current)
  }

  const selectItem = (selected?: CodeConfig) => {
    const variables = selected?.variables
      ?.filter((v) => v.default !== undefined)
      .reduce((acc, variable) => ({...acc, [variable.name]: variable.default}), {})
    updateCurrent(applyLet(selected, variables || {}))
  }

  const save = () => {
    current && onChoose(current)
    onClose()
  }

  const selected = current?.config
  const available = avaliableCodeConfigs[stage]?.filter(({supports}) => supports.includes(taskType))
  return (
    <Modal open={open} onClose={onClose}>
      <Container sx={{flex: 1, ...modalStyle}}>
        <Stack spacing={4}>
          <Stack direction="row" spacing={2}>
            {!selected && <CodeGrid available={available} onSelected={selectItem} />}
            {selected && <ConfigureChosen {...current} onChange={updateCurrent} />}
            {selected && (
              <TestCode
                stage={stage}
                taskType={taskType}
                code={current.code}
                onTest={setTestPassed}
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            {available.length !== 1 && (
              <RoundedButton label="Back" onClick={() => selectItem(undefined)} />
            )}
            <RoundedButton label="Save" onClick={save} disabled={!testPassed} />
          </Stack>
        </Stack>
      </Container>
    </Modal>
  )
}

export default CodeChooser
