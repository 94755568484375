import {ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import {useMediaQuery, useTheme} from '@mui/material'
import {InfoItem, Text} from 'components/text'
import {addFilter} from 'components/filters'
import {taskTypes} from 'components/filters/constants'
import {NavButton} from 'components/forms/inputs'
import Path from 'routeLinks'
import type {Evaluation as EvaluationType, Tag, FiltersTypes} from '@equistamp/types'
import type {FiltersUpdaterFunc} from 'components/filters/types'

type FiltersUpdater = (func: FiltersUpdaterFunc) => void
type EvalTabProps = {
  evaluation: EvaluationType
  shown?: boolean
  filters?: FiltersTypes
  updateFilters?: FiltersUpdater
}

export const BasicEvalInfo = ({evaluation, updateFilters}: EvalTabProps) => {
  const addTag = (tag: Tag) =>
    updateFilters && updateFilters((filters) => addFilter(filters || {tags: []}, 'tags', tag.name))

  return (
    <Stack direction="column" spacing={0} justifyContent="space-around" alignItems="stretch">
      <Typography variant="h4" sx={{color: 'primary.main', fontWeight: 700}}>
        {evaluation.name}
      </Typography>
      <Text
        text={evaluation.description}
        align="justify"
        variant="inherit"
        markdown
        style={{
          overflow: 'hidden',
          WebkitLineClamp: 4,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}
      />
      <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="flex-start" spacing={6}>
        <InfoItem
          title="Types"
          value={evaluation?.task_types
            ?.map((t) => taskTypes[t as keyof typeof taskTypes] || t)
            .join(', ')}
        />
        <InfoItem title="Modalities" value={evaluation.modalities?.join(', ')} />
        <InfoItem
          title="Number of tasks"
          value={
            evaluation?.num_tasks !== undefined ? evaluation?.num_tasks?.toString() : undefined
          }
        />
      </Stack>
      <InfoItem
        title="Tags"
        value={
          evaluation.tags && (
            <Stack direction="row" gap={2} flexWrap="wrap">
              {evaluation.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    addTag(tag)
                  }}
                />
              ))}
            </Stack>
          )
        }
      />
    </Stack>
  )
}

const linkRouter = (evaluation: EvaluationType) => (props: {children: ReactNode}) =>
  <NavLink to={Path.evaluations.show(evaluation.id)} {...props} />

type EvaluationProps = {
  evaluation: EvaluationType
  updateFilters: FiltersUpdater
}

const SmallCard = ({evaluation, updateFilters}: EvaluationProps) => {
  return (
    <Card sx={{borderRadius: '10px'}}>
      <Stack direction={{sm: 'column', md: 'row'}}>
        <CardActionArea sx={{p: 5}} component={linkRouter(evaluation)}>
          <BasicEvalInfo evaluation={evaluation} updateFilters={updateFilters} />
        </CardActionArea>
      </Stack>
    </Card>
  )
}

const LargeCard = ({evaluation, updateFilters}: EvaluationProps) => (
  <Card sx={{borderRadius: '10px'}}>
    <CardActionArea component={linkRouter(evaluation)}>
      <Stack direction={{sm: 'column', md: 'row'}} spacing={8}>
        <CardContent sx={{p: 5, flexGrow: 1}}>
          <BasicEvalInfo evaluation={evaluation} updateFilters={updateFilters} />
        </CardContent>
        <CardActions sx={{p: 5}}>
          <Stack
            direction={{xs: 'column', sm: 'row', md: 'column'}}
            spacing={3}
            alignItems="center"
            sx={{width: '100%'}}
          >
            <NavButton to={Path.evaluations.connections(evaluation.id)} label="Run" />
            <NavButton to={Path.evaluations.tests(evaluation.id)} label="Test yourself" />
          </Stack>
        </CardActions>
      </Stack>
    </CardActionArea>
  </Card>
)

const Evaluation = (props: EvaluationProps) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return isSmallScreen ? <SmallCard {...props} /> : <LargeCard {...props} />
}

export default Evaluation
