import {ReactNode, useEffect, useRef, useState} from 'react'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography, {TypographyProps} from '@mui/material/Typography'
import {styled, useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {HelpTooltip} from 'components/filters/widgets'
import Path from 'routeLinks'
import type {Evaluation, Model, ModelStats, Score} from '@equistamp/types'
import type {Column} from './columns'
import {PotentialAction} from 'components/text'
import ColumnFilter from './ColumnFilter'
import ColumnSort from './ColumnSort'

export type ScoreModel = Model &
  ModelStats & {
    scores: {[k: string]: Score}
  } & {
    [k: string]: number
  }

export const actionCellStyle = {
  fontSize: '16px',
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  justifyContent: 'left',
  pl: 2,
  color: 'primary.light',
}

const StyledHeaderCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
    padding: 0,
    paddingRight: 8,
  },
}))

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.body}`]: {
    border: `5px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.secondary.main,
    height: 80,
    position: 'relative',
    fontSize: '16px',
    width: '1%',
  },
}))

type LongTextProps = {text: string; sx?: {[k: string]: any}} & TypographyProps
const LongText = ({text, sx, ...props}: LongTextProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const textRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [text])

  return (
    <Tooltip title={isOverflowing ? text : ''} arrow>
      <Typography
        ref={textRef}
        {...props}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          ...sx,
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  )
}

const HeaderCell = (col: Column) => {
  const {name, id, hint, columnUrl} = col

  return (
    <StyledHeaderCell key={id} align="left">
      <Stack direction="row" spacing={0.3} justifyContent="flex-start" alignItems="center">
        <PotentialAction action={columnUrl} sx={{pr: 0.5}}>
          <LongText sx={{fontSize: 14, pr: 0.5, color: 'secondary.contrastText'}} text={name} />
          {hint && <HelpTooltip help={hint} sx={{color: 'secondary.contrastText'}} />}
        </PotentialAction>

        <ColumnFilter {...col} />
        <ColumnSort {...col} />
      </Stack>
    </StyledHeaderCell>
  )
}

type ScoreHeaderProps = {
  evals: Evaluation[]
  columns: Column[]
}
const ScoreHeader = ({evals, columns}: ScoreHeaderProps) => (
  <TableHead>
    <TableRow>
      <StyledHeaderCell sx={{border: 0, width: 230}} />
      {evals.map(HeaderCell)}
      {columns.map(HeaderCell)}
    </TableRow>
  </TableHead>
)

const ScoreCell = ({col, score}: {col: Column; score: ScoreModel}) => {
  const [hoverElem, setHoverElem] = useState<ReactNode | undefined>()
  const val = score[col.id]
  const action = col.urlGetter && col.urlGetter(col, score)
  return (
    <StyledTableCell
      align="left"
      sx={{color: action ? 'primary.light' : 'text.primary'}}
      onMouseEnter={() => setHoverElem(col.onHover && col.onHover(col, score))}
      onMouseLeave={() => setHoverElem(undefined)}
    >
      <PotentialAction action={action} sx={actionCellStyle}>
        {col.formatter ? col.formatter(val, score) : val}
        {hoverElem}
      </PotentialAction>
    </StyledTableCell>
  )
}

type ScoreRowProps = {
  score: ScoreModel
  evals: Evaluation[]
  columns: Column[]
}
const ScoreRow = ({score, evals, columns}: ScoreRowProps) => {
  return (
    <TableRow>
      <TableCell sx={{border: 0}}>
        <Stack direction="column">
          <PotentialAction action={Path.models.show(score.id)}>
            <LongText
              color="primary.light"
              sx={{maxWidth: 205, fontWeight: 700}}
              text={score.name}
            />
          </PotentialAction>
          {score.publisher}
        </Stack>
      </TableCell>
      {evals.map((e) => (
        <ScoreCell key={e.id} score={score} col={e} />
      ))}
      {columns.map((c) => (
        <ScoreCell key={c.id} score={score} col={c} />
      ))}
    </TableRow>
  )
}

type ScoresTableProps = {
  items: ScoreModel[]
  evals: Evaluation[]
  columns: Column[]
  tag?: string
}
const ScoresTable = ({items, evals, columns}: ScoresTableProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <TableContainer sx={{maxWidth: '100%', width: 'unset', overflow: 'visible'}}>
      <Table sx={{width: isMobile ? 1000 : undefined, tableLayout: 'fixed'}}>
        <ScoreHeader evals={evals} columns={columns} />
        <TableBody>
          {items.map((score) => (
            <ScoreRow key={score.id} score={score} evals={evals} columns={columns} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ScoresTable
