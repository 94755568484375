import React from 'react'
import Stack from '@mui/material/Stack'
import Page from '../../components/Page'
import {NavButton} from '../../components/forms/inputs'
import Paths from '../../routeLinks'

const AdminPanel: React.FC = () => (
  <Page title="Admin panel" showTitle>
    <Stack sx={{mt: 6}}>
      <NavButton label="Latest evaluation sessions" to={Paths.admin.evaluationSessions()} />
      <NavButton label="Manage scheduled runs" to={Paths.admin.schedules()} />
      <NavButton label="Check model statuses" to={Paths.admin.modelStatuses()} />
    </Stack>
  </Page>
)

export default AdminPanel
