import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import useEditable from 'hooks/useEditable'
import {InfoItem} from 'components/text'
import {RoundedButton} from 'components/forms/inputs'
import Path from 'routeLinks'
import type {Alert as AlertType, Model, Evaluation} from '@equistamp/types'
import {StatusIcons, MetricSelect, ThresholdInput, VisibilityToggle} from './widgets'

type SummaryProps = {
  alert: AlertType
  updateAlert?: (alert: AlertType) => void
  allEvals?: Evaluation[]
  allModels?: Model[]
}
export const AlertSummary = ({alert, updateAlert, allEvals, allModels}: SummaryProps) => {
  const {Editable, onSave, onCancel, onEdit, editable} = useEditable<AlertType>(alert, updateAlert)

  return (
    <Stack
      component="form"
      direction={{sm: 'column', md: 'row'}}
      justifyContent="space-evenly"
      spacing={3}
    >
      <StatusIcons alert={alert} />

      <Grid container spacing={2}>
        <Grid item md={9} sm={12}>
          <Editable getter="name" variant="h3" label="Name" />
        </Grid>
        <Grid item md={3} sm={12}>
          <InfoItem
            title="Alert creator"
            value={alert?.owner?.full_name}
            to={alert?.owner?.id && Path.user.profile(alert?.owner?.id)}
          />
        </Grid>

        <Grid item md={9} sm={12}>
          <Editable getter="description" label="Description" markdown />
        </Grid>
        <Grid item sm={3} xs={12}>
          {/* empty */}
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          {/* empty */}
        </Grid>
        <Grid item sm={6} xs={12} md={3} lg={3}>
          <Editable
            title="Visibility"
            getter="public"
            formatter={(val: boolean) => (val ? 'public' : 'private')}
            editor={VisibilityToggle}
          />
        </Grid>

        <Grid item sm={6} xs={12} md={3} lg={2}>
          <Editable title="Metric" getter="metric" editor={MetricSelect} />
        </Grid>
        <Grid item sm={6} xs={12} md={3} lg={2}>
          <Editable title="Threshold" getter="threshold" editor={ThresholdInput} />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          {/* empty */}
        </Grid>

        {/* #### */}
        <Grid item xs={2} sm={6} md={6} lg={9}>
          <InfoItem title="Last trigger" value={alert.last_trigger_date} evenSpacing />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          {/* empty */}
        </Grid>
        {/* #### */}
        <Grid item xs={12}>
          {/* empty */}
        </Grid>

        {/* #### */}
        <Grid item xs={6} sm={3} md={6} lg={8} />
        <Grid item xs={6} sm={1} md={6} lg={8} />
        <Grid item xs={6} sm={4} md={3} lg={2}>
          {editable && <RoundedButton label="Save" onClick={onSave} />}
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          {editable && <RoundedButton label="Cancel" onClick={onCancel} />}
          {!editable && updateAlert && onEdit && <RoundedButton label="Edit" onClick={onEdit} />}
        </Grid>
      </Grid>
    </Stack>
  )
}

export default AlertSummary
