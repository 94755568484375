import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import Page from 'components/Page'
import {makeApi} from '@equistamp/api'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import useEvaluationSessions from 'hooks/useEvaluationSessions'
import useEvaluations from 'hooks/useEvaluations'
import {EvaluationSessions as EvaluationSessionsWidget} from 'components/search'
import type {Model as ModelType} from '@equistamp/types'
import Path from 'routeLinks'

export const fetchModel = (id: string) => makeApi().models.fetch(id)

const RunsSummary = ({item: model, setItem}: RendererProps<ModelType>) => {
  const {refresh} = useEvaluationSessions()
  const {refresh: refreshEvals} = useEvaluations()

  useEffect(() => {
    refreshEvals({
      filters: {
        public_usable: true,
        model: model.id,
        fields: ['name', 'price'],
      },
    })
    refresh({filters: {models: [{id: model.id, name: model.name}]}})
  }, [model, refresh, refreshEvals])

  return (
    <Page
      title={`${model?.name} runs`}
      showTitle
      breadcrumbs={[
        {title: 'models', link: Path.models.all()},
        {title: model.name, link: Path.models.show(model.id)},
        {title: 'runs'},
      ]}
    >
      <Box sx={{m: 3}}></Box>
      <EvaluationSessionsWidget searchbar item_type="model" />
    </Page>
  )
}

const Runs: React.FC = () => <ItemLoader itemName="model" renderer={RunsSummary} />

export default Runs
