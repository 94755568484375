import {useState, useEffect, useMemo} from 'react'
import {debounce} from 'lodash'
import {NavLink} from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import Chart, {Dataset} from './Chart'
import {titleStyle} from 'components/text'
import type {
  DateMap,
  Eval,
  EvaluationsResult,
  EvalRunsHistory,
  Threshold,
  ItemType,
  FilterConfig,
} from '@equistamp/types'
import Path from 'routeLinks'

type EvalsChartType = {
  dataFetcher: (config: FilterConfig) => Promise<EvaluationsResult | undefined> | undefined
  config: FilterConfig
  itemType: ItemType
  maxHeight?: number
}
type SortFunc = (items: any[]) => any[]
type EvalSummaryType = {
  evals: DateMap
  itemType: ItemType
  sorter: SortFunc
  sx: {[k: string]: any}
}

const getIdentifier = (item: Eval, itemType: ItemType) => {
  if (itemType === 'model') {
    return {
      id: item.evaluatee_id,
      name: item.evaluatee_name,
      link: item.evaluatee_id && Path.models.show(item.evaluatee_id),
    }
  } else if (itemType === 'evaluation') {
    return {
      id: item.evaluation_id,
      name: item.evaluation_name,
      link: item.evaluation_id && Path.evaluations.show(item.evaluation_id),
    }
  }
  return {id: null, name: null, link: null}
}

export const getDates = (dates: Dataset[]) =>
  Object.keys(dates.reduce((acc, {data}) => ({...acc, ...data}), {})).sort()

/*
 * Goes through the provided evaluations, and returns a new mapping of {<model>: <value for date>}
 */
const selectDate = (evals: Dataset[], date: string) =>
  evals.reduce((res, {key, data}) => ({...res, [key]: data[date]}), {})

const HeaderCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    ...titleStyle,
    color: theme.palette.primary.main,
  },
}))

const ItemTitle = ({item, itemType}: {item: Eval; itemType: ItemType}) => {
  const {name, link} = getIdentifier(item, itemType)
  if (link) {
    return (
      <Button component={NavLink} to={link}>
        {name}
      </Button>
    )
  }
  return <Typography>{name}</Typography>
}

const EvalSummary = ({itemType, evals, sorter, sx}: EvalSummaryType) => {
  return (
    <TableContainer component={Paper} sx={{maxWidth: 300, ...sx}}>
      <Table aria-label="Evaluations leaderboard">
        <TableHead>
          <TableRow>
            <HeaderCell>{itemType === 'evaluation' ? 'Evaluation name' : 'Model name'}</HeaderCell>
            <HeaderCell align="right" sx={{pl: 0}}>
              Score
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sorter(Object.values(evals).filter((i) => i)).map((stats) => (
            <TableRow
              key={stats.evaluation_id + stats.evaluatee_id}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th" scope="row" sx={{maxWidth: 220}}>
                <ItemTitle item={stats} itemType={itemType} />
              </TableCell>
              <TableCell align="right" sx={{pl: 0}}>
                {stats.score.toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const compareByDate = (a: Eval, b: Eval) => {
  if (!a?.datetime_completed || !b?.datetime_completed) return 1
  return a.datetime_completed.localeCompare(b.datetime_completed)
}

const makeDateFormatter = (evals: EvalRunsHistory): ((date: string) => string) => {
  let min = ''
  let max = ''
  Object.values(evals).forEach((data) =>
    Object.keys(data).forEach((date) => {
      if (!date) return
      if (!min || min > date) min = date
      if (!max || max < date) max = date
    })
  )

  if (max && min && (new Date(max) as any) - (new Date(min) as any) > 24 * 60 * 6000) {
    return (date: string) => date.slice(0, 10)
  }
  return (date: string) => date.slice(0, 19)
}

const sortByScore = (items: Eval[]) => items.sort((a: Eval, b: Eval) => b.score - a.score)
const topNSeries = (evals: Dataset[], sorter: SortFunc, n: number) => {
  const mostRecentItems = evals.map(({key, data}) => ({
    ...Object.values(data).sort(compareByDate).reverse()[0],
    key,
  }))

  const keys = sorter(mostRecentItems)
    .slice(0, n)
    .map(({key}) => key)
  const map = evals.reduce((acc, {key, data}) => ({...acc, [key as any]: data}), {})
  return keys.map((key) => ({key, data: map[key as keyof typeof map] as any}))
}

const removeDuplicates = (vals: Eval[]) =>
  Object.values(
    vals.reduce((acc, item) => ({...acc, [item.evaluatee_id + item.evaluation_id]: item}), {})
  ) as Eval[]

const leaders = (evals: Dataset[]) => {
  const places = ['best', 'second', 'third']
  const best = {} as {[k: string]: Eval}
  const byBin = Object.values(
    evals
      .map(({key, data}) => Object.values(data).map((v) => ({...v, key})))
      .reduce((vals, items) => [...vals, ...items], [])
      .reduce((acc, item) => ({...acc, [item.bin]: [...(acc[item.bin] || []), item]}), {})
  ) as Eval[][]

  return Object.values(
    Object.values(byBin)
      .map((vals) => sortByScore(removeDuplicates(vals as Eval[])))
      .reduce((acc, bin) => {
        bin.slice(0, places.length).forEach((item, i) => {
          const place = places[i]
          if (!acc[place]) {
            acc[place] = {key: place, data: {}}
          }
          if (!best[place] || best[place].score < item.score) {
            best[place] = item
          }
          if (item?.formatted_date) {
            acc[place].data[item.formatted_date] = best[place]
          }
        })
        return acc
      }, {} as {[k: string]: Dataset})
  )
}

const EvalsChart = ({dataFetcher, config, itemType}: EvalsChartType) => {
  const [loading, setLoading] = useState(false)
  const [evals, setEvalRuns] = useState<Dataset[]>([])
  const [thresholds, setThresholds] = useState<Threshold[]>([])
  const [selectedDate, setSelectedDate] = useState<string | undefined>()
  const [selected, setSelected] = useState<DateMap | undefined>()

  const setSelectedDateMap = (evals: Dataset[], selectedDate?: string) => {
    const date = selectedDate || getDates(evals).pop()
    setSelected(date ? selectDate(evals, date) : undefined)
  }

  const getEvals = useMemo(
    () =>
      debounce(async (config: FilterConfig) => {
        setLoading(true)
        const res = await dataFetcher(config)
        if (!res) return
        const {evals: evalData, thresholds} = res
        const dateFormatter = makeDateFormatter(evalData)

        const evals = Object.entries(evalData).map(([key, data]) => ({
          key,
          data: Object.entries(data).reduce((acc, [datetime, item]) => {
            const date = dateFormatter(datetime || '')
            return {
              ...acc,
              [date]: {
                ...item,
                label: date,
                formatted_date: dateFormatter(item.datetime_completed || ''),
              },
            }
          }, {}),
        }))
        setEvalRuns(evals)
        setThresholds(thresholds)
        setSelectedDateMap(evals, selectedDate)
        setLoading(false)
      }, 200),
    [dataFetcher, selectedDate]
  )

  useEffect(() => {
    getEvals(config)
  }, [getEvals, config])

  const renderTooltipLabel = (dataset: number, index: number, label: string) =>
    datasets[dataset].data[label]?.key
  const isLeader = (config.sort?.key || 'leader') === 'leader'
  const sorter = !isLeader && config.sort?.sorter ? config.sort?.sorter : sortByScore
  const datasets = isLeader ? leaders(evals) : topNSeries(evals, sorter, 10)
  return (
    <Card>
      <CardContent>
        <Stack direction={{xs: 'column-reverse', sm: 'column-reverse', md: 'row'}}>
          <EvalSummary
            evals={selected || {}}
            itemType={itemType}
            sorter={sorter}
            sx={{maxHeight: 500}}
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{width: '100%'}}
          >
            {loading && <CircularProgress size={50} sx={{position: 'absolute', zIndex: 1}} />}
            <Chart
              datasets={datasets}
              thresholds={thresholds}
              mapper={(v: DateMap) => v?.score || null}
              labelsExtractor={getDates}
              tooltipRenderer={isLeader ? renderTooltipLabel : undefined}
              onSelect={(selected: string) => {
                setSelectedDate(selected)
                setSelectedDateMap(evals, selected)
              }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default EvalsChart
