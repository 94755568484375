import {useState} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material'
import {makeApi, ServerError} from '@equistamp/api'
import {modalStyle} from 'components/dialogs'
import {isInvalidURL} from 'components/forms/validators'
import {ButtonInput} from 'components/forms/inputs'
import {taskTypes} from 'components/filters/constants'
import {
  FREE_RESPONSE_QUESTION,
  MULTIPLE_CHOICE_QUESTION,
  BOOLEAN_QUESTION,
  JSON_QUESTION,
  REDACTED_COLUMN,
  TYPE_COLUMN,
  PARAPHRASE,
  QUESTION_COLUMN,
  BOOL_CORRECT_COLUMN,
  FRQ_CORRECT_COLUMN,
  FRQ_INCORRECT_COLUMN,
  MCQ_CORRECT_COLUMN,
  MCQ_INCORRECT_COLUMN,
  JSON_SCHEMA,
  JSON_EXPECTED,
} from '@equistamp/constants'
import type {ColumnsConfig, TaskType} from '@equistamp/types'
import EyeIcon from 'components/icons/Eye'
import type {EvaluationEditProps} from './types'

const sampleData = {
  [MULTIPLE_CHOICE_QUESTION]: [
    ['Question', 'Correct Answer', 'Wrong answer 1', 'Wrong answer 2', 'Wrong answer 3'],
    ['What colour is the sky?', 'Blue', 'Yellow', 'Green', 'Brown'],
    ['What is 2 + 2?', '4', '3', '5', 'It depends on the weather'],
    ['What is the capital of Sierra Leone', 'Freetown', 'Capetown', 'Monrovia', 'Yamoussoukro'],
  ],
  [BOOLEAN_QUESTION]: [
    ['Question', 'Is correct'],
    ['Is the earth flat?', 'False'],
    ['Did a human walk on the moon?', 'True'],
    ['Are birds real?', '1'],
    ['Do vacinnes cause autism?', '0'],
  ],
  [FREE_RESPONSE_QUESTION]: [
    ['Question', 'Correct answer 1', 'Correct answer 2', 'Incorrect answer'],
    ['2 + 2 = _?', '4', '', '3'],
    ['The capital of Australia is _ ?', 'Canberra', '', 'Sydney'],
    ['A bow is used to _ ?', 'Shoot arrows', 'Show respect', 'Keep hands warm'],
  ],
  [JSON_QUESTION]: [
    ['Question', 'Schema', 'Expected'],
    [
      'name: John Doe, age: 30',
      `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "name": {
      "type": "string"
    },
    "age": {
      "type": "integer"
    }
  },
  "required": ["name"]
}`,
      '{"name": "John Doe", "age": 30}',
    ],
    ["I am mr. Blobby. I'm 32 years old", 'my-schema', '{"name": "mr. Blobby", "age": 32"}'],
  ],
  mixed: [
    [
      'Type',
      'Question',
      'Multi choice correct',
      'Multi choice incorrect 1',
      'Multi choice incorrect 2',
      'Multi choice incorrect 3',
      'Boolean correct',
      'Free response correct 1',
      'Free response correct 2',
      'Free response incorrect',
      'JSON example',
      'JSON schema',
    ],
    [
      MULTIPLE_CHOICE_QUESTION,
      'What colour is the sky?',
      'Blue',
      'Yellow',
      'Green',
      'Brown',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    [
      MULTIPLE_CHOICE_QUESTION,
      'What is 2 + 2?',
      '4',
      '3',
      '5',
      'It depends on the weather',
      '',
      '',
      '',
      '',
      '',
      '',
    ],
    [BOOLEAN_QUESTION, 'Is the earth flat?', '', '', '', '', 'False', '', '', '', '', ''],
    [BOOLEAN_QUESTION, 'Did a human walk on the moon?', '', '', '', '', 'True', '', '', '', '', ''],
    [FREE_RESPONSE_QUESTION, '4 * 5 = _?', '', '', '', '', '', '20', '', '9', '', ''],
    [
      FREE_RESPONSE_QUESTION,
      'What animals give milk?',
      '',
      '',
      '',
      '',
      '',
      'mammals',
      'cows',
      'snakes',
      '',
      '',
    ],
    [
      JSON_QUESTION,
      'name: John Doe, age: 30',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `{
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "properties": {
    "name": {
      "type": "string"
    },
    "age": {
      "type": "integer"
    }
  },
  "required": ["name"]
}`,
      '{"name": "John Doe", "age": 30}',
    ],
    [
      JSON_QUESTION,
      "I am mr. Blobby. I'm 32 years old",
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'my-schema',
      '{"name": "mr. Blobby", "age": 32"}',
    ],
  ],
}
const taskSpecificInfo = {
  [MULTIPLE_CHOICE_QUESTION]: [
    'There must be at least one correct answer, and at least one incorrect answer',
    'You can provide as many correct and incorrect answers as you want, but only the first 10 correct and first 20 incorrect answers will be used',
  ],
  [FREE_RESPONSE_QUESTION]: [
    'There must be at least one correct answer or one incorrect answer',
    'You can provide as many correct and incorrect answers as you want, but only the first 10 correct and first 20 incorrect answers will be used',
  ],
  [BOOLEAN_QUESTION]: [
    'You must provide a column that specifies whether a row is correct',
    'Any rows where the "correct" column is 1 or case insensitive true or yes (so e.g. TrUe, TRue or true) will be deemed to be true statements. Anything else is false',
  ],
  [JSON_QUESTION]: [
    'You can provide a column with expected JSON - only responses that match the expected JSON will be marked as correct',
    'You can provide a column with a schema to validate returned JSON - when provided, only JSON objects that conform to the schema will be marked as correct',
  ],
  mixed: [
    'You must provide a column that will specify the type of each task. This must be one of ' +
      Object.keys(taskTypes)
        .map((v) => `"${v}"`)
        .join(', '),
    <>
      You can see all available per task type columns in{' '}
      <a href="docs/evaluations/#configure-csv-columns">the docs</a>
    </>,
  ],
}

const labels = {
  gdocs: {
    radio: 'Google Sheet',
    label: 'Check Sheet',
    hint: 'Google Sheet URL',
  },
  'csv-url': {
    radio: 'CSV File URL',
    label: 'Check CSV File',
    hint: 'URL of CSV File',
  },
}

const contains = (val: string, vals: string[]) => vals.some((v) => val.search(v) >= 0)

const guessTaskType = (column: string) => {
  if (contains(column, ['frq', 'free response'])) {
    return FREE_RESPONSE_QUESTION
  } else if (contains(column, ['mrq', 'multiple choice'])) {
    return MULTIPLE_CHOICE_QUESTION
  } else if (contains(column, ['bool', 'true', 'yes'])) {
    return BOOLEAN_QUESTION
  } else if (contains(column, ['json'])) {
    return JSON_QUESTION
  } else {
    return null
  }
}

const guessType = (column: string, taskType?: TaskType) => {
  const task_type = taskType || guessTaskType(column)

  if (column === 'redacted') {
    return REDACTED_COLUMN
  } else if (['type'].includes(column)) {
    return TYPE_COLUMN
  } else if (['question', 'text'].includes(column)) {
    return QUESTION_COLUMN
  } else if (contains(column, ['incorrect', 'wrong'])) {
    switch (task_type) {
      case FREE_RESPONSE_QUESTION:
        return FRQ_INCORRECT_COLUMN
      default:
        return MCQ_INCORRECT_COLUMN
    }
  } else if (contains(column, ['bool'])) {
    return BOOL_CORRECT_COLUMN
  } else if (contains(column, ['correct'])) {
    switch (task_type) {
      case FREE_RESPONSE_QUESTION:
        return FRQ_CORRECT_COLUMN
      case BOOLEAN_QUESTION:
        return BOOL_CORRECT_COLUMN
      default:
        return MCQ_CORRECT_COLUMN
    }
  } else if (contains(column, ['paraphrase'])) {
    return PARAPHRASE
  } else if (contains(column, ['schema'])) {
    return JSON_SCHEMA
  } else if (contains(column, ['expected'])) {
    return JSON_EXPECTED
  } else {
    return undefined
  }
}

const SamplesTable = ({rows}: {rows: string[][]}) => (
  <Table sx={{minWidth: 650}} aria-label="sample tasks">
    <TableHead>
      <TableRow
        sx={{
          th: {
            border: '1px solid rgba(81, 81, 81, 1)',
            borderColor: 'secondary.divider',
            fontWeight: 600,
          },
        }}
      >
        {rows[0].map((val) => (
          <TableCell key={val}>{val}</TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.slice(1, rows.length).map((row) => (
        <TableRow
          key={row.toString()}
          sx={{
            td: {
              border: '1px solid rgba(81, 81, 81, 1)',
              borderColor: 'secondary.divider',
            },
          }}
        >
          {row.map((val, i) => (
            <TableCell key={row.toString() + val + i}>{val}</TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

type FullSampleProps = {
  rows: string[][]
  open: boolean
  onClose: () => void
}
const FullSample = ({rows, open, onClose}: FullSampleProps) => (
  <Modal open={open} onClose={onClose}>
    <Box sx={{...modalStyle, m: 10}}>
      <SamplesTable rows={rows} />
    </Box>
  </Modal>
)

const Samples = ({rows}: {rows: string[][]}) => {
  const [show, setShow] = useState(false)
  const theme = useTheme()

  return (
    <TableContainer sx={{position: 'relative'}}>
      <SamplesTable
        rows={rows.slice(0, 4).map((row) => (row.length > 5 ? [...row.slice(0, 5), '...'] : row))}
      />
      <Stack
        spacing={1}
        alignItems="center"
        direction="row"
        onClick={() => setShow(true)}
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          position: 'absolute',
          right: 0,
          bottom: 0,
          padding: 1,
          cursor: 'pointer',
          borderRadius: 1,
        }}
      >
        <EyeIcon fill={theme.palette.primary.contrastText} />
        <Typography>Example spreadsheet</Typography>
      </Stack>
      <FullSample rows={rows} open={show} onClose={() => setShow(false)} />
    </TableContainer>
  )
}

const defaultInstructions = [
  'Each row should be a seperate task',
  'You can name the columns however you want - you can specify what each column is in the next step',
  'Each task must have a question',
]
const Instructions = ({taskType}: {taskType: TaskType | 'mixed'}) => (
  <>
    <Samples rows={sampleData[taskType]} />
    <Card elevation={0} sx={{p: 5, bgcolor: 'secondary.main'}}>
      <Typography sx={{fontWeight: 700}}>How to create your spreadsheet</Typography>
      <List>
        {[...defaultInstructions, ...taskSpecificInfo[taskType]].map((info, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={
                <>
                  {`${i + 1}. `} {info}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  </>
)

const CSVFetcher = ({evaluation, onChange}: EvaluationEditProps) => {
  const [checking, setChecking] = useState(false)
  const [uploadType, setUploadType] = useState<keyof typeof labels>('gdocs')
  const [columnsMapping, setColumnsMapping] = useState({} as {[k: string]: ColumnsConfig})
  const [validate, setValidate] = useState(false)
  const [error, setError] = useState<string>()
  const taskType = evaluation?.default_task_type || 'mixed'

  const checkTasksUrl = async () => {
    const url = evaluation.csv_url || ''
    const invalid = url && isInvalidURL(url)
    setValidate(!invalid)

    if (invalid) {
      setError(invalid)
      return
    }
    setChecking(true)

    let mapping = columnsMapping[url]
    if (!mapping) {
      try {
        const {origin, pathname, search} = new URL(url)
        const {columns} = await makeApi().evaluations.fetchEvaluationTasksHeaders(
          origin + pathname + search
        )
        mapping = columns.reduce(
          (acc, col) => ({
            ...acc,
            [col]: {columnType: guessType(col.toLowerCase().trim(), evaluation.default_task_type)},
          }),
          {}
        )
      } catch (err) {
        setError(err instanceof ServerError ? err.error.toString() : `${err}`)
        setError(`${err}`)
        setChecking(false)
        return false
      }
    }
    setColumnsMapping((current) => ({...current, [url]: mapping}))
    onChange('columns_mapping', mapping)
    setError('')
    setChecking(false)
    return true
  }

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      await checkTasksUrl()
    }
  }

  return (
    <Stack spacing={2}>
      <Typography>Upload your evaluation in the form of a spreadsheet</Typography>

      <RadioGroup row defaultValue="gdocs">
        {Object.entries(labels)
          .sort((a, b) => -a[0].localeCompare(b[0]))
          .map(([item, texts]) => (
            <FormControlLabel
              key={item}
              value={item}
              control={<Radio />}
              label={texts.radio}
              onChange={() => setUploadType(item as keyof typeof labels)}
            />
          ))}
      </RadioGroup>

      <ButtonInput
        label={labels[uploadType]?.hint}
        defaultValue={evaluation?.csv_url}
        setter={(url: string) => onChange('csv_url', url)}
        validate={validate}
        loading={checking}
        buttonLabel={labels[uploadType]?.label}
        onClick={checkTasksUrl}
        onKeyDown={handleKeyDown}
      />
      {error && <Typography color="error">Error: {error}</Typography>}

      {!evaluation.columns_mapping && <Instructions taskType={taskType} />}
    </Stack>
  )
}

export default CSVFetcher
