import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import type {RunEvalsStageProps} from './types'
import {ConnectionItem} from './widgets'
import {Typography} from '@mui/material'

const RunSummary = ({itemType, runConfig}: RunEvalsStageProps) => {
  const recurring = (runConfig.cadence || 'once') !== 'once'
  const totalPrice = runConfig.selected.reduce((sum, {price}) => sum + (price || 1), 0)
  return (
    <Stack spacing={2}>
      {runConfig.selected.map((item) => (
        <ConnectionItem
          key={item.id}
          item={item}
          itemType={itemType === 'model' ? 'evaluation' : 'model'}
          alignPrice="right"
          priceHint={recurring ? `Billed ${runConfig.cadence}` : ''}
        />
      ))}
      <Divider />
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Typography sx={{color: 'secondary.subText'}}>Total{recurring && ' due today'}</Typography>
        <Typography sx={{fontWeight: 600}}>${totalPrice.toFixed(2)}</Typography>
      </Stack>
    </Stack>
  )
}

export default RunSummary
