import React, {useState} from 'react'
import Typography from '@mui/material/Typography'
import {Input} from './inputs'
import {isInvalidEmail} from './validators'
import {Form} from '../../components/forms'

interface ResetPasswordFormProps {
  onSubmit: (email: string) => Promise<string | null>
}

type FormStates = 'form' | 'sending' | 'email sent'
const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({onSubmit}) => {
  const [invalid, setInvalid] = useState(false)
  const [email, setEmail] = useState('')
  const [state, setState] = useState<FormStates>('form')

  const isInvalid = () => !!isInvalidEmail(email)
  const submitter = async () => {
    setState('sending')
    const res = await onSubmit(email)
    res &&
      setState(res.startsWith('An email with instructions was sent to') ? 'email sent' : 'form')
    return res && res.toString()
  }

  if (state !== 'email sent') {
    return (
      <Form
        actionLabel="Reset password"
        isInvalid={isInvalid}
        setInvalid={setInvalid}
        submitter={submitter}
        rounded
      >
        <Input
          label="Email address:"
          value={email}
          setter={setEmail}
          validate={invalid}
          validator={isInvalidEmail}
          type="email"
        />
      </Form>
    )
  }
  return (
    <Typography sx={{mt: 10, mb: 10}}>
      If the provided email address was found in the system, an email with instructions was sent to
      it
    </Typography>
  )
}

export default ResetPasswordForm
