import {SVGProps} from 'react'
const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.846 3.914A11.078 11.078 0 0 0 9.999 3a.058.058 0 0 0-.047.02c-.12.227-.26.523-.353.75a10.408 10.408 0 0 0-3.2 0 6.979 6.979 0 0 0-.36-.75C6.032 3.008 6.012 3 5.992 3c-1 .179-1.953.488-2.846.914-.007 0-.014.007-.02.014C1.312 6.725.812 9.448 1.058 12.14c0 .014.006.028.02.035 1.2.907 2.353 1.457 3.493 1.821.02.007.04 0 .047-.014.267-.378.507-.776.713-1.196.014-.027 0-.055-.026-.061-.38-.152-.74-.33-1.094-.537-.026-.013-.026-.055-.006-.075.073-.055.146-.117.22-.172a.04.04 0 0 1 .046-.007c2.294 1.08 4.767 1.08 7.034 0a.04.04 0 0 1 .046.007c.074.062.147.117.22.179.027.02.027.062-.006.075a7.054 7.054 0 0 1-1.094.537c-.026.006-.033.04-.026.061.213.42.453.818.713 1.196.02.007.04.014.06.007 1.147-.364 2.3-.914 3.5-1.821a.038.038 0 0 0 .02-.035c.293-3.113-.486-5.814-2.067-8.213-.006-.007-.013-.014-.026-.014ZM5.679 10.5c-.687 0-1.26-.653-1.26-1.457 0-.805.56-1.458 1.26-1.458.707 0 1.267.66 1.26 1.458 0 .804-.56 1.457-1.26 1.457Zm4.647 0c-.687 0-1.26-.653-1.26-1.457 0-.805.56-1.458 1.26-1.458.706 0 1.266.66 1.26 1.458 0 .804-.554 1.457-1.26 1.457Z"
      stroke="#788492"
    />
  </svg>
)
export default SvgDiscord
