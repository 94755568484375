import {SVGProps} from 'react'
const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={2.5} stroke="#788492" strokeLinecap="square" strokeLinejoin="round" />
    <path
      d="M13.438 10.236a.5.5 0 0 0-.058-.567l-.528-.618s0 0 0 0a1.612 1.612 0 0 1-.379-1.044c0-.388.136-.759.38-1.044l.527-.618a.5.5 0 0 0 .057-.567l-.845-1.527a.5.5 0 0 0-.54-.246l-.78.165h0c-.351.074-.717.013-1.029-.176a1.548 1.548 0 0 1-.67-.861V3.13l-.251-.783A.5.5 0 0 0 8.846 2H7.155a.5.5 0 0 0-.476.346l-.251.777h0l-.001.003a1.548 1.548 0 0 1-.67.861c-.312.189-.678.25-1.028.176h0l-.78-.165a.5.5 0 0 0-.54.245l-.845 1.512a.5.5 0 0 0 .056.57l.528.618.001.001c.246.286.384.66.384 1.05s-.138.763-.384 1.048v.001l-.53.62a.5.5 0 0 0-.054.57l.858 1.52a.5.5 0 0 0 .54.242l.779-.165h0c.35-.074.716-.013 1.028.176.313.188.553.492.67.858l.236.781a.5.5 0 0 0 .479.355h1.69a.5.5 0 0 0 .476-.345l.25-.77h0l.002-.004c.117-.367.357-.673.67-.861.312-.19.678-.25 1.028-.176h0l.78.165a.5.5 0 0 0 .541-.247l.845-1.526Z"
      stroke="#788492"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgSettings
