import {useState, ReactElement, ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button, {buttonClasses} from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import {ConfirmationDialog} from 'components/dialogs'
import {VisuallyHiddenInput, equistampButton, TextVariant, Text} from 'components/text'
import {DarkTooltip, HelpTooltip, OptionsGroup} from 'components/filters/widgets'
import type {SvgIconComponent} from '@mui/icons-material'
import {ActionButtonProps, ButtonProps, RoundedButtonProps} from 'components/filters/types'

const iconOffset = {
  '& .MuiFormLabel-root': {
    pl: '26px',
  },
  '& .MuiInputBase-input': {
    pl: '40px',
  },
}

type WithIconProps = {
  icon?: ReactElement
  children: ReactElement | ReactElement[]
}
export const WithIcon = ({icon, children}: WithIconProps) => {
  if (!icon) return <>{children}</>
  return (
    <Box sx={{...(icon && iconOffset), position: 'relative'}}>
      {icon && <Box sx={{position: 'absolute', top: 18, left: 16}}>{icon}</Box>}
      {children}
    </Box>
  )
}

export type InputProps = {
  label?: string
  value?: string | undefined
  defaultValue?: string | undefined
  setter?: (v: string) => void
  validate?: boolean
  validator?: (v: string | undefined) => string | boolean
  error?: string
  fullWidth?: boolean
  icon?: ReactElement
} & {[key: string]: any}
export const Input = ({
  label,
  value,
  setter,
  validate,
  validator,
  error,
  icon,
  ...props
}: InputProps) => {
  const shownError = error || (validate && validator && validator(value))
  return (
    <WithIcon icon={icon}>
      <TextField
        label={label}
        helperText={shownError}
        error={!!shownError}
        value={value}
        onChange={(e) => setter && setter(e.target.value)}
        {...props}
      />
    </WithIcon>
  )
}

export const MInput = ({
  label,
  value,
  setter,
  validate,
  validator,
  error,
  fullWidth,
  icon,
  sx,
  ...props
}: InputProps) => {
  const shownError = error || (validate && validator && validator(value))
  return (
    <OptionsGroup label={label} sx={fullWidth ? {width: '100%', ...sx} : sx}>
      <TextField
        helperText={shownError}
        error={!!shownError}
        value={value}
        onChange={(e) => setter && setter(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'primary.main',
          },
        }}
        {...props}
      />
    </OptionsGroup>
  )
}

type InputFieldUploadType = {
  text?: string
  sx?: {[k: string]: any}
  onUpload: (data: any) => void
}

export const InputFileUpload = ({onUpload, text, sx}: InputFieldUploadType) => {
  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
      sx={{...equistampButton, ...sx}}
    >
      {text || 'Upload file'}
      <VisuallyHiddenInput
        type="file"
        onChange={(e) => onUpload(e.target?.files && e.target.files[0])}
      />
    </Button>
  )
}

type EditableTextType = {
  value: string | undefined
  variant?: TextVariant
  onChange?: (val: any) => void | Promise<any>
  multiline?: boolean
  markdown?: boolean
  textSx?: any
  editSx?: any
} & InputProps
export const EditableText = ({
  value,
  variant,
  markdown,
  onChange,
  editSx,
  textSx,
  validate,
  validator,
  ...props
}: EditableTextType) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [isEdit, setIsEdit] = useState(false)

  const isInvalid = isEdit && validate && validator && validator(currentValue)

  return (
    <Box>
      {isEdit ? (
        <Stack direction="row">
          <Input
            value={currentValue}
            setter={setCurrentValue}
            sx={editSx}
            validate={validate}
            validator={validator}
            {...props}
          />
          <Button
            disabled={!!isInvalid}
            variant="text"
            onClick={() => {
              onChange && onChange(currentValue)
              setIsEdit(!isEdit)
            }}
          >
            Update
          </Button>
        </Stack>
      ) : (
        <Stack direction="row">
          <Text variant={variant} style={textSx} text={currentValue || ''} markdown={markdown} />
          <Button variant="text" onClick={() => setIsEdit(!isEdit)}>
            Edit
          </Button>
        </Stack>
      )}
    </Box>
  )
}

type ToggleType = {
  title: string | ReactNode
  on?: boolean
  disabled?: boolean
  onChange?: (v: boolean) => void
}
export const Toggle = ({title, on, disabled, onChange}: ToggleType) => {
  const [switchOn, setSwitchOn] = useState(on)
  const toggle = (on: boolean) => {
    setSwitchOn(on)
    onChange && onChange(on)
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{cursor: 'pointer', width: 'fit-content', color: disabled ? 'grey' : undefined}}
      onClick={() => !disabled && toggle(!on)}
    >
      <Switch
        disabled={!!disabled}
        checked={switchOn || false}
        onChange={(e) => toggle(e.target.checked)}
      />
      <Typography>{title}</Typography>
    </Stack>
  )
}

type SelectProps = {
  value?: string | string[]
  label?: string
  defaultValue?: any
  variant?: string
  values: {[key: string]: string}
  onChange?: (v: any) => void
  validate?: boolean
  validator?: (v: any) => string | boolean
  sx?: any
  alwaysSelected?: boolean
  unselected?: string
  icon?: ReactElement
} & {[key: string]: any}
export const SelectElem = ({
  label,
  values,
  variant,
  value,
  defaultValue,
  validate,
  validator,
  onChange,
  alwaysSelected,
  unselected = '-',
  icon,
  sx,
  ...props
}: SelectProps) => {
  const [val, setValue] = useState(defaultValue || '')
  const labelId = (label || '').toLowerCase().replace(' ', '-')

  const handleChange = (e: SelectChangeEvent) => {
    const val = e.target.value
    setValue(val)
    onChange && onChange(val)
  }

  const error = validate && validator && validator(val)
  const showLabel = !value?.length && label
  if (showLabel) {
    props.label = label
  }

  return (
    <WithIcon icon={icon}>
      <FormControl sx={{minWidth: 250, ...sx}} variant={variant as any} error={!!error}>
        {showLabel && <InputLabel id={labelId}>{error || props.label}</InputLabel>}
        <Select
          labelId={labelId}
          value={value as any}
          defaultValue={defaultValue}
          onChange={handleChange}
          {...props}
        >
          {!alwaysSelected && !Array.isArray(value) && (
            <MenuItem value="">
              <em>{unselected}</em>
            </MenuItem>
          )}
          {Object.entries(values).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </WithIcon>
  )
}

type IconButtonProps = ButtonProps & {
  Icon: SvgIconComponent
  selected?: boolean
}
export const IconButton = ({Icon, sx, selected, ...props}: IconButtonProps) => (
  <Button
    variant="outlined"
    startIcon={<Icon sx={{fontSize: 10}} />}
    sx={{
      minWidth: 16,
      width: 16,
      p: '0 12px',
      borderColor: selected ? 'primary.main' : 'primary.border',
      color: selected ? 'primary.main' : 'secondary.subText',
      [`& .${buttonClasses.startIcon}`]: {m: '2px'},
      ...sx,
    }}
    {...props}
  />
)

const variantDefaults = {
  outlined: {
    borderColor: 'primary.border',
    color: 'text.primary',
    fontWeight: 'unset',
  },
  text: {},
  contained: {},
}

export const RoundedButton = ({
  label,
  disabled,
  type,
  variant,
  loading,
  endIcon,
  onClick,
  tooltip,
  sx,
}: RoundedButtonProps) => {
  const Wrapper = ({children}: {children: ReactElement}) => {
    if (tooltip) return <DarkTooltip title={tooltip}>{children}</DarkTooltip>
    return <>{children}</>
  }
  return (
    <Wrapper>
      <Button
        variant={variant || 'contained'}
        disabled={disabled || loading}
        sx={{...equistampButton, ...variantDefaults[variant || 'contained'], ...sx}}
        type={type as any}
        onClick={onClick}
        endIcon={endIcon}
      >
        {loading ? <CircularProgress color="secondary" size={25} /> : label}
      </Button>
    </Wrapper>
  )
}

export type NavButtonProps = RoundedButtonProps & {
  to: string
}
export const NavButton = ({label, to, sx, ...props}: NavButtonProps) => (
  <Button
    variant="contained"
    component={NavLink}
    to={to}
    sx={{...equistampButton, ...variantDefaults['contained'], ...sx}}
    {...props}
  >
    {label}
  </Button>
)

export const ActionButton = (props: ActionButtonProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showLoading, setLoading] = useState(false)

  const executeAction = async () => {
    setLoading(true)
    try {
      await Promise.resolve(props.action())
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const handleDialog = (confirmed: boolean) => {
    if (confirmed) {
      executeAction()
    }
    setShowConfirmation(false)
  }

  const handleClick = async () => {
    if (confirmationText) {
      setShowConfirmation(true)
    } else {
      return executeAction()
    }
  }

  const {loading, confirmationText, confirmationLabel} = props
  const actionProps = ['action', 'loading', 'confirmationText', 'confirmationLabel']
  const buttonProps = Object.fromEntries(
    Object.entries(props).filter(([k, v]) => !actionProps.includes(k))
  )
  return (
    <>
      <ConfirmationDialog
        label={confirmationLabel || ''}
        onSelect={handleDialog}
        open={showConfirmation}
      >
        {confirmationText || ''}
      </ConfirmationDialog>
      <RoundedButton {...buttonProps} onClick={handleClick} loading={showLoading || loading} />
    </>
  )
}

export const RedirectButton = ({
  label,
  disabled,
  urlGetter,
  sx,
}: {
  label?: string | ReactElement
  disabled?: boolean
  urlGetter: () => Promise<string>
  sx?: {[k: string]: any}
}) => (
  <ActionButton
    label={label}
    disabled={disabled}
    sx={sx}
    action={async () => {
      const url = await urlGetter()
      if (url) {
        window.location.href = url
      }
    }}
  />
)

export const handleDownload = async (fetcher: () => Promise<any>) => {
  const blob = await fetcher()
  const blobUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', 'tasks.csv')
  document.body.appendChild(link)
  link.click()
  link?.parentNode?.removeChild(link)
  window.URL.revokeObjectURL(blobUrl)
}

export const DownloadButton = (props: RoundedButtonProps & {fetcher: () => Promise<any>}) => {
  const [error, setError] = useState('')
  const [showLoading, setLoading] = useState(false)

  const handleClick = async () => {
    try {
      setError('')
      setLoading(true)
      await handleDownload(props.fetcher)
    } catch (error) {
      console.error('Error downloading file:', error)
      setError('Error downloading file.')
    }
    setLoading(false)
  }

  return (
    <>
      <RoundedButton {...props} onClick={handleClick} loading={showLoading || props.loading} />
      {error && <Alert severity="error">{error}</Alert>}
    </>
  )
}

type ButtonInputProps = {
  buttonLabel: string
  disabled?: boolean
  loading?: boolean
  onClick: () => void
} & InputProps
export const ButtonInput = ({
  buttonLabel,
  disabled,
  loading,
  onClick,
  sx,
  ...props
}: ButtonInputProps) => (
  <Box sx={{position: 'relative'}}>
    <Input
      {...props}
      sx={{
        width: '100%',
        '.MuiInputBase-input': {
          width: 'calc(100% - 175px)',
        },
        ...sx,
      }}
    />
    <RoundedButton
      label={buttonLabel}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      sx={{position: 'absolute', right: 9, top: 9}}
    />
  </Box>
)

type CheckButtonProps = {
  children: ReactElement | string
  subText?: string
  selected?: boolean
  onClick: () => void
  perRow?: number
  sx?: {[k: string]: any}
} & InputProps
export const CheckButton = ({selected, children, subText, sx, ...props}: CheckButtonProps) => (
  <RoundedButton
    label={
      <Box sx={{width: '100%'}}>
        {children}
        {subText && (
          <Typography color={selected ? undefined : 'secondary.subText'}>{subText}</Typography>
        )}
        {selected && (
          <CheckCircleIcon sx={{width: '14px', right: '5px', top: '5px', position: 'absolute'}} />
        )}
      </Box>
    }
    variant="outlined"
    sx={{
      color: selected ? undefined : 'inherit',
      borderColor: selected ? undefined : 'primary.border',
      height: '100%',
      width: '100%',
      pb: 2,
      pt: 2,
      ...sx,
    }}
    {...props}
  />
)

type ButtonGroupItem = {
  label: string
  subText?: string
  hint?: string
  id?: string
  sx?: {[k: string]: any}
}
type GridButtonProps = ButtonGroupItem & {
  selected?: boolean
  onClick: () => void
  perRow?: number
}
const GridButton = ({perRow, label, hint, sx, ...props}: GridButtonProps) => (
  <Grid item xs={12} sm={6} md={12 / (perRow || 3)} sx={{pr: 3, pt: 3, ...(sx || {})}}>
    <CheckButton {...props}>
      <>
        {label} {hint && <HelpTooltip help={hint} />}
      </>
    </CheckButton>
  </Grid>
)

type ButtonGroupProps = {
  selected?: string | string[]
  items: ButtonGroupItem[]
  onSelect: (id?: string | string[]) => void
  perRow?: number
}
export const ButtonGroup = ({selected, onSelect, items, perRow}: ButtonGroupProps) => {
  const isSelected = (item: ButtonGroupItem) => {
    if (!selected) return false
    if (Array.isArray(selected)) return selected.includes(item.id || '')
    return selected === item.id
  }

  const handleSelect = (item: ButtonGroupItem) => {
    if (Array.isArray(selected) && item.id) {
      onSelect(
        selected.includes(item.id) ? selected.filter((i) => i === item.id) : [...selected, item.id]
      )
    } else {
      onSelect(item.id)
    }
  }

  return (
    <Grid container>
      {items.map((item) => (
        <GridButton
          key={item.id || item.label}
          perRow={perRow}
          onClick={() => handleSelect(item)}
          selected={isSelected(item)}
          {...item}
        />
      ))}
    </Grid>
  )
}
