import {useState, useEffect} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Input} from '../../../components/forms/inputs'
import {isInvalidJSON} from '../../../components/forms/validators'
import type {ModelStageProps} from './types'

const EndpointBody = ({endpoint, onEndpointUpdate, setNextHandler, index}: ModelStageProps) => {
  const [validate, setValidate] = useState(false)
  const [body, setBody] = useState(endpoint?.body)

  useEffect(() => {
    const checkCorrect = () => {
      const valid = !isInvalidJSON(body)
      setValidate(!valid)
      return valid
    }

    setNextHandler(checkCorrect)
  }, [index, body, setNextHandler])

  return (
    <Box>
      <Typography>
        All requests will be sent as POSTs with a JSON body. This field is used to provide a
        template object with the structure that you expect to receive. Please use the string
        "%PROMPT%" to specify where the actual prompt should be injected.
      </Typography>

      <Input
        label="Endpoint Body"
        value={body || ''}
        validate={validate}
        validator={isInvalidJSON}
        setter={(val: string) => {
          setBody(val)
          onEndpointUpdate('body', val)
        }}
        sx={{mt: 5, width: '100%'}}
      />
    </Box>
  )
}

export default EndpointBody
