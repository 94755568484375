import {MInput} from 'components/forms/inputs'
import {isInvalidURL} from 'components/forms/validators'

type UrlInputProps = {
  label?: string
  url?: string
  validate?: boolean
  onChange: (v?: string) => void
}
const UrlInput = ({label, url, onChange, validate}: UrlInputProps) => {
  return (
    <MInput
      label={label || 'URL'}
      value={url || ''}
      setter={onChange}
      validate={!!validate}
      validator={isInvalidURL}
      fullWidth
    />
  )
}
export default UrlInput
