import {formatRoundPercentage} from 'components/formatters'
import {Text} from 'components/text'
import {ScoreModel} from './ScoresTable'
import Path from 'routeLinks'
import type {Evaluation} from '@equistamp/types'
import type {Column} from './columns'
import PastRuns from 'components/evaluationSessions/PastRuns'
import RunEvaluationButton from 'components/evaluationSessions/RunEvaluationButton'

export const unique = (items: Column[]) =>
  Object.values(items.reduce((acc, i) => ({...acc, [i.id]: i}), {})) as Column[]

export const evalScoreFormatter = (e: Evaluation) => (v?: number, m?: ScoreModel) => {
  if (v !== undefined) return formatRoundPercentage(v)
  if (m) return <RunEvaluationButton evaluation={e} model={m} />
  return null
}

export const asColumn = (e: Evaluation) =>
  ({
    ...e,
    formatter: evalScoreFormatter(e),
    columnUrl: Path.evaluations.show(e.id),
    hint: <Text text={e.description} variant="inherit" markdown />,
    type: 'evaluation',
    filterType: 'percentage',
    group: 'Evaluations',
    urlGetter: (col: Column, m: ScoreModel) => {
      const eval_session_id = m.scores[col.id]?.evaluation_session_id
      return eval_session_id && Path.evaluationSessions.show(eval_session_id)
    },
    onHover: (col: Column, s: ScoreModel) =>
      s[col.id] !== undefined && <PastRuns evaluation={col} model={s} />,
  } as Column)
