import {useState, useEffect} from 'react'
import Stack from '@mui/material/Stack'
import useTags from 'hooks/useTags'
import useUser from 'hooks/useUser'
import {MInput, SelectElem, Toggle} from 'components/forms/inputs'
import {HelpTooltip, OptionsGroup} from 'components/filters/widgets'
import {Evaluation as EvaluationType} from '@equistamp/types'
import {PRIVATE_EVALUATION, PRIVATE_REPORTS, USABLE_EVALUATION} from 'permissions'
import type {EvaluationStageProps} from './types'
import {Typography} from '@mui/material'

const BasicSettings = ({evaluation, onChange, setNextHandler, index}: EvaluationStageProps) => {
  const [valid, setValid] = useState({name: false, description: false})
  const {hasPermission} = useUser()
  const [selectedTags, setSelectedTags] = useState(evaluation?.tags?.map(({id}) => id) || [])
  const {tags} = useTags()

  const updateTags = (tagIds: string[]) => {
    setSelectedTags(tagIds)
    onChange(
      'tags',
      tags.filter(({id}) => tagIds.includes(id))
    )
  }

  useEffect(() => {
    const checkCorrect = () => {
      setValid({name: !evaluation.name, description: !evaluation.description})
      return !!(evaluation.name && evaluation.description)
    }

    setNextHandler(checkCorrect)
  }, [index, evaluation, setNextHandler])

  const setVal = (field: keyof EvaluationType) => (val: any) => onChange(field, val)

  return (
    <Stack spacing={2}>
      <MInput
        label="Title"
        defaultValue={evaluation.name}
        setter={setVal('name')}
        validate={valid.name}
        onFocus={() => setValid((current) => ({...current, name: false}))}
        validator={(v) => !v && 'Title is required'}
      />
      <MInput
        label="Evaluation description"
        defaultValue={evaluation.description}
        validate={valid.description}
        setter={setVal('description')}
        validator={(v) => !v && 'Please provide a description'}
        onFocus={() => setValid((current) => ({...current, description: false}))}
        multiline
        rows={4}
      />

      {tags.length > 0 && (
        <OptionsGroup label="Tags">
          <SelectElem
            label="Choose tags"
            multiple
            displayEmpty
            onChange={updateTags}
            value={selectedTags}
            values={tags.reduce((acc, {id, name}) => ({...acc, [id]: name}), {})}
          />
        </OptionsGroup>
      )}

      {hasPermission(PRIVATE_EVALUATION) ? (
        <Toggle
          title={
            <Typography component="span">
              Share publicly{' '}
              <HelpTooltip help="When set, this evaluation can be viewed by anyone - this includes the evaluation page, reports and running it on models, but only you will be able to view the actual tasks." />
            </Typography>
          }
          on={!!evaluation?.public}
          onChange={(val: boolean) => setVal('public')(val)}
        />
      ) : undefined}
      {hasPermission(USABLE_EVALUATION) ? (
        <Toggle
          title={
            <Typography component="span">
              Anyone can run{' '}
              <HelpTooltip help="When set, anyone who can see this evaluation will be able to run it." />
            </Typography>
          }
          on={!!evaluation?.public_usable}
          onChange={(val: boolean) => setVal('public_usable')(val)}
        />
      ) : undefined}
      {hasPermission(PRIVATE_REPORTS) ? (
        <Toggle
          title={
            <Typography component="span">
              Hide reports{' '}
              <HelpTooltip help="When set, only you and those explicitly grantend access will be able to view the reports of this evaluation." />
            </Typography>
          }
          on={!evaluation?.reports_visible}
          onChange={(val: boolean) => setVal('reports_visible')(!val)}
        />
      ) : undefined}
    </Stack>
  )
}

export default BasicSettings
