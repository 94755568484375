import {useState, useEffect} from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import useTags from 'hooks/useTags'
import useUser from 'hooks/useUser'
import {MInput, ButtonGroup, SelectElem, Toggle} from 'components/forms/inputs'
import {HelpTooltip, OptionsGroup} from 'components/filters/widgets'
import {Alert as AlertType} from '@equistamp/types'
import {ALERT_COOLDOWN, PUBLIC_ALERT} from 'permissions'
import type {AlertStageProps} from './types'
import {alertCooldowns} from 'components/filters/constants'

const COOLDOWN_HELP = `This controls how often the alert can be sent. Some triggers will fire multiple times, e.g. whenever a
new model is added. Here you can specify whether an alert should be sent only once, at most once a day, or every time
the trigger happens.`

const BasicSettings = ({alert, onChange, setNextHandler, index}: AlertStageProps) => {
  const [valid, setValid] = useState({name: false, description: false})
  const {hasPermission} = useUser()
  const [selectedTags, setSelectedTags] = useState(alert?.tags?.map(({id}) => id) || [])
  const {tags} = useTags()

  const updateTags = (tagIds: string[]) => {
    setSelectedTags(tagIds)
    onChange(
      'tags',
      tags.filter(({id}) => tagIds.includes(id))
    )
  }

  useEffect(() => {
    const checkCorrect = () => {
      setValid({name: !alert.name, description: !alert.description})
      return !!(alert.name && alert.description)
    }

    setNextHandler(checkCorrect)
  }, [index, alert, setNextHandler])

  const setVal = (field: keyof AlertType) => (val: any) => onChange(field, val)

  return (
    <Stack spacing={2}>
      <MInput
        label="Title"
        defaultValue={alert.name}
        setter={setVal('name')}
        validate={valid.name}
        onFocus={() => setValid((current) => ({...current, name: false}))}
        validator={(v) => !v && 'Title is required'}
      />
      <MInput
        label="Alert description"
        defaultValue={alert.description}
        validate={valid.description}
        setter={setVal('description')}
        validator={(v) => !v && 'Please provide a description'}
        onFocus={() => setValid((current) => ({...current, description: false}))}
        multiline
        rows={4}
      />

      {tags.length > 0 && (
        <OptionsGroup label="Tags">
          <SelectElem
            label="Choose tags"
            multiple
            displayEmpty
            onChange={updateTags}
            value={selectedTags}
            values={tags.reduce((acc, {id, name}) => ({...acc, [id]: name}), {})}
          />
        </OptionsGroup>
      )}

      {hasPermission(ALERT_COOLDOWN) && (
        <OptionsGroup
          label={
            <Typography component="span">
              Repeat rate <HelpTooltip help={COOLDOWN_HELP} />
            </Typography>
          }
        >
          <ButtonGroup
            perRow={3}
            selected={alert.trigger_cooldown}
            onSelect={(v?: string | string[]) => onChange('trigger_cooldown', v)}
            items={Object.entries(alertCooldowns).map(([id, label]) => ({id, label, sx: {pt: 0}}))}
          />
        </OptionsGroup>
      )}

      {hasPermission(PUBLIC_ALERT) && (
        <Toggle
          title={
            <Typography component="span">
              Share publicly{' '}
              <HelpTooltip help="When set, this alert can be viewed and subscribed to by anyone" />
            </Typography>
          }
          on={!!alert?.public}
          onChange={(val: boolean) => setVal('public')(val)}
        />
      )}
    </Stack>
  )
}

export default BasicSettings
