import {useState} from 'react'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {NavButton, RoundedButton} from 'components/forms/inputs'
import {InfoItem} from 'components/text'
import CallToAction from 'components/CallToAction'
import {Evaluation as EvaluationType, Tag as TagType} from '@equistamp/types'
import Path from 'routeLinks'
import {EvalWizardModal} from './EvaluationWizard'
import useUser from 'hooks/useUser'

const Tag = ({id, name}: TagType) => (
  <Stack key={id} spacing={1} direction="row" alignItems="center" sx={{p: 1}}>
    <Avatar src="/imgs/shield.png" sx={{height: 40, width: 40}} />
    <Typography variant="h5">{name}</Typography>
  </Stack>
)

type EditableSummaryProps = {
  evaluation: EvaluationType
  updateEvaluation?: (evaluation: EvaluationType) => void
}
export const EvaluationSummary = ({evaluation, updateEvaluation}: EditableSummaryProps) => {
  const {canEdit} = useUser()
  const [showEditModal, setShowEditModal] = useState(false)
  const updateEval = async (e: EvaluationType) => {
    const res = updateEvaluation && (await updateEvaluation(e))
    setShowEditModal(false)
    return res || null
  }

  return (
    <Container sx={{mt: 3}}>
      <Stack>
        <Grid container spacing={2} sx={{mb: 3}}>
          <Grid item xs={12} md={7}>
            <InfoItem underline markdown title="Description:" value={evaluation.description} />
            {(evaluation.public_usable || canEdit(evaluation)) && (
              <Stack direction="row" spacing={3} sx={{mt: 4}}>
                <CallToAction
                  title="Want to compare models?"
                  actionLabel="Compare"
                  action={Path.evaluations.connections(evaluation.id)}
                >
                  Run this evaluation on another model to compare how they perform
                </CallToAction>

                <CallToAction
                  title="Are you smarter than AI?"
                  actionLabel="Take test"
                  action={Path.evaluations.tests(evaluation.id)}
                >
                  Take this test and add to our human curated data set to improve evaluation
                  standards.
                </CallToAction>
              </Stack>
            )}
          </Grid>
          <Grid item xs={0} md={1}></Grid>

          <Grid item xs={12} md={4}>
            <Stack direction="column" spacing={10} sx={{height: '100%'}}>
              {updateEvaluation && (
                <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="flex-end" spacing={2}>
                  <RoundedButton label="Edit" onClick={() => setShowEditModal(true)} />
                  <NavButton label="Manage Tasks" to={Path.evaluations.tasks(evaluation.id)} />
                </Stack>
              )}
              {(evaluation.tags || []).length > 0 ? (
                <InfoItem underline title="Evaluates:" value={evaluation.tags?.map(Tag)} />
              ) : undefined}
            </Stack>
          </Grid>
        </Grid>

        {updateEvaluation && (
          <EvalWizardModal
            basic
            customCode
            title="Edit evaluation"
            show={showEditModal}
            initial={evaluation}
            onCancel={() => setShowEditModal(false)}
            onSave={updateEval}
          />
        )}
      </Stack>
    </Container>
  )
}

export default EvaluationSummary
