import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {makeApi, ServerError} from '@equistamp/api'
import type {Alert as AlertType} from '@equistamp/types'
import Path from 'routeLinks'
import AlertWizard from 'components/alerts/AlertWizard'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import {InfoDialog} from 'components/dialogs'
import {RoundedButton} from 'components/forms/inputs'
import useAlerts from 'hooks/useAlerts'
import useUser from 'hooks/useUser'
import Page from 'components/Page'

const EditAlert = ({item: alert}: RendererProps<AlertType>) => {
  const {user, isAdmin} = useUser()
  const [showInfo, setShowInfo] = useState(false)
  const {refresh} = useAlerts()
  const navigate = useNavigate()

  const saveAlert = async (a: AlertType) => {
    try {
      const res = await makeApi().alerts.update(a)
      if (res === 'Alert updated') {
        refresh({}, true)
        setShowInfo(true)
      }
      return null
    } catch (e) {
      const error = e instanceof ServerError ? e.error.toString() : `${e}`
      return 'Error while updating alert: ' + error
    }
  }

  const onClose = () => navigate(Path.alerts.all())

  if (!user?.id || !(isAdmin || user?.id === alert.owner?.id)) {
    return (
      <Page title="Permission error" showTitle>
        <div>
          <h1>You're not allowed to edit this alert</h1>
        </div>
      </Page>
    )
  }

  return (
    <>
      <AlertWizard
        title="Create an alert"
        onCancel={onClose}
        onSave={saveAlert}
        initial={alert}
        basic
        triggers
        notifications
      />
      <InfoDialog label="Your alert has been updated" open={showInfo} onClose={onClose}>
        <RoundedButton label="Ok" onClick={onClose} />
      </InfoDialog>
    </>
  )
}

const Editor: React.FC = () => <ItemLoader itemName="alert" renderer={EditAlert} />
export default Editor
