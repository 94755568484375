import React, {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import Page from 'components/Page'
import EvalRuns, {Column} from 'components/evaluationSessions/EvalRuns'
import {Eval, FiltersTypes} from '@equistamp/types'
import Path from 'routeLinks'

type LatestRunsProps = {
  title: string
  filters: FiltersTypes
  columns?: Column[]
}
const defaultCols = [
  'evaluation',
  'model',
  'date_created',
  'run_time',
  'score',
  'price',
  'real_cost',
  'actions',
  'tasks_completed',
] as Column[]

const fields = [
  'evaluation_id',
  'evaluation_name',
  'evaluatee_id',
  'evaluatee_name',
  'datetime_started',
  'datetime_completed',
  'num_questions_answered',
  'num_tasks_to_complete',
  'price',
  'estimated_session_cost_usd',
  'score',
]

const LatestRuns = ({title, filters, columns}: LatestRunsProps) => {
  const [runs, setRuns] = useState<Eval[]>()
  useEffect(() => {
    const fetch = async () => {
      const res = await makeApi().evaluationSessions.list({
        sort: {key: 'datetime_completed', direction: 'Desc'},
        filters: {...filters, fields},
      })
      setRuns(res.items as Eval[])
    }
    fetch()
  }, [filters])

  return (
    <Stack>
      <Typography variant="h4">{title}</Typography>
      {runs &&
        (runs.length > 0 ? (
          <EvalRuns evals={runs} columns={columns || defaultCols} />
        ) : (
          <Typography>Nothing found</Typography>
        ))}
      {!runs && <Typography>Fetching evaluation sessions...</Typography>}
    </Stack>
  )
}

const EvaluationSessions: React.FC = () => (
  <Page
    title="Latest Evaluation Sessions"
    showTitle
    breadcrumbs={[{title: 'admin', link: Path.admin.index()}, {title: 'evaluation sessions'}]}
  >
    <Stack spacing={6} sx={{mt: 6}}>
      <LatestRuns title="Running" filters={{completed: false}} />
      <LatestRuns title="Recently failed" filters={{completed: true, failed: true}} />
      <LatestRuns title="Recently completed" filters={{completed: true, failed: false}} />
    </Stack>
  </Page>
)

export default EvaluationSessions
