import {useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {makeApi} from '@equistamp/api'
import {HeaderCell, StyledTableCell} from 'components/tables'
import {EditableText, RoundedButton} from 'components/forms/inputs'
import {isInvalidJSON} from 'components/forms/validators'
import {parseJSON} from 'components/formatters'
import {InfoItem} from 'components/text'
import {
  FREE_RESPONSE_QUESTION,
  MULTIPLE_CHOICE_QUESTION,
  BOOLEAN_QUESTION,
  JSON_QUESTION,
} from '@equistamp/constants'

import type {Task as TaskType, Answer, Question} from '@equistamp/types'

type ToggleIconProps = {
  isActive: boolean
  ActiveIcon: any
  InactiveIcon: any
  activeHelp?: string
  inactiveHelp?: string
  onActiveClick?: () => Promise<any>
  onInactiveClick?: () => Promise<any>
}
const ToggleIcon = ({
  isActive,
  ActiveIcon,
  activeHelp,
  onActiveClick,
  InactiveIcon,
  inactiveHelp,
  onInactiveClick,
}: ToggleIconProps) => {
  const [updating, setUpdating] = useState(false)
  const handleClick = (handler: undefined | (() => Promise<any>)) => async () => {
    setUpdating(true)
    handler && (await handler())
    setUpdating(false)
  }

  if (updating) return <CircularProgress size={24} />
  return (
    <Box onClick={handleClick(onActiveClick)}>
      <Tooltip title={isActive ? activeHelp : inactiveHelp}>
        {isActive ? ActiveIcon : InactiveIcon}
      </Tooltip>
    </Box>
  )
}

const TextAnswer = ({
  answer: initialAnswer,
  update,
}: {
  answer: Answer
  update: (a: Answer) => Promise<any>
}) => {
  const [answer, setAnswer] = useState(initialAnswer)

  const updateField =
    <K extends keyof Answer>(field: K) =>
    async (val: Answer[K]) => {
      const updatedAnswer = {...answer, [field]: val}
      await update(updatedAnswer)
      setAnswer(updatedAnswer)
    }
  return (
    <TableRow key={answer.id}>
      <StyledTableCell component="td">
        <ToggleIcon
          isActive={answer.correct || false}
          activeHelp="Mark as incorrect"
          inactiveHelp="Mark as correct"
          ActiveIcon={<CheckCircleIcon color="success" style={{cursor: 'pointer'}} />}
          InactiveIcon={<CancelIcon color="error" style={{cursor: 'pointer'}} />}
          onActiveClick={() => updateField('correct')(!answer.correct)}
          onInactiveClick={() => updateField('correct')(!answer.correct)}
        />
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        <EditableText
          value={answer.text}
          onChange={updateField('text')}
          textSx={{width: '100%'}}
          editSx={{width: '100%'}}
        />
      </StyledTableCell>
    </TableRow>
  )
}

const TextAnswers = ({task}: {task: TaskType}) => {
  const [answers, setAnswers] = useState(task.answers || [])

  const updater = (index: number) => async (answer: Answer) => {
    setAnswers((answers) => answers.map((a, i) => (i === index ? {...a, ...answer} : a)))
    if (answer.text) {
      const res = await makeApi().tasks.update({id: task.id, answers: [answer]} as TaskType)
      return res
    }
  }

  const newAnswer = () => {
    setAnswers((answers) => [...answers, {correct: false, task_id: task.id} as Answer])
  }

  return (
    <Stack spacing={2} alignItems="center">
      <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="answers">
          <TableHead>
            <TableRow>
              <HeaderCell sx={{width: 20}}> </HeaderCell>
              <HeaderCell sx={{fontWeight: 700}}>Answer text</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers
              ?.sort((a: Answer, b: Answer) => a.id?.localeCompare(b.id))
              .map((a, index) => (
                <TextAnswer key={a.id || index} answer={a} update={updater(index)} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RoundedButton label="Add answer" onClick={newAnswer} sx={{width: '300px'}} />
    </Stack>
  )
}

const Boolean = ({task}: {task: TaskType}) => {
  const [correct, setCorrect] = useState(task.correct || false)
  const update = async (correct: boolean) => {
    const res = await makeApi().tasks.update({id: task.id, correct} as TaskType)
    setCorrect(correct)
    return res
  }

  return (
    <Stack direction="row" onClick={() => update(!correct)} spacing={2}>
      <ToggleIcon
        isActive={correct}
        activeHelp="Mark as incorrect"
        inactiveHelp="Mark as correct"
        ActiveIcon={<CheckCircleIcon color="success" />}
        InactiveIcon={<CancelIcon color="error" />}
        onActiveClick={() => update(true)}
        onInactiveClick={() => update(false)}
      />
      <Typography variant="h5">{correct ? 'True' : 'False'}</Typography>
    </Stack>
  )
}

const JsonTask = ({task}: {task: TaskType}) => {
  const [schemaStr, setSchemaStr] = useState(JSON.stringify(task.schema))
  const [expectedStr, setExpectedStr] = useState(JSON.stringify(task.expected))

  const update =
    (field: keyof TaskType, stateSetter: (v: string) => void) => async (val: string) => {
      stateSetter(val)
      const parsed = parseJSON(val)
      const res = await makeApi().tasks.update({id: task.id, [field]: parsed} as TaskType)
      return res
    }

  return (
    <Stack spacing={2}>
      <InfoItem
        title="Schema"
        value={
          <EditableText
            label="Schema"
            value={schemaStr || '-'}
            onChange={update('schema', setSchemaStr)}
            validate={true}
            validator={isInvalidJSON}
            multiline
            editSx={{width: '100%'}}
            textSx={{width: '100%', color: task.expected ? 'inherit' : 'grey'}}
          />
        }
      />
      <InfoItem
        title="Expected JSON"
        value={
          <EditableText
            label="Expected JSON"
            value={expectedStr || '-'}
            onChange={update('expected', setExpectedStr)}
            validate={true}
            validator={isInvalidJSON}
            multiline
            editSx={{width: '100%'}}
            textSx={{width: '100%', color: task.expected ? 'inherit' : 'grey'}}
          />
        }
      />
    </Stack>
  )
}

const TaskContent = ({task}: {task: TaskType}) => {
  switch (task.type) {
    case MULTIPLE_CHOICE_QUESTION:
      return <TextAnswers task={task} />
    case FREE_RESPONSE_QUESTION:
      return <TextAnswers task={task} />
    case BOOLEAN_QUESTION:
      return <Boolean task={task} />
    case JSON_QUESTION:
      return <JsonTask task={task} />
    default:
      return null
  }
}

const Task = ({task}: {task: TaskType}) => {
  const [redacted, setRedacted] = useState(task.redacted || false)

  const update = async (val: string) => {
    const question = {id: task?.questions && task?.questions[0]?.id, text: val} as Question
    await makeApi().tasks.update({id: task.id, question} as unknown as TaskType)
  }
  const toggleRedacted = async () => {
    await makeApi().tasks.update({id: task.id, redacted: !redacted} as unknown as TaskType)
    setRedacted(!redacted)
  }
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" sx={{width: '100%'}} spacing={2}>
          <ToggleIcon
            isActive={redacted}
            activeHelp="Click to make live"
            inactiveHelp="Click to mark as hidden"
            ActiveIcon={<VisibilityOffIcon />}
            InactiveIcon={<VisibilityIcon />}
            onActiveClick={toggleRedacted}
            onInactiveClick={toggleRedacted}
          />
          <Box sx={{width: '100%'}}>
            <EditableText
              value={task.questions && task.questions[0]?.text}
              onChange={update}
              textSx={{width: '100%'}}
              editSx={{width: '100%'}}
            />
          </Box>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <TaskContent task={task} />
      </AccordionDetails>
    </Accordion>
  )
}
export default Task
