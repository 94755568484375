import {useState} from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import AddItem from 'components/search/AddItem'
import {taskTypes} from 'components/filters/constants'
import {Input, SelectElem, Toggle, RoundedButton} from 'components/forms/inputs'
import {CREATE_TASK} from 'permissions'
import type {Answer as AnswerType, Task, Evaluation} from '@equistamp/types'
import {isInvalidJSON} from 'components/forms/validators'
import {parseJSON} from 'components/formatters'

type OptionsProps = {
  task: Task
  updateTask: (field: keyof Task, val: any) => void
}
const BoolOptions = ({task, updateTask}: OptionsProps) => (
  <Toggle
    title={task.correct ? 'True' : 'False'}
    on={task.correct || false}
    onChange={(v: boolean) => updateTask('correct', v)}
  />
)

const SchemaOptions = ({task, updateTask}: OptionsProps) => {
  const [schemaStr, setSchemaStr] = useState(JSON.stringify(task.schema))
  const [expectedStr, setExpectedStr] = useState(JSON.stringify(task.expected))
  const update = (field: keyof Task) => (val: string) => {
    if (field === 'schema') {
      setSchemaStr(val)
    } else if (field === 'expected') {
      setExpectedStr(val)
    }
    const parsed = parseJSON(val)
    updateTask(field, parsed)
  }
  return (
    <Stack spacing={2}>
      <Input
        label="Schema"
        value={schemaStr}
        setter={update('schema')}
        validate={true}
        validator={isInvalidJSON}
        multiline
      />
      <Input
        label="Expected JSON"
        value={expectedStr}
        setter={update('expected')}
        validate={true}
        validator={isInvalidJSON}
        multiline
      />
    </Stack>
  )
}

type AnswerProps = {
  i: number
  answer: AnswerType
  updateAnswer: (i: number, a: AnswerType) => void
}
const Answer = ({i, answer, updateAnswer}: AnswerProps) => (
  <Stack direction={{xs: 'column', sm: 'row'}} spacing={4} alignItems="center">
    <Toggle
      title={answer.correct ? 'Correct' : 'Incorrect'}
      on={answer.correct || false}
      onChange={(correct: boolean) => updateAnswer(i, {...answer, correct})}
    />
    <Input
      label="Answer text"
      value={answer.text || ''}
      setter={(text: string) => updateAnswer(i, {...answer, text})}
    />
  </Stack>
)

const CorrectIncorrectOptions = ({task, updateTask}: OptionsProps) => {
  const [answers, setAnswers] = useState((task.answers || []) as AnswerType[])

  const updateAnswer = (i: number, answer: AnswerType) => {
    answers[i] = answer
    setAnswers(answers)
    updateTask('answers', answers)
  }

  return (
    <Stack spacing={2}>
      {answers?.map((a, i) => (
        <Answer key={i} i={i} answer={a} updateAnswer={updateAnswer} />
      ))}
      <RoundedButton
        label="Add answer"
        onClick={() => setAnswers((a) => [...a, {} as AnswerType])}
      />
    </Stack>
  )
}

type AddTaskProps = {
  shown?: boolean
  evaluation: Evaluation
  onClose?: () => void
  onAdded?: (task: Task) => void
}
const AddTask = ({evaluation, onClose, onAdded}: AddTaskProps) => {
  const [shown, setShown] = useState(false)
  const [error, setError] = useState('')
  const [task, setTask] = useState({evaluation_id: evaluation.id} as Task)
  const updateTask = (field: keyof Task, val: any) => {
    if (error) setError('')
    setTask((t) => ({...t, [field]: val}))
  }
  const options = {
    bool: BoolOptions,
    json: SchemaOptions,
    FRQ: CorrectIncorrectOptions,
    MCQ: CorrectIncorrectOptions,
  }
  const Options = options[task.type]

  const addTask = async () => {
    try {
      const newTask = await makeApi().tasks.create(task)
      setShown(false)
      onAdded && onAdded(newTask as Task)
      onClose && onClose()
    } catch (e) {
      setError(`${e}`)
    }
  }

  const close = () => {
    setShown(false)
    onClose && onClose()
  }

  return (
    <Stack>
      <AddItem label="Add task" action={() => setShown(true)} permission={CREATE_TASK} />
      <Dialog open={shown || false} onClose={close} aria-labelledby="Create Task">
        <DialogTitle id="alert-dialog-title">Create Task</DialogTitle>
        <DialogContent>
          <Stack spacing={2} justifyContent="space-around" sx={{p: 3}}>
            <Stack direction={{xs: 'column', sm: 'row'}} spacing={4}>
              <Toggle title="redacted" onChange={(v: boolean) => updateTask('redacted', v)} />
              <SelectElem
                label="type"
                defaultValue=""
                onChange={(v: any) => updateTask('type', v)}
                values={taskTypes}
              />
            </Stack>
            <Input label="Question" setter={(v: string) => updateTask('questions', [{text: v}])} />
            {Options && <Options task={task} updateTask={updateTask} />}
            {error && <Alert severity="error">{error}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={addTask}>Save</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default AddTask
