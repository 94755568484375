import {useEffect, useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {makeApi, ServerError} from '@equistamp/api'
import Page from 'components/Page'
import {AWSSchedule, ItemType, Schedule, ScheduleItem} from '@equistamp/types'
import {ActionButton, MInput, RoundedButton, SelectElem} from 'components/forms/inputs'
import {modalStyle} from 'components/dialogs'
import {formatDuration, splitMinutes} from 'components/formatters'
import Path from 'routeLinks'

const groupedItem = (groupBy: ItemType, item: ScheduleItem) =>
  groupBy === 'model' ? item.model : item.evaluation

const childItem = (groupBy: ItemType, item: ScheduleItem) =>
  groupBy === 'model' ? item.evaluation : item.model

const groupItemsBy = (items: ScheduleItem[], groupBy: ItemType) => {
  const addItem = (acc: {[k: string]: ScheduleItem[]}, item: ScheduleItem) => {
    const name = groupedItem(groupBy, item).name
    const current = acc[name] || []
    return {...acc, [name]: [...current, item]}
  }
  return items.reduce(addItem, {})
}

type EditScheduleProps = ScheduleItem & {
  onClose: () => void
  onSave: (s: Schedule) => void
}
const EditSchedule = ({model, evaluation, schedule, onClose, onSave}: EditScheduleProps) => {
  const [error, setError] = useState('')
  const [start, setStart] = useState<string>(schedule?.start_date || '')
  const [time, setTime] = useState(
    splitMinutes(schedule?.minutes_between_evaluations || 7 * 24 * 60)
  )

  const updateTime = (unit: 'days' | 'hours' | 'minutes') => (v: string) => {
    const val = parseInt(v, 10)
    setTime((current) => ({...current, [unit]: val}))
  }

  const save = async () => {
    const minutes = time.days * 24 * 60 + time.hours * 60 + time.minutes
    try {
      if (schedule) {
        const updated = {...schedule, start_date: start, minutes_between_evaluations: minutes}
        await makeApi().schedules.update(updated)
        onSave(updated)
      } else {
        const res = await makeApi().schedules.create({
          job_name: `${model.name.slice(0, 10)}-${evaluation.name.slice(0, 10)}-${model.id.slice(
            0,
            8
          )}-${minutes}-minutes}`,
          job_description: `This job runs the ${model.name} model on the ${
            evaluation.name
          } evaluation every ${formatDuration(minutes * 60)}`,
          minutes_between_evaluations: minutes,
          start_date: start,
          model_id: model.id,
          evaluation_id: evaluation.id,
        })
        onSave(res)
      }
    } catch (e) {
      setError(e instanceof ServerError ? e.error.toString() : `${e}`)
    }
  }

  return (
    <Modal open={true} onClose={onClose}>
      <Stack sx={{...modalStyle, width: 400, m: '200px auto'}}>
        <MInput
          defaultValue={time.days.toString()}
          label="Days between runs"
          setter={updateTime('days')}
        />
        <MInput
          defaultValue={time.hours.toString()}
          label="Hours between runs"
          setter={updateTime('hours')}
        />
        <MInput
          defaultValue={time.minutes.toString()}
          label="Minutes between runs"
          setter={updateTime('minutes')}
        />
        <MInput defaultValue={start || ''} label="First run" setter={setStart} />
        <ActionButton label="Save" action={save} />
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>
    </Modal>
  )
}

type CommonParams = {
  groupBy: ItemType
  onUpdate: (s: ScheduleItem) => void
}
const EvalPair = ({
  model,
  evaluation,
  schedule,
  groupBy,
  onUpdate,
}: ScheduleItem & CommonParams) => {
  const [showModal, setShowModal] = useState(false)
  const onSave = (s: Schedule) => {
    onUpdate({model, evaluation, schedule: s})
    setShowModal(false)
  }
  const onDelete = async () => {
    if (schedule) {
      await makeApi().schedules.remove(schedule)
      onUpdate({model, evaluation})
    }
    return null
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
      <Typography sx={{flexGrow: 1}}>{childItem(groupBy, {model, evaluation}).name}</Typography>
      {schedule?.aws_schedule && (
        <Typography sx={{width: 260}}>
          Every {formatDuration((schedule?.minutes_between_evaluations || 0) * 60)}, <br />
          next: {schedule?.next_run}
        </Typography>
      )}
      {schedule && !schedule.aws_schedule && (
        <Alert severity="error">No schedule found on AWS!</Alert>
      )}
      <RoundedButton
        label={schedule ? <EditIcon /> : <AddIcon />}
        onClick={() => setShowModal(true)}
        sx={{minWidth: 0}}
      />
      {schedule && <ActionButton label={<DeleteIcon />} action={onDelete} sx={{minWidth: 0}} />}
      {showModal && (
        <EditSchedule
          model={model}
          evaluation={evaluation}
          schedule={schedule}
          onClose={() => setShowModal(false)}
          onSave={onSave}
        />
      )}
    </Stack>
  )
}

type ItemsGroupProps = {
  name: string
  items: ScheduleItem[]
} & CommonParams
const ItemsGroup = ({name, items, groupBy, onUpdate}: ItemsGroupProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{name}</AccordionSummary>
    <AccordionDetails>
      <Paper elevation={2} sx={{p: 2}}>
        <Stack spacing={2}>
          {items
            .sort((a, b) => childItem(groupBy, a).name.localeCompare(childItem(groupBy, b).name))
            .map((item) => (
              <EvalPair
                key={item.evaluation.name + item.model.name}
                groupBy={groupBy}
                onUpdate={onUpdate}
                {...item}
              />
            ))}
        </Stack>
      </Paper>
    </AccordionDetails>
  </Accordion>
)

const Schedules = () => {
  const [schedules, setSchedules] = useState<ScheduleItem[]>()
  const [unknown, setUnknown] = useState<AWSSchedule[]>()
  const [groupBy, setGroupBy] = useState<ItemType>('model')
  const [grouped, setGrouped] = useState<{[k: string]: ScheduleItem[]}>()

  useEffect(() => {
    if (schedules) {
      setGrouped(groupItemsBy(schedules, groupBy))
    }
  }, [schedules, groupBy])

  useEffect(() => {
    const loadSchedules = async () => {
      setSchedules([])
      setGrouped(undefined)

      const {items, unknown} = await makeApi().schedules.all()
      setSchedules(items)
      setUnknown(unknown)
    }
    loadSchedules()
  }, [setSchedules, setUnknown, groupBy])

  const onUpdate = (s: ScheduleItem) => {
    setSchedules((schedules) => {
      if (!schedules) return schedules
      return schedules.map((item) =>
        item.model.id === s.model.id && item.evaluation.id === s.evaluation.id ? s : item
      )
    })
  }

  return (
    <Page
      title="Evaluation Run Schedules"
      showTitle
      breadcrumbs={[{title: 'admin', link: Path.admin.index()}, {title: 'schedules'}]}
    >
      <Stack spacing={2} sx={{mt: 6}}>
        <Typography>Group by:</Typography>
        <SelectElem
          label="Group by"
          alwaysSelected
          value={groupBy}
          onChange={setGroupBy}
          values={{model: 'model', evaluation: 'evaluation'}}
          sx={{width: 200}}
        />
        {unknown?.length ? (
          <>
            <Typography variant="h4">Unknown schedules on AWS</Typography>
            {unknown?.map((i) => (
              <Typography>
                {i.Name} - {i.Arn}
              </Typography>
            ))}
          </>
        ) : undefined}
        <Typography variant="h4">Schedules</Typography>
        <Typography>Reload after updating to see changes</Typography>
        {!schedules?.length && <CircularProgress />}
        {grouped &&
          Object.entries(grouped)
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([name, items]) => (
              <ItemsGroup
                key={name}
                groupBy={groupBy}
                name={name}
                items={items}
                onUpdate={onUpdate}
              />
            ))}
      </Stack>
    </Page>
  )
}

export default Schedules
