import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {Input, Toggle, SelectElem} from 'components/forms/inputs'
import {EditorFuncProps} from 'hooks/useEditable'
import type {Alert as AlertType} from '@equistamp/types'
import {alertTriggerTypes} from 'components/filters/constants'
import {EVAL_SCORE_THRESHOLD} from '@equistamp/constants'

export const allMetrics = {
  max: 'max',
  min: 'min',
  average: 'average',
  median: 'median',
}
const cadences = {
  once: 'Only once',
  daily: 'Daily',
}

export const squareStyle = {
  width: 120,
  height: 120,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

export const ImageSquare = ({url, alt, colour}: {url: string; alt: string; colour?: string}) => (
  <Box sx={{...squareStyle, backgroundColor: colour}}>
    {colour && <img src={url} alt={alt} style={{maxWidth: '100%', maxHeight: '100%'}} />}
  </Box>
)

const AlertTypeBox = ({alert}: {alert: AlertType}) => (
  <Box
    sx={{
      ...squareStyle,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'primary.main',
    }}
  >
    <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
      <Typography variant="h4" color="primary.main">
        <b>T</b>
      </Typography>
      <Typography color="primary.main">
        {
          alertTriggerTypes[
            (alert.triggers && alert.triggers.length > 0
              ? alert.triggers[0].type
              : EVAL_SCORE_THRESHOLD) as keyof typeof alertTriggerTypes
          ]
        }
      </Typography>
    </Stack>
  </Box>
)

export const getFutureDateColour = (dateString: string) => {
  const currentDate = new Date() as any
  const givenDate = new Date(dateString) as any
  const diffTime = Math.abs(givenDate - currentDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays <= 7) {
    // within the next week
    return '#ff4500' // reddish
  } else if (diffDays <= 30) {
    // within the next month
    return '#ffa500' // orange
  } else if (diffDays <= 90) {
    // within the next three months
    return '#E3D026' // yellow
  } else {
    return '#008000' // green, further out
  }
}

export const StatusIcons = ({alert}: {alert: AlertType}) => (
  <Stack direction="column" spacing={2}>
    <ImageSquare
      url="/imgs/danger_white.png"
      alt="danger level"
      colour={getFutureDateColour('2025-01-01')}
    />
    <AlertTypeBox alert={alert} />
  </Stack>
)

const prependArticle = (text: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u']
  const firstLetter = text[0].toLowerCase()

  if (vowels.includes(firstLetter)) {
    return 'an ' + text.toLowerCase()
  } else {
    return 'a ' + text.toLowerCase()
  }
}

type EditorInputProps = EditorFuncProps & {
  sx?: {[k: string]: any}
  validate?: boolean
  multiple?: boolean
}
type Item = {id: string; name: string}
export const itemsSelect =
  (name: string, allItems: Item[]) =>
  ({value, onChange, validate, multiple}: EditorInputProps) =>
    (
      <SelectElem
        label={name}
        values={allItems.reduce((acc, {id, name}) => ({...acc, [id]: name}), {})}
        defaultValue={value}
        onChange={onChange}
        multiple={multiple}
        validate={validate}
        validator={(val: string) => !val && `Please select ${prependArticle(name.toLowerCase())}`}
        sx={{minWidth: 200, width: '100%'}}
      />
    )

export const MetricSelect = ({value, onChange, validate, sx}: EditorInputProps) => (
  <SelectElem
    label="Metric"
    values={allMetrics}
    defaultValue={value}
    onChange={onChange}
    validate={validate}
    validator={(val: string) => !val && 'Please select a metric'}
    sx={{minWidth: 150, ...sx}}
  />
)

export const CadenceSelect = ({value, onChange, validate, sx}: EditorInputProps) => (
  <SelectElem
    label="Cadence"
    values={cadences}
    defaultValue={value}
    validate={validate}
    validator={(val: string) => !val && 'Please select a cadence'}
    onChange={onChange}
    sx={{minWidth: 150, ...sx}}
  />
)

export const ThresholdInput = ({value, onChange, validate, sx}: EditorInputProps) => (
  <Input
    label="Threshold"
    defaultValue={(value || 0).toString()}
    type="number"
    inputProps={{min: 0, max: 100}}
    setter={onChange}
    sx={{width: '100%', ...sx}}
  />
)

export const VisibilityToggle = ({value, onChange, validate}: EditorInputProps) => (
  <Toggle title="Public?" on={value || false} onChange={onChange} />
)
