import {ReactNode} from 'react'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {NavButton, RoundedButton} from '../components/forms/inputs'
import {ColouredTypography} from '../components/text'

type CtAProps = {
  title: string
  children?: ReactNode | string
  actionLabel?: string
  action?: string | (() => any)
}
const CallToAction = ({title, children, action, actionLabel}: CtAProps) => (
  <Card sx={{bgcolor: 'primary.light', borderRadius: 3, p: 3, flex: 1}}>
    <Stack spacing={2} justifyContent="space-between" alignItems="center" sx={{height: '100%'}}>
      <ColouredTypography variant="h4" sx={{fontWeight: 700}}>
        {title}
      </ColouredTypography>
      {typeof children !== 'string' ? (
        children
      ) : (
        <Typography sx={{color: 'white', fontWeight: 700}}>{children}</Typography>
      )}
      {action &&
        (typeof action === 'string' ? (
          <NavButton to={action} label={actionLabel} sx={{fontWeight: 700}} />
        ) : (
          <RoundedButton onClick={action} label={actionLabel} />
        ))}
    </Stack>
  </Card>
)

export default CallToAction
