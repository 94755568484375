import {useState} from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {useTheme} from '@mui/material'
import {RoundedButton, Toggle} from 'components/forms/inputs'
import {modalStyle} from 'components/dialogs'
import EyeIcon from 'components/icons/Eye'
import type {Column} from './columns'

const MIN_SELECTED = 1
const MAX_SELECTED = 6

const groupBy = <T extends any>(col: T[], grouper: (i: T) => any) =>
  col.reduce((acc, i) => {
    const key = grouper(i)
    const group = acc[key as keyof typeof acc] || []
    return {...acc, [key]: [...group, i]}
  }, {}) as {[k: string]: T[]}

type ColumnsGroupProps = {
  name?: string
  group: Column[]
  selected: string[]
  divider?: boolean
  onCheck: (id: string, checked: boolean) => void
}
const ColumnsGroup = ({name, group, selected, divider, onCheck}: ColumnsGroupProps) => (
  <Stack sx={{pt: 2, pb: 0, pl: 6, pr: 6}} spacing={1}>
    {divider && <Divider style={{marginBottom: 8}} />}
    {name && <Typography sx={{fontWeight: 700}}>{name}</Typography>}
    {group.map(({id, name}) => {
      const on = selected.includes(id)
      return (
        <Toggle
          key={id}
          title={name}
          on={on}
          disabled={on ? selected.length <= MIN_SELECTED : selected.length >= MAX_SELECTED}
          onChange={(v: boolean) => onCheck(id, v)}
        />
      )
    })}
  </Stack>
)

type ColumnsModalProps = {
  available: Column[]
  selected: string[]
  onClose: () => void
  onChange: (c: Column[]) => void
}
const ColumnsModal = ({available, selected: initial, onChange, onClose}: ColumnsModalProps) => {
  const [selected, setSelected] = useState(initial)
  const grouped = groupBy<Column>(available, (c: Column) => c.group)

  const toggleSelected = (id: string, isSelected: boolean) => {
    const clean = selected.filter((c) => c !== id)
    const newlySelected = isSelected ? [...clean, id] : clean
    setSelected(newlySelected)
    onChange(available.filter((c) => newlySelected.includes(c.id)))
  }

  return (
    <Stack>
      <Typography align="center" sx={{p: 3, fontWeight: 700}}>
        Customize columns
      </Typography>
      <Divider />
      <Typography align="center" variant="caption" sx={{p: 2, color: 'grey'}}>
        Choose up to {MAX_SELECTED} columns to display
      </Typography>
      {Object.entries(grouped).map(([k, group], i) => (
        <ColumnsGroup
          key={k}
          name={k}
          group={group}
          selected={selected}
          onCheck={toggleSelected}
          divider={i !== 0}
        />
      ))}
      <Divider sx={{mt: 2, mb: 2}} />
      <RoundedButton label="Save" sx={{mr: 2, ml: 2}} onClick={onClose} />
    </Stack>
  )
}

type ColumnsChooserProps = {
  current: Column[]
  available: Column[]
  onChange: (c: Column[]) => void
}
const ColumnsChooser = ({current, available, onChange}: ColumnsChooserProps) => {
  const theme = useTheme()
  const [showChooser, setShowChooser] = useState(false)
  const close = () => setShowChooser(false)

  return (
    <>
      <Modal open={showChooser} onClose={close} sx={{mb: '80px'}}>
        <Box
          sx={{...modalStyle, m: '80px auto', p: 0, border: 'none', borderRadius: 2, maxWidth: 500}}
        >
          <ColumnsModal
            available={available}
            selected={current.map((c) => c.id)}
            onClose={close}
            onChange={onChange}
          />
        </Box>
      </Modal>
      <RoundedButton
        variant="outlined"
        onClick={() => setShowChooser(true)}
        label={
          <Stack direction="row" alignItems="center" spacing={1}>
            <EyeIcon fill={theme.palette.text.primary} />
            <Typography>Customize columns</Typography>
          </Stack>
        }
      />
    </>
  )
}

export default ColumnsChooser
