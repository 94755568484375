import {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid'
import {Model as ModelType} from '@equistamp/types'
import {NumberSelector} from '../widgets'
import type {ModelStageProps} from './types'

const TOKEN_TO_CHAR_RATIO = 4
const CHAR_TO_1M_TOKENS_RATIO = TOKEN_TO_CHAR_RATIO / 1_000_000

const Limitations = ({model, onChange, setNextHandler, index}: ModelStageProps) => {
  const [maxCharPerMinute, setMaxCharPerMinute] = useState(model.max_characters_per_minute)
  const [maxContextWindowTokens, setMaxContextWindowTokens] = useState(
    model.max_context_window_characters
  )
  const [maxRequestsPerMinute, setMaxRequestsPerMinute] = useState(model.max_request_per_minute)

  const [cost1MInputTokens, setCost1MInputTokens] = useState(
    model.cost_per_input_character_usd
      ? model.cost_per_input_character_usd / CHAR_TO_1M_TOKENS_RATIO
      : undefined
  )
  const [cost1MOutputTokens, setCost1MOutputTokens] = useState(
    model.cost_per_output_character_usd
      ? model.cost_per_output_character_usd / CHAR_TO_1M_TOKENS_RATIO
      : undefined
  )
  const [costInstanceHour, setCostInstanceHour] = useState(model.cost_per_instance_hour_usd)

  useEffect(() => {
    setNextHandler(() => true)
  }, [index, model, setNextHandler])

  const setVal =
    (field: keyof ModelType, updater: (v: any) => void, scale = 1) =>
    (val: any) => {
      updater(val * scale)
      onChange(field, val * scale)
    }

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="Max tokens per minute"
          max={6}
          value={(maxCharPerMinute || 0) / 4}
          onChange={(val: number) =>
            setVal('max_characters_per_minute', setMaxCharPerMinute)(val * 4)
          }
          help="The maximum number of tokens per minute allowed by this model with the provided configuration. It is assumed that more tokens will trigger rate limiting"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="Max context window tokens"
          max={6}
          value={maxContextWindowTokens}
          onChange={setVal('max_context_window_characters', setMaxContextWindowTokens)}
          help="The maximum number of tokens allowed in the context window. Larger context windows will be assumed to have undefined behaviour, e.g. some models will use the beginning of the context window, other ones will use the end, and others will just fail - it's on the caller to make sure there are fewer than the max tokens sent "
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="Max requests per minute"
          max={10}
          value={maxRequestsPerMinute}
          onChange={setVal('max_request_per_minute', setMaxRequestsPerMinute)}
          help="The maximum number of requests per minute that can be sent to this model with the provided configuration. It is assumed that more requests will trigger rate limiting"
        />
      </Grid>

      <Grid item xs={12} sx={{p: 4}}></Grid>

      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="($) Cost per 1M input tokens"
          min={-5}
          max={3}
          value={cost1MInputTokens}
          onChange={setVal(
            'cost_per_input_character_usd',
            setCost1MInputTokens,
            CHAR_TO_1M_TOKENS_RATIO
          )}
          help="The USD cost of 1M input tokens. This is covered by you, and here is purely informational so it's possible to estimate how much each evaluation will cost you"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="($) Cost per 1M output tokens"
          min={-5}
          max={3}
          value={cost1MOutputTokens}
          onChange={setVal(
            'cost_per_output_character_usd',
            setCost1MOutputTokens,
            CHAR_TO_1M_TOKENS_RATIO
          )}
          help="The USD cost of 1M output tokens. This is covered by you, and here is purely informational so it's possible to estimate how much each evaluation will cost you"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <NumberSelector
          title="($) Cost per instance hour"
          min={-3}
          max={3}
          value={costInstanceHour}
          onChange={setVal('cost_per_instance_hour_usd', setCostInstanceHour)}
          help="The USD cost of how much it costs to run this model for an hour. This is covered by you, and here is purely informational so it's possible to estimate how much each evaluation will cost you"
        />
      </Grid>
    </Grid>
  )
}

export default Limitations
