import Stack from '@mui/material/Stack'
import {ItemsContainerProps} from 'components/filters/types'
import {CheckButton} from 'components/forms/inputs'
import {
  EVAL_SCORE_THRESHOLD,
  MODEL_AVAILABILITY,
  MODEL_CHANGE,
  MODEL_STABILITY,
  NEW_EVALUATION,
  NEW_MODEL,
  TOP_MODEL,
} from '@equistamp/constants'
import {TriggerTypes} from '@equistamp/types'

const alertTypes = [
  {id: undefined, label: 'All'},
  {id: TOP_MODEL, label: 'Top Model'},
  {id: MODEL_AVAILABILITY, label: 'Availability'},
  {id: MODEL_STABILITY, label: 'Model Stability'},
  {id: NEW_MODEL, label: 'Model Release'},
  {id: NEW_EVALUATION, label: 'Eval Added'},
  {id: EVAL_SCORE_THRESHOLD, label: 'Performance'},
  {id: MODEL_CHANGE, label: 'Metadata'},
]

const SearchContainer = ({children, filters, onFilterChange}: ItemsContainerProps) => {
  const updateTypeFilter = (v?: TriggerTypes) => {
    onFilterChange && onFilterChange({...filters, triggerType: v ? [v] : undefined})
  }
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3.5}
        sx={{pb: 6, pt: 8}}
      >
        {alertTypes.map(({id, label}) => (
          <CheckButton
            key={id}
            onClick={() => updateTypeFilter(id as TriggerTypes)}
            selected={
              id
                ? filters?.triggerType?.includes(id as TriggerTypes)
                : (filters?.triggerType || []).length === 0
            }
            sx={{width: 'inherit', p: 1}}
          >
            {label}
          </CheckButton>
        ))}
      </Stack>
      {children}
    </Stack>
  )
}

export default SearchContainer
