import Fuse from 'fuse.js'
import {ServerObject, ItemFilter} from './types'
import {SearchResult} from '@equistamp/types'

const getVal = (item: ServerObject, key: string | string[], defaultVal: any = {}) => {
  const path = Array.isArray(key) ? key : [key]
  return path.reduce((cur, k) => (cur[k as keyof typeof cur] as any) || defaultVal, item)
}

export const paginate = (items: any[], page: number, perPage: number | 'all'): SearchResult => {
  if (perPage === 'all') {
    return {items, count: items.length, page: 0, per_page: items.length}
  }
  const realPage = Math.min(page, Math.round(items.length / perPage))
  const start = Math.min(realPage * perPage)
  const end = start + perPage
  return {
    items: items.slice(start, end),
    count: items.length,
    page: realPage,
    per_page: perPage,
  }
}

export const equals = (val: any | undefined, key: string | string[]) => (item: ServerObject) => {
  if (val === undefined) return true
  return getVal(item, key) === val
}

export const searchText = (items: ServerObject[], text: string, fields?: string[]) => {
  if (!text) return items

  const fuse = new Fuse(items, {keys: fields || ['name', 'description']})
  return fuse.search(text).map(({item}) => item)
}
export const filterContains =
  (filter: any[] | undefined, key: string | string[], childKey?: string) =>
  (item: ServerObject) => {
    if (!filter || filter.length === 0) return true

    const val = getVal(item, key)
    if (Array.isArray(val)) return val.some((v) => filter.includes(getVal(v, childKey || [])))
    return filter.includes(val)
  }

export const filterItems =
  (items: ServerObject[] | undefined, key?: string | string[]) => (item: ServerObject) => {
    // If items is empty, it means that nothing is being filtered, so this check should
    // be true
    if (!items || items.length === 0) return true

    const ids = items.map((i) => i.id)
    const vals = !key ? [item] : (getVal(item, key, []) as unknown as undefined | ServerObject[])
    return !!vals?.some((i) => ids.includes(i.id))
  }

export const filterBetween =
  (start: any, end: any, key: string | string[], defaultVal: any = {}) =>
  (item: ServerObject) => {
    const hasStart = ![null, undefined].includes(start)
    const hasEnd = ![null, undefined].includes(end)
    if (!hasStart && !hasEnd) return true

    const val = getVal(item, key, defaultVal)
    if (!val && val !== 0) return false
    if (typeof val === 'string') {
      return (
        (!hasStart || start.localeCompare(val) < 0) &&
        (!hasEnd || (val as string).localeCompare(end) < 0)
      )
    }
    return (!hasStart || start <= val) && (!hasEnd || end >= val)
  }

export const allOf = (filters: ItemFilter[]) => (item: ServerObject) =>
  filters.every((filter) => filter(item))

export const anyOf = (filters: ItemFilter[]) => (item: ServerObject) =>
  filters.some((filter) => filter(item))

export const filterChildren =
  (key: string | string[], filter: ItemFilter) => (item: ServerObject) =>
    !!(getVal(item, key, []) as unknown as undefined | any[])?.every((child) => filter(child))

export const isEmpty = (key: string | string[]) => (item: ServerObject) => {
  const val = getVal(item, key)
  return (
    [null, undefined, ''].includes(val as any) ||
    (Array.isArray(val) && val.length === 0) ||
    (typeof val === 'object' && Object.keys(val).length === 0)
  )
}

export const stringToNumber = (str: string): number | string => {
  if (isNaN(parseFloat(str))) {
    return str
  }
  return parseFloat(str)
}
