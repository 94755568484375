import {useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useModels from 'hooks/useModels'
import useEvaluations from 'hooks/useEvaluations'
import {CheckButton, ButtonGroup} from 'components/forms/inputs'
import type {FiltersTypes, Evaluation, ItemType, Model, Trigger} from '@equistamp/types'
import Searcher from 'components/search/Searcher'
import {ServerObject} from 'components/filters'
import {defaultEvaluationSearchOptions, defaultModelSearchOptions} from 'components/search'
import {knownPublishers} from 'components/filters/constants'

type ItemRowProps = {
  item: Model | Evaluation
  onSelect?: (item: ServerObject, selected: boolean) => void
  selected?: boolean
  itemType: ItemType
}
const ItemRow = ({selected, onSelect, item}: ItemRowProps) => {
  return (
    <CheckButton
      selected={selected}
      onClick={() => onSelect && onSelect(item, !selected)}
      disabled={!onSelect}
      sx={
        onSelect
          ? {}
          : {
              '&.Mui-disabled': {
                color: 'inherit',
              },
            }
      }
    >
      {item.name}
    </CheckButton>
  )
}

type SelectItemsProps = {
  itemType: ItemType
  selected?: ServerObject[]
  onSelect?: (items: ServerObject[]) => void
  onFilterChange?: (f?: FiltersTypes) => void
  filters?: FiltersTypes
}
const SelectItems = ({
  selected: initialSelected,
  itemType,
  onSelect,
  onFilterChange,
  filters,
}: SelectItemsProps) => {
  const [selected, setSelected] = useState<{[k: string]: ServerObject}>(
    initialSelected
      ? initialSelected.reduce((acc, item) => ({...acc, [item.id || '']: item}), {})
      : {}
  )
  const {getItems, loading} = (itemType === 'model' ? useModels : useEvaluations)()

  const toggleItem = (item: ServerObject, isSelected: boolean) => {
    let updated = selected
    if (!item.id) return
    if (isSelected) {
      updated = {...selected, [item.id]: item}
    } else {
      delete updated[item.id]
    }
    setSelected({...updated})
    onSelect && onSelect(Object.values(updated))
  }

  return (
    <Searcher
      noUrlUpdate
      getItems={getItems}
      renderItem={(item: ServerObject) => (
        <ItemRow
          key={item.id}
          onSelect={onSelect ? toggleItem : undefined}
          item={item as Model | Evaluation}
          itemType={itemType}
          selected={!!(onSelect && selected[item.id || ''])}
        />
      )}
      config={{perPage: 10, filters}}
      onFilterChange={onFilterChange}
      searchOptions={
        itemType === 'model' ? defaultModelSearchOptions : defaultEvaluationSearchOptions
      }
      loading={loading}
    />
  )
}

const getFilterType = (
  unavailable: FilterType[],
  field: 'models' | 'evaluations',
  filters?: FiltersTypes
) => {
  if (!filters || (Object.keys(filters).length === 0 && !unavailable.includes('all'))) return 'all'
  if (filters[field] && !unavailable.includes('selected')) return 'selected'
  if (filters.publishers && !unavailable.includes('from_org')) return 'from_org'
  return 'from_filter'
}

type FilterType = 'all' | 'selected' | 'from_filter' | 'from_org'
type FilterProps = Trigger & {
  title?: string
  itemType: ItemType
  onChange: (field: keyof Trigger, v: any) => void
  skippedFilterTypes?: FilterType[]
}
const FilterItems = ({
  models,
  evaluations,
  itemType,
  onChange,
  skippedFilterTypes = ['from_org'],
  title,
}: FilterProps) => {
  const filtersField = itemType === 'model' ? 'models' : 'evaluations'
  const filters = itemType === 'model' ? models : evaluations
  const [itemFilters, setItemFilters] = useState<string | undefined>(
    getFilterType(skippedFilterTypes, filtersField, filters)
  )
  const update = (f?: FiltersTypes) => onChange(filtersField, f)
  const updatePublisherFiter = (publishers?: string | string[]) =>
    onChange(filtersField, {
      ...filters,
      publishers: Array.isArray(publishers) ? publishers : [publishers],
    })

  const items = [
    {
      id: 'all' as FilterType,
      label: `All ${itemType}s`,
      hint: `All public ${itemType}s will be checked`,
    },
    {
      id: 'selected' as FilterType,
      label: `Only specific ${itemType}s`,
      hint: `Select the ${itemType}s that will be checked. Only changes to these ${itemType}s will trigger the alert.`,
    },
    {
      id: 'from_org' as FilterType,
      label: `From Organization`,
      hint: `Select the publishers that you care about. Only their ${itemType}s will trigger the alert.`,
    },
    {
      id: 'from_filter' as FilterType,
      label: `From filter`,
      hint: `Contruct a filter that will select the ${itemType}s to be checked. Each time the trigger is evaluated we will apply these filters to the ${itemType}s and check all ${itemType}s that are found.`,
    },
  ]

  const availableItems = items.filter((i) => !(skippedFilterTypes || []).includes(i.id))

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h4">
          {title || `Which ${itemType}s would you like this alert to check?`}
        </Typography>
        <ButtonGroup
          perRow={Math.min(4, availableItems.length)}
          selected={itemFilters}
          onSelect={(v?: string | string[]) => setItemFilters(v as string | undefined)}
          items={availableItems}
        />
      </Stack>
      {['selected', 'from_filter'].includes(itemFilters || '') && (
        <SelectItems
          itemType={itemType}
          selected={filters && filters[filtersField]}
          filters={filters}
          onSelect={
            itemFilters === 'selected'
              ? (items: ServerObject[]) => update({[filtersField]: items.map((i) => ({id: i.id}))})
              : undefined
          }
          onFilterChange={itemFilters === 'from_filter' ? update : undefined}
        />
      )}
      {itemFilters === 'from_org' && (
        <ButtonGroup
          perRow={4}
          selected={filters?.publishers}
          onSelect={updatePublisherFiter}
          items={knownPublishers}
        />
      )}
    </Stack>
  )
}

export default FilterItems
