import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'

const RenderMarkdown = ({content}: {content?: string | null}) => (
  <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
    {content}
  </Markdown>
)

export default RenderMarkdown
