import type {FiltersProps, ServerObject} from './types'
import {paginate, searchText, allOf, filterBetween, filterContains} from './filters'
import {filterChangeHandlers, handleRangeChange, sortItems} from './transformers'
import {FiltersContainer, CheckboxGroup, RangeSlider, DatesPicker} from './widgets'
import type {Eval, FilterConfig, SearchResult} from '@equistamp/types'

const evalSessionStatuses = {
  running: 'Running',
  failed: 'Failed',
  completed: 'Completed',
}

const checkStatus = (statuses?: string[]) => (item: ServerObject) => {
  if (!statuses || statuses.length === 0) return true
  const e = item as Eval
  return !!(
    (!statuses.includes('running') || !e.datetime_completed) &&
    (!statuses.includes('failed') || e.failed) &&
    (!statuses.includes('completed') || e.completed)
  )
}

export const filterRuns = (evals: Eval[], params: FilterConfig): SearchResult => {
  let filtered = searchText(evals, params.filters?.search || '', [
    'evaluation_name',
    'evaluatee_name',
  ]).filter(
    allOf([
      checkStatus(params?.filters?.statuses),
      filterContains(
        params?.filters?.models?.map((m) => m.id),
        'evaluatee_id'
      ),
      filterContains(
        params?.filters?.evaluations?.map((e) => e.id),
        'evaluation_id'
      ),
      filterBetween(
        params?.filters?.startDatetimeStarted,
        params?.filters?.endDatetimeStarted,
        'datetime_started'
      ),
      filterBetween(
        params?.filters?.startDatetimeCompleted,
        params?.filters?.endDatetimeCompleted,
        'datetime_completed'
      ),
      filterBetween(params?.filters?.minScore, params?.filters?.maxScore, 'score'),
    ])
  )
  filtered = sortItems(filtered, params)
  return paginate(filtered, params.page || 0, params.perPage || 20)
}

const RunFilters = ({config, onChange}: FiltersProps) => {
  const {handleChange, updateField} = filterChangeHandlers({config, onChange})

  return (
    <FiltersContainer>
      <CheckboxGroup
        label="Status"
        allOptions={evalSessionStatuses}
        selected={config?.filters?.statuses}
        onChange={handleChange('statuses')}
      />
      <DatesPicker
        label="Started between"
        start={config?.filters?.startDatetimeStarted}
        end={config?.filters?.endDatetimeStarted}
        onChange={updateField}
        field="DatetimeStarted"
      />
      <DatesPicker
        label="Finished between"
        start={config?.filters?.startDatetimeCompleted}
        end={config?.filters?.endDatetimeCompleted}
        onChange={updateField}
        field="DatetimeCompleted"
      />
      <RangeSlider
        label="Score"
        start={config?.filters?.minScore || 0}
        end={config?.filters?.maxScore || 100}
        onChange={handleRangeChange(config?.filters, 'Score', updateField)}
      />
    </FiltersContainer>
  )
}

export default RunFilters
