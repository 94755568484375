import {useState, ReactElement} from 'react'
import useUser from '../hooks/useUser'
import {InfoItem, TextVariant, TextAlign, Text} from '../components/text'
import {Input} from '../components/forms/inputs'

export type EditorFuncProps = {
  value: any
  onChange: (v: any) => void
}

export type EditableProps<T> = {
  getter: string | ((item: T) => any)
  setter?: string | ((item: T) => any)
  label?: string
  title?: string
  variant?: TextVariant
  align?: TextAlign
  editor?: (v: EditorFuncProps) => ReactElement
  formatter?: (val: any) => string
  markdown?: boolean
  sx?: {[k: string]: any}
}
/*
 * This is a doall magical component that makes an field that can be edited. The idea is that
 * users will create their own version that can be used in forms etc., e.g.:
 *
 *    const Editable = makeEditable(item, changes, editable, handleChange)
 *
 *    return (
 *        <Editable getter="foo" />
 *    )
 *
 * Here `item` is the thing that is being used as the source of truth, and `changes` are any changes
 * made to it
 */
const makeEditable =
  <T,>(item: T, changes: T, editable: boolean, handleChange: (field: keyof T, val: any) => void) =>
  ({
    // either a key on `item` or a function that will return the appropriate value from item
    getter,
    // if not provided, will assume that `getter` is a key and will use it to fetch things. If it is provided,
    // it should be either a key in `item` or a function that will set the value
    setter,
    // if provided, the base value will be displayed as an `InfoItem`, otherwise just as text
    title,
    // if provided, will be used as the label for the displayed input
    label,
    variant,
    align,
    // An optional function that will be used to create the edit widget
    editor,
    // An optional formatter for the basic displayed value
    formatter,
    // Whether the text should be rendered as markdown
    markdown,
    sx,
  }: EditableProps<T>) => {
    const getValue = typeof getter === 'string' ? (a: T) => a[getter as keyof T] : getter

    const setValue = (val: any) => {
      if (setter && typeof setter === 'string') {
        handleChange(setter as keyof T, val)
      } else if (setter && typeof setter !== 'string') {
        setter(val)
      } else if (typeof getter === 'string') {
        handleChange(getter as keyof T, val)
      } else {
        throw new Error('no setter found')
      }
    }
    const displayVal = formatter
      ? formatter
      : (val: any) => ([null, undefined].includes(val) ? '' : val).toString()

    const EditWidget = () => {
      const value = getValue(changes) ?? getValue(item)
      if (editor) {
        const Edit = editor
        return <Edit value={value} onChange={setValue} />
      }
      return <Input label={label} defaultValue={value} setter={setValue} sx={{width: '100%'}} />
    }

    if (editable) {
      return <EditWidget />
    } else if (title) {
      return (
        <InfoItem
          align={align}
          title={title}
          value={displayVal(getValue(item))}
          markdown={markdown}
        />
      )
    }
    return (
      <Text
        align={align}
        variant={variant || 'inherit'}
        text={displayVal(getValue(item))}
        style={sx}
        markdown={markdown}
      />
    )
  }

type WithId = {id: string}
const useEditable = <T extends WithId>(
  item: T,
  updateFunc?: (val: T) => void,
  startAsEdit?: boolean
) => {
  const {canEdit} = useUser()
  const [editable, setEditable] = useState(startAsEdit || false)
  const [changes, setChanges] = useState<T>(() => ({} as T))

  const handleChange = (field: keyof T, val: any) => {
    // WARNING: This intentionally mutates the `changes` object, rather
    // than replacing it, in order to not trigger rerenders, which would
    // recreate this component, hence losing focus. This is annoying, but
    // at least it works...
    setChanges((prevChanges) => {
      prevChanges[field] = val
      return prevChanges
    })
  }

  const onSave = () => {
    if (editable && updateFunc && Object.keys(changes as any).length > 0) {
      updateFunc({...changes, id: item.id})
      setChanges({} as T)
    }
    setEditable(!editable)
  }

  const onCancel = () => setEditable(false)
  const onEdit = canEdit(item) ? () => setEditable(true) : undefined

  const Editable = makeEditable<T>(item, changes, editable, handleChange)

  return {
    Editable,
    onSave,
    onCancel,
    onEdit,
    handleChange,
    editable,
    changes,
    setChanges,
  }
}

export default useEditable
