import React, {useState} from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import {InputFileUpload} from '../../components/forms/inputs'
import type {UserEditProps} from './types'

const UserAvatar = ({user, isCurrentUser, onChange}: UserEditProps) => {
  const [image, setImage] = useState(
    isCurrentUser || (user.display_options && user.display_options.user_image)
      ? user.user_image
      : undefined
  )

  const handleImageChange = (file: any) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const img = reader.result
      setImage(img as any)
      onChange('user_image', {type: file.type, data: img})
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  return (
    <Stack direction="column" spacing={4} alignItems="center">
      <Avatar alt="user avatar" src={image} sx={{width: 150, height: 150}} />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h4">{user?.user_name}</Typography>
      </Box>
      {isCurrentUser && (
        <InputFileUpload text="Upload image" onUpload={handleImageChange} sx={{width: 250}} />
      )}
    </Stack>
  )
}

export default UserAvatar
