import Stack from '@mui/material/Stack'
import {modalities, architectures} from './constants'
import {paginate, searchText, allOf, filterContains, filterBetween, equals} from './filters'
import {filterChangeHandlers, handleRangeChange, sortItems} from './transformers'
import {FiltersContainer, CheckboxGroup, RangeSlider} from './widgets'
import {fromMillionTokens} from 'components/formatters'
import type {FiltersProps} from './types'
import type {Model, FilterConfig, SearchResult} from '@equistamp/types'

export const filterModels = (models: Model[], params: FilterConfig): SearchResult => {
  let filtered = searchText(models, params.filters?.search || '', [
    'name',
    'description',
    'publisher',
  ]).filter(
    allOf([
      filterContains(params?.filters?.architecture, 'architecture'),
      filterContains(params?.filters?.modalities, 'modalities'),
      equals(params?.filters?.public_usable, 'pubic_usable'),
      filterContains(
        params?.filters?.models?.map((m) => m.id),
        'id'
      ),
      filterBetween(
        params?.filters?.minNumParameters,
        params?.filters?.maxNumParameters,
        'num_parameters'
      ),
      filterBetween(params?.filters?.minScore, params?.filters?.maxScore, 'score'),
      filterBetween(
        params?.filters?.minLatency ? params?.filters?.minLatency / 1000 : undefined,
        params?.filters?.maxLatency ? params?.filters?.maxLatency / 1000 : undefined,
        ['statistics', 'median_latency']
      ),
      filterBetween(
        fromMillionTokens(params?.filters?.minInputTokensPrice),
        fromMillionTokens(params?.filters?.maxInputTokensPrice),
        'cost_per_input_character_usd'
      ),
      filterBetween(
        fromMillionTokens(params?.filters?.minOutputTokensPrice),
        fromMillionTokens(params?.filters?.maxOutputTokensPrice),
        'cost_per_output_character_usd'
      ),
      filterBetween(
        params?.filters?.minInstanceHourPrice,
        params?.filters?.maxInstanceHourPrice,
        'cost_per_instance_hour_usd'
      ),
    ])
  )
  filtered = sortItems(filtered, params)
  return paginate(
    filtered,
    params.page || 0,
    !params.perPage || params.perPage === 'all' ? 20 : params.perPage
  )
}

const ModelFilters = ({config, onChange}: FiltersProps) => {
  const {handleChange, updateField} = filterChangeHandlers({
    config,
    onChange,
  })

  return (
    <FiltersContainer>
      <CheckboxGroup
        label="Architecture"
        allOptions={architectures}
        selected={config?.filters?.architecture}
        onChange={handleChange('architecture')}
      />
      <CheckboxGroup
        label="Modalities"
        allOptions={modalities}
        selected={config?.filters?.modalities}
        onChange={handleChange('modalities')}
      />
      <Stack>
        <RangeSlider
          label="Score"
          start={config?.filters?.minScore}
          end={config?.filters?.maxScore}
          onChange={handleRangeChange(config?.filters, 'Score', updateField)}
        />
        <RangeSlider
          label="Number of parameters"
          start={config?.filters?.minNumParameters}
          end={config?.filters?.maxNumParameters}
          min={100}
          max={100000000000}
          scale="log"
          onChange={handleRangeChange(config?.filters, 'NumParameters', updateField)}
        />
        <RangeSlider
          label="Average latency (ms)"
          start={config?.filters?.minLatency}
          end={config?.filters?.maxLatency}
          max={60000}
          scale="log"
          onChange={handleRangeChange(config?.filters, 'Latency', updateField)}
        />
      </Stack>
      <Stack>
        <RangeSlider
          label="Price per 1M input token in USD"
          start={config?.filters?.minInputTokensPrice}
          end={config?.filters?.maxInputTokensPrice}
          min={0.0001}
          max={400}
          scale="log"
          onChange={handleRangeChange(config?.filters, 'InputTokensPrice', updateField)}
        />
        <RangeSlider
          label="Price per 1M output token in USD"
          start={config?.filters?.minOutputTokensPrice}
          end={config?.filters?.maxOutputTokensPrice}
          min={0.0001}
          max={400}
          scale="log"
          onChange={handleRangeChange(config?.filters, 'OutputTokensPrice', updateField)}
        />
        <RangeSlider
          label="Price per instance hour in USD"
          start={config?.filters?.minInstanceHourPrice}
          end={config?.filters?.maxInstanceHourPrice}
          min={0.0001}
          max={1000}
          scale="log"
          onChange={handleRangeChange(config?.filters, 'InstanceHourPrice', updateField)}
        />
      </Stack>
    </FiltersContainer>
  )
}

export default ModelFilters
