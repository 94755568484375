const paths = {
  docs: () => '/docs/',
  about: () => '/about',
  compare: () => '/compare',
  privacy: () => '/privacy',
  pricing: () => '/pricing',
  home: () => '/',
  discord: () => 'https://discord.gg/tjKApmzndk',
  evaluations: {
    create: () => '/evaluations/create',
    alerts: (id: string) => `/evaluations/${id}#related-alerts`,
    connections: (id: string) => `/evaluations/${id}/connections`,
    runs: (id: string) => `/evaluations/${id}/runs`,
    tasks: (id: string) => `/evaluations/${id}/tasks`,
    tests: (id: string) => `/evaluations/${id}/tests`,
    show: (id: string) => `/evaluations/${id}`,
    all: () => '/evaluations',
  },
  models: {
    all: () => '/models',
    connections: (id: string) => `/models/${id}/connections`,
    create: () => '/models/create',
    runs: (id: string) => `/models/${id}/runs`,
    show: (id: string) => `/models/${id}`,
  },
  evaluationSessions: {
    show: (id: string) => `/evaluation-sessions/${id}`,
  },
  alerts: {
    all: () => '/alerts',
    create: () => '/alerts/create',
    edit: (id: string) => `/alerts/${id}/edit`,
    show: (id: string) => `/alerts/${id}`,
  },
  subscriptions: {
    all: (redirect?: string) => '/subscriptions' + (redirect ? `?redirect=${redirect}` : ''),
    success: () => '/user/profile',
    cancel: () => '/subscriptions',
    confirm: (id: string) => `/subscriptions/confirm/${id}`,
    unsubscribe: (id: string) => `/subscriptions/${id}/unsubscribe`,
  },
  tests: {
    random: () => '/evaluations/random/tests',
  },
  user: {
    alerts: () => '/user/profile#alerts',
    login: (redirect?: string) => '/user/login' + (redirect ? `?redirect=${redirect}` : ''),
    logout: (redirect?: string) => '/user/logout' + (redirect ? `?redirect=${redirect}` : ''),
    me: () => '/user/profile',
    profile: (id: string) => `/user/${id}`,
    register: (redirect?: string) => '/user/register' + (redirect ? `?redirect=${redirect}` : ''),
    forgotPassword: () => `/user/forgot_password`,
    resetPassword: () => `/user/reset_password`,
  },
  login: {
    github: (redirect?: string) => '/user/login/github' + (redirect ? `?redirect=${redirect}` : ''),
  },
  admin: {
    index: () => '/admin/',
    evaluationSessions: () => '/admin/evaluation-sessions',
    modelStatuses: () => '/admin/model-statuses',
    schedules: () => '/admin/schedules',
  },
}

export default paths
