import {SVGProps} from 'react'
const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.044 10.706a5.5 5.5 0 1 1 .662-.662.502.502 0 0 1 .148.102l3.5 3.5a.5.5 0 0 1-.708.708l-3.5-3.5a.502.502 0 0 1-.102-.148ZM11 6.5a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
      fill="#788492"
    />
  </svg>
)
export default SvgMagnifyingGlass
