import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {InfoItem} from 'components/text'
import type {Response as ResponseType} from '@equistamp/types'

const Response = ({
  id,
  raw_task_text,
  parsed_response_text,
  response_time_in_seconds,
}: ResponseType) => (
  <Card key={id} sx={{p: 1, borderRadius: '10px'}}>
    <CardContent>
      <InfoItem title="Latency in seconds" value={response_time_in_seconds?.toFixed(1)} />
      <InfoItem title="Task" value={raw_task_text} />
      <InfoItem title="Response" value={parsed_response_text} />
    </CardContent>
  </Card>
)

export default Response
