import {parseEDNString, toEDNStringFromSimpleObject} from 'edn-data'

export const smartRound = (num: number) => {
  if (num === 0 || !isFinite(num)) return num // Handle special cases

  const absNum = Math.abs(num)
  if (absNum >= 1) {
    // For numbers greater than or equal to 1, no decimal places
    return Math.round(num)
  } else {
    // For numbers less than 1, find the first significant digit
    const numDigits = Math.ceil(-Math.log10(absNum) + 1)
    return parseFloat(num.toPrecision(numDigits))
  }
}

export const formatNum = (num: number) => {
  if (num < 1) {
    return num.toPrecision(2)
  }
  const units = ['k', 'M', 'B', 'T']
  let unitIndex = -1

  while (num >= 1000 && unitIndex < units.length - 1) {
    unitIndex++
    num /= 1000
  }

  return unitIndex === -1 ? `${smartRound(num)}` : `${smartRound(num)}${units[unitIndex]}`
}

export const formatLatency = (latency?: number) => {
  if (undefined === latency) return '-'
  if (latency < 0) {
    return `${(latency * 1000).toFixed(3)} ms`
  }
  return `${latency.toFixed(1)} s`
}

const HOUR = 3600
const DAY = HOUR * 24
const WEEK = DAY * 7
const MONTH = DAY * 30
export const formatDuration = (seconds: number) => {
  let result = [] as string[]
  ;[
    [MONTH, ' months'],
    [WEEK, ' weeks'],
    [DAY, ' days'],
    [HOUR, 'h'],
    [60, 'min'],
    [1, 's'],
  ].reduce((current, [sec, desc]) => {
    const num = Math.floor(current / (sec as number))
    if (num > 0) {
      result.push(`${num}${desc}`)
    }
    return current % (sec as number)
  }, seconds)
  if (result.length === 0) return '0s'

  return result.join(', ')
}

export const splitMinutes = (mins: number) => ({
  days: Math.floor(mins / (60 * 24)),
  hours: Math.floor(mins / 60) % 24,
  minutes: mins % 60,
})

export const rounded = (val: number) => (val >= 0.01 ? val.toFixed(2) : val.toPrecision(2))
export const formatScore = (v?: number) => (v !== undefined ? rounded(v) : '-')
export const formatPrice = (val?: number) => (val !== undefined ? `$${rounded(val)}` : '-')
export const formatPercentage = (val?: number) => (val !== undefined ? `${rounded(val)}%` : '-')
export const formatRoundPercentage = (val?: number) =>
  val !== undefined ? `${Math.round(val)}%` : '-'
export const formatInteger = (val?: number) =>
  val || val === 0
    ? Math.round(val)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '-'

export const asMillionTokens = (val?: number) => (val ? val * 4000000 : 0)
export const fromMillionTokens = (val?: number) => (val !== undefined ? val / 4000000 : undefined)

export const tokensPrice = (val?: number) => formatPrice(asMillionTokens(val))

export const parseJSON = (v: string | undefined) => {
  try {
    return v && JSON.parse(v)
  } catch (e) {
    return null
  }
}

// The database stores dates as UTC without timezone info, so make sure JS returns the right timezone.
// 20 characters is the length of a basic datetime, like '2021-01-23T12:34:45'
export const parseDate = (date?: string) => date && new Date(date.length < 20 ? date + 'Z' : date)

export const parseEDN = (val: string) => parseEDNString(val)

export const toEdn = (val: string) => {
  const json = parseJSON(val)
  return json ? toEDNStringFromSimpleObject(json, {keysAs: 'string'}) : null
}

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const formatDatetime = (datetime?: string) =>
  datetime &&
  new Date(datetime)?.toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})
