import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import {makeApi} from '@equistamp/api'
import useEvaluationSessions from 'hooks/useEvaluationSessions'
import useModels from 'hooks/useModels'
import Page from 'components/Page'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import type {Evaluation as EvaluationType} from '@equistamp/types'
import {EvaluationSessions as EvaluationSessionsWidget} from 'components/search'
import Path from 'routeLinks'

export const fetchEvaluation = (id: string) => makeApi().evaluations.fetch(id)

const RunsSummary = ({item: evaluation, setItem}: RendererProps<EvaluationType>) => {
  const {refresh} = useEvaluationSessions()
  const {refresh: refreshModels} = useModels()

  useEffect(() => {
    refreshModels({
      filters: {
        public_usable: true,
        evaluation: evaluation.id,
        fields: ['name', 'price'],
      },
    })
    const eval_session_fields = [
      'report_paid',
      'evaluation_session_paid',
      'datetime_started',
      'datetime_completed',
      'evaluation_id',
      'evaluatee_id',
      'evaluation_name',
      'evaluatee_name',
      'completed',
      'failed',
      'score',
      'num_questions_answered',
      'num_answered_correctly',
      'median_seconds_per_task',
    ]
    refresh({
      filters: {
        evaluations: [{id: evaluation.id, name: evaluation.name}],
        fields: eval_session_fields,
      },
    })
  }, [evaluation, refresh, refreshModels])

  return (
    <Page
      title={`${evaluation?.name} runs`}
      showTitle
      breadcrumbs={[
        {title: 'evaluations', link: Path.evaluations.all()},
        {title: evaluation.name, link: Path.evaluations.show(evaluation.id)},
        {title: 'runs'},
      ]}
    >
      <Box sx={{m: 3}}></Box>
      <EvaluationSessionsWidget searchbar item_type="evaluation" />
    </Page>
  )
}

const Runs: React.FC = () => <ItemLoader itemName="evaluation" renderer={RunsSummary} />

export default Runs
