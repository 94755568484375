import React, {useState, ReactNode} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import {useTheme} from '@mui/material/styles'
import {makeApi} from '@equistamp/api'
import useUser from 'hooks/useUser'
import Page from 'components/Page'

type VariantProps = {
  title: string
  titleStyle?: {[key: string]: any}
  price?: string
  priceSubscript?: string
  included: string[]
  children: ReactNode
  onClick?: () => void
}
const Variant = ({
  title,
  titleStyle,
  price,
  priceSubscript,
  included,
  children,
  onClick,
}: VariantProps) => {
  const theme = useTheme()
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    if (!onClick) return
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  return (
    <Card
      elevation={10}
      sx={{
        width: '100%',
        bgcolor: theme.palette.primary.main,
        color: 'primary.contrastText',
      }}
    >
      <CardActionArea sx={{p: 0.5}} onClick={handleClick} disabled={!onClick}>
        <CardContent style={titleStyle} sx={{textAlign: 'center', borderRadius: 1}}>
          <Typography sx={{maxHeight: 20}}>
            {loading ? <CircularProgress color="success" /> : title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Divider sx={{borderBottomWidth: 2, borderColor: 'black', m: 1}} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{textAlign: 'center', minHeight: 100}}
      >
        {children}
      </Stack>
      <Divider sx={{borderBottomWidth: 2, borderColor: 'black', m: 1}} />
      <CardContent>
        <Stack direction="column" justifyContent="space-evenly" alignItems="center">
          <Typography variant="overline">Includes</Typography>
          <Box>
            {included.map((item) => (
              <Box key={item} display="flex">
                <Typography>
                  <DoneIcon /> {item}
                </Typography>
              </Box>
            ))}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

const levelOffers = {
  basic: ['Human Evaluation Test', 'Access to LLM Scores'],
  pro: ['Access to Add Models', 'Access to Create Custom Evaluations'],
  enterprise: ['Private & Custom LLM Testing Capabilities'],
}

const Subscribe: React.FC = () => {
  const theme = useTheme()
  const {user} = useUser()

  const selectSubscription = (level: string) => async () => {
    if (level === 'enterprise') {
      window.location.href = 'mailto:sales@equistamp.com'
    } else if (user?.subscription_level === 'admin') {
      alert("You're an admin - for a normal user this would redirect to Stripe")
    } else {
      const {checkout_url} = await makeApi().users.subscribe(level)
      window.location.href = checkout_url
    }
  }

  return (
    <Page title="Subscription" showTitle>
      <Stack spacing={4} sx={{mt: 5, mb: 5}} direction={{sm: 'column', md: 'row'}}>
        <Variant
          title="Basic"
          included={levelOffers.basic}
          onClick={user?.subscription_level !== 'free' ? selectSubscription('free') : undefined}
          titleStyle={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.dark,
          }}
        >
          <Typography variant="h4">Free</Typography>
        </Variant>
        <Variant
          title="Pro"
          included={[
            ...levelOffers.basic,
            ...levelOffers.pro,
            'Automatic round up to $14 in credits',
          ]}
          onClick={selectSubscription('pro')}
        >
          <Typography variant="h4">$14</Typography>
          <Typography>Per Month Per User</Typography>
        </Variant>
        <Variant
          title="Enterprise"
          included={[...levelOffers.basic, ...levelOffers.pro, ...levelOffers.enterprise]}
          onClick={selectSubscription('enterprise')}
          titleStyle={{
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.light,
          }}
        >
          <Typography>
            Please contact us for pricing at&nbsp;
            <Link href="mailto:sales@equistamp.com" sx={{color: 'primary.contrastText'}}>
              sales@equistamp.com
            </Link>
          </Typography>
        </Variant>
      </Stack>
    </Page>
  )
}

export default Subscribe
