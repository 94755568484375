import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import Page from 'components/Page'
import useEvaluations from 'hooks/useEvaluations'
import {Evaluations as EvaluationsWidget} from 'components/search'

const Evaluations: React.FC = () => {
  const {refresh} = useEvaluations()
  useEffect(() => {
    refresh({})
  }, [refresh])

  return (
    <Page title="Evaluations" showTitle>
      <Box sx={{m: 3}}></Box>
      <EvaluationsWidget searchbar />
      <Box sx={{m: 3}}></Box>
    </Page>
  )
}

export default Evaluations
