import Stack from '@mui/material/Stack'
import {InfoItem} from '../../components/text'
import {CodeConfig} from './defaults'
import EditVariable from './Variable'

type CodeSettingsProps = {
  config?: CodeConfig
  onChange: (name: string, val: any) => void
  vars: {[k: string]: any}
}
const CodeSettings = ({vars, config, onChange}: CodeSettingsProps) => {
  const update = (name: string, val: any) => {
    onChange(name, val)
  }
  return (
    <Stack sx={{minWidth: '250px'}}>
      {config && <InfoItem title="Name" value={config.name} />}
      {config && <InfoItem title="Description" value={config.description} />}
      {config?.variables &&
        config.variables.map((variable) => (
          <EditVariable
            key={`${config.id}-${variable.name}`}
            value={vars[variable.name]}
            variable={variable}
            onChange={(val: any) => update(variable.name, val)}
          />
        ))}
    </Stack>
  )
}

export default CodeSettings
