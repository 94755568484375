import React, {useEffect} from 'react'
import Box from '@mui/material/Box'
import useModels from 'hooks/useModels'
import Page from 'components/Page'
import {Models as ModelsWidget} from 'components/search'

const Models: React.FC = () => {
  const {refresh} = useModels()

  useEffect(() => {
    refresh({})
  }, [refresh])

  return (
    <Page title="AI Models" showTitle>
      <Box sx={{m: 3}}></Box>
      <ModelsWidget searchbar />
      <Box sx={{m: 3}}></Box>
    </Page>
  )
}

export default Models
