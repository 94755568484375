import React, {ReactElement} from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {NavButton} from '../components/forms/inputs'

type ActionType = {
  title: string
  img?: string
  imgWidth?: number
  variant?: string
  action?: string
  actionTitle?: string
  titleFont?: {[k: string]: any}
  actionFont?: {[k: string]: any}
}
export const Action = ({
  title,
  img,
  imgWidth,
  variant,
  action,
  actionTitle,
  titleFont,
  actionFont,
}: ActionType) => (
  <Stack direction="column" spacing={1} justifyContent="space-evenly" alignItems="center">
    {img && <img src={img} alt={title} width={imgWidth || 200} height={(imgWidth || 200) * 0.96} />}
    <Typography
      variant={variant || ('h5' as any)}
      sx={{fontWeight: 600, fontSize: 16, ...titleFont}}
    >
      {title}
    </Typography>
    {action && (
      <NavButton
        to={action}
        label={actionTitle}
        sx={{fontWeight: 400, fontSize: 16, ...actionFont}}
      />
    )}
  </Stack>
)

type HeroProps = {
  text: string
  img: ReactElement
  textSize?: number
}
export const Hero = ({text, img, textSize}: HeroProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <AppBar color="primary" enableColorOnDark position="relative" elevation={0}>
      <Toolbar>
        <Container sx={{pt: 5, pb: 10}}>
          <Stack
            direction={{sm: 'column', md: 'row'}}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography
              variant="h4"
              sx={{fontSize: textSize, fontWeight: 700, maxWidth: isMobile ? 'inherit' : '40%'}}
            >
              {text}
            </Typography>
            {img}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}
