import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import {BaseStats, FailedResponses} from 'components/evaluationSessions/EvaluationSessions'
import PreviousResults from 'components/evaluationSessions/PreviousResults'
import type {Eval} from '@equistamp/types'
import Path from 'routeLinks'

const EvaluationSessionSummary = ({item: evalSession}: RendererProps<Eval>) => {
  return (
    <Page
      showTitle
      title={`Report for "${evalSession.evaluation_name}" on "${evalSession.evaluatee_name}"`}
      breadcrumbs={[
        {
          title: evalSession.evaluation_name,
          link: Path.evaluations.runs(evalSession.evaluation_id),
        },
        {title: evalSession.evaluatee_name, link: Path.models.runs(evalSession.evaluatee_id)},
        {title: 'report'},
      ]}
    >
      <Stack spacing={8} sx={{pt: 4, pb: 8}}>
        <Stack spacing={2}>
          <Typography variant="h3">Basic statistics</Typography>
          <BaseStats {...evalSession} />
        </Stack>

        <PreviousResults {...evalSession} />

        {evalSession && <FailedResponses {...evalSession} />}
      </Stack>
    </Page>
  )
}

const EvaluationSession: React.FC = () => (
  <ItemLoader itemName="evaluation session" renderer={EvaluationSessionSummary} />
)

export default EvaluationSession
