import {ReactElement, useState, useCallback, ReactNode} from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import {RoundedButton} from 'components/forms/inputs'
import {DocItem, DocsPanel} from 'components/documentation'
import OldWizardComp from './OldWizard'

export const OldWizard = OldWizardComp
const range = (n: number) => Array.from({length: n}, (_, i) => i)

type NextHandler = () => boolean | Promise<boolean>
export type StepRendererProps = {
  enableNext: (enabled: boolean) => void
  setNextHandler: (handler: NextHandler) => void
  onNext?: () => Promise<void>
  index: number
}

type Step<T> = (props: StepRendererProps & T) => ReactElement

export type StepProps<U> = {
  label: string
  nextLabel?: string
  Step: Step<U>
  extraProps?: U
  documentation?: string | ReactNode | DocItem[]
}

const ProgressBar = ({currentStep, totalSteps}: {currentStep: number; totalSteps: number}) => (
  <Stack direction="row" spacing={0.5} sx={{backgroundColor: 'primary.dark'}}>
    {range(totalSteps).map((i) => (
      <LinearProgress
        key={i}
        sx={{height: 8, width: '100%'}}
        variant="determinate"
        value={i < currentStep ? 100 : 0}
      />
    ))}
  </Stack>
)

type WizardProps = {
  title: string
  steps: StepProps<any>[]
  sx?: {[k: string]: any}
  onCancel?: () => void
  onFinish: () => Promise<string | null>
}
export const Wizard = ({title, steps, sx, onCancel, onFinish}: WizardProps) => {
  const [index, setIndex] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [activeStep, setActiveStepIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [nextEnabled, setNextEnabled] = useState(true)
  const [nextHandler, setNextHandler] = useState<NextHandler>()

  const totalSteps = () => steps.length
  const isLastStep = () => activeStep === totalSteps() - 1
  const setActiveStep = useCallback((step: number) => {
    setIndex((k) => k + 1)
    setActiveStepIndex(step)
    setNextHandler(undefined)
    setNextEnabled(true)
    setLoading(false)
    setError(null)
  }, [])

  const checkCanContinue = useCallback(async () => {
    setLoading(true)
    try {
      return await Promise.resolve(!nextHandler || nextHandler())
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
    return false
  }, [nextHandler])

  const handleNext = async () => {
    if (loading || !(await checkCanContinue())) {
      // can't continue, so do nothing
    } else if (!isLastStep()) {
      setActiveStep(activeStep + 1)
    } else if (onFinish) {
      setLoading(true)
      setError(await onFinish())
      setLoading(false)
    }
  }
  const updateHandler = useCallback(
    (handler: NextHandler) => setNextHandler(() => handler),
    [setNextHandler]
  )
  const handleBack = useCallback(() => setActiveStep(activeStep - 1), [activeStep, setActiveStep])

  const {Step, label, documentation, nextLabel, extraProps} = steps[activeStep]

  return (
    <Stack direction="row" sx={{minHeight: '100vh'}}>
      <Box sx={{flex: 1, position: 'relative'}}>
        <ProgressBar currentStep={activeStep + 1} totalSteps={steps.length} />
        <Stack sx={{m: '88px 164px'}} spacing={2}>
          <Typography variant="h5" sx={{color: 'secondary.subText'}}>
            {title}
          </Typography>
          <Typography variant="h2">{label}</Typography>
          <Step
            index={index}
            enableNext={setNextEnabled}
            setNextHandler={updateHandler}
            onNext={handleNext}
            {...extraProps}
          />
          {error && <Alert severity="error">{error}</Alert>}
        </Stack>

        <Stack sx={{position: 'absolute', bottom: 0, width: '100%'}}>
          <Divider />
          <Stack direction="row" sx={{m: 3, mr: 14, ml: 14}}>
            {onCancel && (
              <RoundedButton
                variant="text"
                label="Cancel"
                onClick={onCancel}
                sx={{mr: 1, color: 'inherit'}}
              />
            )}
            <Box sx={{flex: '1 1 auto'}} />
            {activeStep > 0 && (
              <RoundedButton
                variant="text"
                onClick={handleBack}
                sx={{mr: 1, color: 'primary.main'}}
                label="Previous"
              />
            )}
            <RoundedButton
              label={nextLabel || (isLastStep() ? 'Save' : 'Next')}
              onClick={handleNext}
              sx={{mr: 1}}
              loading={loading}
              disabled={!nextEnabled}
            />
          </Stack>
        </Stack>
      </Box>

      {documentation && (
        <Box sx={{width: '30%', p: 7, bgcolor: 'secondary.main'}}>
          {Array.isArray(documentation) ? <DocsPanel items={documentation} /> : documentation}
        </Box>
      )}
    </Stack>
  )
}

export default Wizard
