import {useEffect, useRef, useState} from 'react'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi, ServerError} from '@equistamp/api'
import useUser from 'hooks/useUser'
import {formatPrice} from 'components/formatters'
import {ExternalA, PotentialAction} from 'components/text'
import {actionCellStyle} from 'components/Compare/ScoresTable'
import {ConfirmationDialog, InfoDialog} from 'components/dialogs'
import {filterModels} from 'components/filters/Serializer'
import Path from 'routeLinks'
import type {Evaluation, Model, ModelConnection} from '@equistamp/types'

type EvaluationRunProps = {evaluation: Evaluation; model: Model}

const CheckPrice = ({price, changed}: {price?: number; changed: boolean}) => {
  if (price !== undefined) {
    return (
      <Stack spacing={1}>
        {changed && (
          <Alert severity="warning">Sorry, but the underlying price has just changed</Alert>
        )}
        <Typography>Do you want to run this evaluation for {formatPrice(price / 100)}?</Typography>
      </Stack>
    )
  }
  return (
    <Stack alignItems="center">
      <Typography component="div">Checking current price...</Typography>
      <CircularProgress />
    </Stack>
  )
}

type StartEvalSessionProps = EvaluationRunProps & {price?: number; onPriceChange: () => void}
const StartEvalSession = ({evaluation, model, price, onPriceChange}: StartEvalSessionProps) => {
  const status = useRef('init')
  const [error, setError] = useState('')

  useEffect(() => {
    const run = async () => {
      status.current = 'starting'
      const payload = {
        evaluation_id: evaluation.id,
        evaluatee_id: model.id,
        name: `Run evaluation "${evaluation.name}" on "${model.name}"`,
        price,
        cadence: 'once',
      } as ModelConnection
      try {
        const res = await makeApi().modelConnections.connectModels([payload])
        if (res.checkout_url) {
          window.location.href = res.checkout_url
        }
        status.current = 'started'
        setError(' ')
      } catch (e) {
        if (e instanceof ServerError && e.status === 402) {
          onPriceChange()
        } else {
          status.current = 'error'
          setError(e instanceof ServerError ? e.error.toString() : `${e}`)
        }
      }
    }
    if (status.current === 'init' && price !== undefined) {
      run()
    }
  }, [evaluation, model, price, onPriceChange])

  if (['init', 'starting'].includes(status.current)) {
    return (
      <Stack alignItems="center">
        <Typography>Starting evaluation session...</Typography>
        <CircularProgress />
      </Stack>
    )
  } else if (status.current === 'error') {
    return <Alert severity="error">Could not start evaluation: {error}</Alert>
  }
  const runsUrl = Path.evaluations.runs(evaluation.id) + filterModels([model])
  return (
    <Stack>
      <Alert severity="success">Your evaluation has been started</Alert>
      <Typography>
        Your evaluation should soon appear on the list of{' '}
        <ExternalA to={runsUrl} text="evaluation runs." />
        It can take a couple of minutes for it to appear.
      </Typography>
    </Stack>
  )
}

const RunEvaluationButton = ({evaluation, model}: EvaluationRunProps) => {
  const [show, setShow] = useState(false)
  const [cost, setCost] = useState<number | undefined>()
  const [priceChanged, setPriceChanged] = useState(false)
  const [startEval, setStartEval] = useState(false)
  const {loggedIn} = useUser()

  useEffect(() => {
    const getPrice = async () => {
      const {items} = await makeApi().models.list({
        filters: {fields: ['price', 'name'], evaluation: evaluation.id, models: [model]},
      })
      setCost(items[0]?.price)
    }
    if (show && cost === undefined) getPrice()
  }, [show, cost, setCost, evaluation, model])

  const handleConfirm = (confirmed: boolean) => {
    setShow(false)
    setStartEval(confirmed)
  }
  const handlePriceChange = () => {
    setStartEval(false)
    setShow(true)
    setCost(undefined)
    setPriceChanged(true)
  }

  const action = loggedIn
    ? () => setShow(true)
    : Path.user.login(Path.evaluations.connections(evaluation.id) + filterModels([model]))

  return (
    <>
      <PotentialAction action={action} sx={{...actionCellStyle, textTransform: 'none'}}>
        Run Evaluation
      </PotentialAction>

      <ConfirmationDialog
        open={show}
        label={`Run "${evaluation.name}" on "${model.name}"`}
        onSelect={handleConfirm}
      >
        <CheckPrice price={cost} changed={priceChanged} />
      </ConfirmationDialog>

      <InfoDialog
        label={`Starting "${evaluation.name}" on "${model.name}"...`}
        open={startEval}
        onClose={() => setStartEval(false)}
      >
        <StartEvalSession
          evaluation={evaluation}
          model={model}
          price={cost}
          onPriceChange={handlePriceChange}
        />
      </InfoDialog>
    </>
  )
}

export default RunEvaluationButton
