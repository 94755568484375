import {useEffect, useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import {makeApi} from '@equistamp/api'
import useResponses from 'hooks/useResponses'
import useUser from 'hooks/useUser'
import {NavButton, RedirectButton, handleDownload} from 'components/forms/inputs'
import {Responses as SearchResponses} from 'components/search'
import {InfoItem} from 'components/text'
import type {Eval, ItemType, ID} from '@equistamp/types'
import Paths from 'routeLinks'

const getTimeDifference = (d1: string, d2: string) => {
  let difference = Math.abs((new Date(d2) as any) - (new Date(d1) as any))

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  difference -= days * (1000 * 60 * 60 * 24)

  const hours = Math.floor(difference / (1000 * 60 * 60))
  difference -= hours * (1000 * 60 * 60)

  const minutes = Math.floor(difference / (1000 * 60))
  difference -= minutes * (1000 * 60)

  const seconds = Math.floor(difference / 1000)

  return [
    days && `${days} days`,
    hours && `${hours} hours`,
    minutes && `${minutes} minutes`,
    seconds && `${seconds} seconds`,
  ]
    .filter(Boolean)
    .join(', ')
}

export const BuyReportButton = ({evalSessionId}: {evalSessionId: ID}) => {
  const getPurchaseLink = async () => {
    const {to_pay, checkout_url} = await makeApi().evaluationSessions.buyReport(evalSessionId)
    if (!to_pay) {
      return Paths.evaluationSessions.show(evalSessionId)
    } else if (checkout_url) {
      return checkout_url
    }
    throw new Error('Could not get purchase link')
  }
  return <RedirectButton label="Buy full report for $1.00" urlGetter={getPurchaseLink} />
}

const Stat = ({title, value}: {title: string; value: any}) => (
  <Grid item xs={12} sm={6} md={3}>
    <InfoItem title={title} value={value} />
  </Grid>
)

export const Status = ({
  completed,
  failed,
  datetime_completed,
  text,
  num_tasks_to_complete,
  num_questions_answered,
}: Eval & {text?: boolean}) => {
  const Icon = () => {
    if (!datetime_completed || !completed) return <CircularProgress size={25} />
    if (failed) return <CloseIcon color="error" />
    return <DoneIcon color="success" />
  }
  const Text = () => {
    if (!text) return null
    if (!datetime_completed || !completed) {
      return (
        <Typography>
          {num_tasks_to_complete && num_questions_answered < num_tasks_to_complete
            ? `${Math.round((100 * num_questions_answered) / num_tasks_to_complete)}% complete`
            : 'Running'}
        </Typography>
      )
    }
    if (failed) return <Typography>Failed</Typography>
    return <Typography>Completed</Typography>
  }
  return (
    <Stack direction="row" spacing={2}>
      <Icon /> <Text />
    </Stack>
  )
}

export const BaseStats = (evalSession: Eval) => (
  <Grid container spacing={2} sx={{mb: 3}}>
    <Stat title="Status" value={<Status {...evalSession} text />} />
    <Stat title="Tasks answered" value={evalSession.num_questions_answered} />
    <Stat title="Correct tasks" value={evalSession.num_answered_correctly} />
    <Stat
      title="Percentage correct"
      value={
        evalSession.num_questions_answered
          ? (
              (100 * evalSession.num_answered_correctly) /
              evalSession.num_questions_answered
            ).toFixed(1)
          : '-'
      }
    />
    <Stat title="Started at" value={evalSession.datetime_started?.slice(0, 19)} />
    <Stat title="Completed at" value={evalSession.datetime_completed?.slice(0, 19)} />
    {evalSession.datetime_completed ? (
      <Stat
        title="Run time"
        value={getTimeDifference(evalSession.datetime_started, evalSession.datetime_completed)}
      />
    ) : undefined}
  </Grid>
)

export const FailedResponses = ({id, report_paid}: Eval) => {
  const {refresh} = useResponses()

  useEffect(() => {
    report_paid &&
      refresh({
        filters: {
          evaluation_session_id: id,
          correct: false,
          fields: [
            'id',
            'correct',
            'raw_task_text',
            'parsed_response_text',
            'response_time_in_seconds',
            'correctness',
            'creation_date',
            'report_paid',
          ],
        },
      })
  }, [refresh, report_paid, id])

  if (!report_paid) {
    return (
      <Stack direction="row" justifyContent="center">
        <BuyReportButton evalSessionId={id} />
      </Stack>
    )
  }

  const fetcher = async () =>
    handleDownload(async () =>
      makeApi().evaluationSessions.getResponses({
        filters: {evaluation_session_id: id, export: 'csv'},
      })
    )

  return (
    <Stack spacing={2}>
      <Typography variant="h3" sx={{pt: 4}}>
        Invalid responses
      </Typography>
      <SearchResponses
        searchbar
        searchOptions={{extraActions: [{label: 'Download as csv', action: fetcher}]}}
      />
    </Stack>
  )
}

const EvaluationSession = (eval_session: Eval) => {
  const [showImg, setShowImg] = useState(false)
  const {user} = useUser()
  const start = eval_session.datetime_started.slice(0, 19)
  const end = eval_session.datetime_completed?.slice(0, 19)

  const showReport =
    user?.subscription_level === 'admin' ||
    (eval_session.report_paid || 0) > 0 ||
    user?.admin_of?.includes(eval_session.evaluatee_id) ||
    user?.admin_of?.includes(eval_session.evaluation_id)

  return (
    <Accordion key={eval_session.id} onChange={() => setShowImg(true)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction={{xs: 'column', sm: 'row'}} spacing={4}>
          <Status {...eval_session} />
          <Typography>
            {start} - {end}
          </Typography>
          <Typography># Tasks Completed: {eval_session.num_questions_answered}</Typography>
          <Typography>Score: {eval_session.score?.toFixed(2)}</Typography>
          <Typography>
            Median seconds per task: {eval_session.median_seconds_per_task?.toFixed(2)}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack justifyContent="center" alignItems="center">
          {showImg && (
            <img
              src={`/dashboards/${eval_session.id}.png`}
              alt="No performance graphs found"
              width="100%"
              loading="lazy"
            />
          )}
          {showReport ? (
            <NavButton
              label="Show full report"
              to={Paths.evaluationSessions.show(eval_session.id)}
            />
          ) : (
            <BuyReportButton evalSessionId={eval_session.id} />
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

const EvalTitle = ({name, runs}: EvalSessionsProps) => {
  const [paid, setPaid] = useState(false)
  const {isAdmin} = useUser()
  const {evaluatee_id, evaluation_id, price} = runs[0]
  const runEval = async () => {
    const {status, checkout_url} = await makeApi().modelConnections.connectModels([
      {evaluatee_id, evaluation_id, name, price: price || 1, cadence: 'once'},
    ])
    if (status === 'ok' && !checkout_url) {
      setPaid(true)
    }
    return checkout_url
  }

  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{width: '100%', minHeight: 40, p: '0 16px'}}
      >
        <Status {...runs[0]} />
        <Typography sx={{flexGrow: 1}}>{name}</Typography>
        {(price || isAdmin) && !paid && (
          <RedirectButton
            label={price ? `Run for $${(price / 100).toFixed(2)}` : 'Run'}
            urlGetter={runEval}
          />
        )}
        {paid && <Alert severity="success">Evaluation run started</Alert>}
      </Stack>
    </AccordionSummary>
  )
}

type EvalSessionsProps = {
  name: string
  runs: Eval[]
  item_type: ItemType
  sx?: {[k: string]: string}
}

const EvaluationSessions = ({name, runs, item_type, sx}: EvalSessionsProps) => {
  return (
    <Accordion sx={sx}>
      <EvalTitle name={name} runs={runs} item_type={item_type} />
      <AccordionDetails>
        <Paper elevation={2} sx={{p: 2}}>
          {runs
            ?.sort((a: Eval, b: Eval) => b.datetime_started.localeCompare(a.datetime_started))
            .map(EvaluationSession)}
        </Paper>
      </AccordionDetails>
    </Accordion>
  )
}

export default EvaluationSessions
