import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import {EditableText} from 'components/forms/inputs'
import {parseJSON} from 'components/formatters'

import type {SchemaHistory as SchemaType} from '@equistamp/types'
import {isInvalidJSON} from 'components/forms/validators'

const Schema = ({schema}: {schema: SchemaType}) => {
  const update = (field: keyof SchemaType) => async (val: string) => {
    await makeApi().schemas.update({
      id: schema.id,
      [field]: field === 'schema' ? parseJSON(val) : val,
    } as unknown as SchemaType)
  }
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" sx={{width: '100%'}} spacing={2}>
          <Box sx={{width: '100%'}}>
            <EditableText
              value={schema.name || schema.key || '<no name>'}
              onChange={update('name')}
              textSx={{width: '100%', color: schema.name ? 'inherit' : 'grey'}}
              editSx={{width: '100%'}}
            />
          </Box>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={4}>
          <EditableText
            value={schema.description || '<no description>'}
            onChange={update('description')}
            textSx={{width: '100%', color: schema.description ? 'inherit' : 'grey'}}
            editSx={{width: '100%'}}
          />
          <EditableText
            value={JSON.stringify(schema.schema)}
            onChange={update('schema')}
            textSx={{width: '100%'}}
            editSx={{width: '100%'}}
            validate={true}
            validator={isInvalidJSON}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
export default Schema
