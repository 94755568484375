import React, {useState} from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import Page from 'components/Page'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import {Tasks, Schemas} from 'components/search'
import {DownloadButton, RoundedButton, handleDownload} from 'components/forms/inputs'
import {EvalWizardModal} from 'components/evaluations/EvaluationWizard'
import type {Evaluation as EvaluationType} from '@equistamp/types'
import Path from 'routeLinks'

const EvaluationTasksView = ({item: evaluation, setItem}: RendererProps<EvaluationType>) => {
  const [showUploader, setShowUploader] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState('')

  const fetcher = async () => makeApi().tasks.getTasks(evaluation.id, {filters: {export: 'csv'}})
  const uploadTasks = async (e: EvaluationType) => {
    setUploadSuccess('')
    try {
      await makeApi().evaluations.bulkTasksUpload({...e, id: evaluation.id})
      setShowUploader(false)
      setUploadSuccess(
        'The provided CSV file has been uploaded - it can take up to 30min for the tasks to be visible'
      )
      return null
    } catch (error) {
      return `${error}`
    }
  }

  return (
    <Page
      title={`${evaluation?.name} tasks`}
      showTitle
      breadcrumbs={[
        {title: 'evaluations', link: Path.evaluations.all()},
        {title: evaluation.name, link: Path.evaluations.show(evaluation.id)},
        {title: 'tasks'},
      ]}
    >
      <Box sx={{m: 3}}></Box>
      <Stack direction={{xs: 'column', sm: 'row'}} spacing={2} sx={{pb: 3}}>
        <DownloadButton label="Download all tasks as CSV" fetcher={fetcher} />
        <RoundedButton label="Upload tasks" onClick={() => setShowUploader(true)} />
      </Stack>
      {uploadSuccess && <Alert severity="success">{uploadSuccess}</Alert>}
      <EvalWizardModal
        tasksOverwrite
        tasks
        title="Upload tasks"
        show={showUploader}
        onCancel={() => setShowUploader(false)}
        onSave={uploadTasks}
      />
      <Stack spacing={4}>
        <Schemas evaluation={evaluation} config={{perPage: 50}} searchbar />
        <Tasks
          evaluation={evaluation}
          config={{perPage: 50}}
          searchbar
          searchOptions={{
            extraActions: [
              {label: 'Download all tasks', action: async () => handleDownload(fetcher)},
            ],
          }}
        />
      </Stack>
    </Page>
  )
}

const EvaluationTasks: React.FC = () => (
  <ItemLoader itemName="evaluation" renderer={EvaluationTasksView} />
)

export default EvaluationTasks
