import {useState, useCallback} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi, ServerError} from '@equistamp/api'
import SearchBar from 'components/forms/SearchBar'
import ErrorAlert from 'components/ErrorAlert'
import {NavButton} from 'components/forms/inputs'
import {removeFilter} from 'components/filters'
import EvalsChart from 'components/EvalsChart'
import type {
  EvaluationsResult,
  ItemType,
  Evaluation as EvaluationType,
  Model as ModelType,
  FilterConfig,
  FilterKey,
} from '@equistamp/types'
import Path from 'routeLinks'
import {updateConfigVal} from 'hooks/useUrlFilters'
import type {ChangeKey, SearchOptions} from 'components/filters/types'

const mergeIdArrays = (a: undefined | any[], b: undefined | any[]) => [
  ...(a || []),
  ...(b || []).map((id) => id),
]
const mergeConfig = (
  config: FilterConfig,
  evals: undefined | EvaluationType[],
  models: undefined | ModelType[]
) =>
  ({
    ...config,
    filters: {
      ...config?.filters,
      evaluations: mergeIdArrays(config?.filters?.evaluations, evals),
      models: mergeIdArrays(config?.filters?.models, models),
    },
  } as FilterConfig)

type EvalRunsType = {
  searchOptions?: SearchOptions
  initialSort?: string
  itemType?: ItemType
  evals?: EvaluationType[]
  models?: ModelType[]
  transformRuns?: (res: EvaluationsResult) => EvaluationsResult
}
const EvalRunsWidget = ({
  searchOptions,
  initialSort,
  evals,
  models,
  itemType,
  transformRuns,
}: EvalRunsType) => {
  const [config, setConfig] = useState<FilterConfig>({sort: {key: initialSort || 'leader'}})
  const [error, setError] = useState<string | undefined>()

  const updateConfig = (field: ChangeKey, val: any) =>
    setConfig((current) => updateConfigVal(current, field, val))

  const onRemoveFilter = async (field: FilterKey, val: any) =>
    config.filters && updateConfig('filters', removeFilter(config.filters, field, val))

  const getEvalRuns = useCallback(
    async (config: FilterConfig) => {
      try {
        const res = await makeApi().evaluationSessions.getEvaluationRuns(
          mergeConfig(config, evals, models),
          itemType || 'evaluation'
        )
        return transformRuns ? transformRuns(res) : res
      } catch (e) {
        setError(e instanceof ServerError ? e.error.toString() : `${e}`)
      }
      return undefined
    },
    [evals, models, itemType, transformRuns]
  )

  const PreviousRunsButton = () => {
    if (['evaluation', undefined].includes(itemType) && models?.length === 1) {
      return <NavButton label="Previous Runs" to={Path.models.runs(models[0].id)} />
    } else if (itemType === 'model' && evals?.length === 1) {
      return <NavButton label="Previous Runs" to={Path.evaluations.runs(evals[0].id)} />
    }
    return null
  }

  const nameField = itemType === 'model' ? 'evaluatee_name' : 'evaluation_name'
  return (
    <>
      {error && <ErrorAlert error={error} />}
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={8}>
        <Typography variant="h3" sx={{color: 'primary.main'}}>
          Evaluation Runs
        </Typography>
        <PreviousRunsButton />
      </Stack>
      {searchOptions && (
        <SearchBar
          {...searchOptions}
          initial={config}
          onChange={updateConfig}
          onSort={(sortOptions) => setConfig({...config, sort: sortOptions})}
          onRemoveFilter={onRemoveFilter}
          sortLabel={`Display ${itemType || 'evaluation'}s by`}
          sortOptions={{
            leader: 'leader',
            [`${nameField}Asc`]: 'name (asc)',
            [`${nameField}Desc`]: 'name (desc)',
            scoreAsc: 'score (asc)',
            scoreDesc: 'score (desc)',
          }}
        >
          {searchOptions.filtersElem && searchOptions.filtersElem({config, onChange: updateConfig})}
        </SearchBar>
      )}

      <EvalsChart config={config} dataFetcher={getEvalRuns} itemType={itemType || 'evaluation'} />
    </>
  )
}

export default EvalRunsWidget
