import {useState} from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {taskTypes} from 'components/filters/constants'
import {RoundedButton} from 'components/forms/inputs'
import {HelpTooltip} from 'components/filters/widgets'
import {CUSTOM_CODE, DEFAULT} from 'components/code/defaults'
import CodeChooser, {ChosenDSLCode} from 'components/code/Chooser'
import type {QuestionType, CodeStage, CodeOverrides, DSLCode, Evaluation} from '@equistamp/types'
import type {EvaluationStageProps} from './types'

const toCodeOverrides = (val: undefined | DSLCode | CodeOverrides): CodeOverrides => {
  if (!val) {
    return {}
  } else if (typeof val === 'string') {
    return {[DEFAULT]: val as DSLCode}
  } else {
    return val
  }
}
const toChosenDSLCode = (overrides?: CodeOverrides): {[k: string]: ChosenDSLCode} => {
  if (!overrides) {
    return {}
  } else if (typeof overrides === 'string') {
    return {
      [DEFAULT]: {code: overrides, config: CUSTOM_CODE},
    }
  } else {
    return Object.entries(overrides).reduce(
      (acc, [taskType, code]) => ({...acc, [taskType]: {code, config: CUSTOM_CODE}}),
      {}
    )
  }
}

type CodeSettersProps = {
  title: string
  stage: CodeStage
  overrides?: CodeOverrides
  hints?: {[k: string]: string}
  onChange: (stage: CodeStage, taskType: QuestionType, code: DSLCode) => void
}
const CodeSetters = ({stage, title, overrides, hints, onChange}: CodeSettersProps) => {
  const [selected, setSelected] = useState<QuestionType>()
  const [current, setCurrent] = useState(toChosenDSLCode(overrides))
  const showSelector = (taskType?: QuestionType) => () => setSelected(taskType)

  const CodeButton = ({taskType, label}: {taskType: QuestionType; label: string}) => {
    const fullLabel = `${overrides && overrides[taskType] ? 'Change' : 'Set'} ${label} ${stage}`
    return (
      <Grid item xs={12} md={6} lg={4}>
        <RoundedButton
          sx={{width: '95%'}}
          onClick={showSelector(taskType)}
          label={
            <Stack direction="row">
              <Typography>{fullLabel}</Typography>
              {hints && hints[taskType] && <HelpTooltip help={hints[taskType]} />}
            </Stack>
          }
        />
      </Grid>
    )
  }
  return (
    <Stack spacing={2}>
      <Typography variant="h2">{title}</Typography>
      <Grid container spacing={2}>
        <CodeButton taskType="default" label="Default" />
        {Object.entries(taskTypes).map(([taskType, label]) => (
          <CodeButton key={taskType} taskType={taskType as QuestionType} label={label} />
        ))}
      </Grid>
      {selected && (
        <CodeChooser
          open={!!selected}
          stage={stage}
          taskType={selected || DEFAULT}
          current={selected && current[selected as string]}
          onUpdate={(chosen: ChosenDSLCode) =>
            setCurrent((current) => ({...current, [selected as string]: chosen}))
          }
          onChoose={({code}: ChosenDSLCode) => onChange(stage, selected, code)}
          onClose={() => setSelected(undefined)}
        />
      )}
    </Stack>
  )
}

const CustomCode = ({evaluation, onChange, enableNext, setNextHandler}: EvaluationStageProps) => {
  const handleCodeChange = (stage: CodeStage, taskType: QuestionType, code: DSLCode) => {
    const field = stage as keyof Evaluation
    const current = evaluation[field] as undefined | DSLCode | CodeOverrides
    onChange(field, {...toCodeOverrides(current), [taskType]: code})
  }
  return (
    <Stack spacing={4}>
      <CodeSetters
        title="Prompts"
        stage="prompt"
        onChange={handleCodeChange}
        overrides={toCodeOverrides(evaluation.prompt)}
        hints={{
          default:
            "This is the fallback prompt, if no task specific prompt is set. You don't have to set this - there is a system default which should suffice for most cases",
        }}
      />
      <CodeSetters
        title="Graders"
        stage="grader"
        overrides={toCodeOverrides(evaluation.grader)}
        onChange={handleCodeChange}
        hints={{
          default:
            "This is the fallback grader, if no task specific grader is set. You don't have to set this - there is a system default which should suffice for most cases.",
        }}
      />
    </Stack>
  )
}

export default CustomCode
