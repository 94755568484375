import {useState} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {InfoItem} from 'components/text'
import Subscribe from './Subscribe'
import {alertTriggerTypes} from 'components/filters/constants'
import type {Alert as AlertType} from '@equistamp/types'
import Path from 'routeLinks'
import useUser from 'hooks/useUser'
import {ConfirmationDialog} from 'components/dialogs'
import {MenuButton} from 'components/menu'

type AlertProps = {
  alert: AlertType
  onDelete: (alert: AlertType) => Promise<any>
}

const AlertMenu = ({alert, onDelete}: AlertProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const {user, isAdmin} = useUser()

  const handleDelete = async (confirmed?: boolean) => {
    if (confirmed) {
      await onDelete(alert)
    }
    setConfirmDelete(false)
  }

  if (!user?.id || !(isAdmin || user.id === alert?.owner?.id)) return null
  return (
    <Box sx={{position: 'absolute', top: 2, right: 2}}>
      <MenuButton
        side="right"
        Icon={MoreHorizIcon}
        items={[
          {label: 'Edit', to: Path.alerts.edit(alert.id)},
          {label: 'Delete', onClick: () => setConfirmDelete(true)},
        ]}
      />
      <ConfirmationDialog
        open={confirmDelete}
        onSelect={handleDelete}
        label="Are you sure you want to delete this alert?"
      >
        This will permanently remove this alert
      </ConfirmationDialog>
    </Box>
  )
}

const triggerType = (alert: AlertType) => alert.triggers?.map((t) => alertTriggerTypes[t.type])

const Alert = ({alert, onDelete}: AlertProps) => {
  return (
    <Card sx={{borderRadius: 2, p: 4, position: 'relative', overflow: 'visible'}}>
      <CardContent sx={{p: 1}}>
        <AlertMenu alert={alert} onDelete={onDelete} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Stack direction="column" justifyContent="space-between" spacing={2} sx={{mr: 6}}>
              <Typography sx={{fontWeight: 700}}>{alert.name}</Typography>
              <Typography>{alert.description}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <InfoItem
              title="Type"
              value={triggerType(alert)}
              variant="inherit"
              sx={{color: '#596676'}}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <InfoItem
              title="Creator"
              value={
                alert?.owner?.full_name ||
                alert?.owner?.user_name ||
                alert.owner?.email_address ||
                '-'
              }
              to={alert?.owner?.id && Path.user.profile(alert?.owner?.id)}
              sx={{color: '#596676'}}
              variant="inherit"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack
              spacing={5}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{height: '100%'}}
            >
              <Subscribe alert={alert} sx={{width: '100%'}} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Alert
