import {useState} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import SettingsIcon from '@mui/icons-material/Settings'
import AssignmentIcon from '@mui/icons-material/Assignment'
import CloseIcon from '@mui/icons-material/Close'
import {Toggle, NavButton} from 'components/forms/inputs'
import {equistampButton} from 'components/text'
import Path from 'routeLinks'
import type {User} from '@equistamp/types'
import type {ChangeFunc} from './types'

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
}

type SettingsModalType = {
  open: boolean
  user: User
  toggler: (field: string) => (on: boolean) => Promise<User>
  onClose: () => void
}
const SettingsModel = ({open, user, toggler, onClose}: SettingsModalType) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" onSubmit={(e) => e.preventDefault()} action="/" sx={modalStyle}>
        <IconButton
          style={{
            position: 'absolute',
            right: '25px',
            top: '5%',
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Stack direction="column" spacing={4}>
          <Toggle
            on={!!user?.display_options?.user_name}
            title="Show username"
            onChange={toggler('user_name')}
          />
          <Toggle
            on={!!user?.display_options?.full_name}
            title="Show full name"
            onChange={toggler('full_name')}
          />
          <Toggle on={!!user?.display_options?.bio} title="Show bio" onChange={toggler('bio')} />
          <Toggle
            on={!!user?.display_options?.email_address}
            title="Show contact info"
            onChange={toggler('email_address')}
          />
          <Toggle
            on={!!user?.display_options?.tests}
            title="Show tests taken"
            onChange={toggler('tests')}
          />
          <Toggle
            on={!!user?.display_options?.showScores}
            title="Display scores publicaly"
            onChange={toggler('showScores')}
          />
          <Toggle
            on={!!user?.display_options?.join_date}
            title="Display date of sign up"
            onChange={toggler('join_date')}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

const UserActions = ({user, onChange}: {user: User; onChange: ChangeFunc}) => {
  const [showSettings, setShowSettings] = useState(false)

  const toggler = (field: string) => async (on: boolean) =>
    onChange('display_options', {...user.display_options, [field]: on})

  return (
    <Card sx={{minWidth: 300}}>
      <SettingsModel
        open={showSettings}
        user={user}
        toggler={toggler}
        onClose={() => setShowSettings(false)}
      />
      <CardContent>
        <Stack direction="column" spacing={10} justifyContent="space-around" alignItems="center">
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
            <SettingsIcon sx={{fontSize: 100}} />
            <Typography>Choose what info is public</Typography>
            <Button variant="contained" sx={equistampButton} onClick={() => setShowSettings(true)}>
              Settings
            </Button>
          </Stack>
          <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
            <AssignmentIcon sx={{fontSize: 100}} />
            <Typography>Take another test</Typography>
            <NavButton to={Path.evaluations.all()} label="Browse" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default UserActions
