import {ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Score from 'components/Score'
import {asMillionTokens, formatPrice, formatLatency} from 'components/formatters'
import {Model as ModelType} from '@equistamp/types'
import Path from 'routeLinks'
import {HelpTooltip} from 'components/filters/widgets'

type ModelItemProps = {
  label: string
  children: ReactNode
  help?: string
}
const ModelItem = ({label, children, help}: ModelItemProps) => (
  <Stack alignItems="center" justifyContent="space-around" sx={{pt: 1, pb: 1}}>
    <Typography align="center" variant="overline" sx={{color: 'primary.main'}}>
      {label}
      {help && <HelpTooltip help={help} />}
    </Typography>
    {children}
  </Stack>
)

const COSTS_HELP = `
These are the costs as reported by the model publisher, and may not be accurate. Check the
website of the publisher for specifics and the most up to date information.
`

const ModelCosts = ({
  cost_per_input_character_usd,
  cost_per_output_character_usd,
  cost_per_instance_hour_usd,
}: ModelType) => {
  const Price = ({label, val}: {label: string; val?: number}) =>
    val ? (
      <ModelItem label={label}>
        <Typography align="center">{formatPrice(val)}</Typography>
      </ModelItem>
    ) : null

  const prices = Object.entries({
    '1M input tokens': asMillionTokens(cost_per_input_character_usd),
    '1M output tokens': asMillionTokens(cost_per_output_character_usd),
    'instance hour': cost_per_instance_hour_usd,
  }).filter((p) => p[1])

  if (prices.length === 0) {
    return (
      <ModelItem label="Costs">
        <Typography>-</Typography>
      </ModelItem>
    )
  }

  return (
    <ModelItem label="Costs" help={COSTS_HELP}>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {prices.map(([label, val]) => (
          <Price key={label} label={label} val={val} />
        ))}
      </Stack>
    </ModelItem>
  )
}

type ModelProps = {
  model: ModelType
  sx?: {[key: string]: any}
}
const Model = ({model, sx}: ModelProps) => {
  return (
    <Card sx={sx}>
      <CardActionArea component={NavLink} to={Path.models.show(model.id)} sx={{height: '100%'}}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{p: 2}}>
          <Score score={model.score} maxScore={100} />
        </Box>
        <CardContent>
          <Typography align="center" gutterBottom variant="h5" component="div">
            {model.name}
          </Typography>
          <Typography align="center" gutterBottom component="div">
            <Typography component="span">Publisher: </Typography>
            <Typography component="span">{model.publisher}</Typography>
          </Typography>

          <Divider />
          <ModelItem label="Average Latency">
            <Typography align="center">
              {model.statistics?.avg_latency ? formatLatency(model.statistics?.avg_latency) : '-'}
            </Typography>
          </ModelItem>

          <Divider />
          <ModelCosts {...model} />
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default Model
