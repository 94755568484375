import {useState} from 'react'
import Box from '@mui/material/Box'
import {Headers} from 'components/dsl'
import type {ModelStageProps} from './types'
import HeadersInput from 'components/dsl/HeadersInput'

const EndpointHeaders = ({endpoint, onEndpointUpdate}: ModelStageProps) => {
  const [headers, setHeaders] = useState(endpoint?.headers)

  const updateHeaders = (headers: Headers) => {
    setHeaders(headers)
    onEndpointUpdate('headers', headers)
  }

  return (
    <Box>
      <HeadersInput headers={headers} onChange={updateHeaders} />
    </Box>
  )
}

export default EndpointHeaders
