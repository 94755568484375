import {useState} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import {Headers} from './types'
import {Input} from 'components/forms/inputs'
import {OptionsGroup} from 'components/filters/widgets'

type HeaderProps = {
  name: string
  value: string
  onChange: (k: string, v: string) => void
}
const Header = ({name, value, onChange}: HeaderProps) => (
  <Stack direction="row" spacing={4} alignItems="center">
    <Box sx={{width: '100%'}}>
      <Typography>Key:</Typography>
      <Input value={name} setter={(v: string) => onChange(v, value)} sx={{width: '100%'}} />
    </Box>
    <Box sx={{width: '100%'}}>
      <Typography>Value:</Typography>
      <Input value={value} setter={(v: string) => onChange(name, v)} sx={{width: '100%'}} />
    </Box>
  </Stack>
)

const makeHeaders = (headers?: Headers): Headers => {
  if (!headers || !headers.length) return [['', '']]
  const last = headers[headers.length - 1]
  return !last[0] && !last[1] ? headers : [...headers, ['', '']]
}

type HeadersInputProps = {
  headers?: Headers
  onChange: (h: Headers) => void
}
const HeadersInput = ({headers: initialHeaders, onChange}: HeadersInputProps) => {
  const [headers, setHeaders] = useState(() => makeHeaders(initialHeaders))

  const handleChange = (i: number) => (key: string, value: string) => {
    headers[i] = [key, value]
    const newHeaders = makeHeaders(headers)
    onChange(newHeaders)
    setHeaders(newHeaders)
  }

  return (
    <OptionsGroup label="HTTP Headers" sx={{width: '100%'}}>
      <Stack spacing={2}>
        {headers?.map(([k, v], i) => (
          <Header key={i} name={k} value={v} onChange={handleChange(i)} />
        ))}
      </Stack>
    </OptionsGroup>
  )
}

export default HeadersInput
