import {useState, ReactNode, MouseEvent} from 'react'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import MagnifyingGlass from 'components/icons/MagnifyingGlass'
import FilterAlt2 from 'components/icons/FilterAlt2'
import SortAlt from 'components/icons/SortAlt'
import {ActionButton, Input, NavButton, RoundedButton, SelectElem, WithIcon} from './inputs'
import {ChangeVal, formatFilter, makeSorter} from 'components/filters'
import type {ChangeKey, ExtraActionProps} from 'components/filters/types'
import {FilterConfig, ModelsFiltersType, SortOptions} from '@equistamp/types'

export type ChangeType = {
  search?: string
  start?: string
  end?: string
  sort?: string
}

type SearchBarProps = {
  searchLabel?: string
  searchOptions?: string[]
  initial?: FilterConfig
  sortOptions?: {[key: string]: string}
  sortLabel?: string
  onChange: ChangeVal
  onSort?: (options: SortOptions) => void
  onRemoveFilter: (field: keyof ModelsFiltersType, val: any) => void
  extraActions?: ExtraActionProps[]
  ignoredFilters?: ChangeKey[]
  children?: ReactNode
}

type SearchBarWidgetProps = {
  initialSearch?: string
  initialSort?: SortOptions
  searchLabel?: string
  sortOptions?: {[key: string]: string}
  sortLabel?: string
  extraActions?: ExtraActionProps[]
  onChange: ChangeVal
}

const SearchBarWidget = ({
  initialSearch,
  initialSort,
  searchLabel,
  sortOptions,
  sortLabel,
  onChange,
  extraActions,
}: SearchBarWidgetProps) => {
  const [search, setSearch] = useState<string | undefined>(initialSearch)

  const handleChange = (field: ChangeKey, updater?: (val: any) => void) => (val: any) => {
    updater && updater(val)
    onChange(field, val)
  }

  const filtersClick = (e: MouseEvent<HTMLDivElement>) => {
    e.target && (e.target as HTMLDivElement).blur()
    handleChange('showFilters')(true)
  }

  return (
    <Stack direction={{sm: 'column', md: 'row'}} spacing={3}>
      <Input
        label={searchLabel}
        value={search || ''}
        setter={handleChange('search', setSearch)}
        icon={<MagnifyingGlass />}
        sx={{width: '100%'}}
      />
      <Stack
        direction={{xs: 'column', sm: 'row'}}
        spacing={{xs: 2, sm: 3}}
        justifyContent="space-around"
        alignItems="center"
        sx={{height: '100%'}}
      >
        {sortOptions && (
          <SelectElem
            sx={{
              width: '100%',
            }}
            value={initialSort?.key ? initialSort.key + (initialSort?.direction || '') : ''}
            label={sortLabel || 'Ordered by'}
            values={sortOptions}
            icon={<SortAlt />}
            onChange={handleChange('sort')}
          />
        )}
        {/* Hacky way of making a pretend button that is rendered the same as the inputs */}
        <WithIcon icon={<FilterAlt2 />}>
          <Select
            displayEmpty
            IconComponent={() => null}
            value={''}
            renderValue={() => 'Filters'}
            open={false}
            onClick={filtersClick}
          />
        </WithIcon>
        {extraActions?.map(({to, action, sx, ...props}) => {
          const buttonProps = {
            key: props.label?.toString(),
            ...props,
            sx: {pt: 2, pb: 2, width: 140, height: 56, ...sx},
          }
          if (to) {
            return <NavButton to={to} {...buttonProps} />
          } else if (action) {
            return <ActionButton action={action} {...buttonProps} />
          }
          return <RoundedButton {...buttonProps} />
        })}
      </Stack>
    </Stack>
  )
}

type SelectedFiltersProps = {
  filters: ModelsFiltersType
  removeFilter: (field: keyof ModelsFiltersType, val: any) => void
  ignoredFilters?: ChangeKey[]
}
const SelectedFilters = ({filters, removeFilter, ignoredFilters}: SelectedFiltersProps) => {
  const makeVal = (field: string, val: any) => {
    const [label, value] = formatFilter(field, val)
    return (
      <Chip
        key={label + '_' + value}
        label={
          <Typography>
            {label}: {value}
          </Typography>
        }
        variant="outlined"
        onClick={() => removeFilter(field as keyof ModelsFiltersType, val)}
        onDelete={() => removeFilter(field as keyof ModelsFiltersType, val)}
      />
    )
  }

  const items = Object.entries(filters)
    .filter(([f]) => !ignoredFilters || !ignoredFilters.includes(f as ChangeKey))
    .reduce((acc, [field, val]) => {
      if (Array.isArray(val)) {
        return [...acc, ...val.map((v) => makeVal(field, v))]
      }
      return [...acc, makeVal(field, val)]
    }, [] as ReactNode[])

  return <Box>{items}</Box>
}

const SearchBar = ({
  searchLabel,
  initial,
  sortOptions,
  sortLabel,
  onChange,
  onSort,
  onRemoveFilter,
  extraActions,
  ignoredFilters = ['search'],
  children,
}: SearchBarProps) => {
  const [showFilters, setShowFilters] = useState(false)

  const handleBarChange = (field: ChangeKey, val: any) => {
    if (field === 'showFilters') {
      setShowFilters(!showFilters)
    } else if (field === 'sort' && onSort) {
      const [key, direction] = val.match(/(.*)(Asc|Desc)$/)?.slice(1, 3) || [null]
      if (key) {
        onSort({key, direction, sorter: makeSorter(key, direction)})
      } else {
        onSort({key: val, direction, sorter: (items: any[]) => items})
      }
    } else {
      onChange && onChange(field, val)
    }
  }

  return (
    <Stack direction="column" spacing={1}>
      <SearchBarWidget
        initialSearch={initial?.filters?.search}
        initialSort={initial?.sort}
        searchLabel={searchLabel}
        sortOptions={sortOptions}
        sortLabel={sortLabel}
        extraActions={extraActions}
        onChange={handleBarChange}
      />
      {showFilters && children}
      {initial?.filters && (
        <SelectedFilters
          filters={initial?.filters}
          removeFilter={onRemoveFilter}
          ignoredFilters={ignoredFilters}
        />
      )}
    </Stack>
  )
}

export default SearchBar
