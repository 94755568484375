import {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
  ReactElement,
  useRef,
} from 'react'
import {makeApi} from '@equistamp/api'
import type {ServerObject} from 'components/filters/types'
import {filterAlerts} from 'components/filters/Alerts'
import {filterModels} from 'components/filters/Models'
import {filterEvaluations} from 'components/filters/Evaluations'
import {filterRuns} from 'components/filters/EvaluationSessions'
import {filterResponses} from 'components/filters/Responses'
import {filterTags} from 'components/filters/Tags'
import type {
  Evaluation,
  Model,
  Eval as EvalSession,
  Response,
  Tag,
  Alert,
  FilterConfig,
  SearchResult,
} from '@equistamp/types'

export type APICall<T extends ServerObject> = (
  params: FilterConfig,
  transform?: (i: T) => T
) => Promise<SearchResult>
type useObjectsType<T extends ServerObject> = {
  getItems: APICall<T>
  refresh: (params: FilterConfig, force?: boolean) => void
  items: T[]
  loading: boolean
  removeItem: (i: T) => Promise<any>
}

export const deepEqual = (a: any, b: any): boolean => {
  if (typeof a !== typeof b) return false
  if (Array.isArray(a)) {
    return a.length === b.length && a.every((v, i) => deepEqual(v, b[i]))
  }
  if (typeof a === 'object') {
    return (
      deepEqual(Object.keys(a), Object.keys(b)) &&
      Object.entries(a).every(([k, v]) => deepEqual(b[k], v))
    )
  }
  return a === b
}

type Filterer<T extends ServerObject> = (items: T[], params: FilterConfig) => SearchResult
export const useItemsFuncs = <T extends ServerObject>(
  apiFetcher: APICall<T>,
  filterObjects: Filterer<T>,
  apiDeleter?: (i: T) => Promise<any>
): useObjectsType<T> => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<T[]>([])
  const [fetcher, setFetcher] = useState<null | Promise<T[]>>(null)
  const currentParams = useRef<FilterConfig>()

  // Prefetch the objects
  useEffect(() => {
    fetchItems()
  })

  const removeItem = async (item: T) => {
    if (apiDeleter) {
      await apiDeleter(item)
    }
    setItems((current) => current.filter((i) => i.id !== item.id))
  }

  const makeFetcher = useCallback(
    async (params: FilterConfig): Promise<T[]> => {
      currentParams.current = params
      try {
        setLoading(true)
        const {items} = await apiFetcher({...params, perPage: 'all'})
        setItems(items as T[])
        setLoading(false)
        return items as T[]
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
      return []
    },
    [apiFetcher, setLoading]
  )

  const fetchItems = useCallback(async () => {
    // If there are cached items, just return them. They may be a bit stale, but that's fine
    if (items.length > 0) {
      return items
    } else if (fetcher) {
      // The items are already being fetched - return the results of that
      return await fetcher
    } else {
      // There are no cached results and no pending requests, so fire one off
      const fetcher = makeFetcher({})
      setFetcher(fetcher)
      return await fetcher
    }
  }, [fetcher, items, makeFetcher])

  const getItems = useCallback(
    async (params: FilterConfig, transform?: (item: T) => T): Promise<SearchResult> => {
      const items = await fetchItems()
      const transformed = transform ? items.map((i) => transform(i as T)) : items
      return filterObjects(transformed, params)
    },
    [fetchItems, filterObjects]
  )

  const refresh = useCallback(
    (params: FilterConfig, force?: boolean) => {
      if (!force && deepEqual(params, currentParams.current)) return
      setItems([])
      setFetcher(makeFetcher(params))
    },
    [makeFetcher]
  )

  return {getItems, refresh, items, loading, removeItem}
}

type useCachedObjectsType = {
  alertsFuncs: useObjectsType<Alert>
  modelsFuncs: useObjectsType<Model>
  evalsFuncs: useObjectsType<Evaluation>
  runsFuncs: useObjectsType<EvalSession>
  responsesFuncs: useObjectsType<Response>
  tagsFuncs: useObjectsType<Tag>
}
const CachedObjectsContext = createContext<useCachedObjectsType | null>(null)

export const CachedObjectsProvider = ({children}: {children: ReactElement}) => {
  const api = makeApi()
  const alertsFuncs = useItemsFuncs<Alert>(api.alerts.list, filterAlerts, api.alerts.remove)
  const modelsFuncs = useItemsFuncs<Model>(api.models.list, filterModels)
  const evalsFuncs = useItemsFuncs<Evaluation>(api.evaluations.list, filterEvaluations)
  const runsFuncs = useItemsFuncs<EvalSession>(api.evaluationSessions.getRuns, filterRuns)
  const responsesFuncs = useItemsFuncs<Response>(
    api.evaluationSessions.getResponses,
    filterResponses
  )
  const tagsFuncs = useItemsFuncs<Tag>(api.tags.list, filterTags)

  return (
    <CachedObjectsContext.Provider
      value={{alertsFuncs, modelsFuncs, evalsFuncs, runsFuncs, responsesFuncs, tagsFuncs}}
    >
      {children}
    </CachedObjectsContext.Provider>
  )
}

export const useCachedObjects = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useCachedObjectsContext must be used within a CachedObjectsProvider')
  }
  return context
}

export const useAlerts = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useAlerts must be used within a CachedObjectsProvider')
  }
  return context.alertsFuncs
}

export const useModels = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useModels must be used within a CachedObjectsProvider')
  }
  return context.modelsFuncs
}

export const useEvaluations = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useEvaluations must be used within a CachedObjectsProvider')
  }
  return context.evalsFuncs
}

export const useEvaluationSessions = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useEvaluationSessions must be used within a CachedObjectsProvider')
  }
  return context.runsFuncs
}

export const useResponses = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useResponses must be used within a CachedObjectsProvider')
  }
  return context.responsesFuncs
}

export const useTags = () => {
  const context = useContext(CachedObjectsContext)
  if (!context) {
    throw new Error('useTags must be used within a CachedObjectsProvider')
  }
  return context.tagsFuncs
}

export default useCachedObjects
