import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Box from '@mui/material/Box'
import {Model} from '@equistamp/types'
interface TooltipCellProps {
  title: string
  children: React.ReactNode
}
const ModelMetaData = ({model}: {model?: Model}) => {
  const tooltips = {
    owner: 'The individual or organization that added this model to equistamp.',
    public: 'Indicates whether the model is publicly visible.',
    publicUsable:
      'Indicates whether the model can be used by the public to create evaluation reports.',
    maxCharactersPerMinute: 'The maximum number of characters the model can process per minute.',
    maxRequestsPerMinute: 'The maximum number of requests the model can handle per minute.',
    maxContextWindowCharacters:
      'The maximum number of characters that can be included in a single request.',
    medianLatency: 'The median time taken by the model to respond to a request.',
    medianThroughput: 'The median number of characters processed per second by the model.',
    medianVerbosity: "The median length of the model's responses.",
  }

  const TooltipCell: React.FC<TooltipCellProps> = ({title, children}) => (
    <TableCell>
      <Box display="flex" alignItems="center">
        {children}
        <Tooltip title={title} placement="right" arrow>
          <IconButton size="small" sx={{mr: 1}}>
            <HelpOutlineIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
    </TableCell>
  )

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TooltipCell title={tooltips.owner}>Model Owner</TooltipCell>
            <TableCell>{model?.owner?.full_name}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.public}>Public</TooltipCell>
            <TableCell>{model?.public ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.publicUsable}>Public Usable</TooltipCell>
            <TableCell>{model?.public_usable ? 'Yes' : 'No'}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.maxCharactersPerMinute}>
              Max Characters per Minute
            </TooltipCell>
            <TableCell>{model?.max_characters_per_minute}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.maxRequestsPerMinute}>Max Requests per Minute</TooltipCell>
            <TableCell>{model?.max_request_per_minute}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.maxContextWindowCharacters}>
              Max Context Window Characters
            </TooltipCell>
            <TableCell>{model?.max_context_window_characters}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.medianLatency}>Median Latency</TooltipCell>
            <TableCell>{model?.statistics?.median_latency}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.medianThroughput}>Median Throughput</TooltipCell>
            <TableCell>{model?.statistics?.median_throughput}</TableCell>
          </TableRow>
          <TableRow>
            <TooltipCell title={tooltips.medianVerbosity}>Median Verbosity</TooltipCell>
            <TableCell>{model?.statistics?.median_verbosity}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ModelMetaData
