import {useState} from 'react'
import {makeApi} from '@equistamp/api'
import type {Eval, Evaluation, Model} from '@equistamp/types'

const useRuns = () => {
  const [allRuns, setRuns] = useState({} as {[k: string]: Eval[]})
  const getRuns = async (evaluation: Evaluation, model: Model) => {
    const key = evaluation.id + ':' + model.id
    if (allRuns[key]) return allRuns[key]
    const {items} = await makeApi().evaluationSessions.list({
      filters: {
        fields: ['id', 'datetime_completed', 'score'],
        evaluations: [evaluation],
        models: [model],
      },
    })
    const sorted = (items as Eval[]).sort(
      (a, b) =>
        (b.datetime_completed && a.datetime_completed?.localeCompare(b.datetime_completed)) || -1
    )
    setRuns((current) => ({...current, [key]: sorted}))
    return sorted
  }

  return {allRuns, getRuns}
}

export default useRuns
