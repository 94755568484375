import {parseEndpointCode} from 'components/dsl'
import {EMAIL, PHONE_CALL, TEXT_MESSAGE, WEBHOOK} from '@equistamp/constants'
import {Subscription} from '@equistamp/types'

export const isInvalidInt = (val?: string) => !!isNaN(parseInt(val || '', 10)) && 'Number required'

export const isInvalidName = (name: string | undefined) => !name && 'Name required'

export const isInvalidEmail = (email: string | undefined) => {
  if (!email) {
    return 'Email required'
  } else if (!email.includes('@')) {
    return 'Invalid email'
  }
  return false
}

export const isInvalidPassword = (
  password: string | undefined,
  confirmPassword?: string | undefined
) => {
  if (!password) {
    return 'Password is required'
  } else if (confirmPassword && password !== confirmPassword) {
    return 'Passwords must match'
  }
  return false
}

export const isInvalidURL = (url: string | undefined) => {
  try {
    new URL(url || '')
    return false
  } catch (e) {
    return 'Invalid URL provided'
  }
}

export const isInvalidJSON = (data: string | undefined) => {
  try {
    data && JSON.parse(data)
    return false
  } catch (e) {
    return 'Invalid JSON'
  }
}

export const isEmptyJSON = (data: string | undefined) => {
  if (!data) return 'This field is required'
  try {
    const parsedData = JSON.parse(data)
    if (!parsedData || ['[]', '{}'].includes(data.replaceAll(/\s/g, '')))
      return 'Non empty JSON required'
    return false
  } catch (e) {
    return 'Invalid JSON'
  }
}

export const isInvalidKey = (val?: string) => {
  if (!val || val?.match(/^[\w\d+-_]*$/)) {
    return 'Keys must consist of English letters (upper and lowercase), digits and "-", "_", and "."'
  }
  return false
}

const isInvalidPhoneNumber = async (num?: string) => {
  const url = process.env.REACT_APP_TWILIO_VALIDATE_ENDPOINT
  if (!num) return 'No phone number provided'
  // If no twilio verification endpoint provided, then just treat the number as valid
  if (!url) return false

  const data = new URLSearchParams()
  data.append('phone', num)

  const res = await fetch(url, {method: 'POST', body: data})
  const {success, error} = await res.json()
  return !success && error
}

export const isInvalidSubscription = async ({
  method,
  destination,
}: Subscription): Promise<string | boolean> => {
  switch (method) {
    case EMAIL:
      return isInvalidEmail(destination)
    case WEBHOOK:
      return isInvalidURL(parseEndpointCode(destination).url)
    case PHONE_CALL:
    case TEXT_MESSAGE:
      return isInvalidPhoneNumber(destination)
    default:
      return false
  }
}
