import {NavLink} from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import useUser from 'hooks/useUser'
import Page from 'components/Page'
import RegisterForm from 'components/forms/RegisterForm'
import Path from 'routeLinks'

const Register: React.FC = () => {
  const {register} = useUser()

  return (
    <Page title="Sign up" showTitle>
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        <RegisterForm onSubmit={register} />
        <Typography>
          Already have an account? &nbsp;
          <NavLink to={Path.user.login()}>Login here</NavLink>
        </Typography>
      </Stack>
    </Page>
  )
}

export default Register
