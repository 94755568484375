import {useState, useEffect, useMemo, ReactNode, ReactElement} from 'react'
import {debounce} from 'lodash'
import CircularProgress from '@mui/material/CircularProgress'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SearchBar from 'components/forms/SearchBar'
import useUrlFilters from 'hooks/useUrlFilters'
import type {ItemRenderer, SearchOptions, ItemsContainerProps} from 'components/filters/types'
import {FilterConfig, FiltersTypes, SearchResult} from '@equistamp/types'

type SearcherProps = {
  config?: FilterConfig
  searchOptions?: SearchOptions
  getItems: (config: FilterConfig, transformer?: (item: any) => any) => Promise<SearchResult>
  renderItem: ItemRenderer
  AddItem?: ReactNode
  itemsContainer?: (props: ItemsContainerProps) => ReactElement
  noPagination?: boolean
  noUrlUpdate?: boolean
  onFilterChange?: (f?: FiltersTypes) => void
  loading?: boolean
}

export type SearchWidgetProps = {
  config?: FilterConfig
  searchbar?: boolean
  noAdd?: boolean
  noPagination?: boolean
  noUrlUpdate?: boolean
  searchOptions?: SearchOptions
}

export const Searcher = ({
  searchOptions,
  config: initialConfig,
  getItems,
  renderItem,
  AddItem,
  itemsContainer,
  noPagination,
  noUrlUpdate,
  onFilterChange,
  loading,
}: SearcherProps) => {
  const {config, updateConfig, removeFilter, updateFilters} = useUrlFilters({
    initialConfig,
    onFilterChange,
    noUrlUpdate,
  })
  const [fetching, setFetching] = useState(loading)
  const [items, setItems] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(100)
  const [itemsCount, setItemsCount] = useState(0)

  const ItemsContainer = (props: ItemsContainerProps) => {
    if (itemsContainer) return itemsContainer(props)
    return <>{props.children}</>
  }

  const fetchItems = useMemo(
    () =>
      debounce(async (config: FilterConfig) => {
        setFetching(true)
        const {items, count, page, per_page} = await getItems(config)
        setItems(items)
        setPage(page || 0)
        setPerPage(per_page || 100)
        setItemsCount(count)
        setFetching(false)
      }, 100),
    [getItems, setFetching]
  )

  useEffect(() => {
    fetchItems(config)
  }, [config, fetchItems])

  const pages = Math.ceil(itemsCount / perPage)
  const isLoading = fetching || loading

  return (
    <Stack direction="column" spacing={2}>
      {searchOptions && (
        <SearchBar
          initial={config}
          onChange={updateConfig}
          onSort={(sortOptions) => {
            if (items.length < perPage && page === 0 && sortOptions.sorter) {
              config.sort = sortOptions
              setItems([...sortOptions.sorter(items)])
            } else {
              updateConfig('sort', sortOptions)
            }
          }}
          onRemoveFilter={removeFilter}
          {...searchOptions}
        >
          {searchOptions.filtersElem && searchOptions.filtersElem({config, onChange: updateConfig})}
        </SearchBar>
      )}
      {isLoading && (
        <Stack direction="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      )}
      {!noPagination && items.length > 0 && itemsCount > perPage && (
        <Pagination
          count={pages}
          page={page + 1}
          shape="rounded"
          onChange={(e, page) => updateConfig('page', page - 1)}
        />
      )}
      <ItemsContainer
        filters={config.filters}
        onFilterChange={(f?: FiltersTypes) => updateConfig('filters', f)}
      >
        {(items.length > 0 || !isLoading) && AddItem}
        {items.length > 0 || isLoading ? (
          items.map((item) => renderItem(item, config, updateFilters))
        ) : (
          <Stack key="no-items" direction="row" justifyContent="center">
            <Typography>No items found</Typography>
          </Stack>
        )}
      </ItemsContainer>
      {!noPagination && items.length > 0 && itemsCount > perPage && (
        <Pagination
          count={pages}
          page={page + 1}
          shape="rounded"
          onChange={(e, page) => updateConfig('page', page - 1)}
        />
      )}
    </Stack>
  )
}

export default Searcher
