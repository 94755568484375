import React, {useState} from 'react'
import type {User} from '@equistamp/types'
import {Form} from 'components/forms'
import {isInvalidName, isInvalidEmail, isInvalidPassword} from './validators'
import {Input} from './inputs'

interface RegisterFormProps {
  onSubmit: (u: User) => Promise<string | null>
}

const RegisterForm: React.FC<RegisterFormProps> = ({onSubmit}) => {
  const [name, setName] = useState('')
  const [contact, setContact] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [invalid, setInvalid] = useState(false)

  const isInvalid = () =>
    !!(
      isInvalidName(name) ||
      isInvalidEmail(contact) ||
      isInvalidPassword(password, confirmPassword) ||
      isInvalidPassword(confirmPassword, password)
    )
  const submitter = async () =>
    onSubmit({
      user_name: name,
      email_address: contact,
      password,
      human: true,
      join_date: new Date().toISOString(),
    })

  return (
    <Form
      actionLabel="Sign up"
      isInvalid={isInvalid}
      setInvalid={setInvalid}
      submitter={submitter}
      rounded
    >
      <Input
        label="Name:"
        value={name}
        setter={setName}
        validate={invalid}
        validator={isInvalidName}
      />
      <Input
        label="Email:"
        value={contact}
        setter={setContact}
        validate={invalid}
        validator={isInvalidEmail}
      />
      <Input
        label="Password:"
        value={password}
        setter={setPassword}
        validate={invalid}
        validator={(p: string | undefined) => isInvalidPassword(p, confirmPassword)}
        type="password"
      />
      <Input
        label="Confirm password:"
        value={confirmPassword}
        setter={setConfirmPassword}
        validate={invalid}
        validator={(p: string | undefined) => isInvalidPassword(p, password)}
        type="password"
      />
    </Form>
  )
}

export default RegisterForm
