import React from 'react'
import {NavLink} from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsIcon from 'components/icons/Settings'
import PricingIcon from 'components/icons/Paper'
import QuestionMarkIcon from 'components/icons/QuestionMark'
import LogoutIcon from 'components/icons/LogOut'
import DiscordIcon from 'components/icons/Discord'
import useUser from 'hooks/useUser'
import {equistampButton} from 'components/text'
import Path from 'routeLinks'
import {IconType, MenuButton} from 'components/menu'
import LifeRing from 'components/icons/LifeRing'
import BookIcon from 'components/icons/Book'
import PersonAltIcon from 'components/icons/PersonAlt'

type HeaderProps = {
  title?: string
}
type ItemType = 'always' | 'loggedIn' | 'loggedOut' | 'help' | 'admin'

type HeaderLinkProps = {
  label: string
  to: () => string
  variant?: string
  external?: boolean
  Icon?: IconType
  type: ItemType
}
const HeaderLink = ({label, to, variant, external}: HeaderLinkProps) => (
  <Button
    component={external ? 'a' : NavLink}
    to={external ? undefined : to()}
    href={external ? to() : undefined}
    variant={(variant || 'text') as any}
    color={variant === 'contained' ? 'primary' : 'inherit'}
    sx={{
      ...equistampButton,
      fontWeight: 600,
      mb: 'inherit',
      minWidth: 'inherit',
      m: 0.3,
      ':hover': {
        backgroundColor: variant === 'contained' ? 'secondary.dark' : null,
      },
    }}
  >
    {label}
  </Button>
)

const LINKS = [
  {to: Path.compare, label: 'Compare', type: 'always'},
  {to: Path.evaluations.all, label: 'Evaluations', type: 'always'},
  {to: Path.models.all, label: 'Models', type: 'always'},
  {to: Path.alerts.all, label: 'Alerts', type: 'always'},
  {to: Path.about, label: 'About', type: 'help', Icon: QuestionMarkIcon},
  {to: Path.pricing, label: 'Pricing', type: 'help', Icon: PricingIcon},
  {to: Path.docs, label: 'Docs', type: 'help', external: true, Icon: BookIcon},
  {to: Path.discord, label: 'Join us on Discord', type: 'help', external: true, Icon: DiscordIcon},
  {to: Path.admin.index, label: 'Admin Panel', type: 'admin'},

  {to: Path.user.register, label: 'Sign up', type: 'loggedOut', variant: 'contained'},
  {
    to: () => Path.user.login(window.location.pathname + window.location.search),
    label: 'Log in',
    type: 'loggedOut',
    variant: 'outlined',
  },

  {to: Path.user.me, label: 'Account settings', type: 'loggedIn', Icon: SettingsIcon},
  {
    to: () => Path.user.logout(window.location.pathname + window.location.search),
    label: 'Log out',
    type: 'loggedIn',
    Icon: LogoutIcon,
  },
] as HeaderLinkProps[]

const items = (...itemTypes: (ItemType | '')[]) =>
  LINKS.filter((item) => itemTypes.includes(item.type)).map((i) => ({
    ...i,
    to: i.to(),
    variant: undefined,
  }))

const NormalLinks = ({loggedIn, isAdmin}: {loggedIn: boolean; isAdmin: boolean}) => {
  const {palette} = useTheme()
  return (
    <>
      {LINKS.filter((item) => item.type === 'always').map((item) => (
        <HeaderLink key={item.label} {...item} />
      ))}

      {isAdmin &&
        LINKS.filter((item) => item.type === 'admin').map((item) => (
          <HeaderLink key={item.label} {...item} />
        ))}

      <Box sx={{flexGrow: 1}} />
      <MenuButton
        side="right"
        Icon={LifeRing}
        label="Help"
        items={items('help')}
        sx={{minWidth: 'inherit', p: 1, mr: 1, fontWeight: 600}}
      />
      {loggedIn && (
        <MenuButton
          side="right"
          Icon={PersonAltIcon}
          items={items('loggedIn')}
          sx={{
            backgroundColor: 'primary.main',
            '& svg': {stroke: palette.primary.contrastText},
            '&:hover svg': {stroke: '#788492'},
          }}
        />
      )}
      {!loggedIn &&
        LINKS.filter((item) => item.type === 'loggedOut').map((item) => (
          <HeaderLink key={item.label} {...item} />
        ))}
    </>
  )
}

const MobileLinks = ({loggedIn, isAdmin}: {loggedIn: boolean; isAdmin: boolean}) => (
  <>
    <Box sx={{flexGrow: 1}} />
    <MenuButton
      side="right"
      Icon={MenuIcon}
      items={items('always', isAdmin ? 'admin' : '', 'help', loggedIn ? 'loggedIn' : 'loggedOut')}
      sx={{minWidth: 'inherit', p: 1, mr: 1, fontWeight: 600}}
    />
  </>
)

function HideOnScroll({children}: {children: React.ReactElement}) {
  const trigger = useScrollTrigger({})
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const Header = ({title}: HeaderProps) => {
  const {loggedIn, isAdmin} = useUser()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (title) {
    document.title = title
  }

  return (
    <HideOnScroll>
      <AppBar component="nav" elevation={0} color="inherit" enableColorOnDark position="relative">
        <Toolbar>
          <Box
            component={NavLink}
            to={Path.home()}
            alignItems="center"
            display="flex"
            sx={{flexGrow: 0, mr: 15}}
          >
            <img src="/logoBlue.png" alt="EquiStamp" width="224px" height="40px" />
          </Box>

          {isMobile ? (
            <MobileLinks loggedIn={loggedIn} isAdmin={isAdmin} />
          ) : (
            <NormalLinks loggedIn={loggedIn} isAdmin={isAdmin} />
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

export default Header
