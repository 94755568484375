import type {FiltersProps} from './types'
import {taskTypes} from './constants'
import {filterChangeHandlers} from './transformers'
import {FiltersContainer, CheckboxGroup} from './widgets'

const SchemaFilters = ({config, onChange}: FiltersProps) => {
  const {handleChange} = filterChangeHandlers({
    config,
    onChange,
  })

  return (
    <FiltersContainer>
      <CheckboxGroup
        label="Task type"
        allOptions={taskTypes}
        selected={config?.filters?.taskType}
        onChange={handleChange('taskType')}
      />
    </FiltersContainer>
  )
}

export default SchemaFilters
