import {useState} from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import {makeApi, ServerError} from '@equistamp/api'
import AddItem from 'components/search/AddItem'
import {schemaTypes} from 'components/filters/constants'
import {Input, SelectElem} from 'components/forms/inputs'
import {parseJSON} from 'components/formatters'
import {isInvalidJSON, isInvalidKey} from 'components/forms/validators'
import {CREATE_SCHEMA} from 'permissions'
import {JSON_QUESTION} from '@equistamp/constants'
import type {Evaluation, SchemaHistory} from '@equistamp/types'

type AddSchemaProps = {
  shown?: boolean
  evaluation: Evaluation
  onClose?: () => void
  onAdded?: (schema: SchemaHistory) => void
}
const AddSchema = ({evaluation, onClose, onAdded}: AddSchemaProps) => {
  const [shown, setShown] = useState(false)
  const [error, setError] = useState('')
  const [schemaString, setSchemaString] = useState('')
  const [schema, setSchema] = useState({
    evaluation_id: evaluation.id,
    type: JSON_QUESTION,
  } as SchemaHistory)

  const updateSchema = (field: keyof SchemaHistory, val: any) => {
    if (error) {
      setError('')
    }
    setSchema((s) => ({...s, [field]: val}))
  }

  const isInvalid = !!(isInvalidKey(schema.key) || isInvalidJSON(schemaString))

  const addSchema = async () => {
    try {
      const newSchema = await makeApi().schemas.create({...schema, schema: parseJSON(schemaString)})
      setShown(false)
      onAdded && onAdded(newSchema as SchemaHistory)
      onClose && onClose()
    } catch (e) {
      setError(e instanceof ServerError ? e.error.toString() : `${e}`)
    }
  }

  const close = () => {
    setShown(false)
    onClose && onClose()
  }

  return (
    <Stack>
      <AddItem label="Add schema" action={() => setShown(true)} permission={CREATE_SCHEMA} />
      <Dialog open={shown || false} onClose={close} aria-labelledby="Create Schema">
        <DialogTitle id="alert-dialog-title">Create Schema</DialogTitle>
        <DialogContent>
          <Stack spacing={2} justifyContent="space-around" sx={{p: 3, minWidth: 500}}>
            <SelectElem
              label="type"
              defaultValue={schema.type}
              onChange={(v: any) => updateSchema('type', v)}
              values={schemaTypes}
            />
            <Input
              label="Key"
              setter={(v: string) => updateSchema('key', v)}
              value={schema.key || ''}
              validate={true}
              validator={isInvalidKey}
            />
            <Input label="Name" setter={(v: string) => updateSchema('name', v)} />
            <Input label="Description" setter={(v: string) => updateSchema('description', v)} />
            <Input
              label="Schema"
              value={schemaString}
              validate={true}
              validator={isInvalidJSON}
              setter={setSchemaString}
              multiline
            />
            {error && <Alert severity="error">{error}</Alert>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button disabled={isInvalid} onClick={addSchema}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default AddSchema
