import React from 'react'
import Stack from '@mui/material/Stack'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import useUser from 'hooks/useUser'
import Page from 'components/Page'
import UserProfile from 'components/profile'
import {RelatedAlerts} from 'components/search'
import type {User} from '@equistamp/types'

const ShowProfile = ({item: user, setItem: setUser}: RendererProps<User>) => {
  const {isCurrentUser, setUserField} = useUser()

  const updateVal = async (field: string, val: any) => {
    const updated = await setUserField(field, val)
    setUser(updated)
    return updated
  }
  return (
    <Page title="User profile" showTitle>
      <Stack direction="column" spacing={8} justifyContent="space-between" sx={{mt: 5, mb: 5}}>
        <UserProfile user={user} isCurrentUser={isCurrentUser(user)} onChange={updateVal} />

        {isCurrentUser(user) && (
          <>
            <RelatedAlerts title="Your alerts" config={{filters: {owner_id: user.id}}} />
            <RelatedAlerts
              title="Subscribed alerts"
              config={{filters: {subscriber: [user.email_address || '']}}}
            />
          </>
        )}
      </Stack>
    </Page>
  )
}

const Profile: React.FC = () => <ItemLoader itemName="user profile" renderer={ShowProfile} />
export default Profile
