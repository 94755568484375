import {paginate, searchText, allOf, filterBetween} from './filters'
import {filterChangeHandlers, handleRangeChange, sortItems} from './transformers'
import {FiltersContainer, RangeSlider} from './widgets'
import type {FiltersProps} from './types'
import type {Response, FilterConfig, SearchResult} from '@equistamp/types'

export const filterResponses = (responses: Response[], params: FilterConfig): SearchResult => {
  let filtered = searchText(responses, params.filters?.search || '', [
    'raw_task_text',
    'parsed_response_text',
  ]).filter(
    allOf([
      filterBetween(
        params?.filters?.minResponseTime,
        params?.filters?.maxResponseTime,
        'response_time_in_seconds'
      ),
    ])
  )
  filtered = sortItems(filtered, params)
  return paginate(
    filtered,
    params.page || 0,
    !params.perPage || params.perPage === 'all' ? 20 : params.perPage
  )
}

const ResponseFilters = ({config, onChange}: FiltersProps) => {
  const {updateField} = filterChangeHandlers({
    config,
    onChange,
  })

  return (
    <FiltersContainer>
      <RangeSlider
        label="Response time in seconds"
        start={config?.filters?.minResponseTime || 0}
        end={config?.filters?.maxResponseTime || 60 * 15}
        max={900}
        onChange={handleRangeChange(config?.filters, 'ResponseTime', updateField)}
      />
    </FiltersContainer>
  )
}

export default ResponseFilters
