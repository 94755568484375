import Typography from '@mui/material/Typography'
import {ExternalA} from 'components/text'
import {
  MODEL_CHANGE,
  NEW_MODEL,
  TOP_MODEL,
  FREE_RESPONSE_QUESTION,
  MULTIPLE_CHOICE_QUESTION,
  BOOLEAN_QUESTION,
  JSON_QUESTION,
  EMAIL,
  WEBHOOK,
  TEXT_MESSAGE,
  EVAL_SCORE_THRESHOLD,
  SCORE,
  ELO_SCORE,
  MOST_EXPENSIVE,
  LEAST_EXPENSIVE,
  LATENCY,
  ACCURACY,
  MAX,
  MIN,
  NEW_EVALUATION,
  MODEL_AVAILABILITY,
  MODEL_STABILITY,
  MODEL_RANK_CHANGE,
  AVAILABLE,
  UNAVAILABLE,
  ON_UNAVAILABLE,
  CHANGE,
} from '@equistamp/constants'

export const modalities = {
  text: 'Text',
}
export const taskTypes = {
  [FREE_RESPONSE_QUESTION]: 'Free response',
  [MULTIPLE_CHOICE_QUESTION]: 'Multiple choice',
  [BOOLEAN_QUESTION]: 'Boolean',
  [JSON_QUESTION]: 'JSON',
}
export const schemaTypes = {
  [JSON_QUESTION]: 'JSON',
}
export const alertCooldowns = {
  once: 'Only once',
  daily: 'Once per day',
  always: 'Every time',
}
export const architectures = {
  llm: 'LLM',
  nn: 'Neural network',
  Transformer: 'Transformer',
}
export const allTags = {
  truthful: 'truthful',
  helpful: 'helpful',
  harmful: 'harmful',
  alignment: 'alignment',
  deceitful: 'deceitful',
}

export const viewOptions = {
  correct: 'Correct answers',
  incorrect: 'Incorrect answers',
  redacted: 'Redacted',
}

export const knownPublishers = [
  {id: '01.AI', label: '01.AI'},
  {id: 'AI21 Labs', label: 'AI21 Labs'},
  {id: 'Amazon', label: 'Amazon'},
  {id: 'Anthropic', label: 'Anthropic'},
  {id: 'Cohere', label: 'Cohere'},
  {id: 'Deepmind', label: 'Deepmind'},
  {id: 'EleutherAI', label: 'EleutherAI'},
  {id: 'Google AI', label: 'Google AI'},
  {id: 'Google', label: 'Google'},
  {id: 'Microsoft', label: 'Microsoft'},
  {id: 'Mistral AI', label: 'Mistral AI'},
  {id: 'OpenAI', label: 'OpenAI'},
  {id: 'Qwen', label: 'Qwen'},
  {id: 'Together', label: 'Together'},
]
export const runCadences = ['once', 'daily', 'weekly', 'every 2 weeks', 'monthly', 'quarterly']

export const alertTriggerTypes = {
  [TOP_MODEL]: 'New Top Model',
  [MODEL_RANK_CHANGE]: 'Model Rank Change',
  [MODEL_AVAILABILITY]: 'Model Availability',
  [MODEL_STABILITY]: 'Model Stability',
  [NEW_MODEL]: 'New Model Release',
  [NEW_EVALUATION]: 'New Evaluation Added',
  [MODEL_CHANGE]: 'Existing Model Change',
  [EVAL_SCORE_THRESHOLD]: 'Evaluation Score Threshold',
}

export const alertTriggerThresholdMetrics = {
  [MIN]: {
    title: 'Minimum',
    doc: 'Minimum - trigger whenever the score goes below the threshold',
  },
  [MAX]: {
    title: 'Maximum',
    doc: 'Maximum - trigger whenever the score goes above the threshold',
  },
}

export const alertTriggerAvailabilityMetrics = {
  [AVAILABLE]: {
    title: 'Becomes available',
    doc: 'Becomes available - trigger whenever the model is detected as being available again. This will only be triggered if the previous check found the model unavailable.',
  },
  [ON_UNAVAILABLE]: {
    title: 'Becomes unavailable',
    doc: 'Becomes unavailable - trigger whenever we start getting errors from the model. This will only fire if the previous model check was successful.',
  },
  [UNAVAILABLE]: {
    title: 'Is unavailable',
    doc: 'Is unavailable - trigger as long as the model returns errors. We will keep sending you alerts until the model stops returning errors.',
  },
  [CHANGE]: {
    title: 'Any change',
    doc: "Any change - trigger whenever the model's availability changes. So whenever the model stops working, or whenever it starts working again.",
  },
}

export const alertTriggerStabilityMetrics = {
  [MIN]: {
    title: 'Below',
    doc: 'Below - trigger whenever the availability drops below the threshold',
  },
  [MAX]: {
    title: 'Above',
    doc: 'Above - trigger whenever the availability goes above the threshold',
  },
}

export const alertTriggerTopMetrics = {
  [SCORE]: {
    title: 'Equistamp Score',
    doc: (
      <Typography>
        Equistamp Score is our summary of how well a model does compared to others, on a set of high
        quality evaluations. Check the{' '}
        <ExternalA to="/docs/models/#score" text="models documentation" />
        for details.
      </Typography>
    ),
  },
  [ELO_SCORE]: {
    title: 'Elo Score',
    doc: (
      <Typography>
        Elo represents an overall capability score derived from the LMSYS{' '}
        <ExternalA to="https://chat.lmsys.org/" text="Chatbot arena." />.
      </Typography>
    ),
  },
  [MOST_EXPENSIVE]: {
    title: 'Most Expensive',
    doc: 'The most expensive model, as an aggregate of input / output tokens price and price per instance hour',
  },
  [LEAST_EXPENSIVE]: {
    title: 'Least Expensive',
    doc: 'The cheapest model, as an aggregate of input / output tokens price and price per instance hour. This ignores models that are free',
  },
  [LATENCY]: {
    title: 'Fastest Responding',
    doc: 'The fastest model, in tokens per second. For each model response, we calculate how long it took per token by dividing the time it took to fully respond, by the number of tokens it returned. We then use the median of this measure as overall latency',
  },
  [ACCURACY]: {
    title: 'Evaluation Accuracy',
    doc: "The best overall score of any model on any of the selected evaluations. Some evaluations are easier than other, so it's worth filtering out only those evaluations which interest you. Use the Equistamp Score metric for a more general measure of how good a model is.",
  },
}

export const alertTriggerModelChanges = {
  name: {doc: 'The name of the model', title: 'Name'},
  description: {doc: 'The description of the model', title: 'Description'},
  publisher: {
    doc: "The publisher of the model - this should not change often, as the main reason is the publisher changing it's name",
    title: 'Publisher',
  },
  public: {
    title: 'Is visible?',
    doc: 'Whether this model can be publicly viewed - models for which this is disabled can only be viewed by owners and administrators. Anyone else will get an error.',
  },
  public_usable: {
    title: 'Can be evaluated?',
    doc: "Whether anyone can run evaluations on this model. When disabled, only owners and administrators can run evaluations. This doesn't change whether the model can be viewed - that is a separate setting",
  },
  cost_per_input_character_usd: {
    title: 'Input tokens price',
    doc: 'The price for input tokens. AI model providers often charge users directly for how much they use the model. Seeing as most models accept an input (text, images, whatever) and then send something back, they often charge different prices for input and output.',
  },
  cost_per_output_character_usd: {
    title: 'Output tokens price',
    doc: "The price for output tokens. LLMs tend to give output of different sizes - sometimes they'll just reply with a single word, sometimes with large blocks of text. This makes it hard to properly estimate how much a given LLM call will cost",
  },
  cost_per_instance_hour_usd: {
    title: 'Cost per instance hour',
    doc: "The price of running a model for an hour. Some AI model providers set up the infrastructure needed to run a model, then just give you access and charge you for how long the model runs. It's then up to you to turn of the model after you've finished with it.",
  },
  max_request_per_minute: {
    title: 'Requests per minute',
    doc: "The maximum number of requests that Equistamp can send to a model in a given minute. Various AI model providers have various rate limiting methods, which often depend on who's calling them. This makes it hard to specify the expected rate limit for any given user. This field is mainly of use when running Eqiustamp evaluations, as it lets you estimate the lower bound on how long a given evaluation will run.",
  },
  max_characters_per_minute: {
    title: 'Tokens per minute',
    doc: "The maximum number of tokens that Equistamp can send to a model in a given minute. When running evaluations, we'll never send more tokens per minute than specified in the model configuration. This is mainly of interest when working out the lower bound of how long an evaluation will run",
  },
  max_context_window_characters: {
    title: 'Context window size',
    doc: 'The context window size, i.e. the maximum number of tokens that can be sent to an AI model in a single request',
  },
}

export const notificationTypes = {
  [EMAIL]: 'Email',
  [WEBHOOK]: 'Webhook',
  [TEXT_MESSAGE]: 'SMS message',
  // [PHONE_CALL]: 'Phone call',
}
