import React, {useEffect, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import useModels from 'hooks/useModels'
import useUser from 'hooks/useUser'
import Page from 'components/Page'
import EvalRunsWidget from 'components/EvalRunsWidget'
import Summary from 'components/evaluations/Summary'
import Model from 'components/models/Model'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import CallToAction from 'components/CallToAction'
import {ModelFilters} from 'components/filters'
import {NavButton} from 'components/forms/inputs'
import {ColouredDivider, ColouredTypography} from 'components/text'
import type {Eval, Evaluation as EvaluationType, Model as ModelType, User} from '@equistamp/types'
import Path from 'routeLinks'
import ManageUsers from 'components/ManageUsers'

const EvaluationRuns = ({evaluation}: {evaluation: EvaluationType}) => (
  <Container>
    <Stack spacing={4} direction="column">
      <EvalRunsWidget
        evals={[evaluation]}
        itemType="model"
        searchOptions={{
          searchLabel: 'filter models',
          sortLabel: 'Displayed models:',
          sortOptions: {
            scoreDesc: 'With top average score',
            scoreAsc: 'With bottom average score',
          },
          filtersElem: ModelFilters,
        }}
      />
    </Stack>
  </Container>
)

const BestModels = ({id, name}: EvaluationType) => {
  const [best, setBest] = useState<{[k: string]: ModelType}>()
  const {models} = useModels()

  useEffect(() => {
    const getEvalSessions = async () => {
      const {items} = await makeApi().evaluationSessions.getRunsForEvaluation(id)
      const scores = (items as Eval[]).reduce(
        (acc, {evaluatee_id, score}) => ({...acc, [evaluatee_id]: score}),
        {}
      )
      const decent = models
        .map((m) => ({...m, score: scores[m.id as keyof typeof scores]}))
        .filter(({score}) => score && score > 70)
      setBest({
        'Highest Score': decent.sort((a, b) => (b.score || 0) - (a.score || 0))[0],
        'Most Affordable': decent.sort((a, b) => (a.token_cost || 0) - (b.token_cost || 0))[0],
        'Fastest Response Time': decent.sort(
          (a, b) => (a.statistics?.median_latency || 0) - (b.statistics?.median_latency || 0)
        )[0],
      })
    }
    if (models.length > 0 && !best) {
      getEvalSessions()
    }
  }, [models, best, id, name])

  const selectedModels = best && Object.entries(best).filter(([_, model]) => model)
  return (
    <Container>
      <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={3}>
        <ColouredTypography sx={{fontWeight: 700}} variant="h4">
          Top Performing Models on this Evaluation
        </ColouredTypography>
        <ColouredDivider sx={{width: '100%'}} />
        <Stack spacing={8} direction={{sm: 'column', md: 'row'}} sx={{pb: 5}}>
          {!best && <CircularProgress />}
          {selectedModels && selectedModels.length === 0 && (
            <CallToAction
              title="No results found"
              actionLabel="Run models"
              action={Path.evaluations.connections(id)}
            >
              <Typography>
                No models have been checked with this evaluation - now would be a good time to
                change that!
              </Typography>
            </CallToAction>
          )}
          {selectedModels?.map(([title, model]) => (
            <Stack key={title} direction="column" alignItems="center" spacing={2}>
              <ColouredTypography variant="h5">{title}</ColouredTypography>
              <Model model={model} sx={{height: '100%', width: 300, borderRadius: 3}} />
            </Stack>
          ))}
        </Stack>
        {(!selectedModels || selectedModels.length > 0) && (
          <NavButton label="Search more models" to={Path.models.all()} />
        )}
      </Stack>
    </Container>
  )
}

const EvaluationSummary = ({item: evaluation, setItem}: RendererProps<EvaluationType>) => {
  const {canEdit} = useUser()

  const saveEvaluation = async (e: EvaluationType) => {
    const res = await makeApi().evaluations.update(e)
    if (res !== 'Evaluation updated') {
      return res
    }
    setItem({...evaluation, ...e})
    return null
  }
  const addUsers = async (users: string[]) => {
    try {
      await makeApi().evaluations.update({
        id: evaluation.id,
        name: evaluation.name,
        add_admins: users,
      })
    } catch (e) {
      return `${e}`
    }
  }
  const removeUser = async (user: User) => {
    if (!user.email_address) return
    try {
      await makeApi().evaluations.update({
        id: evaluation.id,
        name: evaluation.name,
        delete_admins: [user.email_address],
      })
    } catch (e) {
      return `${e}`
    }
  }

  return (
    <Page
      title={evaluation?.name}
      fullWidth
      showTitle
      breadcrumbs={[{title: 'evaluations', link: Path.evaluations.all()}, {title: evaluation.name}]}
    >
      <Stack spacing={20} sx={{mt: 5, mb: 5}} alignItems="center">
        <Summary
          evaluation={evaluation}
          updateEvaluation={canEdit(evaluation) ? saveEvaluation : undefined}
        />
        <BestModels {...evaluation} />
        <EvaluationRuns evaluation={evaluation} />
        {evaluation.admin_users && (
          <Container>
            <ManageUsers
              users={evaluation.admin_users}
              addUsers={addUsers}
              removeUser={removeUser}
            />
          </Container>
        )}
      </Stack>
    </Page>
  )
}

const Evaluation: React.FC = () => <ItemLoader itemName="evaluation" renderer={EvaluationSummary} />

export default Evaluation
