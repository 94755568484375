import {ReactNode} from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import Alert from 'components/alerts'
import Model from 'components/models/Model'
import Evaluation from 'components/evaluations/Evaluation'
import Response from 'components/Response'
import EvalSessions from 'components/evaluationSessions/EvaluationSessions'
import Task from 'components/tasks/Task'
import AddTask from 'components/tasks/AddTask'
import AddSchema from 'components/schemas/AddSchema'
import {NavButton} from 'components/forms/inputs'
import {
  ModelFilters,
  AlertFilters,
  EvaluationFilters,
  EvaluationSessionsFilters,
  ResponseFilters,
  SchemaFilters,
  TaskFilters,
} from 'components/filters'
import Searcher, {SearchWidgetProps} from './Searcher'
import Path from 'routeLinks'

import useAlerts from 'hooks/useAlerts'
import useEvaluations from 'hooks/useEvaluations'
import useEvaluationSessions from 'hooks/useEvaluationSessions'
import useModels from 'hooks/useModels'
import useResponses from 'hooks/useResponses'
import type {
  Alert as AlertType,
  Evaluation as EvaluationType,
  Eval as EvalType,
  Model as ModelType,
  Task as TaskType,
  SchemaHistory as SchemaType,
  ItemType,
  FilterConfig,
} from '@equistamp/types'
import Schema from 'components/schemas/Schema'
import SearchContainer from 'components/alerts/SearchContainer'
import {ChangeKey, FiltersUpdater} from 'components/filters/types'

export const Tasks = ({
  evaluation,
  config,
  searchbar,
  searchOptions,
  noAdd,
  noPagination,
}: SearchWidgetProps & {evaluation: EvaluationType}) => {
  const {refresh} = useEvaluations()

  const defaultSearchOptions = {
    searchLabel: 'Search tasks',
    sortLabel: 'Sort tasks by:',
    sortOptions: {
      questionDesc: 'question (desc)',
      questionAsc: 'question (asc)',
      liveDesc: 'live (desc)',
      liveAsc: 'live (asc)',
    },
    filtersElem: TaskFilters,
  }

  const props = {
    config,
    noPagination,
    getItems: async (config: FilterConfig) => makeApi().tasks.getTasks(evaluation.id, config),
    renderItem: (t: TaskType) => <Task key={t.id} task={t} />,
    AddItem: noAdd ? undefined : <AddTask evaluation={evaluation} onAdded={() => refresh({})} />,
    searchOptions: searchbar ? {...defaultSearchOptions, ...searchOptions} : undefined,
  }
  return <Searcher {...props} />
}

export const Schemas = ({
  evaluation,
  config,
  searchbar,
  searchOptions,
  noAdd,
  noPagination,
}: SearchWidgetProps & {evaluation: EvaluationType}) => {
  const {refresh} = useEvaluations()

  const defaultSearchOptions = {
    searchLabel: 'Search schemas',
    sortLabel: 'Sort schemas by:',
    sortOptions: {
      nameDesc: 'name (desc)',
      nameAsc: 'name (asc)',
    },
    filtersElem: SchemaFilters,
  }

  const props = {
    config,
    noPagination,
    getItems: async (config: FilterConfig) =>
      makeApi().schemas.forEvaluation(evaluation.id, config),
    renderItem: (s: SchemaType) => <Schema schema={s} />,
    AddItem: noAdd ? undefined : <AddSchema evaluation={evaluation} onAdded={() => refresh({})} />,
    searchOptions: searchbar ? {...defaultSearchOptions, ...searchOptions} : undefined,
  }
  return <Searcher {...props} />
}

export const Alerts = ({
  config,
  searchbar,
  searchOptions,
  noAdd,
  noPagination,
  noUrlUpdate,
}: SearchWidgetProps) => {
  const defaultSearchOptions = {
    searchLabel: 'Search alerts',
    sortLabel: 'Sort alerts by:',
    sortOptions: {
      nameDesc: 'name (desc)',
      nameAsc: 'name (asc)',
    },
    filtersElem: AlertFilters,
    ignoredFilters: ['search', 'triggerType'] as ChangeKey[],
    extraActions: noAdd
      ? []
      : [
          {
            to: Path.alerts.create(),
            label: 'Add alert',
            sx: {width: 'unset'},
          },
        ],
  }

  const {getItems, loading, removeItem} = useAlerts()
  const props = {
    noPagination,
    noUrlUpdate,
    getItems,
    loading,
    config: {...config, perPage: config?.perPage || 20},
    renderItem: (a: AlertType) => <Alert key={a.id} alert={a} onDelete={removeItem} />,
    searchOptions: searchbar ? {...defaultSearchOptions, ...searchOptions} : undefined,
    itemsContainer: SearchContainer,
  }
  return <Searcher {...props} />
}

export const defaultEvaluationSearchOptions = {
  searchLabel: 'Search evaluations',
  sortLabel: 'Sort evaluations:',
  sortOptions: {
    nameAsc: 'By name (asc)',
    nameDesc: 'By name (desc)',
  },
  filtersElem: EvaluationFilters,
}
export const Evaluations = ({
  config,
  searchbar,
  searchOptions,
  noPagination,
}: SearchWidgetProps) => {
  const {loading, getItems} = useEvaluations()
  const props = {
    noPagination,
    getItems,
    loading,
    config: {...config, perPage: config?.perPage || 10},
    renderItem: (e: EvaluationType, config?: FilterConfig, updateFilters?: FiltersUpdater) => (
      <Evaluation key={e.id} evaluation={e} updateFilters={updateFilters as FiltersUpdater} />
    ),
    searchOptions: !searchbar
      ? undefined
      : {
          ...defaultEvaluationSearchOptions,
          ...searchOptions,
          extraActions: [
            {
              to: Path.evaluations.create(),
              label: 'Add evaluation',
              sx: {width: 'unset'},
            },
          ],
        },
  }
  return <Searcher {...props} />
}

const useEvaluationEvalSessions = () => {
  const {evaluations} = useEvaluations()
  const {getGroupedItems} = useEvaluationSessions()

  const sortOptions = {
    evaluation_nameAsc: 'By name (asc)',
    evaluation_nameDesc: 'By name (desc)',
  } as any as {[k: string]: string}

  const sortLabel = 'Sort evaluations:'

  const prices = evaluations.reduce((acc, {id, price}) => ({...acc, [id]: price}), {}) as {
    [k: string]: number
  }
  const getter = getGroupedItems('model')
  const getItems = async (params: FilterConfig) =>
    getter(params, (e: EvalType) => ({...e, price: prices[e.evaluation_id]}))

  return {sortLabel, sortOptions, getItems}
}

const useModelEvalSessions = () => {
  const {models} = useModels()
  const {getGroupedItems} = useEvaluationSessions()

  const sortOptions = {
    evaluatee_nameAsc: 'By name (asc)',
    evaluatee_nameDesc: 'By name (desc)',
  } as any as {[k: string]: string}

  const sortLabel = 'Sort models:'

  const prices = models.reduce((acc, {id, price}) => ({...acc, [id]: price}), {}) as {
    [k: string]: number
  }
  const getter = getGroupedItems('evaluation')
  const getItems = async (params: FilterConfig) =>
    getter(params, (e: EvalType) => ({...e, price: prices[e.evaluatee_id]}))

  return {sortLabel, sortOptions, getItems}
}

export const EvaluationSessions = ({
  config,
  searchbar,
  searchOptions,
  noPagination,
  item_type,
}: SearchWidgetProps & {item_type: ItemType}) => {
  const {sortLabel, sortOptions, getItems} = (
    item_type === 'model' ? useEvaluationEvalSessions : useModelEvalSessions
  )()
  const defaultSearchOptions = {
    sortLabel,
    sortOptions,
    searchLabel: 'filter runs',
    filtersElem: EvaluationSessionsFilters,
  }

  type GroupedEvals = {name: string; runs: EvalType[]}
  const props = {
    noPagination,
    getItems,
    config: {...config, perPage: config?.perPage || 20},
    renderItem: ({name, runs}: GroupedEvals) => (
      <EvalSessions key={name} name={name} runs={runs} item_type={item_type} />
    ),
    searchOptions: searchbar ? {...defaultSearchOptions, ...searchOptions} : undefined,
  }
  return <Searcher {...props} />
}

export const defaultModelSearchOptions = {
  searchLabel: 'Search models',
  sortLabel: 'Sort by:',
  sortOptions: {
    scoreDesc: 'Top average score',
    scoreAsc: 'Bottom average score',
    nameAsc: 'Model name (asc)',
    nameDesc: 'Model name (desc)',
    tokens_costDesc: 'Most expensive',
    tokens_costAsc: 'Cheapest',
    publisherAsc: 'Publisher (asc)',
    publisherDesc: 'Publisher (desc)',
  },
  filtersElem: ModelFilters,
}
export const Models = ({config, searchbar, searchOptions, noPagination}: SearchWidgetProps) => {
  const {loading, getItems} = useModels()
  const props = {
    noPagination,
    getItems,
    loading,
    config: {...config, perPage: config?.perPage || 20},
    renderItem: (model: ModelType) => (
      <Grid key={model.name} item xs={12} md={4} sm={6} lg={3}>
        <Model model={model} sx={{height: '100%', borderRadius: 3}} />
      </Grid>
    ),
    searchOptions: !searchbar
      ? undefined
      : {
          ...defaultModelSearchOptions,
          ...searchOptions,
          extraActions: [
            {
              to: Path.models.create(),
              label: 'Add model',
              sx: {width: 'unset'},
            },
          ],
        },
    itemsContainer: ({children}: {children: ReactNode}) => (
      <Grid container spacing={2}>
        {children}
      </Grid>
    ),
  }
  return <Searcher {...props} />
}

export const Responses = ({config, searchbar, searchOptions, noPagination}: SearchWidgetProps) => {
  const defaultSearchOptions = {
    searchLabel: 'Search responses',
    sortLabel: 'Sort responses:',
    sortOptions: {
      creation_dateAsc: 'Oldest first',
      creation_dateDesc: 'Newest first',
      raw_task_textAsc: 'By task (asc)',
      raw_task_textDesc: 'By task (desc)',
      parsed_response_textAsc: 'By response (asc)',
      parsed_response_textDesc: 'By response (desc)',
    },
    filtersElem: ResponseFilters,
  }

  const {getItems, loading} = useResponses()
  const props = {
    noPagination,
    getItems,
    loading,
    config: {...config, perPage: config?.perPage || 10},
    renderItem: Response,
    searchOptions: searchbar ? {...defaultSearchOptions, ...searchOptions} : undefined,
  }
  return <Searcher {...props} />
}

type RelatedAlertsProps = {
  config: FilterConfig
  title?: string
  noAdd?: boolean
}
export const RelatedAlerts = ({config, title, noAdd}: RelatedAlertsProps) => (
  <Container id={(title || 'related-alerts').toLowerCase().replace(' ', '-')}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h4" sx={{color: 'primary.main'}}>
        {title || 'Related Alerts'}
      </Typography>
      <NavButton to={Path.alerts.all()} label="Show More" />
    </Stack>
    <Alerts config={config} noAdd noUrlUpdate />
  </Container>
)
