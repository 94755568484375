import {ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import RenderMarkdown from '../components/RenderMarkdown'
import {styled} from '@mui/material/styles'

export type TextVariant =
  | 'inherit'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | undefined

export type TextAlign = 'right' | 'left' | 'center' | 'inherit' | 'justify' | undefined

export const titleStyle = {
  color: 'primary.main',
  textTransform: 'uppercase',
}

type TextProps = {
  text: string | ReactNode
  align?: TextAlign
  variant?: TextVariant
  style?: {[k: string]: any}
  markdown?: boolean
}
export const Text = ({text, align, variant, style, markdown}: TextProps) => (
  <Typography component="div" align={align} variant={variant || 'h5'} style={style}>
    {markdown ? <RenderMarkdown content={`${text}`} /> : text}
  </Typography>
)

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const equistampButton = {
  textTransform: 'none',
  marginBottom: '10px',
  minWidth: 140,
  fontWeight: 700,
  color: 'text.main',
}

type PotentialActionProps = {
  action?: string | (() => void)
  children: ReactNode
  sx?: {[k: string]: any}
}
export const PotentialAction = ({action, children, sx}: PotentialActionProps) => {
  if (!action) {
    return <>{children}</>
  }

  let props = {
    sx: {width: 'fit-content', pl: 0, textTransform: 'none', ...sx},
  } as {[k: string]: any}
  if (typeof action === 'string') {
    props = {...props, component: NavLink, to: action}
  } else {
    props = {...props, onClick: action}
  }
  return <Button {...props}>{children}</Button>
}

type InfoItemProps = {
  title: string
  value?: string | ReactNode
  to?: string
  align?: TextAlign
  variant?: TextVariant
  evenSpacing?: boolean
  maxLines?: number
  markdown?: boolean
  underline?: boolean
  sx?: {[k: string]: any}
}
export const InfoItem = ({
  title,
  value,
  to,
  align,
  variant,
  evenSpacing,
  maxLines,
  markdown,
  underline,
  sx,
}: InfoItemProps) => {
  if (!value) return evenSpacing ? <Box></Box> : null

  return (
    <Stack direction="column">
      <ColouredTypography sx={{fontWeight: 700, ...sx}} align={align}>
        {title}
      </ColouredTypography>
      {underline ? <ColouredDivider /> : null}

      <PotentialAction action={to}>
        <Text
          align={align}
          variant={variant}
          text={value}
          markdown={markdown}
          style={
            maxLines
              ? {
                  overflow: 'hidden',
                  WebkitLineClamp: maxLines,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }
              : undefined
          }
        />
      </PotentialAction>
    </Stack>
  )
}

type InfoBoxProps = {
  title?: string | ReactNode
  children: string | ReactNode | ReactNode[]
  sx?: {[k: string]: any}
}
export const InfoBox = ({title, children, sx}: InfoBoxProps) => (
  <Stack
    spacing={2}
    sx={{
      borderWidth: '1px',
      borderColor: 'primary.border',
      borderStyle: 'solid',
      borderRadius: '6px',
      p: 3,
      ...sx,
    }}
  >
    {title && <Typography sx={{fontWeight: 700}}>{title}</Typography>}
    {typeof children === 'string' ? <Typography>{children}</Typography> : children}
  </Stack>
)

export const ColouredTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.primary.main,
}))

export const ColouredDivider = styled(Divider)(({theme}) => ({
  borderColor: theme.palette.primary.main,
}))

export const CodeBlock = ({children}: {children: ReactNode}) => {
  return (
    <Box component="pre" sx={{overflow: 'auto', padding: '16px', borderRadius: '4px'}}>
      <Typography component="code" sx={{fontFamily: 'monospace', whiteSpace: 'pre-wrap'}}>
        {children}
      </Typography>
    </Box>
  )
}

export const ExternalA = ({
  to,
  text,
  style,
}: {
  to: string
  text: string
  style?: React.CSSProperties
}) => (
  <>
    <a href={to} target="_blank" rel="noreferrer" style={{color: 'inherit', ...style}}>
      {text}
    </a>{' '}
  </>
)
