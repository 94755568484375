import React, {useState} from 'react'
import type {User} from '@equistamp/types'
import {Input} from './inputs'
import {isInvalidName, isInvalidPassword} from './validators'
import {Form} from 'components/forms'

interface LoginFormProps {
  onSubmit: (u: User) => Promise<string | null>
}

const LoginForm: React.FC<LoginFormProps> = ({onSubmit}) => {
  const [invalid, setInvalid] = useState(false)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')

  const isInvalid = () => !!(isInvalidName(name) || isInvalidPassword(password))
  const submitter = async () => onSubmit({user_name: name, password} as User)

  return (
    <Form
      actionLabel="Log in"
      isInvalid={isInvalid}
      setInvalid={setInvalid}
      submitter={submitter}
      rounded
    >
      <Input
        label="Name:"
        value={name}
        setter={setName}
        validate={invalid}
        validator={isInvalidName}
        type="name"
      />
      <Input
        label="Password:"
        value={password}
        setter={setPassword}
        validate={invalid}
        validator={isInvalidPassword}
        type="password"
      />
    </Form>
  )
}

export default LoginForm
