import {useEffect, useState} from 'react'
import {Evaluation} from '@equistamp/types'
import {useEvaluations as useCachedEvaluations} from './useCachedObjects'

const useEvaluations = () => {
  const [evaluations, setEvaluations] = useState([] as Evaluation[])
  const {loading, getItems, refresh} = useCachedEvaluations()

  useEffect(() => {
    getItems({}).then(({items}) => setEvaluations(items as Evaluation[]))
  }, [setEvaluations, getItems])

  return {loading, getItems, refresh, evaluations}
}

export default useEvaluations
