import {ReactElement, SVGProps, useState} from 'react'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import {NavButton, RoundedButton} from 'components/forms/inputs'
import type {RoundedButtonProps} from 'components/filters/types'
import {SvgIconTypeMap, Typography} from '@mui/material'
import {OverridableComponent} from '@mui/material/OverridableComponent'

type MIcon = (props: SVGProps<SVGSVGElement>) => JSX.Element
type MUIIcon = OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {muiName: string}
export type IconType = MIcon | MUIIcon

type MenuItemProps = RoundedButtonProps & {
  to?: string
  Icon?: IconType
}

type TriggerButtonProps = {
  label?: string | ReactElement
  Icon?: IconType
  to?: string
}

const Label = ({label, Icon}: TriggerButtonProps) => (
  <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
    {Icon && <Icon />}
    <Typography sx={{fontWeight: 'inherit'}}>{label}</Typography>
  </Stack>
)

const menuItemSx = {justifyContent: 'left', color: 'inherit', fontWeight: 'inherit', mb: 0, p: 1.5}
const TriggerButton = ({sx, label, Icon, to, ...props}: MenuItemProps) => {
  if (Icon && !label) {
    return (
      <IconButton onClick={props.onClick} sx={sx}>
        <Icon />
      </IconButton>
    )
  }
  const buttonProps = {
    label: <Label label={label} Icon={Icon} />,
    variant: 'text',
    sx: {...menuItemSx, ...sx},
    ...props,
  } as RoundedButtonProps
  return to ? <NavButton to={to} {...buttonProps} /> : <RoundedButton {...buttonProps} />
}

type PopupProps = {
  label?: string
  Icon?: IconType
  side?: 'left' | 'right'
  sx?: {[k: string]: any}
}
type PopupButtonProps = PopupProps & {
  children: ReactElement | ReactElement[]
}
export const PopupButton = ({side, children, Icon, label, sx}: PopupButtonProps) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <Box sx={{position: 'relative', overflow: 'shown'}}>
      <TriggerButton Icon={Icon} label={label} onClick={() => setShowMenu((val) => !val)} sx={sx} />
      {showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
          <Paper
            variant="outlined"
            sx={{[side || 'left']: 6, mt: 1, width: 256, p: 0.5, position: 'absolute', zIndex: 4}}
          >
            <Stack>{children}</Stack>
          </Paper>
        </ClickAwayListener>
      )}
    </Box>
  )
}

type MenuButtonProps = PopupProps & {
  items: MenuItemProps[]
}
export const MenuButton = ({items, ...props}: MenuButtonProps) => (
  <PopupButton {...props}>
    {items.map((props, i) => (
      <TriggerButton
        key={(props.label || props.to || props.onClick?.toString() || i).toString()}
        {...props}
      />
    ))}
  </PopupButton>
)
