import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import {Alerts as AlertsWidget} from 'components/search'

const Alerts: React.FC = () => {
  return (
    <Page title="Public alerts">
      <Stack direction="column" spacing={7} sx={{mt: 3, mb: 6}}>
        <Typography variant="h1">Alerts</Typography>
        <AlertsWidget searchbar />
      </Stack>
    </Page>
  )
}

export default Alerts
