import {SVGProps} from 'react'
const SvgQuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13A5 5 0 1 0 8 3a5 5 0 0 0 0 10Zm0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12ZM5.9 6.8a2.1 2.1 0 1 1 2.6 2.04v.36a.5.5 0 0 1-1 0v-.8a.5.5 0 0 1 .5-.5 1.1 1.1 0 1 0-1.1-1.1.5.5 0 0 1-1 0ZM8 11.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      fill="#788492"
    />
  </svg>
)
export default SvgQuestionMark
