import {useEvaluationSessions as useRuns} from './useCachedObjects'
import {paginate} from 'components/filters/filters'
import {sortItems} from 'components/filters//transformers'
import type {EvalSessionGroup} from 'components/filters/types'
import type {FilterConfig, SearchResult, Eval, ItemType} from '@equistamp/types'

const useEvaluationSessions = () => {
  const {refresh, getItems, items} = useRuns()

  const getGroupedItems =
    (item_type: ItemType) =>
    async (params: FilterConfig, transform?: (i: Eval) => Eval): Promise<SearchResult> => {
      const res = await getItems({...params, perPage: 'all'}, transform)
      const grouper = item_type === 'evaluation' ? 'evaluatee_name' : 'evaluation_name'
      const items = (res.items as Eval[]).reduce((acc: {[k: string]: Eval[]}, run) => {
        const name = run[grouper] as EvalSessionGroup
        const vals = acc[name] || []
        return {
          ...acc,
          [name]: [...vals, run],
        }
      }, {})
      const grouped = Object.entries(items).map(([name, runs]) => ({name, runs}))
      return paginate(sortItems(grouped, params), params.page || 0, params.perPage || 20)
    }

  return {refresh, getItems, getGroupedItems, items}
}
export default useEvaluationSessions
