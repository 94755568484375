import {useEffect, useState} from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import {MInput, ButtonGroup, CheckButton} from 'components/forms/inputs'
import {notificationTypes} from 'components/filters/constants'
import type {Subscription as SubType, SubscriptionType} from '@equistamp/types'
import type {AlertStageProps} from './types'
import {EMAIL, PHONE_CALL, TEXT_MESSAGE, WEBHOOK} from '@equistamp/constants'
import UrlInput from 'components/dsl/UrlInput'
import AddItem from 'components/search/AddItem'
import {isInvalidSubscription} from 'components/forms/validators'
import {
  EndpointConfig,
  makeEndpointCode,
  methods,
  parseEndpointCode,
  Headers,
  JSONType,
  GET,
  PUT,
  PATCH,
  POST,
} from 'components/dsl'
import {OptionsGroup} from 'components/filters/widgets'
import HeadersInput from 'components/dsl/HeadersInput'
import BodyInput from 'components/dsl/BodyInput'
import useUser from 'hooks/useUser'

type SubscriptionProps = {
  subscription: SubType
  error: boolean | string
  onChange: (n: SubType) => void
  onDelete: () => void
}

type ItemInputProps = {
  value: string
  error: boolean | string
  onChange: (v: string) => void
}
const EmailInput = ({value, error, onChange}: ItemInputProps) => {
  const {user} = useUser()
  return (
    <Stack>
      <MInput
        label="Email address"
        value={value || ''}
        setter={onChange}
        validate={!!error}
        validator={(val?: string) => error}
        fullWidth
      />
      {value !== user?.email_address && (
        <Box sx={{pl: 3}}>
          <Alert severity="warning" sx={{width: 'max-content'}}>
            We will send an email to this address asking them to confirm that they want to subscribe
            to this alert
          </Alert>
        </Box>
      )}
    </Stack>
  )
}

const WebhookInput = ({value, error, onChange}: ItemInputProps) => {
  const [config, setConfig] = useState<EndpointConfig>({method: GET, ...parseEndpointCode(value)})

  const onUpdate = (field: keyof EndpointConfig) => (val?: string | Headers | JSONType) => {
    const updated = {...config, [field]: val}
    setConfig(updated)
    console.log(makeEndpointCode(updated))
    onChange(makeEndpointCode(updated))
  }
  return (
    <Stack>
      <UrlInput
        label="Webhook URL"
        url={config.url}
        onChange={onUpdate('url')}
        validate={!!error}
      />
      <OptionsGroup label="HTTP Method" sx={{width: '100%'}}>
        <Stack
          direction={{xs: 'column', md: 'row'}}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {methods.map((method) => (
            <CheckButton
              key={method}
              selected={method === config.method}
              onClick={() => onUpdate('method')(method)}
            >
              {method}
            </CheckButton>
          ))}
        </Stack>
      </OptionsGroup>
      <HeadersInput headers={config.headers} onChange={onUpdate('headers')} />
      {[POST, PATCH, PUT].includes(config.method || '') && (
        <BodyInput body={config.body || ''} onChange={onUpdate('body')} />
      )}
    </Stack>
  )
}

const PhoneNumberInput = ({value, error, onChange}: ItemInputProps) => {
  return (
    <MInput
      label="Phone number"
      value={value || ''}
      setter={onChange}
      validate={!!error}
      validator={(val?: string) => error}
      fullWidth
    />
  )
}

const itemInputs = {
  [EMAIL]: EmailInput,
  [WEBHOOK]: WebhookInput,
  [PHONE_CALL]: PhoneNumberInput,
  [TEXT_MESSAGE]: PhoneNumberInput,
}

const Subscription = ({subscription, error, onChange, onDelete}: SubscriptionProps) => {
  const {isAdmin} = useUser()
  const [selected, setSelected] = useState(subscription.method as string)
  const {method, destination} = subscription
  const [values, setValues] = useState(
    Object.keys(itemInputs).reduce((acc, l) => ({...acc, [l]: method === l ? destination : ''}), {})
  )

  const onSelected = (selected?: string | string[]) => {
    if (selected) {
      setSelected(selected as string)
      onChange({
        ...subscription,
        method: selected as SubscriptionType,
        destination: values[selected as keyof typeof values],
      })
    }
  }
  const onPayloadChange = (v: string) => {
    setValues((current) => ({...current, [selected]: v}))
    onChange({...subscription, destination: v})
  }

  // Only show notification methods that can be confirmed
  const availableNotificationTypes = isAdmin
    ? notificationTypes
    : {
        [EMAIL]: notificationTypes[EMAIL],
        [WEBHOOK]: notificationTypes[WEBHOOK],
      }
  const ItemInput = itemInputs[selected as keyof typeof itemInputs]
  return (
    <Card sx={{width: '100%', height: '100%', borderRadius: 3, p: 2, position: 'relative'}}>
      <IconButton size="small" sx={{position: 'absolute', right: 8, top: 8}} onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
      <CardContent>
        <Box sx={{pl: 3}}>
          <ButtonGroup
            perRow={Math.min(3, Object.keys(availableNotificationTypes).length)}
            selected={selected}
            onSelect={onSelected}
            items={Object.entries(availableNotificationTypes).map(([id, label]) => ({id, label}))}
          />
        </Box>
        <Box sx={{pr: 6}}>
          <ItemInput
            value={values[selected as keyof typeof values]}
            onChange={onPayloadChange}
            error={error}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

const NotificationMethods = ({
  alert,
  onChange,
  setNextHandler,
  enableNext,
  index,
}: AlertStageProps) => {
  const [errors, setErrors] = useState<(boolean | string)[]>(
    alert.subscriptions?.map((_) => false) || []
  )

  const handleChange = (i: number) => (s: SubType) => {
    if (!alert.subscriptions) return

    alert.subscriptions[i] = s
    onChange('subscriptions', alert.subscriptions)
    setErrors((current) => {
      current[i] = false
      return current
    })
  }

  const deleteItem = (index: number) => {
    if (!alert?.subscriptions) return
    alert.subscriptions?.splice(index, 1)
    onChange('subscriptions', [...alert.subscriptions])
    enableNext(alert.subscriptions && alert.subscriptions.length > 0)
  }

  const addItem = () => {
    onChange('subscriptions', [...(alert.subscriptions || []), {method: EMAIL} as SubType])
    enableNext(true)
  }

  useEffect(() => {
    const checkCorrect = async () => {
      const invalid = await Promise.all(alert.subscriptions?.map(isInvalidSubscription) || [])
      setErrors(invalid)

      return !invalid.some(Boolean)
    }

    setNextHandler(checkCorrect)
  }, [index, alert, setErrors, setNextHandler])

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Please specify all ways you wish to get notified.</Typography>
      {alert.subscriptions?.map((sub, i) => (
        <Subscription
          key={i}
          subscription={sub}
          onChange={handleChange(i)}
          onDelete={() => deleteItem(i)}
          error={errors[i]}
        />
      ))}
      {(alert?.subscriptions?.length || 0) < 20 ? (
        <AddItem label="Add notification method" action={addItem} />
      ) : undefined}
    </Stack>
  )
}

export default NotificationMethods
