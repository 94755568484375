import React, {useEffect} from 'react'
import useEvaluations from 'hooks/useEvaluations'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import {EvaluationFilters} from 'components/filters'
import type {Model as ModelType} from '@equistamp/types'
import RunEvalsWizard from 'components/connections/EvalRunWizard'

const RenderModelConnections = ({item: model, setItem}: RendererProps<ModelType>) => {
  const {loading, refresh, getItems} = useEvaluations()
  useEffect(
    () =>
      refresh({
        filters: {
          model: model.id,
          fields: [
            'name',
            'description',
            'next_run',
            'modalities',
            'task_types',
            'min_questions_to_complete',
            'tags',
            'price',
            'public',
            'public_usable',
            'reports_visible',
          ],
          public_usable: true,
        },
      }),
    [model.id, refresh]
  )
  return (
    <RunEvalsWizard
      title={`Evaluate ${model.name}`}
      itemType="model"
      item={model}
      itemsGetter={getItems}
      loading={loading}
      searchOptions={{
        searchLabel: 'filter evaluations',
        sortLabel: 'Sort evaluations:',
        sortOptions: {
          nameAsc: 'By name (asc)',
          nameDesc: 'By name (desc)',
        },
        filtersElem: EvaluationFilters,
      }}
    />
  )
}

const ModelConnections: React.FC = () => (
  <ItemLoader itemName="model" renderer={RenderModelConnections} />
)
export default ModelConnections
