import React, {ReactNode, ReactElement} from 'react'
import {NavLink} from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import useUser from '../../hooks/useUser'
import {Permission} from '../../permissions'
import Path from '../..//routeLinks'

type AddItemType = {
  label: string
  action: string | (() => void)
  permission?: Permission
  wrapper?: ({children}: {children: ReactNode}) => ReactElement
}

const AddItem = ({label, action, permission, wrapper}: AddItemType) => {
  const {hasPermission} = useUser()

  const actionProps = () => {
    const isUrl = typeof action === 'string'
    if (permission && !hasPermission(permission)) {
      return {
        component: NavLink,
        to: Path.subscriptions.all(isUrl ? action : ''),
      }
    } else if (isUrl) {
      return {component: NavLink, to: action}
    } else {
      return {onClick: action}
    }
  }
  const Wrapper = wrapper || (({children}: {children: ReactNode}) => <>{children}</>)
  return (
    <Wrapper>
      <Card sx={{width: '100%', height: '100%', borderRadius: 3}}>
        <CardActionArea {...actionProps()} sx={{height: '100%', display: 'flex', padding: 5}}>
          <CardContent sx={{flexGrow: 1}}>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
              <Box>
                <AddCircleIcon sx={{height: 90, width: 90}} />
              </Box>

              <Typography align="center" gutterBottom variant="h5" component="div">
                {label}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Wrapper>
  )
}

export default AddItem
