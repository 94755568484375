import {memo} from 'react'
import Box from '@mui/material/Box'

import {
  Chart as ChartJS,
  ChartEvent,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {getRelativePosition} from 'chart.js/helpers'
import annotationPlugin, {LabelAnnotationOptions} from 'chartjs-plugin-annotation'
import {Line} from 'react-chartjs-2'

export type Dataset = {
  key: string
  data: {[k: string]: any}
}
type Threshold = {
  title: string
  mean: number
  min?: number
  max?: number
}
type ChartType = {
  datasets: Dataset[]
  title?: string
  thresholds?: Threshold[]
  labelsExtractor?: (d: Dataset[]) => string[]
  mapper?: (v: any) => any
  onSelect?: (v: any) => void
  tooltipRenderer?: (dataset: number, index: number, label: string) => string
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
)

const colours = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
]
const baselineColours = [
  'rgb(128, 0, 0)',
  'rgb(0, 128, 0)',
  'rgb(0, 0, 128)',
  'rgb(128, 128, 0)',
  'rgb(255, 0, 0)',
  'rgb(0, 255, 0)',
  'rgb(0, 0, 255)',
  'rgb(255, 255, 0)',
  'rgb(255, 0, 255)',
  'rgb(0, 255, 255)',
]

type Bounds = {
  min: number | string
  max: number | string
  span: number
}
type GraphBounds = {
  x: Bounds
  y: Bounds
}
type TooltipLabel = {
  label: string
  dataIndex: number
  datasetIndex: number
  formattedValue: string
  dataset: any
}
export const makeOptions = (
  title: string | undefined,
  bounds: GraphBounds,
  thresholds: Threshold[],
  onSelect?: (i: number) => any,
  tooltipRender?: (dataset: number, index: number, label: string) => string
) => ({
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  onClick: (e: ChartEvent, _: any, chart: any) => {
    const canvasPosition = getRelativePosition(e, chart)
    onSelect && onSelect(chart.scales.x.getValueForPixel(canvasPosition.x))
  },
  plugins: {
    title: {
      display: true,
      text: title,
    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        filter: (item: any, data: any) => !thresholds.map((t) => t.title).includes(item.text),
      },
    } as any,
    tooltip: {
      callbacks: {
        label: ({formattedValue, dataset, label, dataIndex, datasetIndex}: TooltipLabel) => {
          const name = tooltipRender ? tooltipRender(datasetIndex, dataIndex, label) : dataset.label
          return `${name}: ${formattedValue}`
        },
      },
      filter: (item: any) => !thresholds.map((t) => t.title).includes(item.dataset.label),
    },
    annotation: {
      annotations: thresholds
        .slice(0, colours.length)
        .reduce((acc: {[key: string]: any}, {title, mean, max, min}, i) => {
          acc[title] = {
            type: 'label',
            xValue: bounds.x.min,
            yValue: mean - bounds.y.span / 20,
            content: [title],
            color: baselineColours[i],
            position: {
              x: 'start',
            },
          } as LabelAnnotationOptions

          if (max || min) {
            acc[title + 'box'] = {
              type: 'box',
              xMin: bounds.x.min,
              xMax: bounds.x.max,
              yMin: min || mean,
              yMax: max || mean,
              borderWidth: 0,
              backgroundColor: baselineColours[i].replace(')', ', 0.1)'),
              drawTime: 'beforeDatasetsDraw',
            }
          }

          return acc
        }, {}),
    },
  },
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
  },
})

/* Return a hash of any jsonofiable object
 *
 * This is used to minimize the number of rerenders of the underlying chart. The
 * chart doesn't handle lots of frequent renders very well, resulting in both laggy
 * display, and console errors.
 *
 * The function callers can't be depended on to always provide exactly the same dataset objects
 * between renders, so this will check whether the acutual contents are the same, rather than
 * looking at the object itself
 */
function hashObject(obj: any) {
  if (!obj) return null
  var stringified = JSON.stringify(obj)
  var hash = 0,
    i,
    chr
  if (stringified.length === 0) return hash
  for (i = 0; i < stringified.length; i++) {
    chr = stringified.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const dataBounds = (data: any) => {
  const vals = data.datasets
    .reduce((acc: any[], d: {data: any[]}) => [...acc, ...d.data], [])
    .filter((i: any) => i)
  const min = Math.min.apply(null, vals)
  const max = Math.max.apply(null, vals)
  return {
    min,
    max,
    span: max - min,
  }
}

const formatData = (
  labels: string[],
  data: Dataset[],
  thresholds: Threshold[],
  mapper: (v: any) => any
) => {
  const datasets = data.slice(0, colours.length).map(({key, data}, i) => ({
    label: key,
    data: labels.map((l: string) => mapper(data[l])),
    borderColor: colours[i],
    backgroundColor: colours[i].replace(')', ', 0.5)'),
    yAxisID: 'y',
    spanGaps: true,
  }))

  const thresholdLines = thresholds.slice(0, colours.length).map(({title, mean, min, max}, i) => ({
    label: title,
    data: labels.map((l) => mean),
    borderDash: [5, 5],
    borderColor: baselineColours[i],
    backgroundColor: baselineColours[i].replace(')', ', 0.5)'),
  }))

  return {labels, datasets: [...datasets, ...thresholdLines]}
}

const Chart = memo(
  ({
    datasets,
    title,
    thresholds,
    labelsExtractor = (dates: Dataset[]) =>
      Object.keys(dates.reduce((acc, {data}) => ({...acc, ...data}), {})).sort(),
    mapper = (v: any) => v,
    onSelect,
    tooltipRenderer,
  }: ChartType) => {
    const labels = labelsExtractor(datasets)
    const data = formatData(labels, datasets, thresholds || [], mapper)

    const bounds = {
      x: {
        min: labels[0],
        max: labels[labels.length - 1],
        span: labels.length,
      },
      y: dataBounds(data),
    }

    const handleClick = (x: number) => onSelect && onSelect(labels[x])

    return (
      <Box sx={{width: '100%'}}>
        <Line
          height={200}
          options={makeOptions(title, bounds, thresholds || [], handleClick, tooltipRenderer)}
          data={data}
        />
      </Box>
    )
  },
  (prevProps, currentProps) =>
    hashObject(prevProps.datasets) === hashObject(currentProps.datasets) &&
    prevProps.title === currentProps.title &&
    hashObject(prevProps.thresholds) === hashObject(currentProps.thresholds)
)

export default Chart
