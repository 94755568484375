import {useEffect, useState} from 'react'
import {useLocation, useNavigate, Location} from 'react-router-dom'
import {removeFilter as removeFromFilters} from 'components/filters'
import {ObjectToParams, ParamsToObject} from 'components/filters/Serializer'
import type {ChangeKey, FiltersUpdaterFunc} from 'components/filters/types'
import {FilterConfig, FiltersTypes} from '@equistamp/types'

const makeInitialConfig = (location: Location, overrides?: FilterConfig) => {
  const urlConfig = ParamsToObject(location.search)

  let initialConfig = {...overrides, ...urlConfig}
  if (location.state) {
    initialConfig = {...initialConfig, ...location.state}
  }
  return {
    ...(initialConfig || {}),
    ...ParamsToObject(location.search),
    ...(location.state || {}),
  }
}

export const updateConfigVal = (config: FilterConfig, field: ChangeKey, val: any) => {
  if (field !== 'search') return {...config, [field]: val}

  // Get the filters object without the current search
  const filters = Object.entries(config.filters || {}).reduce(
    (acc, [k, v]) => (k !== 'search' ? {...acc, [k]: v} : acc),
    {}
  )

  if (!val) return {...config, filters}
  return {...config, filters: {...filters, search: val}}
}

type Props = {
  initialConfig?: FilterConfig
  onFilterChange?: (f?: FiltersTypes) => void
  noUrlUpdate?: boolean
}
const useUrlFilters = ({initialConfig, onFilterChange, noUrlUpdate}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [config, setConfig] = useState<FilterConfig>(() =>
    makeInitialConfig(location, initialConfig)
  )

  useEffect(() => {
    if (!noUrlUpdate) {
      let cacheState = JSON.stringify({...config})
      cacheState = JSON.parse(cacheState)
      navigate('?' + ObjectToParams(cacheState), {replace: true, state: cacheState})
    }
  }, [navigate, config, config.sort, noUrlUpdate])

  const updateConfig = async (field: ChangeKey, val: any) => {
    const updatedConfig = updateConfigVal(config, field, val)
    setConfig(updatedConfig)
    onFilterChange && onFilterChange(updatedConfig.filters)
  }

  const removeFilter = async (field: keyof FiltersTypes, val: any) =>
    config.filters && updateConfig('filters', removeFromFilters(config.filters, field, val))

  const updateFilters = (func: FiltersUpdaterFunc) => updateConfig('filters', func(config.filters))

  return {config, updateConfig, removeFilter, updateFilters}
}

export default useUrlFilters
