import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi, ServerError} from '@equistamp/api'
import useUser from 'hooks/useUser'
import Wizard, {StepProps} from 'components/forms/Wizard'
import {ExternalA} from 'components/text'
import {modalStyle} from 'components/dialogs'
import {SearchOptions} from 'components/filters/types'
import {ChooseCadence, RunSummary, SelectItems} from 'components/connections/wizard'
import {BuyCredits, ManageSubscriptions} from 'components/profile'
import type {SearchResult, FilterConfig, Cadence, ItemType} from '@equistamp/types'
import type {ConnectionsConfig, Item} from 'components/connections/wizard'
import Path from 'routeLinks'

const PRICES_CALCULATION = {
  title: 'How are prices calculated?',
  contents: (
    <Stack spacing={2}>
      <Typography>
        Different AI model providers charge different amounts for access to their models. It's
        usually some variant of charging per the amount of data sent to and from the model. Some
        models are cheaper, others are more expensive.
      </Typography>
      <Typography>
        Evaluations also differ in the amount of data sent to the models. Some evaluations only have
        a couple of tasks, while others can have thousands. Large evaluations will send lots of data
        to models. But they also will take longer to complete, which can also effect the price.
      </Typography>
      <Typography>
        The cost of running an evaluation on a model is therefore dependant on the size of the
        evaluation and what the tested model charges. We abstract all this away for you by
        presenting you with a single price.
      </Typography>
      <Typography>
        This price also includes a bit extra to cover our infrastructure costs. See our{' '}
        <ExternalA to={Path.pricing()} text="pricing page" />
        for more information.
      </Typography>
    </Stack>
  ),
}

const selectDocs = {
  model: [
    {
      title: 'Which models should I choose?',
      contents: (
        <Stack spacing={2}>
          <Typography>
            You can use the filters and search bar to find specific models that interest you. The
            search bar will search over names, descriptions and publishers.
          </Typography>
          <Typography>
            If you need more specific information, check the{' '}
            <ExternalA to={Path.models.all()} text="models" /> list.
          </Typography>
        </Stack>
      ),
    },
    PRICES_CALCULATION,
  ],
  evaluation: [
    {
      title: 'Which evaluations should I choose?',
      contents: (
        <Stack spacing={2}>
          <Typography>
            This depends a lot on what you want to check. You can use the filters and search bar to
            find specific evaluations that interest you. The search bar will search over names and
            descriptions.
          </Typography>
          <Typography>
            If you need more specific information, check the{' '}
            <ExternalA to={Path.evaluations.all()} text="evaluations" /> list.
          </Typography>
        </Stack>
      ),
    },
    PRICES_CALCULATION,
  ],
}

const ONCE_PAYMENT = {
  title: 'How do I pay for this?',
  contents: (
    <Stack spacing={2}>
      <Typography>
        If you have credits available, we'll use them to pay for your evaluation runs. If you don't
        have enough credits, you'll get redirected to our checkout page, where you can choose your
        payment method.
      </Typography>
      <Typography>
        Each evaluation run is considered seperately when we check for credits - so if you e.g.
        wanted to run an evaluation on three items for $1.00 each, but only had $2.34 in credits, we
        will subtract $2.00 of your credits and ask you to pay the remainding $1.00.
      </Typography>
      <Typography>
        You can top up your credits at any time from your{' '}
        <ExternalA to={Path.user.me()} text="user profile" />, or using this button:
      </Typography>
      <BuyCredits />
    </Stack>
  ),
}
const RECURRING_PAYMENT = {
  title: 'How do I pay for this?',
  contents: (
    <Stack spacing={2}>
      <Typography>
        When you click on the <code>Run evaluation</code> button, we will redirect you to our
        checkout page, where you can choose your payment method.
      </Typography>
      <Typography>
        The price displayed on the checkout page is for a single set of evaluation runs - you will
        be charged this amount every time your scheduled evaluation runs take place.
      </Typography>
      <Typography>
        As an example, assume you select items that sum up to $5.00 on a weekly cadence. This means
        that today we will both charge you $5.00 and start your evaluation runs. Then for each
        subsequent week, until you cancel this schedule, we will again charge you $5.00 and start a
        new batch of evaluation runs.
      </Typography>
      <Typography>
        You can cancel any evaluation subscriptions from your{' '}
        <ExternalA to={Path.user.me()} text="user profile" />
        at any time. You can also do it here:
      </Typography>
      <ManageSubscriptions />
    </Stack>
  ),
}

const documentationItems = (item: Item, itemType: ItemType, cadence?: Cadence) => {
  return {
    select: itemType === 'model' ? selectDocs.evaluation : selectDocs.model,
    cadence: [
      {
        title: 'Once or recurring?',
        contents: [
          `Some AI models are trained once and don't really change after that. For these models it's enough
               to evaluate them once, unless the evaluation itself has changed drastically.`,
          `Other models get updated often, and each update can result in a different evaluation score. For
              these kinds of models its worth running evaluations recurrently, in order to track how well the changes do`,
        ],
      },
      {
        title: 'How does this effect the price?',
        contents: [
          `The displayed price is for a single run of your selected items. You will be charged this amount
              every time your evaluations run.`,
          `So if you, e.g. choose "Weekly" and have selected 3 items for $1.00 each, then each week we will
              charge you $3.00 and as soon as the payment goes through, start three evaluation runs.`,
        ],
      },
      {
        title: 'How can I cancel recurring evaluation runs?',
        contents: (
          <Stack spacing={2}>
            <Typography>
              You can cancel any evaluation subscriptions from your{' '}
              <ExternalA to={Path.user.me()} text="user profile" />
              at any time. You can also do it here:
            </Typography>
            <ManageSubscriptions />
          </Stack>
        ),
      },
    ],
    summary: [
      (cadence || 'once') === 'once' ? ONCE_PAYMENT : RECURRING_PAYMENT,
      {
        title: 'Is this the final price?',
        contents: [
          `It should be. It's possible for the price on the actual payments page to be different, though very unlikely.
                This can happen if the underlying evaluation gets changed or if the model's prices get updated while you are
                configuring your evaluation runs.`,
          `If the evaluation has a bunch of tasks removed, or if the model's pricing becomes cheaper, you will be quoted
                a lower price`,
          `If the evaluation has a bunch of tasks added, or if the model's pricing becomes more expensive, you will be asked
                to pay a higher price`,
        ],
      },
      {
        title: 'Are there any other hidden costs?',
        contents: [
          `No. You pay here once and that's it. This price already includes taxes and other service charges, so nothing to worry about.`,
        ],
      },
      {
        title: 'Where will I see the results of my evaluation runs?',
        contents: (
          <Stack spacing={2}>
            <Typography>
              We will send you an email with a link to a full report on each evaluation run
              completion. This report will contain detailed statistics, along with a list of failed
              tasks, so you can see exactly where the model is struggling.
            </Typography>
            <Typography>
              You can also view the results by finding your evaluation run on the list of{' '}
              <ExternalA
                to={
                  itemType === 'model' ? Path.models.runs(item.id) : Path.evaluations.runs(item.id)
                }
                text="past runs."
              />
              The reports there will show up to date results, so you can use them to track how your
              evaluation runs are doing.
            </Typography>
            <Typography>
              The past evaluation runs page can be a couple of minutes out of date - so if you can't
              see your evaluation run on the list, try refreshing the page in a few minutes.
            </Typography>
          </Stack>
        ),
      },
    ],
  }
}

type RunEvalsWizardProps = {
  item: Item
  itemType: ItemType
  title: string
  searchOptions: SearchOptions
  itemsGetter: (params: FilterConfig, transform?: (item: Item) => Item) => Promise<SearchResult>
  loading: boolean
}
const RunEvalsWizard = ({
  itemType,
  item,
  title,
  searchOptions,
  itemsGetter,
  loading,
}: RunEvalsWizardProps) => {
  // Make sure each model has the price added - this makes it a lot easier to sort
  const {user} = useUser()
  const navigate = useNavigate()
  const [runConfig, setConfig] = useState({selected: []} as ConnectionsConfig)

  const getItems = async (params: FilterConfig) =>
    itemsGetter({...params, perPage: params.perPage || 6}, (item: Item) => ({
      ...item,
      price: item.price && Math.max(item.price ? item.price / 100 : 1, 1),
    }))

  const updateRunConfig = (field: keyof ConnectionsConfig, value: any) => {
    setConfig((current) => ({...current, [field]: value}))
  }

  const save = async () => {
    if (!user) return 'No user provided'

    const connections = runConfig.selected
      .filter((c) => c.price)
      .map(({id, name, price}) => ({
        evaluatee_id: itemType === 'model' ? item.id : id,
        evaluation_id: itemType === 'model' ? id : item.id,
        name: itemType === 'model' ? `${name}: ${item.name}` : `${item.name}: ${name}`,
        cadence: runConfig.cadence || 'once',
        price: Math.round((price || 1) * 100), // Transform back to cents
      }))
    try {
      const {checkout_url} = await makeApi().modelConnections.connectModels(connections)
      if (checkout_url) {
        window.location = checkout_url
      }
      navigate(itemType === 'model' ? Path.models.runs(item.id) : Path.evaluations.runs(item.id))
    } catch (e) {
      return e instanceof ServerError ? e.error.toString() : `${e}`
    }
    return null
  }

  const props = {itemType, item, updateRunConfig, runConfig}
  const documentation = documentationItems(item, itemType, runConfig.cadence)
  const steps = [
    {
      label: `Choose ${itemType === 'model' ? 'evaluations to run on' : 'models to evaluate'}`,
      Step: SelectItems,
      documentation: documentation.select,
      extraProps: {...props, loading, getItems, searchOptions},
    },
    {
      label: `How often would you like to run ${
        itemType === 'evaluation' || runConfig.selected.length === 1
          ? 'this evaluation'
          : 'these evaluations'
      }?`,
      Step: ChooseCadence,
      documentation: documentation.cadence,
      extraProps: props,
    },
    {
      label: 'Summary',
      Step: RunSummary,
      documentation: documentation.summary,
      extraProps: props,
      nextLabel: 'Run evaluation',
    },
  ].filter(Boolean) as StepProps<any>[]
  if (steps.length) {
    return <Wizard title={title} onFinish={save} onCancel={() => navigate(-1)} steps={steps} />
  }
  return null
}

type EvalWizardModalProps = {
  show?: boolean
  onCancel: () => void
} & RunEvalsWizardProps
export const EvalWizardModal = (props: EvalWizardModalProps) => {
  return (
    <Modal open={props.show || false} onClose={props.onCancel}>
      <Box sx={{...modalStyle, m: 10, p: 0, border: 'none'}}>
        <RunEvalsWizard {...props} />
      </Box>
    </Modal>
  )
}

export default RunEvalsWizard
