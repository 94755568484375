import {EDNList, parseEDNString} from 'edn-data'
import {toEdn} from 'components/formatters'
import type {EndpointConfig, HTTPMethod, JSONType, Headers} from './types'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'
export const OPTIONS = 'OPTIONS'
export const methods = [GET, POST, PATCH, PUT, OPTIONS, DELETE]

export const replacePrompt = (obj: any): any => {
  if (Array.isArray(obj)) return obj.map(replacePrompt)
  if (!obj || typeof obj !== 'object') return obj
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (k === 'sym' && v === 'prompt') {
      return '%PROMPT%'
    } else {
      return {...acc, [k]: replacePrompt(v)}
    }
  }, {})
}

export const parseEndpointCode = (code?: string): EndpointConfig => {
  if (!code) return {} as unknown as EndpointConfig

  const [method, url, config] = ((
    parseEDNString(code || '', {mapAs: 'object', keywordAs: 'string'}) as EDNList | undefined
  )?.list || []) as {[k: string]: any}[]

  if (!method || typeof method !== 'object' || !methods.includes(method.sym)) {
    return {} as unknown as EndpointConfig // There is a custom code being used
  }

  return {
    method: method.sym as HTTPMethod,
    url: url.toString(),
    headers: Object.entries(config.headers || {}).map(([k, v]) => [k, v?.toString() || '']),
    json: JSON.stringify(replacePrompt(config?.json || {}), null, 2),
    body: config?.body,
  }
}

const asBody = ({json, body, asJson}: EndpointConfig) => {
  if (!asJson) return `:body "${body?.replaceAll('"', '\\"')}"`

  const bodyEdn = toEdn(json || body || '')
  if (!bodyEdn) return ''
  return `:json ${bodyEdn}`
}
export const makeEndpointCode = ({url, method, headers, body, json, asJson}: EndpointConfig) => {
  const headersEdn = toEdn(
    headers ? JSON.stringify(Object.fromEntries(headers.filter(([k, v]) => k && v))) : ''
  )
  const headersStr = headersEdn ? ':headers ' + headersEdn : ''

  return `(${method} "${url || ''}" {${headersStr} ${asBody({body, json, asJson})}})`.replace(
    '"%PROMPT%"',
    'prompt'
  )
}

export type {EndpointConfig, HTTPMethod, JSONType, Headers}
