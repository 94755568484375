import React, {useState, ReactNode} from 'react'
import {useLocation} from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {useTheme} from '@mui/material'
import {RoundedButton} from './inputs'
import Path from '../../routeLinks'

const ActionParams = ({action}: {action: string}) => {
  const url = new URL(action, window.location.origin)
  const params = new URLSearchParams(url.search)
  return (
    <>
      {Array.from(params.entries()).map(([param, val]) => (
        <input key={param} type="hidden" name={param} value={val} />
      ))}
    </>
  )
}

type FormProps = {
  actionLabel: string
  children: ReactNode
  isInvalid: () => boolean
  setInvalid: (v: boolean) => void
  submitter: () => Promise<string | null>
  action?: string
  rounded?: boolean
  disabled?: boolean
}
export const Form = ({
  actionLabel,
  children,
  isInvalid,
  setInvalid,
  submitter,
  action,
  rounded,
  disabled,
}: FormProps) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const formAction = action || searchParams.get('redirect') || Path.home()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (isInvalid()) {
      setInvalid(true)
      return
    }

    setLoading(true)
    const error = await submitter()
    if (error) {
      setInvalid(true)
      setError(error)
    } else {
      ;(e.target as any).submit()
    }
    setLoading(false)
  }

  const roundedInputs = {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50px',
      p: 1,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
  }

  return (
    <Box component="form" onSubmit={handleSubmit} action={formAction} sx={{pt: 5, maxWidth: 1000}}>
      <ActionParams action={formAction} />
      <Stack
        direction="column"
        spacing={4}
        alignItems="center"
        sx={rounded ? roundedInputs : undefined}
      >
        {children}

        {error && <Alert severity="error">{error}</Alert>}
        <RoundedButton loading={loading} label={actionLabel} type="submit" disabled={disabled} />
      </Stack>
    </Box>
  )
}

export default Form
