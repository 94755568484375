import {useEffect, useState} from 'react'
import {Tag} from '@equistamp/types'
import {useTags as useCachedTags} from './useCachedObjects'
import {makeSorter} from 'components/filters'

const useTags = () => {
  const [tags, setTags] = useState([] as Tag[])
  const {getItems, refresh} = useCachedTags()

  useEffect(() => {
    getItems({}).then(({items}) => {
      setTags(makeSorter('name', 'Asc')(items))
    })
  }, [setTags, getItems])

  return {getItems, refresh, tags}
}

export default useTags
