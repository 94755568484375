import React, {ReactNode} from 'react'
import Stack from '@mui/material/Stack'
import Page from '../components/Page'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {Action} from '../components/Landing'
import ContactForm from '../components/forms/Contact'
import {ExternalA} from '../components/text'
import {NavButton} from '../components/forms/inputs'
import {Container, Grid, List, ListItem, ListItemText} from '@mui/material'

const Pricing: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  type TextedIconProps = {
    title: string
    img?: string
    children: ReactNode
  }
  const TextedIcon = ({title, img, children}: TextedIconProps) => (
    <Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      sx={{pt: 2, maxWidth: isMobile ? 250 : 560}}
    >
      <Action
        title={title}
        img={img}
        imgWidth={160}
        titleFont={{fontWeight: 700, fontSize: 25, color: 'primary.main'}}
      />
      <Box>{children}</Box>
    </Stack>
  )

  return (
    <Page title="Pricing EquiStamp" fullWidth>
      <Container maxWidth="xl">
        <Stack
          direction={{lg: 'column', xl: 'row'}}
          spacing={4}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{mt: 8}}
        >
          <TextedIcon title="Pay as you Go">
            <Stack spacing={2}>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        You pay to <i>run</i> evaluations, not to <i>view</i> them.
                      </>
                    }
                    secondary="You see in-depth reports, showing failed tasks; everyone else sees the basic scores."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="You only pay for running evaluations on the models you want to use."
                    secondary="Cost is dependent on the models and evaluations you select, so you can save money testing only what matters to you."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="No hidden costs."
                    secondary="You only pay the rate of the model, evaluation, and the cost of our infrastructure."
                  />
                </ListItem>
              </List>
            </Stack>
          </TextedIcon>

          <TextedIcon title="Detailed Reports">
            <Stack spacing={2}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Each evaluation in our database has a detailed report."
                    secondary="This report shows in-depth statistics, so you can select the best model for you and your buisness."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="See what each model struggled with the most."
                    secondary="Our reports contain a list of the lowest scored responses, so you can tell exactly what kind of questions are causing the model problems."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Your detailed report is included in your evaluation for free."
                    secondary="However, reports for previous evaluations can be purchased for a one time cost of $1.00 USD. Each evaluation report sold seperately."
                  />
                </ListItem>
              </List>
            </Stack>
          </TextedIcon>

          <TextedIcon title="EquiStamp Credits">
            <Stack spacing={2} alignItems="center">
              <List>
                <ListItem>
                  <ListItemText
                    primary="We offers credits so things run smoother."
                    secondary="Having to go through a checkout for each evaluation run or report purchase can be annoying. Your account can be loaded with credits to make it easier."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Credits enable you to easily use our API."
                    secondary="We have a RESTful API to integrate us with your business' automatic workflow."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Credits do not expire."
                    secondary="If you buy a credit today, it will stay on your account until you use it. One credit is exactly $1.00 USD."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="You can buy more credits at any time."
                    secondary={
                      <>
                        You can do so in your <ExternalA to="/user/profile" text="user profile" />.
                        During checkout you will have the option to specify how many credits you
                        want to buy. We set it to $20 USD by default, but it's totally up to you.
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Stack>
          </TextedIcon>
        </Stack>
      </Container>

      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{mt: 8, mb: 4}}
          width="100%"
        >
          <TextedIcon title="Subscriptions">
            <center>
              <NavButton to="/subscriptions" label="Sign Up Today!" />
            </center>
          </TextedIcon>
        </Stack>
        <Grid container justifyContent="space-evenly">
          <Grid xl>
            <TextedIcon title="Free Tier">
              <Stack spacing={2} alignItems="center">
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Human evaluation tests."
                      secondary="See how well you compare to AI models by taking tests yourself."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Access to LLM scores."
                      secondary="See how models scored against previous evaluations over time."
                    />
                  </ListItem>
                  {/*
                <ListItem>
                  <ListItemText
                    primary="Alert Subscription"
                    secondary="Get notified when a model scores above or below a threshold of your choosing."
                  />
                </ListItem>
                */}
                </List>
              </Stack>
            </TextedIcon>
          </Grid>

          <Grid xl>
            <TextedIcon title="Pro Tier">
              <Stack spacing={2} alignItems="center">
                <List>
                  {/*
                <ListItem>
                  <ListItemText
                    primary="Add more models."
                    secondary="Want to evaluate a model that we don't have? No problem! Tell us about your model and we'll add it for you."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Create your own evaluations."
                    secondary="Why evaluate models on our datasets when you can evaluate them on yours! This way you can be sure the model will work for your exact needs."
                  />
                </ListItem>
                */}
                  <ListItem>
                    <ListItemText
                      primary="Create your own evaluations and test your own models."
                      secondary="Why evaluate our models on our datasets when you can evaluate them on yours! Add any model and test it to be sure it will work for your exact needs."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Automatic round up."
                      secondary="If you're below $14 in credits when your subscription renews, we will top you back up to $14!"
                    />
                  </ListItem>
                </List>
              </Stack>
            </TextedIcon>
          </Grid>

          <Grid xl>
            <TextedIcon title="Enterprise Tier">
              <Stack spacing={2} alignItems="center">
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Private & custom LLM testing capabilities."
                      secondary="We will custom tailor your LLM testing experience to your exact needs."
                    />
                  </ListItem>
                  {/*
                <ListItem>
                  <ListItemText
                    primary="24/7 Jailbreak Alert System"
                    secondary="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse rhoncus augue ac mauris dapibus, ac venenatis turpis imperdiet."
                  />
                </ListItem>
                */}
                  <ListItem>
                    <ListItemText
                      primary="Custom tailored price and features"
                      secondary="Pay for exactly what you need, and nothing more. We'll work with you to make sure everything is perfect."
                    />
                  </ListItem>
                </List>
              </Stack>
            </TextedIcon>
          </Grid>
        </Grid>
        <Stack
          direction={{sm: 'column', md: 'row'}}
          spacing={12}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{mt: 8}}
        >
          <TextedIcon title="Have questions?">
            <ContactForm />
          </TextedIcon>
        </Stack>
      </Container>
    </Page>
  )
}

export default Pricing
