import {useState} from 'react'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import {RoundedButton} from './inputs'
import {ExternalA} from 'components/text'
import Path from 'routeLinks'

const INITIAL = 'initial'
const SENDING = 'sending'
const SENT = 'sent'
const FAILED = 'failed'

type Status = typeof INITIAL | typeof SENDING | typeof SENT | typeof FAILED

const ContactForm = () => {
  const [focused, setFocused] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<Status>(INITIAL)

  const sendMail = async () => {
    setStatus(SENDING)
    try {
      await makeApi().contact({
        text: message,
        to: 'info@equistamp.com',
        subject: 'Contact form message',
      })
      setStatus(SENT)
    } catch (e) {
      setStatus(FAILED)
    }
  }

  return (
    <Stack spacing={4} alignItems="center" sx={{pb: 5}}>
      <Typography align="center">
        Please use our contact form or join our{' '}
        <ExternalA to={Path.discord()} text="Discord server" />
        for any questions or feedback you have &ndash; we'd love to hear from you.
      </Typography>
      {status !== SENT ? (
        <TextField
          onFocus={() => setFocused(true)}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          multiline
          rows={focused ? 20 : 2}
          fullWidth
          sx={{'& .MuiInputBase-inputMultiline': {p: 2}}}
        />
      ) : undefined}
      {status === SENT && <Alert severity="success">Your message has been sent to our team</Alert>}
      {status === FAILED && <Alert severity="error">Error sending message</Alert>}
      {status !== SENT ? (
        <RoundedButton
          onClick={sendMail}
          loading={status === 'sending'}
          disabled={!message}
          label="Send message"
        />
      ) : (
        ''
      )}
    </Stack>
  )
}

export default ContactForm
