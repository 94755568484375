import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {makeApi, ServerError} from '@equistamp/api'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import {RoundedButton} from 'components/forms/inputs'

const Unsubscribe: React.FC = () => {
  const [status, setStatus] = useState('checking')
  const [searchParams] = useSearchParams()
  const {id} = useParams()
  const name = searchParams.get('name')

  useEffect(() => {
    const confirm = async () => {
      setStatus('loading')

      if (!id) {
        setStatus('unknown subscription token')
        return
      }

      try {
        await makeApi().subscriptions.remove({id, type: 'alert'} as any)
        setStatus('done')
      } catch (e) {
        setStatus(e instanceof ServerError ? e.error.toString() : `${e}`)
      }
    }
    if (status === 'init') {
      confirm()
    }
  }, [id, status, setStatus])

  const Status = () => {
    if (['init', 'loading'].includes(status)) {
      return (
        <Stack direction="row" spacing={4} alignItems="center">
          <Typography variant="h5">This should just take a moment...</Typography>
        </Stack>
      )
    } else if (status === 'done') {
      return (
        <Alert severity="success">
          You have been unsubscribed from {name ? `the "${name}"` : 'this'} alert
        </Alert>
      )
    } else if (status === 'checking') {
      return (
        <Stack spacing={4}>
          <Typography>
            Are you sure you want to unsubscribe from {name ? `the "${name}"` : 'this'} alert?
          </Typography>
          <RoundedButton
            label="Unsubscribe"
            onClick={() => setStatus('init')}
            sx={{width: 'fit-content'}}
          />
        </Stack>
      )
    }
    return <Alert severity="error">An error occured while unsubscribing: {status}</Alert>
  }

  return (
    <Page showTitle title={`Unsubscribe from ${name || 'alert'}`}>
      <Stack spacing={2} sx={{pt: 6}}>
        <Status />
      </Stack>
    </Page>
  )
}

export default Unsubscribe
