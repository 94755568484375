import React, {useState, useEffect} from 'react'
import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import Page from 'components/Page'
import AlertSummary from 'components/alerts/Summary'
import Subscribe from 'components/alerts/Subscribe'
import type {Alert as AlertType, Evaluation, Model} from '@equistamp/types'
import Path from 'routeLinks'

const ShowAlert = ({item: alert, setItem: setAlert}: RendererProps<AlertType>) => {
  const [allEvals, setEvaluations] = useState([] as Evaluation[])
  const [allModels, setModels] = useState([] as Model[])

  useEffect(() => {
    const getter = async () => {
      makeApi()
        .evaluations.list({filters: {fields: ['name']}})
        .then((r) => setEvaluations(r.items as Evaluation[]))
      makeApi()
        .models.list({filters: {fields: ['name']}})
        .then((r) => setModels(r.items as Model[]))
    }
    getter()
  }, [])

  const saveAlert = async (a: AlertType) => {
    const res = await makeApi().alerts.update(a)
    if (res === 'Alert updated') setAlert({...alert, ...a})
  }

  return (
    <Page
      title={alert.name}
      showTitle
      breadcrumbs={[{title: 'alerts', link: Path.alerts.all()}, {title: alert.name}]}
    >
      <Stack direction="column" spacing={7} sx={{m: 5}}>
        <AlertSummary
          alert={alert}
          updateAlert={saveAlert}
          allEvals={allEvals}
          allModels={allModels}
        />

        <Stack direction="row" justifyContent="center">
          <Subscribe alert={alert} sx={{width: 300, p: 1.8, fontSize: '1.5em', borderRadius: 40}} />
        </Stack>
      </Stack>
    </Page>
  )
}

const Alert: React.FC = () => <ItemLoader itemName="alert" renderer={ShowAlert} />
export default Alert
