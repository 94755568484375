import React from 'react'
import {redirect} from 'react-router-dom'
import {makeApi} from '@equistamp/api'
import useUser from 'hooks/useUser'
import Page from 'components/Page'
import ChangePasswordForm from 'components/forms/ChangePasswordForm'
import {User} from '@equistamp/types'
import Path from 'routeLinks'

export const setUser = (getUser: () => Promise<any>) => async (context: any) => {
  const url = new URL(context.request.url)
  const params = new URLSearchParams(url.search)
  const api = makeApi()

  const token = params.get('token')
  if (!token) {
    return redirect(Path.user.login(context.request.url))
  }
  if (token !== api.auth.getSessionId()) {
    const today = new Date()
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)

    await api.auth.setSession({
      session_token: token,
      token_expiration: nextWeek.toUTCString(),
    })
  }

  const user = await getUser()
  if (!user) {
    return redirect(Path.user.login(context.request.url))
  }

  return context
}

const ChangePassword: React.FC = () => {
  const {user} = useUser()

  const setPassword = async (password: string) => {
    const res = await makeApi().users.update({id: user?.id, password} as unknown as User)
    if (res !== 'User updated') return res
    return null
  }

  return (
    <Page title="Set password" showTitle>
      <ChangePasswordForm onSubmit={setPassword} />
    </Page>
  )
}

export default ChangePassword
