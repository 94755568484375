import {useState, useEffect, useCallback, createContext, useContext, ReactElement} from 'react'
import {makeApi, ServerError} from '@equistamp/api'
import type {LoginParams, User} from '@equistamp/types'
import {Permission, hasPermission as checkPermission} from 'permissions'

type useUserType = {
  user?: User | null
  getUser: () => Promise<User | null>
  register: (u: User) => Promise<string | null>
  login: (params: LoginParams) => Promise<string | null>
  logout: () => Promise<any>
  checkUser: (u: User) => Promise<null | string>
  getExternalUser: (params: LoginParams) => Promise<string | null>
  setUserField: (f: string, v: any) => Promise<User>
  isCurrentUser: (u: User) => boolean
  hasPermission: (p: Permission) => boolean
  loggedIn: boolean
  isAdmin: boolean
  canEdit: (item?: any) => boolean
}

let userFetched = false // a very crude method of avoiding multiple calls to the auth endpoint

export const useUserFuncs = (): useUserType => {
  const [loggedIn, setLoggedIn] = useState(makeApi().auth.isLoggedIn)
  const [user, setUserVal] = useState<User | null | undefined>()

  const getUser = useCallback(async (): Promise<User | null> => {
    const user = await makeApi().auth.me()
    setUserVal(user)
    userFetched = !!user
    if (!user) setLoggedIn(false)
    return user
  }, [])

  useEffect(() => {
    if (!userFetched) {
      userFetched = true
      getUser()
    }
  }, [getUser])

  const register = useCallback(async (user: User) => {
    try {
      setUserVal(await makeApi().users.register(user))
      return null
    } catch (e) {
      return e instanceof ServerError ? e.error.toString() : `${e}`
    }
  }, [])

  const logout = useCallback(async () => {
    await makeApi().auth.logout()
    setUserVal(null)
    setLoggedIn(false)
  }, [])

  const setUserField = useCallback(
    async (field: string, value: any) => {
      const updatedUser = {...user, [field]: value} as User
      await makeApi().users.update(updatedUser)
      setUserVal(updatedUser)
      return updatedUser
    },
    [user]
  )

  const login = useCallback(async (props: LoginParams) => {
    try {
      const user = await makeApi().auth.login(props)
      if (!user) return 'invalid credentials'
    } catch (e) {
      return 'Could not validate user'
    }
    setLoggedIn(true)
    return null
  }, [])

  const checkUser = useCallback(
    async (user: User) => {
      if (!user.password) return 'No password provided'
      const res = await login({login: user.user_name, password: user.password})
      if (!res) setUserVal(user)
      return res
    },
    [login]
  )

  const getExternalUser = useCallback(
    async (props: LoginParams) => {
      const res = await login(props)
      if (res) return res

      await getUser()
      return null
    },
    [login, getUser]
  )

  const isAdmin = user?.subscription_level === 'admin'

  const canEdit = (item: any) =>
    isAdmin ||
    (item.owner && isCurrentUser(item.owner)) ||
    ((user && user.admin_of) || []).includes(item.id)

  const hasPermission = (permission: Permission) => !!checkPermission(user, permission)

  const isCurrentUser = (currentUser: User) => !!(currentUser?.id && user?.id === currentUser?.id)

  return {
    user,
    register,
    login,
    logout,
    getUser,
    checkUser,
    getExternalUser,
    setUserField,
    isCurrentUser,
    hasPermission,
    loggedIn,
    isAdmin,
    canEdit,
  }
}

const UserContext = createContext<useUserType | null>(null)

export const UserProvider = ({children}: {children: ReactElement}) => {
  const user = useUserFuncs()

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export default useUser
