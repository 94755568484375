import React, {useEffect, useState} from 'react'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Page from 'components/Page'
import {makeApi, ServerError} from '@equistamp/api'
import {useParams} from 'react-router-dom'

const ConfirmSubscription: React.FC = () => {
  const {id} = useParams()
  const [status, setStatus] = useState('init')

  useEffect(() => {
    const confirm = async () => {
      setStatus('loading')

      if (!id) {
        setStatus('unknown subscription token')
        return
      }

      try {
        await makeApi().subscriptions.confirm(id)
        setStatus('done')
      } catch (e) {
        setStatus(e instanceof ServerError ? e.error.toString() : `${e}`)
      }
    }
    if (status === 'init') {
      confirm()
    }
  }, [id, status, setStatus])

  const Status = () => {
    if (['init', 'loading'].includes(status)) {
      return (
        <Stack direction="row" spacing={4} alignItems="center">
          <Typography variant="h5">This should just take a moment...</Typography>
        </Stack>
      )
    } else if (status === 'done') {
      return <Alert severity="success">Thank you for verifying your subscription</Alert>
    }
    return (
      <Alert severity="error">An error occured while verifying your subscription: {status}</Alert>
    )
  }

  return (
    <Page showTitle title="Confirming subscription">
      <Stack spacing={2} sx={{pt: 6}}>
        <Status />
      </Stack>
    </Page>
  )
}

export default ConfirmSubscription
