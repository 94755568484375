import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import {EditableText} from 'components/forms/inputs'
import {InfoItem} from 'components/text'
import type {User, TestTaken} from '@equistamp/types'
import type {UserInfoItemType, ChangeFunc} from './types'

const TaskTaken = ({task}: {task: TestTaken}) => (
  <Stack direction="row" spacing={2}>
    <DoneIcon />
    <Typography variant="subtitle2">{task.name}</Typography>
  </Stack>
)

const UserInfo = ({user, onChange}: {user: User; onChange?: ChangeFunc}) => {
  const showField = ({title, field, value}: UserInfoItemType) =>
    !!(
      // is this is set, then the component is editable and should show all fields
      (
        onChange ||
        // otherwise only show it if it has a value and has been set as visible (with a default of no)
        (value && user.display_options && user.display_options[field as string])
      )
    )

  const changer = (field: string) => (v: any) => onChange && onChange(field, v)

  return (
    <Stack direction="column" spacing={2}>
      {[
        {
          title: 'Full name',
          field: 'full_name',
          value: user.full_name,
          editable: true,
        },
        {
          title: 'Bio',
          field: 'bio',
          value: user.bio,
          editable: true,
          multiline: true,
          markdown: true,
        },
        {
          title: 'Email',
          field: 'email_address',
          value: user.email_address,
          editable: true,
        },
        {
          title: 'Tests taken',
          field: 'testsTaken',
          value: user?.tests_taken?.map((t) => <TaskTaken key={t.evaluation_id} task={t} />),
        },
        {
          title: 'Member since',
          field: 'join_date',
          value:
            user.join_date &&
            new Date(user.join_date).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }),
        },
        {
          title: 'Available credits',
          field: 'credits',
          value: `$${(user.credits || 0).toFixed(2) || 0.0}`,
        },
      ]
        .filter(showField)
        .map(({title, field, value, editable, multiline, markdown}, i) => (
          <InfoItem
            key={i}
            title={title}
            value={
              editable && onChange ? (
                <EditableText
                  value={value}
                  onChange={changer(field)}
                  variant="h5"
                  multiline={multiline}
                  markdown={markdown}
                />
              ) : (
                value
              )
            }
          />
        ))}
    </Stack>
  )
}

export default UserInfo
