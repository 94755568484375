import React, {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import {makeApi, ServerError} from '@equistamp/api'
import Page from 'components/Page'
import useModels from 'hooks/useModels'
import useEvaluations from 'hooks/useEvaluations'
import {Evaluation, Model, Task} from '@equistamp/types'
import Path from 'routeLinks'

type StatusType = 'init' | 'loading' | 'ok' | 'error'

const StatusIcon = ({status}: {status: StatusType}) => {
  switch (status) {
    case 'ok':
      return <CheckCircleIcon color="success" />
    case 'error':
      return <CancelIcon color="error" />
    case 'init':
    case 'loading':
      return <CircularProgress size={20} />
  }
}

const Status = ({id, name, task}: Model & {task: Task}) => {
  const [status, setStatus] = useState<StatusType>('init')
  const [error, setError] = useState('')

  useEffect(() => {
    const fetcher = async () => {
      try {
        await makeApi().tester.evaluateTask(id, task.id, {grader: '{"correctness" 1}'})
        setStatus('ok')
        setError('')
      } catch (e) {
        setStatus('error')
        setError(e instanceof ServerError ? e.error.toString() : `${e}`)
      }
    }
    if (status === 'init' && task?.id) {
      fetcher()
    }
  }, [id, status, setStatus, task.id])
  return (
    <Stack key={id} direction="row" spacing={2}>
      <StatusIcon status={status} />
      <Typography>{name}</Typography>
      <Typography color="red">{error}</Typography>
    </Stack>
  )
}

const ModelStatuses: React.FC = () => {
  const [task, setTask] = useState<Task>()
  const {models, refresh} = useModels()
  const {getItems} = useEvaluations()

  useEffect(() => {
    const fetcher = async () => {
      refresh({
        perPage: 'all',
        filters: {minScore: 60, fields: ['name', 'id', 'publisher', 'score', 'owner']},
      })

      // Get a task, any task, as the tester endpoint requires one
      const evals = await getItems({perPage: 1})
      if (!evals?.items[0]) return
      const {id} = evals?.items[0] as Evaluation

      const tasks = await makeApi().tasks.getTasks(id, {perPage: 1})
      if (!tasks?.items[0]) return
      setTask(tasks.items[0] as Task)
    }
    fetcher()
  }, [setTask, getItems, refresh])

  return (
    <Page
      title="Model Statuses"
      showTitle
      breadcrumbs={[{title: 'admin', link: Path.admin.index()}, {title: 'statuses'}]}
    >
      <Stack sx={{mt: 6}} spacing={2}>
        {task ? (
          models
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((m) => <Status key={m.id} task={task} {...m} />)
        ) : (
          <Typography>Fetching models...</Typography>
        )}
      </Stack>
    </Page>
  )
}

export default ModelStatuses
