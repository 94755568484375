import {useState, useEffect} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Chart from 'components/Chart'
import useEvaluationSessions from 'hooks/useEvaluationSessions'
import type {Eval} from '@equistamp/types'
import {SelectElem} from 'components/forms/inputs'

const PreviousResults = ({evaluatee_id, evaluation_id}: Eval) => {
  const {items, refresh} = useEvaluationSessions()
  const [metric, setMetric] = useState<keyof Eval>('score')

  useEffect(() => {
    refresh({
      filters: {
        evaluations: [{id: evaluation_id, name: ''}],
        models: [{id: evaluatee_id, name: ''}],
      },
    })
  }, [evaluatee_id, evaluation_id, refresh])

  return (
    <Stack>
      <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between">
        <Typography variant="h3">Past runs</Typography>
        <SelectElem
          value={metric}
          values={{
            score: 'Score',
            num_questions_answered: 'Number of questions answered',
            num_endpoint_calls: 'Number of model calls',
            num_endpoint_failures: 'Number of model call failures',
            min_seconds_per_task: 'Min seconds per task',
            median_seconds_per_task: 'Median seconds per task',
            max_seconds_per_task: 'Max seconds per task',
          }}
          onChange={setMetric}
          alwaysSelected
        />
      </Stack>
      <Chart
        datasets={[
          {
            key: metric,
            data: items.reduce(
              (acc, item) => ({...acc, [item.datetime_completed?.slice(0, 19) || '']: item}),
              {}
            ),
          },
        ]}
        mapper={(v: Eval) => (v && v[metric]) || null}
        labelsExtractor={(d) => Object.keys(d[0].data).map((date) => date.slice(0, 19))}
      />
    </Stack>
  )
}

export default PreviousResults
