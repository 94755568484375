import {SVGProps} from 'react'
const SvgPersonAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    stroke="#788492"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={4} r={2.5} strokeLinecap="square" strokeLinejoin="round" />
    <path d="M13 13.5a5 5 0 0 0-10 0" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
export default SvgPersonAlt
