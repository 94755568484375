import {stringToNumber} from 'components/filters/filters'
import {Model} from '@equistamp/types'

const extractValues = (obj: any, path: string[] = []): string[] => {
  if (Array.isArray(obj) && obj.some((i) => typeof i === 'object')) {
    return obj.map((item, i) => extractValues(item, [...path, i.toString()])).flat()
  } else if (Array.isArray(obj)) {
    const vals = obj.map(encodeURIComponent).join(':')
    return [`${encodeURIComponent(path.join('.'))}=${encodeURIComponent(vals)}`]
  } else if (typeof obj === 'object') {
    return Object.entries(obj)
      .map(([k, v]) => extractValues(v, [...path, k]))
      .flat()
  }
  return [`${encodeURIComponent(path.join('.'))}=${encodeURIComponent(obj)}`]
}

export const ObjectToParams = (obj: Object) => extractValues(obj).join('&')

const extractNext = (obj: any, key: number | string, nextKey?: string) => {
  if (obj[key] === undefined) {
    if (nextKey && Number.isInteger(parseFloat(nextKey))) return []
    return {}
  }
  return obj[key]
}

/* Insert `v` in `obj` at the path defined by keys.
 *
 * e.g.
 *
 *    const item = {a: [1, 2, {b: {c: 3}}]}
 *    assocIn(item, ['a', 2, 'b', d], 'bla')
 *
 *  will result in more or less the same as doing
 *
 *    item['a'][2]['b']['d'] = 'bla'
 *    return copy(item)
 *
 *  Missing items will automatically be created - if the key can be parsed as
 *  an integer, a list will be created, otherwise it will make an object, e.g.
 *
 *    assocIn({}, ["a", "b", 1, 2, "c"], 123) === {"a": {"b": [undefined, [undefined, undefined, {"c": 123}]]}}
 */
const assocIn = (obj: any, [k, ...keys]: string[], v?: any): any => {
  if (!k) return v

  const int = parseFloat(k)
  const index = Number.isInteger(int) ? int : k
  const next = extractNext(obj, index, keys[0])

  if (Array.isArray(obj)) {
    const item = [...obj]
    item[index as number] = assocIn(next, keys, v)
    return item
  } else if (typeof obj === 'object') {
    return {...obj, [index]: assocIn(next, keys, v)}
  } else {
    console.error('Can only assoc objects and arrays - got ' + obj)
  }
}

const parseVal = (v: string) => {
  const val = decodeURIComponent(v.trim())
  if (val.includes(':')) return val.split(':').map((v) => stringToNumber(decodeURIComponent(v)))
  if (parseFloat(val).toString() === val) return parseFloat(val)
  return val
}

export const ParamsToObject = (str: string) => {
  if (str === '' || !(str.includes('?') && str.includes('='))) return {}
  return (
    str
      .slice(1)
      .split('&') // split into param items
      .filter(Boolean) // remove any that are empty
      .map((item) => item.split('=')) // split each item into key and value pairs
      .filter(([k, v]) => k.trim() && v.trim()) // remove any items that have empty values or keys
      // insert each item into the end result
      .reduce((acc, [key, val]) => assocIn(acc, key.split('.'), parseVal(val)), {})
  )
}

export const filterModels = (models: Model[]) =>
  '?' + ObjectToParams({filters: {models: models.map((m) => ({id: m.id, name: m.name}))}})
