import React, {useEffect, useState, useCallback} from 'react'
import {NavLink, redirect} from 'react-router-dom'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import ItemLoader, {RendererProps} from 'components/ItemLoader'
import Page from 'components/Page'
import {TasksSummary, TestCard, TaskStats, TestCardWrapper} from 'components/tests'
import {RoundedButton, NavButton} from 'components/forms/inputs'
import type {Evaluation as EvaluationType, Task as TaskType, Eval} from '@equistamp/types'
import Path from 'routeLinks'

export const randomEvaluation = async () => {
  const {items: evals} = await makeApi().evaluations.list({})
  const chosen = evals[Math.floor(Math.random() * evals.length)] as EvaluationType
  return redirect(Path.evaluations.tests(chosen.id))
}

const timestamp = () => Math.floor(Date.now() / 1000)

const RenderTest = ({item: evaluation, setItem}: RendererProps<EvaluationType>) => {
  const [evalSession, setEvalSession] = useState<Eval | undefined>()
  const [tasks, setTasks] = useState<TaskType[]>([])
  const [currentTask, setCurrentTask] = useState(0)
  const [stats, setStats] = useState<TaskStats>({
    correct: 0,
    completed: 0,
    total: 0,
    secondsSpent: 0,
    questionStartedAt: timestamp(),
  })
  const [startedAt, setStartedAt] = useState(timestamp)

  const newTasks = useCallback(
    async (restart?: boolean) => {
      const evalSession = await makeApi().evaluationSessions.getHumanTasks(evaluation.id, restart)
      setEvalSession(evalSession)

      const {tasks, num_answered_correctly, num_questions_answered} = evalSession
      if (tasks) {
        setTasks(tasks)
        setCurrentTask(num_questions_answered)
        setStartedAt(Math.floor(Date.now() / 1000))
        setStats({
          correct: num_answered_correctly,
          completed: num_questions_answered,
          total: tasks.length,
          secondsSpent: 0,
          questionStartedAt: timestamp(),
        })
      }
    },
    [evaluation.id]
  )

  const restart = async () => newTasks(true)

  useEffect(() => {
    if (evaluation) newTasks()
  }, [newTasks, evaluation])

  const onSubmit = async (answer: any) => {
    const task = tasks && currentTask < tasks.length && tasks[currentTask]
    if (!task || !evalSession) return

    const result = await makeApi().tasks.checkAnswer({
      evaluation_session_id: evalSession.id,
      task_id: task.id,
      task_type: task.type,
      question: task.question,
      answer_id: answer.id,
      answer_text: answer.text,
      response_time_in_seconds: timestamp() - stats.questionStartedAt,
    })

    if (result) {
      setStats({
        ...stats,
        correct: result.correct ? stats.correct + 1 : stats.correct,
        completed: stats.completed + 1,
        secondsSpent: Math.floor(Date.now() / 1000) - startedAt,
        questionStartedAt: timestamp(),
      })
      setCurrentTask(currentTask + 1)
    }
  }

  const getPhase = () => {
    if (tasks.length === 0) return 'initialising'
    if (currentTask !== tasks.length) return 'testing'
    return 'completed'
  }
  const phase = getPhase()

  return (
    <Page
      fullWidth
      sx={{backgroundColor: 'primary.main'}}
      breadcrumbs={[
        {title: 'evaluations', link: Path.evaluations.all()},
        {title: evaluation.name, link: Path.evaluations.show(evaluation.id)},
        {title: 'tasks'},
      ]}
    >
      <Box sx={{width: '100%', pt: 2}}>
        <Container>
          <Typography variant="h4" sx={{color: 'primary.contrastText'}}>
            Human evaluation test
          </Typography>
          <Breadcrumbs>
            <Link component={NavLink} underline="hover" color="inherit" to={Path.evaluations.all()}>
              evaluations
            </Link>
            <Link
              component={NavLink}
              underline="hover"
              color="inherit"
              to={Path.evaluations.show(evaluation.id)}
            >
              {evaluation.name}
            </Link>
            <Typography color="text.primary">Human test</Typography>
          </Breadcrumbs>

          {phase === 'initialising' && (
            <TestCardWrapper stats={stats}>
              <Typography align="center">Loading...</Typography>
            </TestCardWrapper>
          )}
          {phase === 'testing' && (
            <TestCard
              title={evaluation?.name}
              task={tasks[currentTask]}
              stats={stats}
              onSubmit={onSubmit}
              onRestart={restart}
            />
          )}
          {phase === 'completed' && (
            <>
              <TasksSummary evaluation={evaluation} stats={stats} />
              <Stack
                direction={{xs: 'column', sm: 'row'}}
                justifyContent="flex-end"
                spacing={2}
                sx={{mt: 2}}
              >
                <RoundedButton
                  label="Start Over"
                  sx={{backgroundColor: 'primary.light'}}
                  onClick={restart}
                />
                <NavButton
                  label="Take Another"
                  sx={{backgroundColor: 'primary.light'}}
                  to={Path.evaluations.all()}
                />
              </Stack>
            </>
          )}
        </Container>
      </Box>
    </Page>
  )
}

const Test: React.FC = () => <ItemLoader itemName="evaluation" renderer={RenderTest} />
export default Test
