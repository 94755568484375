import {useEffect, useState} from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {makeApi, ServerError} from '@equistamp/api'
import {MULTIPLE_CHOICE_QUESTION} from '@equistamp/constants'
import type {
  QuestionType,
  CodeStage,
  TaskType,
  DSLCode,
  AcceptableOverrides,
} from '@equistamp/types'
import {taskTypes} from 'components/filters/constants'
import {parseEDN} from 'components/formatters'
import {DEFAULT, testVariables} from './defaults'
import EditVariable, {Variable} from './Variable'

type TestVariablesEditProps = {
  label: string
  variables: Variable[]
  defaultExpanded?: boolean
  onChange: (name: string, val: any) => void
}
const TestVariablesEdit = ({
  label,
  variables,
  defaultExpanded,
  onChange,
}: TestVariablesEditProps) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>{label}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Stack>
        {variables.map((variable) => (
          <EditVariable
            key={variable.name}
            variable={variable}
            onChange={(val: any) => onChange(variable.name, val)}
          />
        ))}
      </Stack>
    </AccordionDetails>
  </Accordion>
)

const FormattedCode = ({code, stage}: {code: string; stage: CodeStage}) => {
  const parsed = parseEDN(code)
  if (['prompt', 'system_prompt'].includes(stage) && typeof parsed === 'string') {
    return (
      <Stack spacing={1}>
        {parsed.split('\n').map((line, i) => (
          <Typography key={line + i} component="code">
            {line || ' '}
          </Typography>
        ))}
      </Stack>
    )
  }
  return <Typography component="code">{code}</Typography>
}

const stageTitles = {
  system_prompt: 'System prompt',
  prompt: 'Prompt',
  grader: 'Grader result',
  request: 'Response',
  response: 'Parsed response',
}

type TestCodeProps = {
  stage: CodeStage
  taskType: QuestionType
  code: DSLCode
  onTest: (passed: boolean) => void
}
const Tester = ({stage, taskType, code, onTest}: TestCodeProps) => {
  const [result, setResult] = useState('')
  const [error, setError] = useState('')
  const [variables] = useState(testVariables[stage as keyof typeof testVariables])
  const [values, setValues] = useState(
    variables[DEFAULT].reduce((acc, v) => ({...acc, [v.name]: v.default}), {
      'task-type': taskType === DEFAULT ? MULTIPLE_CHOICE_QUESTION : taskType,
    }) as AcceptableOverrides
  )

  const update = (name: string, val: any) => {
    setValues((current) => ({...current, [name]: val}))
  }

  useEffect(() => {
    const runTest = async () => {
      try {
        const res = await makeApi().tester.testDSL({...values, code: code || '', stage})
        setError('')
        setResult(res)
        onTest(true)
      } catch (e) {
        setError(e instanceof ServerError ? e.error.toString() : `${e}`)
        setResult('')
        onTest(false)
      }
    }
    runTest()
  }, [values, code, onTest, stage])

  return (
    <Stack direction="row" spacing={2}>
      <Stack sx={{minWidth: '280px'}}>
        <Typography variant="h4">DSL Context</Typography>
        {taskType === DEFAULT && (
          <FormControl>
            <FormLabel>Task type</FormLabel>
            <RadioGroup
              defaultValue={MULTIPLE_CHOICE_QUESTION}
              name="task-type-buttons-group"
              onChange={(e) =>
                setValues((current) => ({...current, 'task-type': e.target.value as TaskType}))
              }
            >
              {Object.entries(taskTypes).map(([t, label]) => (
                <FormControlLabel key={t} value={t} control={<Radio />} label={label} />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        <TestVariablesEdit
          label="Common variables"
          onChange={update}
          variables={variables[DEFAULT]}
        />
        {Object.entries(taskTypes)
          .filter(([t]) => taskType === DEFAULT || t === taskType)
          .map(([taskType, label]) => (
            <TestVariablesEdit
              key={taskType}
              label={`${label} variables`}
              onChange={update}
              variables={variables[taskType as QuestionType]}
            />
          ))}
      </Stack>
      <Stack>
        <Typography variant="h4">{stageTitles[stage]}:</Typography>
        {result ? (
          <FormattedCode code={result} stage={stage} />
        ) : (
          <Typography color="red">{error}</Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default Tester
