import {paginate, searchText} from './filters'
import {sortItems} from './transformers'
import {FiltersContainer} from './widgets'
import type {FiltersProps} from './types'
import type {Tag, FilterConfig, SearchResult} from '@equistamp/types'

export const filterTags = (tags: Tag[], params: FilterConfig): SearchResult =>
  paginate(
    sortItems(searchText(tags, params.filters?.search || ''), params),
    params.page || 0,
    !params.perPage || params.perPage === 'all' ? 20 : params.perPage
  )

const TagFilters = ({config, onChange}: FiltersProps) => {
  return <FiltersContainer> </FiltersContainer>
}

export default TagFilters
