import Stack from '@mui/material/Stack'
import {SelectElem} from 'components/forms/inputs'
import {OptionsGroup} from 'components/filters/widgets'
import {
  TYPE_COLUMN,
  QUESTION_COLUMN,
  PARAPHRASE,
  REDACTED_COLUMN,
  BOOL_CORRECT_COLUMN,
  MCQ_CORRECT_COLUMN,
  MCQ_INCORRECT_COLUMN,
  FRQ_CORRECT_COLUMN,
  FRQ_INCORRECT_COLUMN,
  JSON_SCHEMA,
  JSON_EXPECTED,
  MULTIPLE_CHOICE_QUESTION,
  FREE_RESPONSE_QUESTION,
  BOOLEAN_QUESTION,
  JSON_QUESTION,
} from '@equistamp/constants'
import type {ColumnType, ColumnConfig, ColumnsConfig} from '@equistamp/types'
import type {EvaluationEditProps} from './types'

export const paraphraseable = [
  QUESTION_COLUMN,
  FRQ_CORRECT_COLUMN,
  FRQ_INCORRECT_COLUMN,
  MCQ_CORRECT_COLUMN,
  MCQ_INCORRECT_COLUMN,
]
const defaultCols = {
  [REDACTED_COLUMN]: 'Marking a row as redacted',
  [QUESTION_COLUMN]: 'The question to be answered',
  [PARAPHRASE]: 'A paraphrase of an other column',
}
const typeSpecificColums = {
  [MULTIPLE_CHOICE_QUESTION]: {
    ...defaultCols,
    [MCQ_CORRECT_COLUMN]: 'A correct answer',
    [MCQ_INCORRECT_COLUMN]: 'An incorrect answer',
  },
  [FREE_RESPONSE_QUESTION]: {
    ...defaultCols,
    [FRQ_CORRECT_COLUMN]: 'A correct answer',
    [FRQ_INCORRECT_COLUMN]: 'An incorrect answer',
  },
  [BOOLEAN_QUESTION]: {
    ...defaultCols,
    [BOOL_CORRECT_COLUMN]: 'True/false',
  },
  [JSON_QUESTION]: {
    ...defaultCols,
    [JSON_SCHEMA]: 'A JSON schema',
    [JSON_EXPECTED]: 'An expected JSON object',
  },
  mixed: {
    ...defaultCols,
    [TYPE_COLUMN]: 'Task type',
    [BOOL_CORRECT_COLUMN]: 'Boolean: true/false',
    [MCQ_CORRECT_COLUMN]: 'Multiple choice: correct answer',
    [MCQ_INCORRECT_COLUMN]: 'Multiple choice: incorrect answer',
    [FRQ_CORRECT_COLUMN]: 'Free response: correct answer',
    [FRQ_INCORRECT_COLUMN]: 'Free response: incorrect answer',
    [JSON_SCHEMA]: 'JSON: Schema',
    [JSON_EXPECTED]: 'JSON: Expected',
  },
}

type ColumnProps = {
  column: string
  columnNum: number
  config: ColumnConfig
  columns: ColumnsConfig
  columnTypes: {[k: string]: string}
  onChange: (columns: ColumnsConfig) => void
}
const Column = ({column, columnNum, config, columns, onChange, columnTypes}: ColumnProps) => {
  const changeType = (val: ColumnType) => {
    if ([TYPE_COLUMN, QUESTION_COLUMN].includes(val)) {
      Object.values(columns)
        .filter(({columnType}) => columnType === val)
        .forEach((conf) => {
          conf.columnType = undefined
        })
    }
    onChange({...columns, [column]: {...config, columnType: val}})
  }
  const setParaphrase = (val: string) => {
    const {paraphrases} = columns[val]
    columns[val].paraphrases = [...(paraphrases || []), column]
    columns[column].paraphraseOf = val
    onChange({...columns})
  }

  return (
    <OptionsGroup label={`Interpret column ${columnNum}, titled "${column}" as:`}>
      <Stack
        direction={{sm: 'column', md: 'row'}}
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
      >
        <SelectElem
          values={columnTypes}
          value={columnTypes[config.columnType || ''] ? config.columnType : ''}
          onChange={changeType}
          unselected="Ignored"
          sx={{width: '100%'}}
        />
        {config.columnType !== PARAPHRASE ? null : (
          <SelectElem
            label="Paraphrase of"
            values={Object.entries(columns).reduce(
              (acc, [col, {columnType}]) =>
                paraphraseable.includes(columnType || '') ? {...acc, [col]: col} : acc,
              {}
            )}
            value={config.paraphraseOf || ''}
            onChange={setParaphrase}
          />
        )}
      </Stack>
    </OptionsGroup>
  )
}

const ConfigureColumns = ({evaluation, onChange}: EvaluationEditProps) => {
  const columns = evaluation.columns_mapping

  const updateConfig = (config: ColumnsConfig) => {
    onChange('columns_mapping', config)
  }

  return (
    <Stack spacing={2}>
      {columns &&
        Object.entries(columns).map(([col, config], columnNum) => (
          <Column
            key={col}
            column={col}
            columnNum={columnNum + 1}
            config={config}
            columns={columns}
            columnTypes={typeSpecificColums[evaluation.default_task_type || 'mixed']}
            onChange={updateConfig}
          />
        ))}
    </Stack>
  )
}

export default ConfigureColumns
