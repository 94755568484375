import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

function Footer() {
  return (
    <AppBar position="static" color="primary" enableColorOnDark component="footer" elevation={0}>
      <Toolbar sx={{mt: 3}}>
        <Container>
          <img src="/logoWhite.png" alt="EquiStamp" width="226px" height="45px" />
          <Typography variant="subtitle1" color="inherit">
            © {new Date().getFullYear()} EquiStamp. All rights reserved
          </Typography>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

export default Footer
