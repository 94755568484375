import {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {Input, ButtonGroup} from 'components/forms/inputs'
import {
  alertTriggerAvailabilityMetrics,
  alertTriggerModelChanges,
  alertTriggerStabilityMetrics,
  alertTriggerThresholdMetrics,
  alertTriggerTopMetrics,
  alertTriggerTypes,
} from 'components/filters/constants'
import type {Trigger, TriggerMetric, TriggerTypes} from '@equistamp/types'
import type {AlertStageProps} from './types'
import {
  ACCURACY,
  MODEL_AVAILABILITY,
  MODEL_CHANGE,
  MODEL_RANK_CHANGE,
  NEW_EVALUATION,
  NEW_MODEL,
  SCORE,
  EVAL_SCORE_THRESHOLD,
  TOP_MODEL,
  MODEL_STABILITY,
} from '@equistamp/constants'
import FilterItems from './FilterItems'

const docsToItems = (items: {[k: string]: {title: string}}) =>
  Object.entries(items).reduce((acc, [k, i]) => ({...acc, [k]: i.title}), {})

type ThresholdProps = {
  editThreshold?: boolean
}
type MetricsProps = {
  availableMetrics?: {[k: string]: string}
  multiple?: boolean
  metricsTitle?: string
}
type FilterProps = {
  modelFilterTitle?: string
  evaluationFilterTitle?: string
  modelMetrics?: TriggerMetric[] // These are metrics for which models can be chosen. All if undefined
  evaluationMetrics?: TriggerMetric[] // These are metrics for which evaluations can be chosen. All if undefined
}
type TriggerDetailsProps = Trigger & {
  title?: string
  onChange: (field: keyof Trigger, v: any) => void
} & MetricsProps &
  FilterProps &
  ThresholdProps

const ChooseMetric = ({
  availableMetrics,
  metricsTitle,
  multiple,
  onChange,
  metric,
}: TriggerDetailsProps) => {
  if (!availableMetrics) return null

  const selected = multiple ? metric?.split(',') : metric

  return (
    <Stack>
      <Typography variant="h4">{metricsTitle || 'What metric do you want to test?'}</Typography>
      <ButtonGroup
        perRow={Math.min(4, Object.keys(availableMetrics).length)}
        selected={selected}
        onSelect={(selected?: string | string[]) =>
          onChange('metric', Array.isArray(selected) ? selected?.join(',') : selected)
        }
        items={Object.entries(availableMetrics).map(([id, label]) => ({id, label}))}
      />
    </Stack>
  )
}

const ChooseThreshold = ({threshold: initialThreshold, onChange}: TriggerDetailsProps) => {
  const [threshold, setThreshold] = useState<string | undefined>(initialThreshold?.toString() || '')

  const onThreshold = (v: string) => {
    if (!v) {
      setThreshold(v)
      onChange('threshold', undefined)
    }

    const num = parseFloat(v)
    if (num > 100 || num < 0) return

    if (!isNaN(num)) {
      setThreshold(v)
      onChange('threshold', num)
    }
  }

  return (
    <Stack spacing={4} justifyContent="space-around">
      <Typography variant="h4">At what value should the alert trigger?</Typography>
      <Input
        setter={onThreshold}
        value={threshold}
        type="number"
        sx={{width: 200}}
        min={0}
        max={100}
        required={true}
      />
    </Stack>
  )
}

const FilterMetric = ({
  modelFilterTitle,
  evaluationFilterTitle,
  modelMetrics,
  evaluationMetrics,
  editThreshold,
  ...props
}: TriggerDetailsProps) => (
  <Stack spacing={4} justifyContent="space-around">
    <ChooseMetric {...props} />
    {editThreshold && <ChooseThreshold {...props} />}
    {(!modelMetrics || (props.metric && modelMetrics.includes(props.metric))) && (
      <FilterItems {...props} title={modelFilterTitle} itemType="model" />
    )}
    {(!evaluationMetrics || (props.metric && evaluationMetrics.includes(props.metric))) && (
      <FilterItems {...props} title={evaluationFilterTitle} itemType="evaluation" />
    )}
  </Stack>
)

const NewModel = (props: TriggerDetailsProps) => (
  <Stack spacing={4} justifyContent="space-around">
    <FilterItems
      title="Which new models should be checked?"
      itemType="model"
      skippedFilterTypes={['selected', 'from_filter']}
      {...props}
    />
  </Stack>
)

const NewEvaluation = (props: TriggerDetailsProps) => (
  <Stack spacing={4} justifyContent="space-around"></Stack>
)

const TriggerFilters = (props: TriggerDetailsProps) => {
  switch (props.type) {
    case TOP_MODEL:
    case MODEL_RANK_CHANGE:
      return (
        <FilterMetric
          availableMetrics={docsToItems(alertTriggerTopMetrics)}
          evaluationMetrics={[ACCURACY]}
          {...props}
        />
      )
    case MODEL_CHANGE:
      return (
        <FilterMetric
          metricsTitle="What model changes do you want to check?"
          availableMetrics={docsToItems(alertTriggerModelChanges)}
          evaluationMetrics={[]}
          multiple
          {...props}
        />
      )
    case NEW_MODEL:
      return <NewModel {...props} />
    case MODEL_AVAILABILITY:
      return (
        <FilterMetric
          evaluationMetrics={[]}
          metricsTitle="Do you want to be notified when model stability goes below or above the threshold?"
          availableMetrics={docsToItems(alertTriggerAvailabilityMetrics)}
          {...props}
        />
      )
    case MODEL_STABILITY:
      return (
        <FilterMetric
          editThreshold
          evaluationMetrics={[]}
          metricsTitle="Do you want to be notified when model stability goes below or above the threshold?"
          availableMetrics={docsToItems(alertTriggerStabilityMetrics)}
          {...props}
        />
      )
    case EVAL_SCORE_THRESHOLD:
      return (
        <FilterMetric
          editThreshold
          metricsTitle="How should the threshold be compared?"
          availableMetrics={docsToItems(alertTriggerThresholdMetrics)}
          {...props}
        />
      )
    case NEW_EVALUATION:
      return <NewEvaluation {...props} />
    default:
      return null
  }
}

const emptyTrigger = (triggerType: TriggerTypes): Trigger => {
  switch (triggerType) {
    case TOP_MODEL:
    case MODEL_RANK_CHANGE:
      return {metric: SCORE, type: triggerType}
    default:
      return {type: triggerType}
  }
}

const emptyChosen = Object.keys(alertTriggerTypes).reduce(
  (acc, t) => ({...acc, [t]: emptyTrigger(t as TriggerTypes)}),
  {}
) as {[k: string]: Trigger}

type ChooseTriggerProps = AlertStageProps & {trigger: Trigger; triggerChanged: (t: Trigger) => void}
const ChooseTrigger = ({trigger, triggerChanged, enableNext, index}: ChooseTriggerProps) => {
  const [chosen, setChosen] = useState(emptyChosen)
  const [selected, setSelected] = useState<string | undefined>(trigger?.type)

  useEffect(() => {
    enableNext(!!selected)
  }, [index, enableNext, selected])

  const handleChange = (selected?: string | string[]) => {
    setSelected(selected as string)
    triggerChanged(selected ? chosen[selected as string] : emptyTrigger(selected as TriggerTypes))
  }

  const handleFiltersChange = (field: keyof Trigger, v: any) => {
    const updated = {...trigger, [field]: v}
    setChosen((current) => ({...current, [trigger.type]: updated}))
    triggerChanged(updated)
  }

  return (
    <Stack spacing={4} sx={{pb: 4}}>
      <ButtonGroup
        perRow={4}
        selected={selected}
        onSelect={handleChange}
        items={Object.entries(alertTriggerTypes).map(([id, label]) => ({id, label}))}
      />
      {selected && <TriggerFilters {...trigger} onChange={handleFiltersChange} />}
    </Stack>
  )
}

export default ChooseTrigger
