import React, {useEffect, useState} from 'react'
import {useLocation, NavLink} from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {GitHub as GitHubIcon} from '@mui/icons-material'
import useUser from 'hooks/useUser'
import Page from 'components/Page'
import LoginForm from 'components/forms/LoginForm'
import Path from 'routeLinks'

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const GITHUB_CLIENT_ID = process.env.REACT_APP_GITHUB_CLIENT_ID

type SignInProps = {
  redirectUrl?: string | null
}
const GoogleSignInButton = ({redirectUrl}: SignInProps) => {
  const [state, setState] = useState('initial')
  const {getExternalUser} = useUser()

  useEffect(() => {
    const onLogin = async (response: any) => {
      await getExternalUser({...response, external_auth: 'google'})
      window.location.href = redirectUrl || Path.home()
    }

    const initializeGoogleSignIn = () => {
      const google = (window as any).google
      if (google && google.accounts) {
        google.accounts.id.initialize({
          client_id: GOOGLE_CLIENT_ID,
          callback: onLogin,
        })
        google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
          theme: 'outline',
          size: 'large',
        })
        setState('initialized')
      }
    }

    if (state === 'initial') {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.onload = () => {
        setState('loaded')
      }
      document.body.appendChild(script)
    } else if (state === 'loaded') {
      initializeGoogleSignIn()
    }
  }, [state, getExternalUser, redirectUrl])

  return <Box id="google-signin-button" sx={{width: '100%'}}></Box>
}

const GithubSignInButton = ({redirectUrl}: SignInProps) => {
  let redirect_uri = redirectUrl
  if (redirect_uri && !redirect_uri.startsWith(window.location.origin)) {
    redirect_uri = window.location.origin + redirect_uri
  }
  const params = new URLSearchParams({
    scope: 'user:email',
    client_id: GITHUB_CLIENT_ID || '',
    redirect_uri: redirectUrl
      ? window.location.origin + encodeURI(Path.login.github(redirectUrl))
      : '',
  })
  const loginUrl = `https://github.com/login/oauth/authorize?${params}`
  return (
    <NavLink to={loginUrl} style={{width: '100%'}}>
      <Button
        variant="contained"
        startIcon={<GitHubIcon />}
        sx={{
          textTransform: 'none',
          backgroundColor: 'black',
          color: 'white',
          '&:hover': {
            backgroundColor: 'grey',
          },
          width: '100%',
        }}
      >
        Login with GitHub
      </Button>
    </NavLink>
  )
}

const Login: React.FC = () => {
  const {checkUser} = useUser()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const redirectUrl = searchParams.get('redirect')

  return (
    <Page title="Log in" showTitle>
      <Stack justifyContent="center" alignItems="center" spacing={1}>
        <LoginForm onSubmit={checkUser} />
        <Stack spacing={1} sx={{maxWidth: '350px'}}>
          <GoogleSignInButton redirectUrl={redirectUrl} />
          <GithubSignInButton redirectUrl={redirectUrl} />
        </Stack>
        <Typography sx={{pt: 3}}>
          Don't have an account? &nbsp; <NavLink to={Path.user.register()}>Sign up here</NavLink>
        </Typography>
        <Typography>
          Forgot your password? &nbsp;{' '}
          <NavLink to={Path.user.forgotPassword()}>Reset it here</NavLink>
        </Typography>
      </Stack>
    </Page>
  )
}

export default Login
