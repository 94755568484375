import {NavLink} from 'react-router-dom'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {styled} from '@mui/material/styles'
import {makeApi} from '@equistamp/api'
import {ActionButton, NavButton} from 'components/forms/inputs'
import {formatDuration, parseDate} from 'components/formatters'
import type {Eval} from '@equistamp/types'
import Path from 'routeLinks'

export const RunCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.body}`]: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    /* fontSize: 'larger', */
  },
  [`&.${tableCellClasses.body}`]: {
    border: 0,
  },
}))

const Link = ({to, label}: {to: string; label: string}) => (
  <Button
    component={NavLink}
    to={to}
    sx={{
      width: 'fit-content',
      textTransform: 'inherit',
      fontWeight: 700,
      fontSize: 'larger',
    }}
  >
    {label}
  </Button>
)

const columnDefs = {
  model: {
    label: 'Model',
    align: 'left',
    formatter: (e: Eval) => <Link to={Path.models.show(e.evaluatee_id)} label={e.evaluatee_name} />,
  },
  evaluation: {
    label: 'Evaluation',
    align: 'left',
    formatter: (e: Eval) => (
      <Link to={Path.evaluations.show(e.evaluation_id)} label={e.evaluation_name} />
    ),
  },
  date_created: {
    label: 'Creation Date',
    formatter: (e: Eval) => parseDate(e.datetime_started)?.toString().slice(0, 19),
  },
  date_completed: {
    label: 'Date Run',
    formatter: (e: Eval) => parseDate(e.datetime_completed)?.toString().slice(0, 10),
  },
  run_time: {
    label: 'Run Time',
    formatter: (e: Eval) =>
      e.datetime_started &&
      formatDuration(
        (((e.datetime_completed ? parseDate(e.datetime_completed) : new Date()) as any) -
          (parseDate(e.datetime_started) as any)) /
          1000
      ),
  },
  tasks_completed: {
    label: 'Tasks completed',
    formatter: (e: Eval) => `${e.num_questions_answered}/${e.num_tasks_to_complete || '??'}`,
  },
  score: {
    label: 'Score',
    formatter: (e: Eval) => e.score?.toFixed(1),
  },
  price: {
    label: 'Cost to Run',
    formatter: (e: Eval) => e.price && `$${Math.max(1, (e.price || 0) / 100).toFixed(2)}`,
  },
  real_cost: {
    label: 'Estimated Cost',
    formatter: (e: Eval) =>
      e.estimated_session_cost_usd && `$${e.estimated_session_cost_usd?.toFixed(2)}`,
  },
  actions: {
    label: 'Actions',
    formatter: (e: Eval) => (
      <Stack direction="column">
        <NavButton to={Path.evaluationSessions.show(e.id)} label="Report" />
        {!e.completed && (
          <ActionButton
            label="Cancel"
            confirmationText="Are you sure you want to cancel this run?"
            action={async () => makeApi().evaluationSessions.remove(e)}
          />
        )}
      </Stack>
    ),
  },
} as {
  [k: string]: {
    label: string
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined
    formatter: (e: Eval) => any
  }
}

export type Column = keyof typeof columnDefs
const availableColumns = [
  'evaluation',
  'model',
  'date_created',
  'date_completed',
  'run_time',
  'tasks_completed',
  'score',
  'price',
  'real_cost',
  'actions',
] as Column[]

const EvalRuns = ({evals, columns}: {evals?: Eval[]; columns: Column[]}) => {
  const cols = availableColumns
    .filter((c) => columns.includes(c))
    .map((coll: Column) => columnDefs[coll])
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {cols.map(({label, align}) => (
              <RunCell key={label} align={align}>
                {label}
              </RunCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {evals?.map((e) => (
            <TableRow key={e.id}>
              {cols.map(({align, formatter}) => (
                <RunCell key={e.id + formatter.toString()} component="td" align={align}>
                  {formatter(e)}
                </RunCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default EvalRuns
