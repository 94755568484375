import {ReactNode} from 'react'
import Typography from '@mui/material/Typography'
import {
  formatLatency,
  formatPrice,
  formatInteger,
  formatScore,
  formatPercentage,
} from 'components/formatters'
import {ExternalA} from 'components/text'
import {ScoreModel} from './ScoresTable'
import Path from 'routeLinks'
import {ItemType, Direction} from '@equistamp/types'

export const minMaxTypes = ['percentage', 'number', 'price', 'latency']
export type ColumnType = 'percentage' | 'number' | 'price' | 'latency'
export type Column = {
  id: string
  name: string
  hint?: string | ReactNode
  formatter?: (val: any, m?: ScoreModel) => string | number | undefined
  urlGetter?: (col: Column, m: ScoreModel) => string | undefined // gets the url for a specific cell
  columnUrl?: string // an optional url to the page of this column
  group?: string
  type?: ItemType
  filterType?: ColumnType
  minValue?: number
  maxValue?: number
  step?: number
  filters?: {
    min?: number
    max?: number
  }
  sortDir?: Direction
  onFilter?: (min?: number, max?: number) => void
  onSort?: () => void
  onHover?: (col: Column, m: ScoreModel) => ReactNode
}

export const modelColumns = {
  cost_per_1M_input_tokens_usd: {
    id: 'cost_per_1M_input_tokens_usd',
    name: 'Input Cost per 1M tokens',
    formatter: formatPrice,
    group: 'Price',
    filterType: 'price',
    step: 0.01,
  },
  cost_per_1M_output_tokens_usd: {
    id: 'cost_per_1M_output_tokens_usd',
    name: 'Output Cost per 1M tokens',
    formatter: formatPrice,
    group: 'Price',
    filterType: 'price',
    step: 0.01,
  },
  /*   median_accuracy: {
   *   id: 'median_accuracy',
   *   name: 'Median accuracy',
   *   group: 'Accuracy',
   *   formatter: formatPercentage,
   * },
   * avg_accuracy: {
   *   id: 'avg_accuracy',
   *   name: 'Average accuracy',
   *   group: 'Accuracy',
   *   formatter: formatPercentage,
   * },
   * min_accuracy: {
   *   id: 'min_accuracy',
   *   name: 'Min accuracy',
   *   group: 'Accuracy',
   *   formatter: formatPercentage,
   * },
   * max_accuracy: {
   *   id: 'max_accuracy',
   *   name: 'Max accuracy',
   *   group: 'Accuracy',
   *   formatter: formatPercentage,
   * }, */
  availability: {
    id: 'availability',
    name: 'Availability',
    formatter: formatPercentage,
    group: 'Availability',
    filterType: 'percentage',
  },
  min_latency: {
    id: 'min_latency',
    name: 'Min latency',
    formatter: formatLatency,
    group: 'Latency',
    filterType: 'latency',
  },
  max_latency: {
    id: 'max_latency',
    name: 'Max latency',
    formatter: formatLatency,
    group: 'Latency',
    filterType: 'latency',
  },
  avg_latency: {
    id: 'avg_latency',
    name: 'Average latency',
    formatter: formatLatency,
    group: 'Latency',
    filterType: 'latency',
  },
  median_latency: {
    id: 'median_latency',
    name: 'Median latency',
    formatter: formatLatency,
    group: 'Latency',
    filterType: 'latency',
  },
} as {[k: string]: Column}

export const generalColumns = [
  {
    id: 'score',
    name: 'Overall',
    hint: (
      <Typography>
        The overall Equistamp capability score is created by us, using an algorithm that averages
        trustworthy evaluations while specifically avoiding common data-consolidation errors that
        can skew or bias results.{' '}
        <ExternalA to="/docs/models/#score" text="Learn more about our algorithm." />
      </Typography>
    ),
    group: 'General information',
    type: 'evaluation',
    filterType: 'number',
    formatter: formatScore,
    urlGetter: (col: Column, s: ScoreModel) => Path.models.runs(s.id),
  },
  {
    id: 'elo_score',
    name: 'Elo',
    formatter: formatInteger,
    group: 'General information',
    type: 'evaluation',
    filterType: 'number',
    maxValue: 5000,
    hint: (
      <Typography>
        Elo represents an overall capability score derived from the LLM leaderboard tool at{' '}
        <ExternalA to="https://chat.lmsys.org/" text="lmsys.org." />
        We consider the Elo score from LYMSYS to be reliable due to its thoroughly scrutinized and
        crowd-sourced data collection methods.
      </Typography>
    ),
  },
] as Column[]
