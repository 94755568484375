import Stack from '@mui/material/Stack'
import type {
  Alert,
  Evaluation as EvalType,
  Model as ModelType,
  FilterConfig,
  SearchResult,
} from '@equistamp/types'
import useModels from 'hooks/useModels'
import useEvaluations from 'hooks/useEvaluations'
import type {FiltersProps} from './types'
import {alertCooldowns} from './constants'
import {filterChangeHandlers, sortItems} from './transformers'
import {
  FiltersContainer,
  OptionsGroup,
  FilterAutocomplete,
  CheckboxGroup,
  DatesPicker,
} from './widgets'
import {
  allOf,
  anyOf,
  equals,
  filterBetween,
  filterChildren,
  filterContains,
  filterItems,
  isEmpty,
  paginate,
  searchText,
} from './filters'
import useUser from 'hooks/useUser'

export const filterAlerts = (alerts: Alert[], params: FilterConfig): SearchResult => {
  let filtered = searchText(alerts, params.filters?.search || '', ['name', 'description']).filter(
    allOf([
      equals(params?.filters?.owner_id, ['owner', 'id']),
      equals(params?.filters?.subscribed, 'subscribed'),
      filterContains(params?.filters?.subscriber, 'subscriptions', 'destination'),
      filterContains(params?.filters?.triggerCooldown, 'trigger_cooldown'),
      filterBetween(
        params?.filters?.startCreationDate,
        params?.filters?.endCreationDate,
        'creationDate'
      ),
      filterItems(params?.filters?.alerts),
      filterChildren(
        'triggers',
        allOf([
          filterBetween(params?.filters?.minThreshold, params?.filters?.maxThreshold, 'threshold'),
          anyOf([filterItems(params?.filters?.models, ['models', 'models']), isEmpty('models')]),
          anyOf([
            filterItems(params?.filters?.evaluations, ['evaluations', 'evaluations']),
            isEmpty('evaluations'),
          ]),
          filterContains(params?.filters?.triggerType, 'type'),
        ])
      ),
    ])
  )
  filtered = sortItems(filtered, params)
  return paginate(
    filtered,
    params.page || 0,
    !params.perPage || params.perPage === 'all' ? 20 : params.perPage
  )
}

type ItemType = EvalType | ModelType
type TaggedEval = EvalType & {tag: string}
const AlertFilters = ({config, onChange}: FiltersProps) => {
  const {isAdmin} = useUser()
  const {handleChange, updateField} = filterChangeHandlers({
    config,
    onChange,
  })
  const {models} = useModels()
  const {evaluations} = useEvaluations()

  const makeOptions = (items: ItemType[], all?: ItemType[]) =>
    items
      ?.filter(({id}) => !all?.some((i) => i?.id === id))
      ?.map((item) => ({id: item.id, name: item.name}))

  return (
    <FiltersContainer>
      <OptionsGroup label="Sources" sx={{width: 450, p: 1}}>
        <Stack direction="column" spacing={5} justifyContent="flex-start" alignItems="center">
          <FilterAutocomplete
            options={makeOptions(models, config?.filters?.models)}
            label="Filter models"
            onSelect={(m: ModelType) => updateField('models', m)}
          />
          <FilterAutocomplete
            options={makeOptions(evaluations, config?.filters?.evaluations)}
            label="Filter evaluations"
            onSelect={(e: TaggedEval) => updateField('evaluations', e)}
            groupBy={({tag}: TaggedEval) => tag}
          />
        </Stack>
      </OptionsGroup>
      {isAdmin && (
        <CheckboxGroup
          label="Cooldown"
          allOptions={alertCooldowns}
          selected={config?.filters?.triggerCooldown}
          onChange={handleChange('triggerCooldown')}
        />
      )}
      <DatesPicker
        label="Created between"
        start={config?.filters?.startCreationDate}
        end={config?.filters?.endCreationDate}
        onChange={updateField}
        field="CreationDate"
      />
    </FiltersContainer>
  )
}

export default AlertFilters
