import {SVGProps} from 'react'
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4a1 1 0 0 1 1-1h4.5c.597 0 1.134.262 1.5.677A1.995 1.995 0 0 1 9.5 3H14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H8.5a.5.5 0 0 1-1 0H2a1 1 0 0 1-1-1V4Zm6.5 9V5a1 1 0 0 0-1-1H2v9h5.5Zm1-8v8H14V4H9.5a1 1 0 0 0-1 1Z"
      fill="#788492"
    />
  </svg>
)
export default SvgBook
