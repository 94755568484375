import {useState} from 'react'
import Alert from '@mui/material/Alert'
import Container from '@mui/material/Container'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {HeaderCell, StyledTableCell} from 'components/tables'
import type {User} from '@equistamp/types'
import {ActionButton, Input, RoundedButton} from 'components/forms/inputs'
import {modalStyle} from 'components/dialogs'

type ManageUsersProps = {
  users: User[]
  removeUser: (u: User) => Promise<string | undefined>
  addUsers: (emails: string[]) => Promise<any>
  sx?: {[k: string]: any}
}
const ManageUsers = ({users: initialUsers, removeUser, addUsers, sx}: ManageUsersProps) => {
  const [users, setUsers] = useState(initialUsers)
  const [showAddUsers, setShowAddUsers] = useState(false)
  const [emails, setEmails] = useState('')
  const [error, setError] = useState('')

  const deleteUser = (user: User) => async () => {
    const res = await removeUser(user)
    if (!res) {
      setUsers((current) => current.filter((u) => u.id !== user.id))
    }
  }

  const addNewUsers = async () => {
    setError('')
    const users = emails.split(/\s/).filter(Boolean)
    const res = await addUsers(users)
    if (!res) {
      setUsers((current) => [...current, ...users.map((u) => ({email_address: u, user_name: ''}))])
      setShowAddUsers(false)
    } else {
      setError('Could not add users: ' + res)
    }
  }

  return (
    <Stack spacing={2} sx={sx}>
      <Stack justifyContent="space-between" direction="row">
        <Typography variant="h3" sx={{color: 'primary.main'}}>
          Manage Admin Users
        </Typography>
        <RoundedButton label="Add Users" onClick={() => setShowAddUsers(true)} />
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell sx={{width: 160}}>Actions</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.email_address}>
                <StyledTableCell>
                  {user.id ? (
                    user.full_name || user.user_name
                  ) : (
                    <Typography variant="caption">Pending...</Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell>{user.email_address}</StyledTableCell>
                <StyledTableCell>
                  {user.id && <ActionButton label="Remove" action={deleteUser(user)} />}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={showAddUsers} onClose={() => setShowAddUsers(false)}>
        <Container sx={{...modalStyle, m: '160px auto', p: 4, border: 'none', width: 600}}>
          <Stack spacing={2}>
            <Typography variant="h5">
              Please provide the email addresses of the users you'd like to add as admins
            </Typography>
            <Typography>Emails that don't exist in our system will be ignored</Typography>
            <Input multiline setter={setEmails} rows={2} />
            <ActionButton label="Add users" action={addNewUsers} />
            {error && <Alert severity="error">{error}</Alert>}
          </Stack>
        </Container>
      </Modal>
    </Stack>
  )
}

export default ManageUsers
