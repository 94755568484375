import {ReactNode, useEffect, useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {CheckButton} from 'components/forms/inputs'
import Searcher from 'components/search/Searcher'
import {SearchOptions} from 'components/filters/types'
import {ConnectionItem} from './widgets'
import {ItemType, FilterConfig, SearchResult} from '@equistamp/types'
import type {Connections, Item, RunEvalsStageProps} from './types'

type ItemRowProps = {
  item: Item
  onSelect: (item: Item, selected: boolean) => void
  selected?: boolean
  itemType: ItemType
}
const ItemRow = ({selected, onSelect, item, itemType}: ItemRowProps) => {
  return (
    <CheckButton selected={selected} onClick={() => onSelect(item, !selected)}>
      <ConnectionItem item={item} itemType={itemType} priceHint="Evaluation price" />
    </CheckButton>
  )
}

type SelectItemsProps = RunEvalsStageProps & {
  getItems: (params: FilterConfig) => Promise<SearchResult>
  loading?: boolean
  searchOptions: SearchOptions
}
const SelectItems = ({
  getItems,
  loading,
  searchOptions,
  itemType,
  updateRunConfig,
  runConfig,
  enableNext,
}: SelectItemsProps) => {
  const [selected, setSelected] = useState(
    runConfig.selected.reduce((acc, c) => ({...acc, [c.id]: c}), {} as Connections)
  )
  const connectedType = itemType === 'model' ? 'evaluation' : 'model'

  const toggleItem = (item: Item, isSelected: boolean) => {
    let updated = selected
    if (isSelected) {
      updated = {...selected, [item.id]: item}
    } else {
      delete updated[item.id]
    }
    setSelected(updated)
    updateRunConfig('selected', Object.values(updated))
    enableNext(Object.keys(updated).length > 0)
  }

  useEffect(() => enableNext(runConfig.selected.length > 0), [enableNext, runConfig.selected])

  const totalPrice = Object.values(selected).reduce((sum, {price}) => sum + (price || 1), 0)

  const ItemsContainer = ({children}: {children: ReactNode}) =>
    totalPrice === 0 ? (
      <>{children}</>
    ) : (
      <Stack spacing={2} sx={{position: 'relative'}}>
        {children}
        <Stack
          spacing={2}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: -90,
          }}
        >
          <Typography sx={{fontWeight: 600}}>${totalPrice.toFixed(2)}</Typography>
          <Typography variant="caption" sx={{color: 'secondary.subText'}}>
            Total price
          </Typography>
        </Stack>
      </Stack>
    )

  return (
    <Searcher
      getItems={getItems}
      loading={!!loading}
      renderItem={(item: Item) => (
        <ItemRow
          key={item.id}
          onSelect={toggleItem}
          item={item}
          itemType={connectedType}
          selected={!!selected[item.id]}
        />
      )}
      searchOptions={searchOptions}
      itemsContainer={ItemsContainer}
    />
  )
}

export default SelectItems
