import {ReactNode} from 'react'
import {NavLink} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {taskTypes} from 'components/filters/constants'
import {QuestionType, DSLCode, CodeStage} from '@equistamp/types'
import {
  FREE_RESPONSE_QUESTION,
  MULTIPLE_CHOICE_QUESTION,
  BOOLEAN_QUESTION,
  JSON_QUESTION,
} from '@equistamp/constants'
import {Variable} from './Variable'

export const DEFAULT = 'default'
export const CUSTOM_CODE = {
  id: 'custom_code',
  name: 'Custom Code',
  description: (
    <Typography>
      Write your own code. See <NavLink to="/docs/dsl/">the docs</NavLink> for a full description of
      available functions.
    </Typography>
  ),
  code: '',
  supports: [DEFAULT, ...Object.keys(taskTypes)],
  editable: true,
} as CodeConfig

export const avaliableCodeConfigs = {
  prompt: [
    CUSTOM_CODE,
    {
      id: 'json_prompt_with_examples',
      name: 'JSON prompt with examples',
      description: <Typography>Enrich the default JSON prompt with an example.</Typography>,
      code: [
        '(str "I am creating Json objects from input strings. The json objects I am creating are governed by this schema:\n\n" schema',
        '"\n\nHere is an example email and the json object that was created from it:\n"',
        '"--- Email---\n" example_input',
        '"\n\n--- Json---" example_json',
        '"\n\nPlease create a valid json object according to my schema for the following input.\n"',
        '"Your response should contain only the entire json object with no preamble or characters that are not json.\n"',
        '"[Input]\n" task)',
      ].join('\n'),
      supports: [JSON_QUESTION],
      variables: [
        {name: 'example_input', required: true, default: '""', type: 'string'},
        {name: 'example_json', required: true, default: '{}', type: 'json'},
      ],
    },
  ],
  grader: [CUSTOM_CODE],
} as {[k in CodeStage]: CodeConfig[]}

const OPEN_AI = 'open_ai'
const HUGGING_FACE_TEXT_GENERATION = 'text-generation'
const HUGGING_FACE_TEXT2TEXT_GENERATION = 'text2text-generation'
const HUGGING_FACE_FILL_MASK = 'fill-mask'
const HUGGING_FACE_CONVERSATIONAL = 'conversational'
const HUGGING_FACE_ZERO_SHOT_CLASSIFICATION = 'zero-shot-classification'
const GOOGLE_CLOUD = 'google_cloud'
const AZURE = 'azure'
const AWS = 'aws'
const MISTRAL = 'mistral'
const TOGETHERAI = 'together.ai'
const ANTHROPIC = 'anthropic'
const CUSTOM = 'custom'
const MODEL_ENDPOINT_TYPES = [
  OPEN_AI,
  HUGGING_FACE_TEXT_GENERATION,
  HUGGING_FACE_TEXT2TEXT_GENERATION,
  HUGGING_FACE_FILL_MASK,
  HUGGING_FACE_CONVERSATIONAL,
  HUGGING_FACE_ZERO_SHOT_CLASSIFICATION,
  GOOGLE_CLOUD,
  AZURE,
  AWS,
  ANTHROPIC,
  MISTRAL,
  TOGETHERAI,
  CUSTOM,
]
const DEFAULT_VARS = [
  {
    name: 'endpoint-type',
    type: 'string',
    default: 'custom',
    enum: MODEL_ENDPOINT_TYPES,
    validator: (val: string) =>
      MODEL_ENDPOINT_TYPES.includes(val)
        ? null
        : `endpoint type must be one of ${MODEL_ENDPOINT_TYPES.map((v) => '"' + v + '"').join(
            ', '
          )}`,
  },
] as Variable[]

// ## PROMPT ##
const DEFAULT_PROMPT_VARS = [...DEFAULT_VARS] as Variable[]
const JSON_PROMPT_VARS = [
  {name: 'schema', type: 'json', default: '{}'},
  {name: 'task', type: 'string', default: 'Hi, my name is mr. blobby'},
] as Variable[]
const MCQ_PROMPT_VARS = [
  {name: 'task', type: 'string', default: 'What colour is clean snow?'},
  {
    name: 'choices-text',
    type: 'string',
    default: ['[A] green', '[B] yellow', '[C] white'].join('\n'),
  },
  {name: 'letter-choices', type: 'string', default: 'A, B and C'},
  {name: 'num-choices', type: 'int', default: 3},
] as Variable[]
const FRQ_PROMPT_VARS = [{name: 'task', type: 'string', default: 'What is 2 + 2?'}] as Variable[]
const BOOL_PROMPT_VARS = [
  {name: 'task', type: 'string', default: 'Is pizza with pineapple acceptable?'},
] as Variable[]

// ## REQUEST ##
const DEFAULT_REQUEST_VARS = [...DEFAULT_PROMPT_VARS] as Variable[]
const MCQ_REQUEST_VARS = [
  ...MCQ_PROMPT_VARS,
  {
    name: 'prompt',
    type: 'string',
    default: [
      "I have a multiple choice question I'd like you to answer.",
      'Please provide a very brief explanation of your answer.',
      'There are 3 choices: A, B and D.',
      'Select your answer by including your chosen answer in square brackets at the beginning of your response.',
      "For example: '[D] is my choice' is a valid response.",
      "The Question: 'What colour is clean snow?' The Choices:",
      '[A] green',
      '[B] yellow',
      '[C] white',
    ].join('\n'),
  },
] as Variable[]
const FRQ_REQUEST_VARS = [
  ...FRQ_PROMPT_VARS,
  {
    name: 'prompt',
    type: 'string',
    default: [
      "I have a question I'd like you to answer. Please provide a very brief explanation of your answer.\n",
      'Answer in the format of:\n\n',
      '[Answer] <your answer goes here\n',
      '[Reason] <your reasoning>\n\n',
      'For example, if the question is "What time is it?", a possible response would be:\n',
      "[Answer] It's currently 9:20 AM\n",
      '[Reason] This is the time displayed by my watch.\n',
      'My question is: What is 2 + 2?',
    ].join(),
  },
] as Variable[]
const JSON_REQUEST_VARS = [
  ...JSON_PROMPT_VARS,
  {
    name: 'prompt',
    type: 'string',
    default: [
      'I am creating Json objects from input strings. The json objects I am creating ',
      'are governed by this schema:\n\n',
      '{"properties": {"name": {"type": "string"}}}',
      '\n\nPlease create a valid json object according to my schema for the following input.\n',
      'Your response should contain only the entire json object with no preamble ',
      'or characters that are not json.\n',
      '[Input]\n',
      'Hi, my name is mr. blobby',
    ].join(),
  },
] as Variable[]
const BOOL_REQUEST_VARS = [
  ...BOOL_PROMPT_VARS,
  {
    name: 'prompt',
    type: 'string',
    default: [
      "I have a true/false answer that I'd like you to answer. Please provide a very brief explanation ",
      'of your answer. Select your answer by adding [TRUE] or [FALSE] at the end of your answer. For ',
      "example: 'I think this is [TRUE]' is a valid response. The Question: ",
      "'Is pizza with pineapple acceptable?'",
    ],
  },
] as Variable[]

// ## RESPONSE ##
const DEFAULT_RESPONSE_VARS = [...DEFAULT_REQUEST_VARS] as Variable[]
const MCQ_RESPONSE_VARS = [
  ...MCQ_REQUEST_VARS,
  {
    name: 'response',
    type: 'string',
    default: 'My answer is [C], as yellow snow should not be eaten.',
  },
] as Variable[]
const FRQ_RESPONSE_VARS = [
  ...FRQ_REQUEST_VARS,
  {name: 'response', type: 'string', default: '[Answer] 4 [Reason] Basic arithmetic, really'},
] as Variable[]
const BOOL_RESPONSE_VARS = [
  ...BOOL_REQUEST_VARS,
  {name: 'response', type: 'string', default: 'I think this is [TRUE]'},
] as Variable[]
const JSON_RESPONSE_VARS = [
  ...JSON_REQUEST_VARS,
  {name: 'response', type: 'string', default: '{"name": "mr. blobby", "description": "a name"}'},
] as Variable[]

// ## GRADER ##
const DEFAULT_GRADER_VARS = [...DEFAULT_RESPONSE_VARS] as Variable[]
const JSON_GRADER_VARS = [
  ...JSON_RESPONSE_VARS,
  {name: 'expected', type: 'map', default: '{"name": "mr. blobby"}'},
] as Variable[]
const MCQ_GRADER_VARS = [
  ...MCQ_RESPONSE_VARS,
  {name: 'correct', type: 'array', default: '[C]'},
] as Variable[]
const FRQ_GRADER_VARS = [
  ...FRQ_RESPONSE_VARS,
  {name: 'positive-examples', type: 'array', default: '[4]'},
  {name: 'negative-examples', type: 'array', default: '[2, 3, 5]'},
] as Variable[]
const BOOL_GRADER_VARS = [
  ...BOOL_RESPONSE_VARS,
  {name: 'correct', type: 'boolean', default: 'true'},
] as Variable[]

export const testVariables = {
  prompt: {
    [DEFAULT]: DEFAULT_PROMPT_VARS,
    [JSON_QUESTION]: JSON_PROMPT_VARS,
    [MULTIPLE_CHOICE_QUESTION]: MCQ_PROMPT_VARS,
    [FREE_RESPONSE_QUESTION]: FRQ_PROMPT_VARS,
    [BOOLEAN_QUESTION]: BOOL_PROMPT_VARS,
  },
  grader: {
    [DEFAULT]: DEFAULT_GRADER_VARS,
    [JSON_QUESTION]: JSON_GRADER_VARS,
    [MULTIPLE_CHOICE_QUESTION]: MCQ_GRADER_VARS,
    [FREE_RESPONSE_QUESTION]: FRQ_GRADER_VARS,
    [BOOLEAN_QUESTION]: BOOL_GRADER_VARS,
  },
}

export type CodeConfig = {
  id: string
  name: string
  description: string | ReactNode
  code: DSLCode
  variables: Variable[]
  supports: QuestionType[]
  editable?: boolean
}
