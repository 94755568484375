import {useState, useEffect, ReactElement} from 'react'
import {useLoaderData} from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Page from '../components/Page'

type ItemWithError<T> = T & {error?: string}
export type ItemSetter<T> = (v: ItemWithError<T> | undefined | null) => void
export type RendererProps<T> = {
  item: ItemWithError<T>
  setItem: ItemSetter<T>
}
type ItemLoaderProps<T> = {
  itemName: string
  renderer: ({item, setItem}: RendererProps<T>) => ReactElement
  onLoad?: (v: T) => void
}
const ItemLoader = <T,>({itemName, renderer, onLoad}: ItemLoaderProps<T>) => {
  const {item: loader} = useLoaderData() as {item: Promise<ItemWithError<T>>}
  const [item, setItem] = useState<ItemWithError<T> | null | undefined>()

  useEffect(() => {
    const awaitLoader = async () => {
      const item = await loader
      console.log(item)
      setItem(item)
      onLoad && onLoad(item)
    }
    awaitLoader()
  }, [loader, onLoad])

  if (item === undefined) {
    return (
      <Page title={`Loading ${itemName}...`} showTitle>
        <Stack direction="row" justifyContent="center" sx={{p: 6}}>
          <CircularProgress />
        </Stack>
      </Page>
    )
  } else if (item === null || item.error) {
    console.log(item)
    return (
      <Page title="Loading error" showTitle>
        <Typography align="center" variant="h4" sx={{p: 6}}>
          {item?.error || `Could not load ${itemName}`}
        </Typography>
      </Page>
    )
  }

  const Renderer = renderer
  return <Renderer item={item} setItem={setItem} />
}

export default ItemLoader
