import React from 'react'
import {NavLink} from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {makeApi} from '@equistamp/api'
import Page from 'components/Page'
import ResetPasswordForm from 'components/forms/ResetPasswordForm'
import Path from 'routeLinks'

const ResetPassword: React.FC = () => {
  return (
    <Page title="Reset password" showTitle>
      <Stack justifyContent="center" alignItems="center" spacing={4}>
        <ResetPasswordForm
          onSubmit={async (email: string) => makeApi().users.resetPassword(email)}
        />
        <Typography>
          Don't have an account? &nbsp; <NavLink to={Path.user.register()}>Register here</NavLink>
        </Typography>
      </Stack>
    </Page>
  )
}

export default ResetPassword
