import React from 'react'
import {useNavigate} from 'react-router-dom'
import {makeApi} from '@equistamp/api'
import {MULTIPLE_CHOICE_QUESTION} from '@equistamp/constants'
import useEvaluations from 'hooks/useEvaluations'
import useUser from 'hooks/useUser'
import EvaluationWizard from 'components/evaluations/EvaluationWizard'
import {CREATE_EVALUATION} from 'permissions'
import Path from 'routeLinks'
import type {Evaluation as EvalType} from '@equistamp/types'

const CreateEvaluation: React.FC = () => {
  const {user, hasPermission} = useUser()
  const navigate = useNavigate()
  const {refresh} = useEvaluations()

  const saveEvaluation = async (e: EvalType) => {
    try {
      const {id} = await makeApi().evaluations.create({...e, owner: user || undefined})
      refresh({})
      navigate(Path.evaluations.show(id))
      return null
    } catch (e) {
      console.error(e)
      return `Error while creating evaluation: ${e}`
    }
  }

  if (!user || !hasPermission(CREATE_EVALUATION)) return null
  return (
    <EvaluationWizard
      basic
      tasks
      title="Create an evaluation"
      onSave={saveEvaluation}
      onCancel={() => navigate(Path.evaluations.all())}
      initial={
        {
          public: true,
          default_task_type: MULTIPLE_CHOICE_QUESTION,
        } as unknown as EvalType
      }
    />
  )
}

export default CreateEvaluation
