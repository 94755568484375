import {paginate, searchText, allOf, filterContains, filterBetween, equals} from './filters'
import {filterChangeHandlers, handleRangeChange, sortItems} from './transformers'
import {modalities, taskTypes} from './constants'
import {FiltersContainer, CheckboxGroup, RangeSlider, OptionsGroup} from './widgets'
import {SelectElem} from 'components/forms/inputs'
import useTags from 'hooks/useTags'
import type {Evaluation, FilterConfig, SearchResult} from '@equistamp/types'
import type {FiltersProps} from './types'

export const filterEvaluations = (evals: Evaluation[], params: FilterConfig): SearchResult => {
  let filtered = searchText(evals, params.filters?.search || '', ['name', 'description']).filter(
    allOf([
      filterContains(params?.filters?.tags, 'tags', 'name'),
      filterContains(params?.filters?.types, 'task_types'),
      filterContains(params?.filters?.modalities, 'modalities'),
      filterBetween(params?.filters?.minNumTasks, params?.filters?.maxNumTasks, 'num_tasks'),
      equals(params?.filters?.public_usable, 'public_usable'),
    ])
  )
  filtered = sortItems(filtered, params)
  return paginate(
    filtered,
    params.page || 0,
    !params.perPage || params.perPage === 'all' ? 20 : params.perPage
  )
}

const EvaluationFilters = ({config, onChange}: FiltersProps) => {
  const {handleChange, updateField} = filterChangeHandlers({
    config,
    onChange,
  })
  const {tags} = useTags()

  const selectedTags = config?.filters?.tags || []

  return (
    <FiltersContainer>
      {tags.length > 0 && (
        <OptionsGroup label="Tags">
          <SelectElem
            label="Tags"
            multiple
            onChange={handleChange('tags')}
            value={selectedTags}
            values={tags
              .filter(({name}) => name && !selectedTags?.includes(name))
              .reduce((acc, {id, name}) => ({...acc, [name]: name}), {})}
            sx={{maxWidth: 300}}
          />
        </OptionsGroup>
      )}
      <CheckboxGroup
        label="Type"
        allOptions={taskTypes}
        selected={config?.filters?.types}
        onChange={handleChange('types')}
      />
      <CheckboxGroup
        label="Modalities"
        allOptions={modalities}
        selected={config?.filters?.modalities}
        onChange={handleChange('modalities')}
      />
      <RangeSlider
        label="Num tasks"
        start={config?.filters?.minNumTasks || 0}
        end={config?.filters?.maxNumTasks || 600000}
        max={600000}
        scale="log"
        onChange={handleRangeChange(config?.filters, 'NumTasks', updateField)}
      />
    </FiltersContainer>
  )
}

export default EvaluationFilters
