import {useState, useEffect} from 'react'
import {parseEDNString} from 'edn-data'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {makeApi, ServerError} from '@equistamp/api'
import {CodeBlock} from 'components/text'
import {makeEndpointCode} from 'components/dsl'
import type {ModelStageProps} from './types'

const EndpointTest = ({endpoint, enableNext, onChange, onEndpointUpdate}: ModelStageProps) => {
  const [result, setResult] = useState()
  const [request, setRequest] = useState('')
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    const runTest = async () => {
      try {
        setResult(undefined)
        setError(undefined)
        enableNext(true)
        const res = await makeApi().tester.testDSL({code, stage: 'request'})
        onChange('request_code', code)
        onEndpointUpdate('response', res)
        const result = parseEDNString(res, {mapAs: 'object', keywordAs: 'string'}) as any
        setResult(result)
      } catch (e) {
        setError(e instanceof ServerError ? e.error.toString() : `${e}`)
        enableNext(false)
      }
    }

    const code = makeEndpointCode({...endpoint, asJson: true, method: 'POST'})
    if (code !== request) {
      setRequest(code)
      runTest()
    }
  }, [request, enableNext, endpoint, onChange, onEndpointUpdate])

  return (
    <Box>
      {!result && !error && <CircularProgress color="secondary" size={40} />}
      {error && (
        <Box>
          <Typography variant="h5">Test failed:</Typography>
          <Typography>{error}</Typography>
        </Box>
      )}
      {result && (
        <Box>
          <Typography variant="h5">Endpoint response:</Typography>
          <CodeBlock>{JSON.stringify(result, null, 2)}</CodeBlock>
        </Box>
      )}
    </Box>
  )
}

export default EndpointTest
