import React from 'react'
import Box from '@mui/material/Box'
import Page from 'components/Page'
import Compare from 'components/Compare'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {Action, Hero} from 'components/Landing'
import useUser from 'hooks/useUser'
import useModels from 'hooks/useModels'
import useEvaluations from 'hooks/useEvaluations'
import Path from 'routeLinks'

const Home: React.FC = () => {
  const {loggedIn} = useUser()
  const {allModels} = useModels()
  const {evaluations} = useEvaluations()

  return (
    <Page title="EquiStamp" fullWidth>
      <Hero
        text="Gain confidence that an LLM actually works for your use case through our Evaluation and Monitoring tools"
        img={<img alt="Sample cards" src="/imgs/cards.png" width="540" height="445" />}
      />

      <Container sx={{pt: 6, pb: 6}}>
        <Typography variant="h3" sx={{textAlign: 'center', fontWeight: 700}}>
          LLM Comparison Tool
        </Typography>
      </Container>

      <Compare models={allModels} evaluations={evaluations} />

      <Container sx={{mt: 8, mb: 8}}>
        <Stack
          direction={{sm: 'column', md: 'row'}}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{mt: 5, mb: 5}}
        >
          <Action title="Monitor & Alert" img="/imgs/danger.png" variant="overline" />
          <Action
            title="Verify AI performance against claims"
            img="/imgs/database.png"
            variant="overline"
          />
          <Action title="Mitigate risk" img="/imgs/world.png" variant="overline" />
        </Stack>
      </Container>

      <AppBar color="primary" enableColorOnDark position="relative" elevation={0}>
        <Toolbar>
          <Container sx={{pt: 6, pb: 6}}>
            <Typography variant="h3" sx={{textAlign: 'center', fontWeight: 700}}>
              We provide transparent evaluations of LLMs
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>

      <Container>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{mt: 6, mb: 10}}
        >
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={2}>
            <Action
              title="Subscribe today"
              img="/imgs/subscribe.png"
              action={
                loggedIn ? Path.subscriptions.all() : Path.user.register(Path.subscriptions.all())
              }
              actionTitle="Sign up"
              titleFont={{fontWeight: 700}}
            />
          </Grid>
          <Grid item xs={1} md={1}></Grid>

          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={2}>
            <Action
              title="Compare models"
              img="/imgs/danger.png"
              action={Path.models.all()}
              actionTitle="View"
              titleFont={{fontWeight: 700}}
            />
          </Grid>
          <Grid item xs={1} md={3}></Grid>

          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={2}>
            <Action
              title="Compare yourself"
              img="/imgs/clipboard.png"
              action={Path.tests.random()}
              actionTitle="Start"
              titleFont={{fontWeight: 700}}
            />
          </Grid>
          <Grid item xs={1} md={1}></Grid>

          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={2}>
            <Action
              title="Test your model"
              img="/imgs/graph.png"
              action={Path.evaluations.all()}
              actionTitle="Get started"
              titleFont={{fontWeight: 700}}
            />
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      </Container>
      <AppBar color="primary" enableColorOnDark position="relative" elevation={0}>
        <Toolbar>
          <Container sx={{pt: 6, pb: 6}}>
            <Typography variant="h3" sx={{textAlign: 'center', fontWeight: 700}}>
              Our Mission
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{mt: 6, mb: 6}}>
        <Box maxWidth="md" mx="auto" px={4}>
          <Typography variant="h5" align="center" gutterBottom>
            EquiStamp's mission is to provide a platform that allows users to evaluate the
            capabilities of their AI models. We aim to provide a stamp of approval that lets
            companies and individuals know how much they can trust AI models.
          </Typography>
        </Box>
      </Container>
    </Page>
  )
}

export default Home
