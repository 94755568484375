import {SVGProps} from 'react'
const SvgSortAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 4ZM2 7.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5ZM2.5 10.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM10.707 11.146a.5.5 0 1 0-.707.707l2.196 2.196a.498.498 0 0 0 .711.005l2.2-2.2a.5.5 0 1 0-.707-.708l-1.347 1.347V7.5a.5.5 0 0 0-1 0v4.993l-1.346-1.347Z"
      fill="#788492"
    />
  </svg>
)
export default SvgSortAlt
