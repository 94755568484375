import React from 'react'
import {createBrowserRouter, RouterProvider, redirect} from 'react-router-dom'
import About from 'pages/about'
import AdminPanel from 'pages/admin'
import EvaluationSessions from 'pages/admin/evaluationSessions'
import Schedules from 'pages/admin/schedules'
import Alerts from 'pages/alerts'
import ModelStatuses from 'pages/admin/modelStatuses'
import Alert from 'pages/alerts/alert'
import EditAlert from 'pages/alerts/edit'
import ChangePassword, {setUser} from 'pages/user/changePassword'
import CompareLLMs from 'pages/compare'
import CreateAlert from 'pages/alerts/create'
import CreateEvaluation from 'pages/evaluations/create'
import CreateModel from 'pages/models/create'
import Evaluation from 'pages/evaluations/evaluation'
import Evaluations from 'pages/evaluations'
import EvaluationConnections from 'pages/evaluations/connections'
import EvaluationSession from 'pages/evaluationSessions/evaluationSession'
import EvaluationTasks from 'pages/evaluations/tasks'
import ErrorPage from 'pages/error'
import Home from 'pages/home'
import Login from 'pages/user/login'
import LoginChecker from 'pages/user/check'
import Model from 'pages/models/model'
import Models from 'pages/models'
import ModelRuns from 'pages/models/runs'
import ModelConnections from 'pages/models/connections'
import Pricing from 'pages/pricing'
import Privacy from 'pages/privacy'
import Profile from 'pages/user/profile'
import ResetPassword from 'pages/user/resetPassword'
import Register from 'pages/user/register'
import Runs from 'pages/evaluations/runs'
import Subscribe from 'pages/subscribe'
import ConfirmSubscription from 'pages/subscriptions/confirm'
import Unsubscribe from 'pages/subscriptions/unsubscribe'
import Test, {randomEvaluation} from 'pages/test'
import useUser from 'hooks/useUser'
import {
  Permission,
  CREATE_MODEL,
  CONNECT_MODELS,
  CREATE_EVALUATION,
  VIEW_RUNS,
  ADMIN,
} from 'permissions'
import Path from 'routeLinks'
import {ServerError, makeApi} from '@equistamp/api'

type ItemGetter = (id: string) => Promise<any>
type Loader = (a: any) => any

const logoutAction = (logout: () => void) => (args: any) => {
  logout()

  const url = new URL(args?.request?.url || '')
  const searchParams = new URLSearchParams(url.search)
  const redirectUrl = searchParams.get('redirect')
  window.location.href = redirectUrl || Path.home()
  return null
}

const requiresUser = (loggedIn: boolean, loader?: Loader) => (args: any) => {
  if (!loggedIn) return redirect(Path.user.login(args?.request?.url))
  if (loader) return loader(args)
  return args
}

const getById = (getter: ItemGetter) => async (context: any) => ({
  ...context,
  item: getter(context.params.id).catch((error) =>
    error instanceof ServerError ? error : {error}
  ),
})

const itemFetcher = (path: string, getter: ItemGetter, element: React.ReactNode) => ({
  path,
  element,
  loader: getById(getter),
  errorElement: <ErrorPage />,
})

const requiresPermission =
  (hasPermission: (p: Permission) => boolean, user: any) =>
  (permission: Permission, loader?: Loader) =>
  (args: any) => {
    if (user !== undefined && !hasPermission(permission)) {
      return redirect(Path.subscriptions.all(args?.request?.url))
    }

    if (loader) return loader(args)
    return args
  }

const Routes = () => {
  const {logout, loggedIn, user, hasPermission, getUser} = useUser()
  const api = makeApi()

  const authPath = (path: string, element: any) => ({
    path,
    element,
    loader: requiresUser(loggedIn),
  })

  const permissionChecker = requiresPermission(hasPermission, user)
  const withPermission = (permission: Permission, route: any) => {
    const loader = requiresUser(loggedIn, permissionChecker(permission, route.loader))
    return {...route, loader}
  }

  const router = createBrowserRouter([
    {path: Path.home(), index: true, element: <Home />},
    {path: Path.about(), element: <About />},
    {path: Path.compare(), element: <CompareLLMs />},
    {path: Path.privacy(), element: <Privacy />},
    {path: Path.pricing(), element: <Pricing />},

    // Subscriptions
    authPath(Path.subscriptions.all(), <Subscribe />),
    authPath(Path.subscriptions.confirm(':id'), <ConfirmSubscription />),
    authPath(Path.subscriptions.unsubscribe(':id'), <Unsubscribe />),

    // Evaluations
    withPermission(CREATE_EVALUATION, {
      path: Path.evaluations.create(),
      element: <CreateEvaluation />,
    }),
    {path: Path.tests.random(), loader: randomEvaluation},
    {
      path: Path.evaluations.tests(':id'),
      element: <Test />,
      loader: requiresUser(loggedIn, getById(api.evaluations.fetch)),
      errorElement: <ErrorPage />,
    },
    withPermission(CONNECT_MODELS, {
      path: Path.evaluations.connections(':id'),
      element: <EvaluationConnections />,
      loader: requiresUser(loggedIn, getById(api.evaluations.fetch)),
      errorElement: <ErrorPage />,
    }),
    withPermission(VIEW_RUNS, {
      path: Path.evaluations.runs(':id'),
      element: <Runs />,
      loader: getById(api.evaluations.fetch),
      errorElement: <ErrorPage />,
    }),
    itemFetcher(Path.evaluations.show(':id'), api.evaluations.fetch, <Evaluation />),
    itemFetcher(Path.evaluations.tasks(':id'), api.evaluations.fetch, <EvaluationTasks />),
    {path: Path.evaluations.all(), element: <Evaluations />},

    // Evaluation Sessions
    itemFetcher(
      Path.evaluationSessions.show(':id'),
      api.evaluationSessions.fetch,
      <EvaluationSession />
    ),

    // Models
    {path: Path.models.all(), element: <Models />},
    withPermission(CREATE_MODEL, {
      path: Path.models.create(),
      element: <CreateModel />,
    }),
    withPermission(CONNECT_MODELS, {
      path: Path.models.connections(':id'),
      element: <ModelConnections />,
      loader: requiresUser(loggedIn, getById(api.models.fetch)),
      errorElement: <ErrorPage />,
    }),
    withPermission(VIEW_RUNS, {
      path: Path.models.runs(':id'),
      element: <ModelRuns />,
      loader: getById(api.models.fetch),
      errorElement: <ErrorPage />,
    }),
    itemFetcher(Path.models.show(':id'), api.models.fetch, <Model />),

    // Alerts
    {path: Path.alerts.all(), element: <Alerts />},
    authPath(Path.alerts.create(), <CreateAlert />),
    itemFetcher(Path.alerts.show(':id'), api.alerts.fetch, <Alert />),
    itemFetcher(Path.alerts.edit(':id'), api.alerts.fetch, <EditAlert />),

    // Users
    {path: Path.user.forgotPassword(), element: <ResetPassword />},
    {path: Path.user.resetPassword(), loader: setUser(getUser), element: <ChangePassword />},
    {path: Path.user.logout(), loader: logoutAction(logout)},
    itemFetcher(Path.user.me(), async () => api.auth.me(), <Profile />),
    itemFetcher(Path.user.profile(':id'), api.users.fetch, <Profile />),
    {path: Path.user.register(), element: <Register />},

    // Login
    {path: Path.login.github(), element: <LoginChecker />},
    {path: Path.user.login(), element: <Login />},

    // Admin
    withPermission(ADMIN, {
      path: Path.admin.index(),
      element: <AdminPanel />,
      errorElement: <ErrorPage />,
    }),
    withPermission(ADMIN, {
      path: Path.admin.evaluationSessions(),
      element: <EvaluationSessions />,
      errorElement: <ErrorPage />,
    }),
    withPermission(ADMIN, {
      path: Path.admin.modelStatuses(),
      element: <ModelStatuses />,
      errorElement: <ErrorPage />,
    }),
    withPermission(ADMIN, {
      path: Path.admin.schedules(),
      element: <Schedules />,
      errorElement: <ErrorPage />,
    }),
    {
      path: '*',
      loader: async () => {
        throw new Response('Not Found', {status: 404})
      },
      errorElement: <ErrorPage />,
    },
  ])

  return <RouterProvider router={router} />
}

export default Routes
