import {useState} from 'react'
import {Input, SelectElem} from '../../components/forms/inputs'
import {parseEDNString, toEDNStringFromSimpleObject} from 'edn-data'
import {parseJSON} from '../formatters'

export type VariableType = 'string' | 'int' | 'float' | 'vector' | 'map' | 'boolean' | 'json'
export type Variable = {
  name: string
  required?: boolean
  default?: any
  type: VariableType
  enum?: any[]
  validator?: (val: any) => null | undefined | string
}
type EditVariableProps = {
  variable: Variable
  value?: any
  onChange: (val: any) => void
}
const EditVariable = ({value, variable, onChange}: EditVariableProps) => {
  const [error, setError] = useState('')

  const setVal = (val: any) => {
    setError('')
    onChange(val)
  }

  const getDefault = () => {
    if (!value) {
      return variable.default || ''
    } else if (variable.type === 'string') {
      return parseJSON(value)
    } else {
      return value
    }
  }

  const updateVal = (val: string) => {
    const parsed = parseEDNString(val)
    if (variable.type === 'string') {
      setVal(JSON.stringify(val))
    } else if (variable.type === 'json') {
      if (parseJSON(val)) {
        setVal(val)
      } else {
        setError(`${variable.name} must be valid JSON`)
      }
    } else if (parsed === null) {
      setVal('nil')
    } else if (typeof parsed === 'object' && parsed[variable.type as keyof typeof parsed]) {
      setVal(toEDNStringFromSimpleObject(parsed[variable.type as keyof typeof parsed]))
    } else if (typeof parsed == 'number') {
      if (variable.type === 'float' || parsed === parseInt(val, 10)) {
        setVal(val.toString())
      } else {
        setError(`${variable.name} must be an integer`)
      }
    } else if (typeof parsed === variable.type) {
      setVal(val)
    } else {
      setError(`${variable.name} must be a ${variable.type}`)
    }
  }
  if (variable.enum) {
    return (
      <SelectElem
        label={variable.name}
        defaultValue={getDefault()}
        onChange={updateVal}
        values={variable.enum.reduce((acc, v) => ({...acc, [v]: v}), {})}
      />
    )
  }
  return (
    <Input
      label={variable.name}
      multiline
      defaultValue={getDefault()}
      error={error}
      setter={updateVal}
    />
  )
}

export default EditVariable
