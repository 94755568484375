export const themeColours = {
  light: {
    primary: {
      light: '8eacfe',
      main: '#3351FF',
      border: '#DFE3E9',
    },
    secondary: {
      light: '#DBDFF6',
      main: '#F1F3FF',
      contrastText: '#1B2B3E',
      subText: '#788492',
      divider: '#DBDFF6',
    },
  },
  dark: {
    primary: {
      light: '#0094FF',
      main: '#5770FF',
      dark: '#1D2472',
      contrastText: '#DBDFF6',
      border: '#DBDFF6',
    },
    secondary: {
      light: '#4dabf5',
      main: '#171A2A',
      contrastText: '#DBDFF6',
      subText: 'rgba(255, 255, 255, 0.7)',
      divider: 'rgba(255, 255, 255, 0.23)',
    },
  },
}

type Colours = {
  main: string
  light?: string
  dark?: string
  contrastText?: string
  subText?: string
  divider?: string
}
export type EquistampPalette = {
  primary: Colours
  secondary: Colours
  docs?: Colours
}
