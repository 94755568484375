import {useState} from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Wizard, {StepProps} from 'components/forms/Wizard'
import type {Alert as AlertType, Trigger, TriggerTypes} from '@equistamp/types'
import {
  alertTriggerModelChanges,
  alertTriggerTopMetrics,
  alertTriggerThresholdMetrics,
  alertTriggerAvailabilityMetrics,
  alertTriggerStabilityMetrics,
} from 'components/filters/constants'
import {
  MODEL_CHANGE,
  NEW_MODEL,
  TOP_MODEL,
  EVAL_SCORE_THRESHOLD,
  NEW_EVALUATION,
  MODEL_AVAILABILITY,
  MODEL_STABILITY,
} from '@equistamp/constants'
import {ChooseTrigger, NotificationMethods, BasicSettings} from './configure'
import {ExternalA} from 'components/text'
import Path from 'routeLinks'

const documentationItems = {
  settings: [
    {
      title: 'What is an alert?',
      contents: (
        <Stack>
          <Typography>
            Alerts are notifications that are sent to you when something specific happens. For
            example:
          </Typography>
          <ul>
            <li>
              an alert that will send you an email whenever a specific model scores more than 90% on
              a specific evaluation
            </li>
            <li>an alert that will send a request to a webhook whenever a new model is added</li>
          </ul>
        </Stack>
      ),
    },
    /* {
     *   title: 'What is the repeat rate?',
     *   contents: [
     *     `Some alerts will only ever fire once. But some alerts can check for triggers that can happen often, e.g. model rank
     *        changes for models that are very close to each other.`,
     *     'You can control how often an alert can fire with the repeat rate:',
     *     <ul>
     *       <li>
     *         Only once - you'll be alerted when the trigger first fires, but no more, no matter how
     *         many subsequent trigger changes happen
     *       </li>
     *       <li>
     *         Once per day - there's a 24 hour cooldown between alerts, during which time we won't
     *         send any notifications
     *       </li>
     *       <li>Every time - each and every trigger fire will cause you to be notified</li>
     *     </ul>,
     *   ],
     * }, */
  ],
  notifications: [
    {
      title: 'What are notification methods?',
      contents: [
        'Notification methods specify how we should let you be alerted.',
        'You can add up to 20 addititional notification methods.',
      ],
    },
  ],
  baseTriggerDocs: [
    {
      title: 'What are triggers?',
      contents: [
        'Triggers define what has to happen for an alert to be sent.',
        'There are various kinds of triggers with various levels of configurability.',
        `Most triggers allow you to select models that should be checked - triggers with
                 specified models or evaluations will only take these into consideration when checking
                 whether an alert should be sent - all other items will be ignored.`,
      ],
    },
    {
      title: 'Specific items vs filters',
      contents: [
        `When specifying models or evaluations to be considered by the trigger, you can either select
                specific items, or define a filter.`,
        `In the case of specific items, only the items you explicitly select by clicking them will be
                 used by the trigger.`,
        `Filters are more generic - you can use the search bar and filters to find items you're interested
                 in. Every time the trigger checks for items, it will apply your selected filters and use the resulting
                items.`,
        `Select specific items when you only want to check those specific items, e.g. you only want to check
                your specific version of Llama 3.`,
        `Use filters when you want to check for general things, e.g. any OpenAI model.`,
      ],
    },
  ],
  [EVAL_SCORE_THRESHOLD]: [
    {
      title: 'What is a Evaluation Score Threshold?',
      contents: [
        'Each time a model takes an evaluation, it scores a value between 0 and 100. This is basically the percentage of tasks ' +
          'it got correct. Some evaluations are easy, and a lot of models score above 90%, other evaluations are very hard and ' +
          'no model gets more than e.g. 60%.',
        'Evaluation score threshold triggers allow you to set an alert to inform you whenever a model crosses the threshold you ' +
          'provide, so you can know as soon as an AI model is capable to pass your tests.',
      ],
    },
    {
      title: 'How is the threshold compared?',
      contents: [
        'The evaluation scores are compared to the threshold using one of the following methods:',
        <ul>
          {Object.values(alertTriggerThresholdMetrics).map(({title, doc}) => (
            <li key={title}>{doc}</li>
          ))}
        </ul>,
      ],
    },
  ],
  [NEW_MODEL]: [
    {
      title: 'What is a new model release?',
      contents: [
        'A new model release is when a model gets added to our system.',
        'You can set a trigger to fire on any new model, or specify a filter to only fire on new models from a specific publisher.',
      ],
    },
  ],
  [NEW_EVALUATION]: [
    {
      title: 'When are new evaluations added?',
      contents: [
        <Typography>
          Evaluations can be added by any{' '}
          <ExternalA to={Path.subscriptions.all()} text="Pro or Enterprise" />
          users with the{' '}
          <ExternalA to={Path.evaluations.create()} text="evaluation creation wizard." />
        </Typography>,
        'We also add interesting or common evaluations when they get published.',
      ],
    },
  ],
  [TOP_MODEL]: [
    {
      title: 'What is a top model?',
      contents: [
        'A top model is a model that scores the highest on a given metric, e.g. has the highest score, or is the cheapest.',
        'This trigger will fire whenever the best model gets beaten by a different model',
      ],
    },
    {
      title: 'What are the available metrics?',
      contents: [
        <ul>
          {Object.values(alertTriggerTopMetrics).map(({title, doc}) => (
            <li key={title}>{doc}</li>
          ))}
        </ul>,
      ],
    },
  ],
  [MODEL_CHANGE]: [
    {
      title: 'What kind of model changes will trigger this?',
      contents: [
        'You can select from the following fields:',
        <ul>
          {Object.values(alertTriggerModelChanges).map(({title, doc}) => (
            <li key={title}>{doc}</li>
          ))}
        </ul>,
      ],
    },
  ],
  [MODEL_AVAILABILITY]: [
    {
      title: 'How is availability checked?',
      contents: [
        'Around every 20 minutes, we check all models that have opted in for availability checks to see if they respond to requests.',
        'An available model is one for which the check was successful, where a successful check is one where the model responds with ' +
          'a proper answer, or where it returns a permissions or rate limit error - as that should mean that the model is working.',
      ],
    },
    {
      title: 'What causes availability triggers to fire?',
      contents: [
        'Model availability triggers can fire when a model enters one of the following states:',
        <ul>
          {Object.values(alertTriggerAvailabilityMetrics).map(({title, doc}) => (
            <li key={title}>{doc}</li>
          ))}
        </ul>,
        'Apart from the "Is unavailable" trigger type, a model becoming unavailable or available will only trigger a single ' +
          'alert - you will get notified that the model is down or back up, and then nothing more until that changes. If you ' +
          'want to keep getting alerts until the model is back up, choose the "Is available" trigger type.',
      ],
    },
  ],
  [MODEL_STABILITY]: [
    {
      title: 'What is model stability?',
      contents: [
        'Around every 20 minutes, we check all models that have opted in for availability checks to see if they respond to requests.',
        'We calculate stability as the percentage of checks that were successful, where a successful check is one where the model responds with ' +
          'a proper answer, or where it returns a permissions or rate limit error - as that should mean that the model is working.',
      ],
    },
    {
      title: 'What is the stability compared to?',
      contents: [
        'Model stability triggers are defined by a threshold and a metric, where the metric is one of the following:',
        <ul>
          {Object.values(alertTriggerStabilityMetrics).map(({title, doc}) => (
            <li key={title}>{doc}</li>
          ))}
        </ul>,
      ],
    },
  ],
}

const triggerDocs = (triggerType?: TriggerTypes) => [
  ...documentationItems.baseTriggerDocs,
  ...(documentationItems[triggerType as keyof typeof documentationItems] || []),
]

type AlertWizardProps = {
  initial?: AlertType
  onSave: (e: AlertType) => Promise<string | null>
  onCancel?: () => void
  basic?: boolean
  triggers?: boolean
  notifications?: boolean
  title: string
}
const AlertWizard = ({
  initial,
  onSave,
  onCancel,
  basic,
  triggers,
  notifications,
  title,
}: AlertWizardProps) => {
  const [alert, setAlert] = useState(initial || ({} as unknown as AlertType))

  const onChange = (field: string, val: any) => setAlert((e) => ({...e, [field]: val}))
  const onSubmit = async () => onSave(alert)

  const props = {alert, onChange}
  const steps = [
    basic && {
      label: 'Basic information',
      Step: BasicSettings,
      documentation: documentationItems.settings,
      extraProps: props,
    },
    notifications && {
      label: 'How should you be alerted?',
      Step: NotificationMethods,
      documentation: documentationItems.notifications,
      extraProps: props,
    },
    triggers && {
      label: 'What should trigger your Alert?',
      Step: ChooseTrigger,
      documentation: triggerDocs((alert.triggers && alert.triggers[0])?.type),
      extraProps: {
        ...props,
        trigger: alert.triggers && alert.triggers[0],
        triggerChanged: (t: Trigger) => onChange('triggers', [t]),
      },
    },
  ].filter(Boolean) as StepProps<any>[]
  if (steps.length) {
    return <Wizard title={title} onFinish={onSubmit} onCancel={onCancel} steps={steps} />
  }
  return null
}

export default AlertWizard
