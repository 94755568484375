import React from 'react'
import {useNavigate} from 'react-router-dom'
import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import Page from 'components/Page'
import useUser from 'hooks/useUser'
import ModelWizard from 'components/models/ModelWizard'
import type {Model as ModelType} from '@equistamp/types'
import {CREATE_MODEL} from 'permissions'
import Path from 'routeLinks'

const CreateModel: React.FC = () => {
  const {user, hasPermission} = useUser()
  const navigate = useNavigate()

  const saveModel = async (a: ModelType) => {
    try {
      const {id} = await makeApi().models.create({...a, owner: user || undefined})
      navigate(Path.models.show(id))
      return null
    } catch (e) {
      console.error(e)
      return 'Error while creating model'
    }
  }

  return (
    <Page title="Create model" showTitle>
      <Stack direction="column" spacing={7} sx={{m: 5}}>
        {user && hasPermission(CREATE_MODEL) && (
          <ModelWizard basic limitations endpointConfig onSave={saveModel} />
        )}
      </Stack>
    </Page>
  )
}

export default CreateModel
