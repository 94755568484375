import {useState, useEffect} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Input} from '../../../components/forms/inputs'
import {isInvalidURL} from '../../../components/forms/validators'
import type {ModelStageProps} from './types'

const validator = (url: string | undefined) => (url ? isInvalidURL(url) : 'A valid URL is required')

const EndpointUrl = ({endpoint, onEndpointUpdate, setNextHandler, index}: ModelStageProps) => {
  const [validate, setValidate] = useState(false)
  const [url, setUrl] = useState(endpoint?.url)

  useEffect(() => {
    const checkCorrect = () => {
      const valid = !validator(url)
      setValidate(!valid)
      return valid
    }

    setNextHandler(checkCorrect)
  }, [index, url, setNextHandler])

  return (
    <Box>
      <Typography>
        For your model to be tested, we need to know how to send tasks to it. We assume that your
        model has a HTTP endpoint that can be queried with one task per request. The following
        fields are used to configure those requests. This section is confidential - the only time it
        will be displayed is if you want to edit it at a later time.
      </Typography>

      <Input
        label="Endpoint URL"
        value={url || ''}
        validate={validate}
        validator={validator}
        setter={(val: string) => {
          setUrl(val)
          onEndpointUpdate('url', val)
        }}
        sx={{mt: 5, width: '100%'}}
      />
    </Box>
  )
}

export default EndpointUrl
