import Stack from '@mui/material/Stack'
import {makeApi} from '@equistamp/api'
import useUser from 'hooks/useUser'
import UserAvatar from './UserAvatar'
import UserInfo from './UserInfo'
import UserActions from './UserActions'
import type {UserEditProps} from './types'
import {ChangePasswordWidget} from 'components/forms/ChangePasswordForm'
import {RedirectButton, NavButton} from 'components/forms/inputs'
import {User as UserType} from '@equistamp/types'
import Path from 'routeLinks'

export const ManageSubscriptions = ({sx}: {sx?: {[k: string]: any}}) => {
  const {user} = useUser()

  const manageSubscriptions = async () =>
    user && (await makeApi().users.manageSubscription(user, 'evaluations')).manage_subscription_url
  return <RedirectButton label="Manage payments" urlGetter={manageSubscriptions} sx={sx} />
}

export const BuyCredits = ({sx}: {sx?: {[k: string]: any}}) => {
  const getCreditsLink = async () => (await makeApi().users.buyCredits()).checkout_url
  return <RedirectButton label="Buy more credits" urlGetter={getCreditsLink} sx={{width: 250}} />
}

const SubscriptionLevel = ({user, sx}: {user: UserType; sx?: {[k: string]: any}}) => {
  const getSubscriptionUrl = async () =>
    (await makeApi().users.manageSubscription(user)).manage_subscription_url

  if (user.subscription_level === 'admin') {
    return null
  } else if (!user.subscription_level || user.subscription_level === 'free') {
    return <NavButton label="Subscription Level: Basic" to={Path.subscriptions.all()} sx={sx} />
  } else {
    return (
      <RedirectButton
        label={'Subscription Level: ' + user.subscription_level}
        urlGetter={getSubscriptionUrl}
        sx={sx}
      />
    )
  }
}

const UserProfile = ({user, isCurrentUser, onChange}: UserEditProps) => {
  return (
    <Stack
      direction={{sm: 'column', md: 'row'}}
      spacing={8}
      sx={{mt: 5}}
      justifyContent="space-around"
      alignItems="center"
    >
      <Stack direction="column" spacing={3} alignItems="center">
        <UserAvatar user={user} onChange={onChange} isCurrentUser={isCurrentUser} />
        {isCurrentUser && <ChangePasswordWidget user={user} sx={{width: 250}} />}
        {isCurrentUser && <SubscriptionLevel user={user} sx={{width: 250}} />}
        {isCurrentUser && <ManageSubscriptions sx={{width: 250}} />}
        {isCurrentUser && <BuyCredits sx={{width: 250}} />}
      </Stack>
      <Stack direction="column" spacing={8}>
        <UserInfo user={user} onChange={isCurrentUser ? onChange : undefined} />
      </Stack>
      {isCurrentUser && <UserActions user={user} onChange={onChange} />}
    </Stack>
  )
}

export default UserProfile
