import type {User} from '@equistamp/types'

export const ADMIN = 'admin'
export const CREATE_ALERT = 'createAlert'
export const CREATE_EVALUATION = 'createEvaluation'
export const CREATE_MODEL = 'createModel'
export const CREATE_TASK = 'createTask'
export const CREATE_SCHEMA = 'createSchema'
export const CONNECT_MODELS = 'connectModels'
export const VIEW_RUNS = 'viewRuns'
export const VIEW_REPORTS = 'viewReports'

export const PRIVATE_MODEL = 'privateModel'
export const PRIVATE_EVALUATION = 'privateEvaluation'
export const USABLE_EVALUATION = 'usableEvaluation'
export const PRIVATE_REPORTS = 'privateReports'
export const PUBLIC_ALERT = 'publicAlert'

export const ALERT_COOLDOWN = 'alertCooldown'

const EVALUATION_PERMISSIONS = [
  CREATE_EVALUATION,
  CREATE_TASK,
  CREATE_SCHEMA,
  USABLE_EVALUATION,
  PRIVATE_REPORTS,
]
const BASE_PERMISSIONS = [CONNECT_MODELS, VIEW_RUNS, VIEW_REPORTS, CREATE_ALERT]
const SUBSCRIPTION_LEVELS = {
  free: BASE_PERMISSIONS,
  pro: [CREATE_MODEL, PUBLIC_ALERT, ...BASE_PERMISSIONS, ...EVALUATION_PERMISSIONS],
  enterprise: [
    CREATE_MODEL,
    PUBLIC_ALERT,
    PRIVATE_MODEL,
    PRIVATE_EVALUATION,
    ...BASE_PERMISSIONS,
    ...EVALUATION_PERMISSIONS,
  ],
}

export type Permission =
  | typeof CREATE_MODEL
  | typeof CREATE_ALERT
  | typeof CREATE_EVALUATION
  | typeof CREATE_TASK
  | typeof CREATE_SCHEMA
  | typeof CONNECT_MODELS
  | typeof VIEW_RUNS
  | typeof VIEW_REPORTS
  | typeof PRIVATE_MODEL
  | typeof PRIVATE_EVALUATION
  | typeof PRIVATE_REPORTS
  | typeof USABLE_EVALUATION
  | typeof PUBLIC_ALERT
  | typeof ADMIN
  | typeof ALERT_COOLDOWN

export const hasPermission = (user: User | undefined | null, permission: Permission) => {
  if (!user?.subscription_level) return false
  if (user?.subscription_level === 'admin') return true
  return !!SUBSCRIPTION_LEVELS[user.subscription_level]?.includes(permission)
}
